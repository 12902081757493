import { create } from 'zustand';

interface IAuthStore {
	firstTimeUsername: string;
	setFirstTimeUsername: (username: string) => void;
}

export const useAuthStore = create<IAuthStore>((set) => ({
	firstTimeUsername: '',
	setFirstTimeUsername: (username) => set(() => ({ firstTimeUsername: username })),
}));
