import Icon from '@mobe/components/icon/Icon';
import { Switch } from '@mobe/components/switch';
import Text from '@mobe/components/text/Text';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

export interface ISettingsItemProps {
	type: 'switch' | 'button' | 'radio';
	label: string;
	onPress: () => void;
	value?: boolean;
	disabled?: boolean;
}

export default function SettingsItem({
	label,
	type,
	value,
	onPress,
	disabled = false,
}: ISettingsItemProps) {
	const styleRules = useStyleRules();

	if (type === 'switch') {
		return (
			<Pressable role="switch" aria-checked={value} disabled={disabled} onPress={onPress}>
				<View style={styles.container}>
					<Text style={styles.containerLabel}>{label}</Text>
					<Switch value={value} disabled={disabled} onValueChange={onPress} />
				</View>
			</Pressable>
		);
	}

	if (type === 'button') {
		return (
			<Pressable role="button" disabled={disabled} onPress={onPress}>
				<View style={styles.container}>
					<Text style={styles.containerLabel}>{label}</Text>
					<Icon name="right" size={20} />
				</View>
			</Pressable>
		);
	}

	if (type === 'radio') {
		return (
			<Pressable role="radio" aria-checked={value} disabled={disabled} onPress={onPress}>
				<View style={styles.container}>
					<Text style={styles.containerLabel}>{label}</Text>
					{value ? (
						<Icon name="checkmark" size={15 * styleRules.settings.fontScale} color="primary" />
					) : null}
				</View>
			</Pressable>
		);
	}

	return null;
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		minHeight: 62,
		paddingVertical: 8,
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	containerLabel: {
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 0,
	},
});
