import { useIsAuthenticated } from '@mobe/api/authentication/authApiHooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getBlobUrl, getPDFData } from './fileService';

export function useGetPDFDataQuery(url: string) {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: ['getPDFData', url],
		queryFn: () => getPDFData(url),
		enabled: isAuthenticated,
		refetchOnWindowFocus: false,
	});
}

export function useGetBlobUrlMutation() {
	return useMutation({
		mutationFn: getBlobUrl,
	});
}
