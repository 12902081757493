import { useAccessibilityFocus } from '@mobe/utils/a11y';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useCalendarTheme from '@mobe/utils/styles/useCalendarTheme';
import { addMinutes, format, isSameDay } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, View, ViewStyle } from 'react-native';
import { Calendar, DateData } from 'react-native-calendars';
import { MarkingProps } from 'react-native-calendars/src/calendar/day/marking';
import { Direction } from 'react-native-calendars/src/types';
import Icon from '../icon/Icon';
import Popup from '../popup/Popup';
import InputText, { IInputTextProps } from './InputText';

const US_DATE_FORMAT = 'MM/dd/yyyy';
const DATE_KEY_FORMAT = 'yyyy-MM-dd';

interface IInputCalendarProps extends Omit<IInputTextProps, 'value'> {
	value: Date;
	onChangeDate: (date: Date) => void;
	popupLabel?: string;
}

function InputCalendar(
	{ value, onChangeDate, popupLabel, ...inputTextProps }: IInputCalendarProps,
	ref: any
) {
	const { t } = useTranslation();
	const styleRules = useStyleRules();
	const calendarTheme = useCalendarTheme();
	const [popupIsOpen, setPopupIsOpen] = React.useState(false);
	const [inputFocusRef, setFocusToInput] = useAccessibilityFocus();

	const popupTitle = popupLabel !== undefined ? popupLabel : inputTextProps.label;

	const calendarButtonStyles: ViewStyle = {
		height: '100%',
		paddingHorizontal: 12,
		backgroundColor: styleRules.colors.primary,
		alignContent: 'center',
		justifyContent: 'center',
		borderTopRightRadius: styleRules.borderRadius - 1,
		borderBottomRightRadius: styleRules.borderRadius - 1,
	};

	const markedDates: Record<string, MarkingProps> = {
		[format(value, DATE_KEY_FORMAT)]: {
			selected: true,
			disabled: false,
			selectedColor: styleRules.colors.primary,
		},
	};

	function closePopup() {
		setPopupIsOpen(false);
		setFocusToInput();
	}

	function handleCalendarOpenPress() {
		setPopupIsOpen(true);
	}

	function handleCalendarClosePress() {
		closePopup();
	}

	function handleDayPress(day: DateData) {
		const now = new Date(Date.now());

		// Date string to date results in time of `00:00.00.000`
		const date = new Date(day.dateString);

		// Set time to noon of selected day in current timezone
		const dateWithLocalTime = addMinutes(date, date.getTimezoneOffset() + 60 * 12);

		// Time zone offset applied to date to ensure that date is correct relative to device clock
		onChangeDate(isSameDay(dateWithLocalTime, now) ? now : dateWithLocalTime);

		// Slight delay so that user can see that their selection was applied
		setTimeout(() => {
			closePopup();
		}, 300);
	}

	/**
	 * Android and iOS seem to handle inputs within pressable elements quite differently.
	 * This implementation works for both scenarios, otherwise one OS or the other will drop
	 * interactions on input press.
	 */
	function renderPressableWrapper(children: React.ReactNode) {
		const label = `${inputTextProps.label}, ${format(value, US_DATE_FORMAT)}, ${t(
			'calendar.input.buttonLabel'
		)}`;

		return (
			<Pressable aria-label={label} ref={inputFocusRef} onPress={handleCalendarOpenPress}>
				<View style={{ pointerEvents: 'none' }}>{children}</View>
			</Pressable>
		);
	}

	return (
		<>
			{renderPressableWrapper(
				<InputText
					ref={ref}
					type="date"
					inputRightContainerStyle={{ paddingRight: 0, height: '100%' }}
					readOnly={true}
					value={format(value, US_DATE_FORMAT)}
					inputRight={() => (
						<View style={calendarButtonStyles}>
							<Icon color="textInverted" name="schedule" />
						</View>
					)}
					{...inputTextProps}
				/>
			)}
			{popupIsOpen && (
				<Popup heading={popupTitle} onClosePress={handleCalendarClosePress}>
					<Calendar
						current={format(value, DATE_KEY_FORMAT)}
						markedDates={markedDates}
						maxDate={format(new Date(Date.now()), DATE_KEY_FORMAT)}
						onDayPress={handleDayPress}
						monthFormat={'MMMM yyyy'}
						hideExtraDays={true}
						renderArrow={(direction: Direction) => (
							<Icon
								name={direction}
								color="primary"
								aria-label={
									Platform.OS !== 'ios'
										? direction === 'left'
											? t('calendar.accessibilityLabels.previousMonth')
											: t('calendar.accessibilityLabels.nextMonth')
										: undefined
								}
							/>
						)}
						theme={calendarTheme}
					/>
				</Popup>
			)}
		</>
	);
}

export default React.forwardRef(InputCalendar);
