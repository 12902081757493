import env from '@mobe/env/env';
import AsyncStorage, { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const ASYNC_STORAGE_KEY = 'asyncStorage';

export function useAsyncStorageQuery<StoredValueType>(
	key: string,
	firstTimeQueryDefaultValue?: StoredValueType
) {
	const asyncStorage = useAsyncStorage(key);

	return useQuery({
		queryKey: [ASYNC_STORAGE_KEY, key],
		queryFn: async () => {
			const value = await asyncStorage.getItem();

			if (value) {
				return JSON.parse(value) as StoredValueType;
			}

			if (firstTimeQueryDefaultValue !== undefined) {
				void asyncStorage.setItem(JSON.stringify(firstTimeQueryDefaultValue));
				return firstTimeQueryDefaultValue;
			}
		},
		staleTime: Infinity,
		placeholderData: keepPreviousData,
	});
}

export function useAsyncStorageMutation<NewValueType>(key: string) {
	const queryClient = useQueryClient();
	const asyncStorage = useAsyncStorage(key);

	return useMutation<void, unknown, NewValueType>({
		mutationFn: async (newValue) => {
			return await asyncStorage.setItem(JSON.stringify(newValue));
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [ASYNC_STORAGE_KEY, key] });
		},
	});
}

export function useRemoveFromAsyncStorage() {
	const queryClient = useQueryClient();

	return (queryKeys: string[] = []) => {
		queryKeys.forEach(async (key) => {
			if (env.isDev) {
				console.warn(`Removing: ${key} from async storage`);
			}

			await AsyncStorage.removeItem(key);
			queryClient.invalidateQueries({ queryKey: [ASYNC_STORAGE_KEY, key] });

			if (env.isDev) {
				console.info(`Removed: ${key} from async storage`);
			}
		});
	};
}
