import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import { ExploreQueryKeys } from './exploreQueryKeys';
import {
	IExploreContentItemFull,
	getAllContent,
	getAllFeaturedSharedContent,
	getContentDetail,
	getContentModules,
	setFavoriteStatus,
	setHelpfulStatus,
	setViewTime,
} from './exploreService';

export function useAllContentQuery() {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [ExploreQueryKeys.AllContent],
		queryFn: getAllContent,
		enabled: isAuthenticated,
		staleTime: 30 * 1000,
	});
}

export function useAllFeaturedSharedContentQuery() {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [ExploreQueryKeys.AllFeaturedSharedContent],
		queryFn: getAllFeaturedSharedContent,
		enabled: isAuthenticated,
		staleTime: Infinity,
		select: (data) => {
			return {
				welcomeVideo: data[0] || null,
				trackingProgressArticle: data[1] || null,
				featuredContent: data.slice(2),
			};
		},
	});
}

export function useSetFavoriteStatusMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: setFavoriteStatus,
		onMutate: async ({ sharedContentId, isFavorite }) => {
			await queryClient.cancelQueries({ queryKey: [ExploreQueryKeys.ContentDetail] });

			const previousContent = queryClient.getQueryData<IExploreContentItemFull>([
				ExploreQueryKeys.ContentDetail,
				sharedContentId,
			]);

			if (previousContent) {
				queryClient.setQueryData<IExploreContentItemFull>(
					[ExploreQueryKeys.ContentDetail, sharedContentId],
					{
						...previousContent,
						isFavorite: isFavorite,
					}
				);
			}

			return { previousContent };
		},
		onError: (error, args, context) => {
			if (context?.previousContent) {
				queryClient.setQueryData<IExploreContentItemFull>(
					[ExploreQueryKeys.ContentDetail, args.sharedContentId],
					{
						...context.previousContent,
						isFavorite: !args.isFavorite,
					}
				);
			}
		},
		// Always refetch after error or success
		onSettled: (data, error, args) => {
			// To update favorite icons that appear on content
			queryClient.invalidateQueries({ queryKey: [ExploreQueryKeys.AllContent] });

			// To update favorite icon on detail screen (for Explore and Activities)
			queryClient.invalidateQueries({
				queryKey: [ExploreQueryKeys.ContentDetail, args.sharedContentId],
			});

			// To update favorite icons on Featured Shared Content
			queryClient.invalidateQueries({
				queryKey: [ExploreQueryKeys.AllFeaturedSharedContent],
				refetchType: 'none',
			});
		},
	});
}

export function useSetIsHelpfulStatusMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: setHelpfulStatus,
		onMutate: async ({ sharedContentId, isHelpful }) => {
			await queryClient.cancelQueries({ queryKey: [ExploreQueryKeys.ContentDetail] });

			const previousContent = queryClient.getQueryData<IExploreContentItemFull>([
				ExploreQueryKeys.ContentDetail,
				sharedContentId,
			]);

			if (previousContent) {
				const optimisticUpdate = { ...previousContent };

				queryClient.setQueryData<IExploreContentItemFull>(
					[ExploreQueryKeys.ContentDetail, sharedContentId],
					{
						...optimisticUpdate,
						isHelpful,
						helpfulCount: isHelpful
							? optimisticUpdate.helpfulCount + 1
							: optimisticUpdate.helpfulCount - 1,
					}
				);
			}
		},
		onSettled: (data, err, args) => {
			queryClient.invalidateQueries({
				queryKey: [ExploreQueryKeys.ContentDetail, args.sharedContentId],
			});
		},
	});
}

export function useContentDetailQuery(sharedContentId: number | null) {
	return useQuery({
		queryKey: [ExploreQueryKeys.ContentDetail, sharedContentId],
		queryFn: async () => {
			// This can't actually occur, but TS doesn't now that. Query isn't enabled until sharedContentId isn't null
			if (sharedContentId === null) {
				throw new Error('Shared content ID can not be null');
			}

			return await getContentDetail(sharedContentId);
		},
		retry: false,
		enabled: sharedContentId !== null,
		refetchOnWindowFocus: false,
	});
}

export function useSetViewTimeMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: setViewTime,
		onSuccess: () => {
			// To update viewed icon on all explore content
			queryClient.invalidateQueries({ queryKey: [ExploreQueryKeys.AllContent] });

			// To update viewed icon on Featured Shared Content
			queryClient.invalidateQueries({
				queryKey: [ExploreQueryKeys.AllFeaturedSharedContent],
				refetchType: 'none',
			});
		},
	});
}

export function useContentModulesQuery() {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [ExploreQueryKeys.ContentModules],
		queryFn: getContentModules,
		enabled: isAuthenticated,
		staleTime: 30 * 1000,
	});
}
