import {
	IAppointment,
	useDeleteAppointmentMutation,
} from '@mobe/api/appointments/appointmentApiHooks';
import { Button } from '@mobe/components/button';
import Heading from '@mobe/components/heading/Heading';
import Icon from '@mobe/components/icon/Icon';
import { InputText, useControlledInputProps } from '@mobe/components/input';
import ButtonRow from '@mobe/components/layout/ButtonRow';
import Row from '@mobe/components/layout/Row';
import ProfileHeader from '@mobe/components/profileHeader/ProfileHeader';
import { Text } from '@mobe/components/text';
import TextButton from '@mobe/components/textButton/TextButton';
import useCancelCallToast from '@mobe/components/toast/useCancelCallToast';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { addMinutes, format, isAfter } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { GuideAppointmentDetailScreenNavigationProp } from '../../navigation/modal/types';
import * as AppointmentsAnalyticsEvents from '../analyticsEvents';
import ApptConfirmationBox from '../components/ApptConfirmationBox';
import useAddToCalendar from '../useAddToCalendar';
import useConfirmCancelAlert from '../useConfirmCancelAlert';
import useDeleteFromCalendar from '../useDeleteFromCalendar';
import useAppointmentPhoneNumberController from './appointmentPhoneNumberController';

interface IGuideAppointmentDetailViewProps {
	appointment: IAppointment;
	navigation: GuideAppointmentDetailScreenNavigationProp;
}

export default function GuideAppointmentDetailView({
	appointment,
	navigation,
}: IGuideAppointmentDetailViewProps) {
	const { vr, vrTop, wrapperHorizontal } = useStyleHelpers();
	const { t } = useTranslation();
	const styles = useStyles();
	const confirmCancelAlert = useConfirmCancelAlert();
	const genericErrorAlert = useGenericErrorAlert();
	const cancelCallToast = useCancelCallToast();
	const deleteAppointmentMutation = useDeleteAppointmentMutation();
	const apptDate = new Date(appointment.appointmentStartDate || Date.now());
	const apptDurationInMinutes = appointment.durationInMinutes || 0;
	const apptId = appointment.confirmationId;
	const deleteFromCalendar = useDeleteFromCalendar(appointment, appointment.preferredName || '');

	const shouldShowRescheduleButton = isAfter(apptDate, new Date(Date.now()));

	const phoneNumberUpdateController = useAppointmentPhoneNumberController({ appointment });

	const phoneNumberProps = useControlledInputProps({
		name: 'phoneNumber',
		control: phoneNumberUpdateController.form.control,
	});

	let calendarEventTitle = '';
	if (appointment.isGuide) {
		calendarEventTitle = t('appointments.appointmentCard.guide.title', {
			guideName: appointment.preferredName,
		});
	}
	if (appointment.isPharmacist) {
		calendarEventTitle = t('appointments.appointmentCard.pharmacist.title', {
			pharmacistName: appointment.preferredName,
		});
	}

	const addToCalendar = useAddToCalendar({
		startDate: apptDate,
		duration: apptDurationInMinutes,
		title: calendarEventTitle,
	});

	if (!appointment.appointmentStartDate) {
		return null;
	}

	function handleReschedulePress() {
		AppointmentsAnalyticsEvents.appointmentInitiateReschedulePress();

		navigation.navigate('APPOINTMENT_RESCHEDULE_SCREEN', {
			confirmationId: appointment.confirmationId,
		});
	}

	function handleCancelPress() {
		AppointmentsAnalyticsEvents.appointmentCancelPress();

		confirmCancelAlert({
			apptDate,
			guideName: appointment.preferredName || '',
			onConfirm: async () => {
				deleteFromCalendar();
				deleteAppointmentMutation.mutate(
					{ confirmationId: appointment.confirmationId },
					{
						onSuccess: () => {
							navigation.popToTop();
							cancelCallToast();
						},
						onError: () => genericErrorAlert(navigation.popToTop),
					}
				);
			},
		});
	}

	return (
		<>
			<ProfileHeader
				headerColor={appointment.isPharmacist ? 'tertiary' : 'primary'}
				imageSrc={appointment.avatarUrl ? { uri: appointment.avatarUrl } : undefined}
				style={vr(5)}
			/>
			<View style={[wrapperHorizontal, vr(10)]}>
				<Heading level="h3" accessibilityAutoFocus style={vr(3)}>
					{t('appointments.appointmentDetail.heading', { guideName: appointment.preferredName })}
				</Heading>

				{appointment.appointmentStartDate && (
					<View style={vr(6)}>
						<Text>{format(new Date(appointment.appointmentStartDate), 'EEEE, MMMM d')}</Text>
						<Text style={vr(2)}>
							{format(new Date(appointment.appointmentStartDate), 'p')} –{' '}
							{format(
								addMinutes(
									new Date(appointment.appointmentStartDate),
									appointment.durationInMinutes
								),
								'p'
							)}
						</Text>
						{Platform.OS !== 'web' && (
							<Pressable
								style={[vrTop(2), { minHeight: 30 }]}
								onPress={() => addToCalendar.addEventToCalendar()}
							>
								{addToCalendar.status === 'hasExistingEvent' ||
								addToCalendar.status === 'newEventAdded' ? (
									<Row gutterSize={6}>
										<Row.Item>
											<Icon size={15} name="checkmark" color="successDark" />
										</Row.Item>
										<Row.Item>
											<Text color="successDark" weight="bold">
												{t('appointments.appointmentCard.addedToCalendar')}
											</Text>
										</Row.Item>
									</Row>
								) : null}
								{addToCalendar.status === 'noExistingEvent' ||
								addToCalendar.status === 'noPermissions' ? (
									<Row gutterSize={6}>
										<Row.Item>
											<Icon size={15} name="add" color="primary" />
										</Row.Item>
										<Row.Item>
											<Text color="primary" weight="bold">
												{t('appointments.appointmentCard.addToCalendar')}
											</Text>
										</Row.Item>
									</Row>
								) : null}
							</Pressable>
						)}
					</View>
				)}

				{appointment.phoneNumber && (
					<InputText
						label={t('appointments.appointmentDetail.phoneNumberInputLabel')}
						type="phone"
						inputRight={() =>
							!phoneNumberUpdateController.isInUpdateMode ? (
								<TextButton
									title="Update"
									onPress={phoneNumberUpdateController.startPhoneNumberUpdate}
								/>
							) : (
								<TextButton
									title="Save"
									onPress={phoneNumberUpdateController.handleSubmitPress}
									loading={phoneNumberUpdateController.updateAppointmentMutation.isPending}
								/>
							)
						}
						disabled={!phoneNumberUpdateController.isInUpdateMode}
						enterKeyHint="next"
						errorMessage={phoneNumberUpdateController.form.formState.errors.phoneNumber?.message}
						{...phoneNumberProps}
					/>
				)}
			</View>

			<ApptConfirmationBox
				confirmationId={apptId}
				containerStyle={styles.appointmentConfirmationBox}
			>
				{shouldShowRescheduleButton && (
					<ButtonRow>
						<Button
							variant="secondary"
							size="sm"
							title={t('appointments.appointmentDetail.cancelButton')}
							onPress={handleCancelPress}
							loading={deleteAppointmentMutation.isPending}
							style={{ width: '100%' }}
						/>
						<Button
							variant="secondary"
							size="sm"
							title={t('appointments.appointmentDetail.rescheduleButton')}
							onPress={handleReschedulePress}
							style={{ width: '100%' }}
						/>
					</ButtonRow>
				)}
			</ApptConfirmationBox>
		</>
	);
}
const useStyles = () => {
	const { bottom: bottomSafeInset } = useSafeAreaInsets();

	const styles = StyleSheet.create({
		appointmentConfirmationBox: {
			marginTop: 'auto',
			width: '100%',
			paddingBottom: Math.max(bottomSafeInset, 20),
		},
	});
	return styles;
};
