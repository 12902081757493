import { GoalStatus, GoalType } from '@mobe/api/goals/goalsService';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useColorScheme from '@mobe/utils/useColorScheme';
import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface IGoalCardGraphicProps extends SvgProps {
	goalType: GoalType;
	goalStatus: GoalStatus;
}

export default function GoalCardGraphic({
	goalType = 'LongTerm',
	goalStatus = 'InProgress',
	...props
}: IGoalCardGraphicProps) {
	const colorScheme = useColorScheme();
	const { colors } = useStyleRules();
	const lightSchemeForegroundWaveColor = goalStatus === 'InProgress' ? '#81C9F2' : '#EDEDED';
	const lightSchemeBackgroundWaveColor = goalStatus === 'InProgress' ? '#C7E5F6' : '#F6F6F6';
	const darkSchemeForegroundWaveColor =
		goalStatus === 'InProgress' ? colors.backgroundPrimary : '#333333';
	const darkSchemeBackgroundWaveColor = goalStatus === 'InProgress' ? '#22303A' : '#444444';
	const opacity = goalType === 'LongTerm' ? 1 : 0.5;

	return (
		<Svg
			width="100%"
			height={54}
			viewBox="0 0 184 58"
			preserveAspectRatio="none"
			fill="none"
			style={{ opacity }}
			{...props}
		>
			{goalType === 'LongTerm' && (
				<Path
					fill={
						colorScheme === 'light' ? lightSchemeBackgroundWaveColor : darkSchemeBackgroundWaveColor
					}
					d="M51.5 21.088C34.213 9.563 16.262 4.049 0 1.998V58h184V38.169c-5.692.688-11.236 1.821-16.5 3.417C142 51 92.3 48.288 51.5 21.088z"
				/>
			)}
			<Path
				fill={
					colorScheme === 'light' ? lightSchemeForegroundWaveColor : darkSchemeForegroundWaveColor
				}
				d="M117 21.088C76.2 48.288 26.5 51 1 41.586c-.331-.1-.667-.194-1-.291V58h184V1.002c-19.804.033-43.995 4.749-67 20.086z"
			/>
		</Svg>
	);
}
