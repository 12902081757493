import { useLogoutMutation } from '@mobe/api/authentication/authApiHooks';
import { usePermissionsQuery } from '@mobe/api/permissions/permissionsApiHooks';
import MobeLogoGraphic from '@mobe/components/graphics/MobeLogoGraphic';
import Icon, { IconNames } from '@mobe/components/icon/Icon';
import IconButton from '@mobe/components/iconButton/IconButton';
import Row from '@mobe/components/layout/Row';
import LoadingOverlay from '@mobe/components/loadingOverlay/LoadingOverlay';
import Text from '@mobe/components/text/Text';
import TextButton from '@mobe/components/textButton/TextButton';
import env from '@mobe/env/env';
import MobeLinking from '@mobe/utils/linking';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useLayout from '@mobe/utils/styles/useLayout';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import { DrawerContentScrollView } from '@react-navigation/drawer';
import { CommonActions, useNavigationState } from '@react-navigation/native';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, View } from 'react-native';
import { BottomTabStackParamList } from '../../../bottomTabs/types';
import { useModalNavigation } from '../../../modal/ModalNavigationProvider';
import { MenuParamList } from '../../types';
import useMenuDrawerStyles from './menuDrawerStyles';

interface INavItem {
	label: string;
	labelTag?: string;
	screen: keyof MenuParamList;
	iconKey: IconNames;
}

interface IWebMainNavItems {
	label: string;
	iconName: IconNames;
	iconNameFocused?: IconNames;
	screen: keyof BottomTabStackParamList;
}

interface IBottomNavItem {
	label: string;
	action: () => void;
}

/**
 * Construct the contents of the main menu drawer.
 * In the future, this could use the `descriptors` prop to build the menu, but the
 * current thought is to keep this drawer responsible for its own content and appearance
 */
export default function MenuDrawer({ closeDrawer = noop }: { closeDrawer?: () => void }) {
	const remoteConfigData = useRemoteConfigData();
	const { notLastChild, vr, constrain } = useStyleHelpers();
	const menuStyles = useMenuDrawerStyles();
	const { t } = useTranslation();
	const modalNavigation = useModalNavigation();
	const logoutMutation = useLogoutMutation();
	const layout = useLayout();
	const isWeb = Platform.OS === 'web';
	const [isSigningOut, setIsSigningOut] = React.useState(false);
	const permissionsQuery = usePermissionsQuery();

	// Logic for tracking the active route for the sake of highlighting the link in the menu
	const [currentRoute, setCurrentRoute] = React.useState('HOME_SCREEN');
	const modalNavigationState = useNavigationState((state) => state);
	const menuNavigationState =
		modalNavigationState.routes[modalNavigationState.routeNames.indexOf('MENU_STACK') || 0].state;
	const bottomTabsNavigationState =
		menuNavigationState?.routes[menuNavigationState.routeNames?.indexOf('BOTTOM_TAB_STACK') || 0]
			.state;

	React.useEffect(() => {
		const routeNames = menuNavigationState?.routes.map((route) => route.name) || [];
		const menuNavRouteName = routeNames[menuNavigationState?.index || 0];
		setCurrentRoute(menuNavRouteName);

		if (menuNavRouteName === 'BOTTOM_TAB_STACK') {
			const routeNames = bottomTabsNavigationState?.routes.map((route) => route.name) || [];
			setCurrentRoute(routeNames[bottomTabsNavigationState?.index || 0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menuNavigationState]);

	const webMainNavItems: IWebMainNavItems[] = [
		{
			label: t('home.screenBottomTabTitle'),
			iconName: 'home',
			screen: 'HOME_SCREEN',
		},
		{
			label: t('track.screenBottomTabTitle'),
			iconName: 'track',
			screen: 'TRACK_SCREEN',
		},
		{
			label: t('plan.screenBottomTabTitle'),
			iconName: 'plan',
			screen: 'PLAN_SCREEN',
		},
		{
			label: t('progress.screenBottomTabTitle'),
			iconName: 'progress',
			screen: 'PROGRESS_SCREEN',
		},
		{
			label: t('explore.screenBottomTabTitle'),
			iconName: 'explore',
			screen: 'EXPLORE_SCREEN',
		},
	];

	const topNavItems: INavItem[] = [
		{
			label: t('menuDrawer.profileLabel'),
			screen: 'PROFILE_SCREEN',
			iconKey: 'profile',
		},
		{
			label: t('menuDrawer.guideProfileLabel'),
			screen: 'MY_GUIDE_SCREEN',
			iconKey: 'guide',
		},
		...(permissionsQuery.data?.hasMedServicesAccess
			? ([
					{
						label: t('menuDrawer.pharmacistProfileLabel'),
						screen: 'MY_PHARMACIST_SCREEN',
						iconKey: 'pharmacist',
					},
			  ] as INavItem[])
			: []),
		{
			label: t('menuDrawer.callsLabel'),
			screen: 'CALLS_SCREEN',
			iconKey: 'schedule',
		},
		{
			label: t('menuDrawer.connectHealthDataLabel'),
			screen: 'CONNECT_HEALTH_DATA_SCREEN',
			iconKey: 'connectHealthData',
		},
		{
			label: t('menuDrawer.settingsLabel'),
			screen: 'SETTINGS_SCREEN',
			iconKey: 'settings',
		},
	];

	const appSurveyButtonConfig: IBottomNavItem = {
		label: remoteConfigData.feedbackSurvey.label || '',
		action: () => {
			if (remoteConfigData.feedbackSurvey.surveyID) {
				modalNavigation.navigate('ASSIGN_SURVEY_SCREEN', {
					surveyId: remoteConfigData.feedbackSurvey.surveyID,
				});
				closeDrawer();
			}
		},
	};

	let bottomNavItems: IBottomNavItem[] = [
		...(remoteConfigData.feedbackSurvey.enabled ? [appSurveyButtonConfig] : []),
		{
			label: t('menuDrawer.termsAndConditionsLabel'),
			action: () => {
				isWeb
					? MobeLinking.openUrl(env.TERMS_AND_CONDITIONS_URL)
					: modalNavigation.navigate('TERMS_AND_CONDITIONS_MODAL_SCREEN', {
							view: 'termsAndConditions',
					  });
			},
		},
		{
			label: t('menuDrawer.privacyPolicyLabel'),
			action: () => {
				isWeb
					? MobeLinking.openUrl(env.PRIVACY_POLICY_URL)
					: modalNavigation.navigate('TERMS_AND_CONDITIONS_MODAL_SCREEN', {
							view: 'privacyPolicy',
					  });
			},
		},
		{
			label: t('menuDrawer.logoutLabel'),
			action: () => {
				setIsSigningOut(true);
				logoutMutation.mutate({});
			},
		},
	];

	// Dev only bottom nav items
	if (env.isDev) {
		bottomNavItems = [
			// Access dev tools screen
			{
				label: 'Dev Tools',
				action: () => {
					modalNavigation.navigate('MENU_STACK', { screen: 'DEV_TOOLS_SCREEN' });
				},
			},
			...bottomNavItems,
		];
	}

	return (
		<DrawerContentScrollView
			role="navigation"
			aria-label={
				Platform.OS === 'web'
					? layout.isWebDesktop
						? t('accessibility.primaryNavigationLabel')
						: t('accessibility.secondaryNavigationLabel')
					: undefined
			}
			style={menuStyles.scrollView}
			contentContainerStyle={menuStyles.contentContainer}
		>
			<View style={vr(10)}>
				{!layout.isWebDesktop && (
					<View style={[menuStyles.closeButton, vr(2)]}>
						<IconButton
							aria-label={t('accessibility.closeButtonLabel')}
							hitSlop={20}
							name="close"
							onPress={() => closeDrawer()}
						/>
					</View>
				)}
				{layout.isWebDesktop && (
					<View style={vr(5)}>
						<View style={[constrain(120), vr(10), { alignSelf: 'flex-start' }]}>
							<Pressable
								role="button"
								aria-label="MOBE"
								onPress={() => modalNavigation.dispatch(CommonActions.navigate('HOME_SCREEN'))}
							>
								<MobeLogoGraphic />
							</Pressable>
						</View>
						{webMainNavItems.map((webNavItem) => (
							<Pressable
								key={webNavItem.label}
								role="button"
								style={menuStyles.drawerItemMainNav}
								onPress={() => modalNavigation.dispatch(CommonActions.navigate(webNavItem.screen))}
							>
								<Row key={webNavItem.label}>
									<Row.Item>
										<View style={{ alignItems: 'center', width: 30 }}>
											<Icon
												size={25}
												color={webNavItem.screen === currentRoute ? 'primary' : 'textLight'}
												name={
													webNavItem.screen === currentRoute && webNavItem.iconNameFocused
														? webNavItem.iconNameFocused
														: webNavItem.iconName
												}
											/>
										</View>
									</Row.Item>
									<Row.Item>
										<Text
											size="lg"
											weight="medium"
											color={webNavItem.screen === currentRoute ? 'primary' : 'regular'}
										>
											{webNavItem.label}
										</Text>
									</Row.Item>
								</Row>
							</Pressable>
						))}
					</View>
				)}
				{topNavItems.map((navItem) => (
					<Pressable
						key={navItem.label}
						aria-label={navItem.label}
						role="button"
						onPress={() => modalNavigation.dispatch(CommonActions.navigate(navItem.screen))}
						style={menuStyles.drawerItemTop}
					>
						<Row wrap importantForAccessibility="no-hide-descendants">
							<Row.Item>
								<Icon
									name={navItem.iconKey}
									color={navItem.screen === currentRoute ? 'primary' : 'textLight'}
								/>
							</Row.Item>
							<Row.Item>
								<Text
									size={layout.isWebDesktop ? 'md' : 'lg'}
									weight={layout.isWebDesktop ? 'regular' : 'semiBold'}
									color={navItem.screen === currentRoute ? 'primary' : 'light'}
								>
									{navItem.label}
								</Text>
							</Row.Item>
							{Boolean(navItem.labelTag) && (
								<Row.Item>
									<View style={menuStyles.labelTag}>
										<Text weight="semiBold" size="sm" color="inverted">
											{navItem.labelTag}
										</Text>
									</View>
								</Row.Item>
							)}
						</Row>
					</Pressable>
				))}
			</View>
			<View>
				{bottomNavItems.map((navItem, index, array) => (
					<TextButton
						key={navItem.label}
						title={navItem.label}
						onPress={() => navItem.action()}
						style={notLastChild(array.length, index, vr(isWeb ? 3 : 5))}
						weight={layout.isWebDesktop ? 'regular' : undefined}
					/>
				))}
			</View>
			<LoadingOverlay visible={isSigningOut} />
		</DrawerContentScrollView>
	);
}
