import {
	useNotificationsPreferencesQuery,
	useUpdateNotificationsPreferenceMutation,
} from '@mobe/api/notifications/notificationsApiHooks';
import { useNotificationsSettings } from '@mobe/api/notifications/notificationsSettingsProvider';
import { useLoginOptions } from '@mobe/utils/storage/authStateStorage';
import { ColorSchemePreference, useColorSchemePreference } from '@mobe/utils/useColorScheme';
import { useTranslation } from 'react-i18next';
import { Linking, Platform } from 'react-native';
import { useBioAuthContent } from '../../auth/useBioAuthContent';
import useBioAuthService from '../../auth/useBioAuthService';
import * as SettingsAnalyticsEvents from '../analyticsEvents';
import { IActionSectionWithInstructionsProps } from '../components/ActionSectionWithInstructions';
import { ISettingsItemProps } from '../components/SettingsItem';
import { ISettingsRootScreenProps } from './SettingsRootScreen';

export interface ISettingsSectionProps {
	title: string;
	data: (ISettingsItemProps | IActionSectionWithInstructionsProps)[];
}

export default function useSettingsRootScreenController({ navigation }: ISettingsRootScreenProps) {
	const { t } = useTranslation();
	const {
		updateSavedLoginCredentials,
		enableBiometricLogin,
		toggleEnableBiometricLogin,
		rememberMe,
		toggleRememberMe,
	} = useLoginOptions();
	const bioAuthContent = useBioAuthContent();
	const bioAuthService = useBioAuthService();
	const notificationsPreferences = useNotificationsPreferencesQuery();
	const updateNotificationPreferenceMutation = useUpdateNotificationsPreferenceMutation();
	const { notificationsPermitted } = useNotificationsSettings();
	const [colorSchemePreference, setColorSchemePreference] = useColorSchemePreference();

	const authSettingsSectionsData: ISettingsItemProps[] = [
		{
			type: 'button',
			label: t('settings.editPasswordLabel'),
			onPress: () => navigation.navigate('SETTINGS_EDIT_PASSWORD_SCREEN'),
		},
		{
			type: 'button',
			label: t('settings.editEmailLabel'),
			onPress: () => navigation.navigate('SETTINGS_EDIT_EMAIL_SCREEN'),
		},
		{
			type: 'switch',
			label: t('settings.rememberMeLabel'),
			onPress: () => {
				const nextValue = !rememberMe;
				SettingsAnalyticsEvents.toggleRememberMe(nextValue);
				toggleRememberMe();
				updateSavedLoginCredentials({ username: null, password: null, rememberMe: nextValue });
			},
			value: rememberMe,
		},
		...(bioAuthService.isBioAuthAvailable
			? [
					{
						type: 'switch',
						label: bioAuthContent.settingsLabel,
						onPress: () => {
							const nextValue = !enableBiometricLogin;
							SettingsAnalyticsEvents.toggleBioAuth(nextValue);
							toggleEnableBiometricLogin();

							// If nextValue is true, that means that rememberMe will have been flipped to true as well;
							// update saved credentials accordingly
							if (nextValue) {
								updateSavedLoginCredentials({ username: null, password: null, rememberMe: true });
							}
						},
						value: enableBiometricLogin,
					} as ISettingsItemProps,
			  ]
			: []),
		{
			type: 'button',
			label: t('settings.account.screenTitle'),
			onPress: () => navigation.navigate('SETTINGS_ACCOUNT_SCREEN'),
		},
	];

	const notificationsSettingsData = () => {
		let result: ISettingsItemProps[] | IActionSectionWithInstructionsProps[] = [];

		if (notificationsPreferences.data) {
			const {
				isActivityNotificationEnabled,
				isChatNotificationEnabled,
				isAppointmentReminderEnabled,
			} = notificationsPreferences.data;
			result = [
				{
					type: 'switch',
					label: t('settings.allowChatNotificationsLabel'),
					onPress: () =>
						updateNotificationPreferenceMutation.mutate({
							isChatNotificationEnabled: !isChatNotificationEnabled,
						}),
					value: isChatNotificationEnabled,
				},
				{
					type: 'switch',
					label: t('settings.allowNewActivityNotificationsLabel'),
					onPress: () =>
						updateNotificationPreferenceMutation.mutate({
							isActivityNotificationEnabled: !isActivityNotificationEnabled,
						}),
					value: isActivityNotificationEnabled,
				},
				{
					type: 'switch',
					label: t('settings.allowUpcomingCallNotificationLabel'),
					onPress: () =>
						updateNotificationPreferenceMutation.mutate({
							isAppointmentReminderEnabled: !isAppointmentReminderEnabled,
						}),
					value: isAppointmentReminderEnabled,
				},
			];
		}
		if (!notificationsPermitted) {
			result = [
				{
					type: 'buttonWithInstructions',
					buttonTitle: t('settings.enableNotificationsButtonTitle'),
					label: t('settings.notificationsNotAllowedLabel'),
					onPress: () => Linking.openSettings(),
				},
			];
		}

		return result;
	};

	function handleAppearancePress(colorSchemePreference: ColorSchemePreference) {
		setColorSchemePreference(colorSchemePreference);

		switch (colorSchemePreference) {
			case ColorSchemePreference.Light:
				SettingsAnalyticsEvents.lightColorSchemeEnabled();
				break;
			case ColorSchemePreference.Dark:
				SettingsAnalyticsEvents.darkColorSchemeEnabled();
				break;
			case ColorSchemePreference.Auto:
				SettingsAnalyticsEvents.autoColorSchemeEnabled();
				break;
			default:
				break;
		}
	}

	const appearanceSectionsData: ISettingsItemProps[] = [
		{
			type: 'radio',
			label: t('settings.lightAppearance'),
			onPress: () => handleAppearancePress(ColorSchemePreference.Light),
			value: colorSchemePreference === 'light',
		},
		{
			type: 'radio',
			label: t('settings.darkAppearance'),
			onPress: () => handleAppearancePress(ColorSchemePreference.Dark),
			value: colorSchemePreference === 'dark',
		},
		...(Platform.OS !== 'web'
			? ([
					{
						type: 'radio',
						label: t('settings.autoAppearance'),
						onPress: () => handleAppearancePress(ColorSchemePreference.Auto),
						value: colorSchemePreference === 'auto',
					},
			  ] as ISettingsItemProps[])
			: []),
	];

	const settingsSectionsData: ISettingsSectionProps[] = [
		{
			title: t('settings.appearanceHeader'),
			data: appearanceSectionsData,
		},
		...(Platform.OS !== 'web'
			? [
					{
						title: t('settings.notificationsHeader'),
						data: notificationsSettingsData(),
					},
			  ]
			: []),
		{
			title: t('settings.loginAndSecurityHeader'),
			data:
				Platform.OS !== 'web'
					? authSettingsSectionsData
					: authSettingsSectionsData.filter((item) => item.label !== t('settings.rememberMeLabel')),
		},
	];

	return {
		settingsSectionsData,
	};
}
