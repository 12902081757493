import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { format, parse, set } from 'date-fns';
import IMask from 'imask/esm/imask';
import { noop, uniqueId } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	InputAccessoryView,
	NativeSyntheticEvent,
	Platform,
	StyleSheet,
	TextInput,
	TextInputFocusEventData,
	View,
} from 'react-native';
import { TextProps } from '../text/Text';
import TextButton from '../textButton/TextButton';
import BaseInputError from './BaseInputError';
import BaseInputLabel from './BaseInputLabel';
import InputText, { IInputTextProps, buildErrorMessageId } from './InputText';

const DAY_REGEX = /^(?!00)(0?[0-9]|1[0-9]|2[0-9]|3[0-1])$/; // No double zeros (?!00)
const dayInputMask = IMask.createMask({
	mask: DAY_REGEX,
});

const MONTH_REGEX = /^(?!00)(0?[0-9]|1[0-2]?)?$/; // No double zeros (?!00)
const monthInputMask = IMask.createMask({
	mask: MONTH_REGEX,
});

const date = new Date(Date.now());
const currentYear = date.getFullYear();
const yearInputMask = IMask.createMask({
	mask: 'YYYY',
	blocks: {
		YYYY: {
			mask: IMask.MaskedRange,
			from: currentYear - 150,
			to: currentYear,
			maxLength: 4,
		},
	},
});

interface IDateInputsProps extends IInputTextProps {
	initialValue?: string;
	yearInputProps?: Omit<IInputTextProps, 'label'>;
	onDateEntered?: (dateString: string | null) => void;
	onBlur?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
	onToolbarSubmit?: () => void;
}

export interface IExpandedDateInputMethods {
	focus: () => void;
}

type ActiveFieldStateKey = '' | 'day' | 'month' | 'year';

function ExpandedDateInput(
	{
		initialValue = '',
		collapseEmptyError = false,
		errorMessage,
		hasError,
		label,
		yearInputProps = {},
		onDateEntered = noop,
		onBlur = noop,
		onToolbarSubmit,
	}: IDateInputsProps,
	ref: React.Ref<IExpandedDateInputMethods>
) {
	const styles = useStyles();
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();
	const [day, setDay] = React.useState(() =>
		initialValue ? `${parse(initialValue, 'MM/dd/yyyy', new Date()).getDate()}` : ''
	);
	const [month, setMonth] = React.useState(() =>
		initialValue ? `${parse(initialValue, 'MM/dd/yyyy', new Date()).getMonth() + 1}` : ''
	);
	const [year, setYear] = React.useState(() =>
		initialValue ? `${parse(initialValue, 'MM/dd/yyyy', new Date()).getFullYear()}` : ''
	);
	const dayInputRef = React.useRef<TextInput | null>(null);
	const monthInputRef = React.useRef<TextInput | null>(null);
	const yearInputRef = React.useRef<TextInput | null>(null);
	const [activeInput, setActiveInput] = React.useState<ActiveFieldStateKey>('');

	const shouldRenderErrorComponent = (!collapseEmptyError && !errorMessage) || errorMessage;
	const errorMessageId = buildErrorMessageId(label);
	const inputLabelProps: TextProps = { size: 'sm', weight: 'regular' };
	const accessoryViewId = React.useMemo(() => uniqueId('expanded-date-input-'), []);
	const inputAccessibilityHint = hasError
		? `${t('accessibility.inputErrorMessageHintInvalid')}: ${errorMessage ? errorMessage : ''}`
		: undefined;

	React.useImperativeHandle(ref, () => ({
		focus() {
			monthInputRef.current?.focus();
		},
	}));

	React.useEffect(() => {
		validateCurrentValue();
	}, [day, month, year]);

	function validateCurrentValue() {
		if (day && month && year.length === 4) {
			try {
				// Get date object
				const date = set(new Date(), {
					year: Number(year),
					month: Number(month) - 1,
					date: Number(day),
					hours: 0,
					minutes: 0,
					seconds: 0,
					milliseconds: 0,
				});

				// Broadcast to parent components a date string
				onDateEntered(format(date, 'MM/dd/yyyy'));
			} catch (error) {
				console.warn(
					`ExpandedDateInput:validateCurrentValue: something went wrong validating the entered date: Month: ${month}, Day: ${day}, Year: ${year}`
				);
				onDateEntered(null);
			}
		} else {
			onDateEntered(null);
		}
	}

	function handleInputFocus(fieldKey: ActiveFieldStateKey) {
		return () => {
			setActiveInput(fieldKey);
		};
	}

	function handleInputBlur(fieldKey: ActiveFieldStateKey) {
		return (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
			// Extra things for year input blur because it is likely meaning the user entered a full date
			if (fieldKey === 'year') {
				validateCurrentValue();

				// Broadcast onBlur event to parent component
				onBlur(event);
			}

			setActiveInput('');
		};
	}

	function handleChangeText(
		inputMask: IMask.MaskedPattern<string> | IMask.MaskedRegExp,
		setState: React.Dispatch<React.SetStateAction<string>>
	) {
		return (text: string) => {
			inputMask.resolve(text);
			setState(inputMask.value);
		};
	}

	function handleToolbarPress(actionKey: 'prev' | 'next' | 'submit') {
		return () => {
			if (actionKey === 'prev') {
				switch (activeInput) {
					case 'day':
						monthInputRef.current?.focus();
						break;
					case 'year':
						dayInputRef.current?.focus();
						break;
					default:
						break;
				}
			}
			if (actionKey === 'next') {
				switch (activeInput) {
					case 'day':
						yearInputRef.current?.focus();
						break;
					case 'month':
						dayInputRef.current?.focus();
						break;
					default:
						break;
				}
			}
			if (actionKey === 'submit' && typeof onToolbarSubmit === 'function') {
				onToolbarSubmit();
			}
		};
	}

	return (
		<>
			<BaseInputLabel color={hasError ? 'error' : 'regular'} style={vr(1)}>
				{label}
			</BaseInputLabel>

			<View style={styles.inputContainer}>
				{/* Month */}
				<View style={styles.monthInput}>
					<InputText
						ref={monthInputRef}
						aria-label={`${label}, ${t('forms.expandedDateInput.monthInputLabel')}`}
						accessibilityHint={inputAccessibilityHint}
						label={t('forms.expandedDateInput.monthInputLabel')}
						labelProps={inputLabelProps}
						type="integer"
						maxLength={2}
						placeholder="mm"
						hasError={hasError}
						collapseEmptyError
						value={month}
						inputAccessoryViewID={accessoryViewId}
						enterKeyHint="next"
						blurOnSubmit={false} // To stop keyboard flickering/drop on android
						onSubmitEditing={() => dayInputRef.current?.focus()}
						onChangeText={handleChangeText(monthInputMask, setMonth)}
						onFocus={handleInputFocus('month')}
						onBlur={handleInputBlur('month')}
					/>
				</View>
				{/* Day */}
				<View style={styles.dayInput}>
					<InputText
						ref={dayInputRef}
						aria-label={`${label}, ${t('forms.expandedDateInput.dayInputLabel')}`}
						accessibilityHint={inputAccessibilityHint}
						label={t('forms.expandedDateInput.dayInputLabel')}
						labelProps={inputLabelProps}
						type="integer"
						maxLength={2}
						placeholder="dd"
						hasError={hasError}
						collapseEmptyError
						value={day}
						inputAccessoryViewID={accessoryViewId}
						enterKeyHint="next"
						blurOnSubmit={false} // To stop keyboard flickering/drop on android
						onSubmitEditing={() => yearInputRef.current?.focus()}
						onChangeText={handleChangeText(dayInputMask, setDay)}
						onFocus={handleInputFocus('day')}
						onBlur={handleInputBlur('day')}
					/>
				</View>
				{/* Year */}
				<View style={styles.yearInput}>
					<InputText
						ref={yearInputRef}
						aria-label={`${label}, ${t('forms.expandedDateInput.yearInputLabel')}`}
						accessibilityHint={inputAccessibilityHint}
						label={t('forms.expandedDateInput.yearInputLabel')}
						labelProps={inputLabelProps}
						type="integer"
						maxLength={4}
						placeholder="yyyy"
						hasError={hasError}
						collapseEmptyError
						value={year}
						inputAccessoryViewID={accessoryViewId}
						onChangeText={handleChangeText(yearInputMask, setYear)}
						onFocus={handleInputFocus('year')}
						onBlur={handleInputBlur('year')}
						{...yearInputProps}
					/>
				</View>

				{/* Accessory input toolbar, this only works for iOS */}
				{Platform.OS === 'ios' ? (
					<InputAccessoryView nativeID={accessoryViewId}>
						<View style={styles.inputToolbar}>
							<TextButton
								title={t('forms.expandedDateInput.inputToolbarPreviousButton')}
								style={styles.inputToolbarItem}
								disabled={activeInput === 'month'}
								onPress={handleToolbarPress('prev')}
							/>
							<TextButton
								title={t('forms.expandedDateInput.inputToolbarNextButton')}
								style={styles.inputToolbarItem}
								disabled={activeInput === 'year'}
								onPress={handleToolbarPress('next')}
							/>
							{typeof onToolbarSubmit === 'function' ? (
								<TextButton
									title={t('forms.expandedDateInput.inputToolbarSubmitButton')}
									style={styles.inputToolbarItem}
									disabled={year.length < 4 || hasError}
									onPress={handleToolbarPress('submit')}
								/>
							) : null}
						</View>
					</InputAccessoryView>
				) : null}
			</View>

			{shouldRenderErrorComponent && (
				<BaseInputError id={errorMessageId}>{errorMessage}</BaseInputError>
			)}
		</>
	);
}

function useStyles() {
	const styleRules = useStyleRules();

	return StyleSheet.create({
		inputContainer: {
			flexDirection: 'row',
			gap: styleRules.spacing.appHorizontalMargin,
		},

		// Input accessory component child styles
		inputToolbar: {
			flexDirection: 'row',
			justifyContent: 'flex-end',
			paddingHorizontal: styleRules.spacing.appHorizontalMargin,
			paddingVertical: 10,
			borderTopColor: styleRules.colors.stroke,
			borderTopWidth: StyleSheet.hairlineWidth,
		},
		inputToolbarItem: {
			marginLeft: 20,
		},

		// Adding basis widths here to make sure the fields don't change size as text is entered
		monthInput: {
			flexBasis: '25%',
			flexShrink: 1,
			flexGrow: 1,
		},
		dayInput: {
			flexBasis: '25%',
			flexShrink: 1,
			flexGrow: 1,
		},
		yearInput: {
			flexBasis: '50%',
			flexShrink: 1,
			flexGrow: 1,
		},
	});
}

export default React.forwardRef(ExpandedDateInput);
