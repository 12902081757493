import { yupResolver } from '@hookform/resolvers/yup';
import { useProfileQuery, useUpdateProfileMutation } from '@mobe/api/account/accountApiHooks';
import { IUpdatableUserProfile } from '@mobe/api/account/accountService';
import {
	AppointmentQueryKeys,
	useAppointmentTimeSlotsQuery,
	useScheduleFollowUpMutation,
	useScheduleInitialAppointmentWithPharmacistMutation,
} from '@mobe/api/appointments/appointmentApiHooks';
import {
	ContactOptions,
	ITimeSlots,
	SchedulingContext,
} from '@mobe/api/appointments/appointmentsService';
import { CoachType, CoachesQueryKeys, ICoach } from '@mobe/api/guides/guidesApi';
import { useGuideQuery, usePharmacistQuery } from '@mobe/api/guides/guidesApiHooks';
import useControlledCheckboxProps, {
	IControlledCheckboxProps,
} from '@mobe/components/checkbox/useControlledCheckboxProps';
import { useControlledInputProps } from '@mobe/components/input';
import { IControlledInputProps } from '@mobe/components/input/useControlledInputProps';
import StepIndicator from '@mobe/components/stepIndicator/StepIndicator';
import * as GlobalAnalyticsEvents from '@mobe/utils/analyticsEvents';
import { useAlert } from '@mobe/utils/useAlert';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { validatePhoneNumber } from '@mobe/utils/validationUtils';
import { useNavigation } from '@react-navigation/native';
import { UseQueryResult, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { UseControllerReturn, UseFormReturn, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import * as Yup from 'yup';
import { ApptFollowUpStackScreenNavigationProp } from '../../AppointmentStackScreen';
import * as AppointmentsAnalyticsEvents from '../../analyticsEvents';
import { GuideTypeValue } from '../../useGuideTypeOptions';

function useValidationSchema() {
	const profileQuery = useProfileQuery();
	const { t } = useTranslation();

	return Yup.object({
		coachType: Yup.string().oneOf(Object.values(GuideTypeValue)).required(),
		apptDate: Yup.date().required(),
		contactOption: Yup.string().oneOf(Object.values(ContactOptions)).required(),
		primaryPhoneNumber: Yup.string()
			.label(t('appointments.contactOptionsView.primaryPhoneInputLabel'))
			.min(10, t('forms.errors.phone'))
			.max(10, t('forms.errors.phone'))
			.required()
			.test('valid-phone-if-no-default', t('forms.errors.phone'), (value) => {
				if (!profileQuery.data?.phoneNumber) {
					return validatePhoneNumber(value);
				}

				return true;
			}),
		otherPhoneNumber: Yup.string()
			.label(t('appointments.contactOptionsView.otherPhoneInputLabel'))
			.when('contactOption', {
				is: ContactOptions.Other,
				then: Yup.string().test('valid-phone-number', t('forms.errors.phone'), (value) =>
					validatePhoneNumber(value)
				),
			}),
		saveOtherAsAlternatePhoneNumber: Yup.boolean(),
	});
}

type FormSchema = Yup.InferType<ReturnType<typeof useValidationSchema>>;

export interface IFollowUpContext {
	form: UseFormReturn<FormSchema>;
	coachTypeControl: UseControllerReturn<FormSchema, 'coachType'>;
	apptDateControl: UseControllerReturn<FormSchema, 'apptDate'>;
	contactOptionControl: UseControllerReturn<FormSchema, 'contactOption'>;
	primaryPhoneNumberProps: IControlledInputProps;
	otherPhoneNumberProps: IControlledInputProps;
	saveOtherAsAlternatePhoneNumberProps: IControlledCheckboxProps;
	apptDatesQuery: UseQueryResult<ITimeSlots, Error>;

	/** Null implies that this is the initial appointment with the Pharmacist */
	selectedGuide: ICoach | null;
	allowCoachToggle: boolean;
	isFetching: boolean;
	currentStep: number;
	canSubmit: boolean;
	getApptDurationInMinutes: () => number;
	setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
	handleCoachTypeSelection: (value: GuideTypeValue) => void;
	handleTimeSelection: (diKeys: string[]) => void;
	handleNoTimeSlotsRetryPress: () => void;
	handleSubmitPress: () => void;
}

const FollowUpContext = React.createContext<IFollowUpContext | undefined>(undefined);

function FollowUpProvider({
	initialGuideType,
	initialGuide,
	allowCoachToggle,
	children,
}: {
	initialGuideType: GuideTypeValue;
	initialGuide: ICoach | null;
	allowCoachToggle: boolean;
	children: React.ReactNode;
}) {
	const navigation = useNavigation<ApptFollowUpStackScreenNavigationProp>();
	const guide = useGuideQuery(true).data;
	const pharmacist = usePharmacistQuery(true).data;
	const profileQuery = useProfileQuery();
	const updateProfileMutation = useUpdateProfileMutation();
	const scheduleFollowUpMutation = useScheduleFollowUpMutation();
	const scheduleInitialAppointmentWithPharmacistMutation =
		useScheduleInitialAppointmentWithPharmacistMutation();
	const queryClient = useQueryClient();
	const validationSchema = useValidationSchema();
	const genericErrorAlert = useGenericErrorAlert();
	const { t } = useTranslation();
	const [currentStep, setCurrentStep] = React.useState<number>(0);
	const [isComplete, setIsComplete] = React.useState(false);
	const [availableGuideDiKeys, setAvailableGuideDiKeys] = React.useState<string[]>([]);
	const [selectedGuide, setSelectedGuide] = React.useState<ICoach | null>(initialGuide);
	const { mobeAlert } = useAlert();

	// Keeps initialGuide and selectedGuide in sync in the case of a guide reassignment
	React.useEffect(() => {
		if (selectedGuide !== initialGuide) {
			setSelectedGuide(initialGuide);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialGuide]);

	const form = useForm<FormSchema>({
		mode: 'onChange',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			coachType: initialGuideType,
			apptDate: new Date(Date.now()),
			contactOption: ContactOptions.Primary,
			primaryPhoneNumber: profileQuery.data?.phoneNumber || '',
			otherPhoneNumber: '',
			saveOtherAsAlternatePhoneNumber: false,
		},
	});
	const coachTypeControl = useController({ name: 'coachType', control: form.control });
	const apptDateControl = useController({ name: 'apptDate', control: form.control });
	const contactOptionControl = useController({ name: 'contactOption', control: form.control });
	const primaryPhoneNumberProps = useControlledInputProps({
		name: 'primaryPhoneNumber',
		control: form.control,
	});
	const otherPhoneNumberProps = useControlledInputProps({
		name: 'otherPhoneNumber',
		control: form.control,
	});
	const saveOtherAsAlternatePhoneNumberProps = useControlledCheckboxProps({
		name: 'saveOtherAsAlternatePhoneNumber',
		control: form.control,
	});

	const canSubmit = form.formState.isValid;

	// Appt dates query
	const apptDatesQuery = useAppointmentTimeSlotsQuery(selectedGuide, !selectedGuide);

	// Get the first appt time slot duration in minutes
	function getApptDurationInMinutes() {
		if (apptDatesQuery.data) {
			return apptDatesQuery.data.appointmentDurationInMinutes;
		}
		return 0;
	}

	// Fetching state
	const isFetching =
		apptDatesQuery.isFetching ||
		scheduleFollowUpMutation.isPending ||
		scheduleInitialAppointmentWithPharmacistMutation.isPending ||
		updateProfileMutation.isPending;

	const shouldShowGenericErrorAlert =
		apptDatesQuery.isError ||
		scheduleFollowUpMutation.isError ||
		scheduleInitialAppointmentWithPharmacistMutation.isError ||
		updateProfileMutation.isError;

	const shouldShowAlertOnExit = !isComplete && form.formState.isDirty;

	// Update the steps graphic in the parent navigator header
	React.useEffect(() => {
		navigation.setOptions({
			headerBackground: () => <StepIndicator totalSteps={2} currentStepIndex={currentStep} />,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStep]);

	// If the user attempts to exit at any point during the Guide Matching process, given they've
	// made any progress, prompt an alert to confirm action.
	React.useEffect(() => {
		if (shouldShowGenericErrorAlert) {
			genericErrorAlert();
			return;
		}

		if (!shouldShowAlertOnExit) {
			return;
		}

		const removeListener = navigation.addListener('beforeRemove', (event) => {
			event.preventDefault();

			mobeAlert(
				t('appointments.followUp.notCompleteAlertTitle'),
				t('appointments.followUp.notCompleteAlertBody'),
				[
					{
						text: t('appointments.followUp.notCompleteAlertExitButton'),
						style: 'destructive',
						onPress: () => {
							if (selectedGuide) {
								AppointmentsAnalyticsEvents.guideSchedulingExitConfirm(selectedGuide.coachType);
							} else {
								AppointmentsAnalyticsEvents.guideMatchingExitConfirm(CoachType.Pharmacist);
							}
							navigation.dispatch(event.data.action);
						},
					},
					{
						text: t('appointments.followUp.notCompleteAlertContinueButton'),
						style: 'cancel',
						onPress: () => {
							if (selectedGuide) {
								AppointmentsAnalyticsEvents.guideSchedulingExitCancel(selectedGuide.coachType);
							} else {
								AppointmentsAnalyticsEvents.guideMatchingExitCancel(CoachType.Pharmacist);
							}
						},
					},
				]
			);
		});

		return removeListener;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigation, shouldShowGenericErrorAlert, shouldShowAlertOnExit]);

	// Always unset saveOtherAsAlternatePhoneNumber when other not selected
	React.useEffect(() => {
		if (contactOptionControl.field.value !== ContactOptions.Other) {
			form.setValue('saveOtherAsAlternatePhoneNumber', false);
		}
	}, [contactOptionControl.field.value, form]);

	function handleCoachTypeSelection(value: GuideTypeValue) {
		coachTypeControl.field.onChange(value);
		coachTypeControl.field.onBlur();

		// Reset currently selected appt date to null
		apptDateControl.field.onChange(null);

		if (value === GuideTypeValue.Guide && guide) {
			GlobalAnalyticsEvents.startGuideSchedulingPress(guide.coachType);
			setSelectedGuide(guide);
		}

		if (value === GuideTypeValue.Pharmacist && pharmacist) {
			GlobalAnalyticsEvents.startGuideSchedulingPress(pharmacist.coachType);
			setSelectedGuide(pharmacist);
		}
	}

	function handleTimeSelection(diKeys: string[]) {
		setAvailableGuideDiKeys(diKeys);
	}

	function handleNoTimeSlotsRetryPress() {
		apptDatesQuery.refetch();
	}

	function handleSubmitPress() {
		form.handleSubmit(submit)();
	}

	async function submit(formValues: FormSchema) {
		try {
			let data;

			if (selectedGuide) {
				// Setting an appt with an already assigned guide
				data = await scheduleFollowUpMutation.mutateAsync({
					guideDiKey: selectedGuide.guideDIKey,
					appointmentDateTime: formValues.apptDate.toISOString(),
					coachTypeId: selectedGuide.coachTypeId,
					phoneNumber: getPhoneNumberForAppt(formValues),
					schedulingLocation:
						Platform.OS === 'web'
							? SchedulingContext.ConnectGuideFollowUpForm
							: SchedulingContext.AppGuideFollowUpForm,
				});

				AppointmentsAnalyticsEvents.appointmentFollowUpSuccess(selectedGuide.coachType);
			} else {
				// Setting an initial appt with pharmacist
				data = await scheduleInitialAppointmentWithPharmacistMutation.mutateAsync({
					guideDiKey: availableGuideDiKeys[0],
					appointmentDateTime: formValues.apptDate.toISOString(),
					phoneNumber: getPhoneNumberForAppt(formValues),
					schedulingLocation:
						Platform.OS === 'web'
							? SchedulingContext.ConnectGuideMatchForm
							: SchedulingContext.AppGuideMatchForm,
				});

				queryClient.invalidateQueries({ queryKey: [CoachesQueryKeys.Coaches] });

				AppointmentsAnalyticsEvents.pharmacistInitialScheduleSuccess();
			}

			if (!data.isAppointmentScheduleSuccessful) {
				genericErrorAlert();
				return;
			}

			await Promise.all([
				queryClient.invalidateQueries({ queryKey: [AppointmentQueryKeys.AllAppointments] }),
				// Update profile based on form inputs and invalidate profile data
				updateProfile(formValues),
			]);

			setIsComplete(true);

			navigation.navigate('APPT_CONFIRMATION_SCREEN', {
				apptID: data.appointmentConfirmationId?.toString(),
			});
		} catch (error) {
			genericErrorAlert();
		}
	}

	function getPhoneNumberForAppt(formValues: FormSchema) {
		const phoneNumberFromForm = formValues.primaryPhoneNumber ?? '';
		let phoneNumber = profileQuery.data?.phoneNumber || phoneNumberFromForm;
		if (
			formValues.contactOption === ContactOptions.Alternate &&
			profileQuery.data?.alternatePhoneNumber
		) {
			phoneNumber = profileQuery.data.alternatePhoneNumber;
		}
		if (formValues.contactOption === ContactOptions.Other && formValues.otherPhoneNumber?.length) {
			phoneNumber = formValues.otherPhoneNumber;
		}
		return phoneNumber;
	}

	async function updateProfile(formValues: FormSchema) {
		const updatedProfileData: Partial<IUpdatableUserProfile> = {};

		if (formValues.primaryPhoneNumber) {
			updatedProfileData.phoneNumber = formValues.primaryPhoneNumber;
		}

		if (formValues.saveOtherAsAlternatePhoneNumber) {
			updatedProfileData.alternatePhoneNumber = formValues.otherPhoneNumber;
		}

		// Make updates if conditions to make updates above were met
		if (Object.keys(updatedProfileData).length > 0) {
			await updateProfileMutation.mutateAsync(updatedProfileData);
		}
	}

	return (
		<FollowUpContext.Provider
			value={{
				form,
				coachTypeControl,
				apptDateControl,
				contactOptionControl,
				primaryPhoneNumberProps,
				otherPhoneNumberProps,
				saveOtherAsAlternatePhoneNumberProps,
				apptDatesQuery,
				selectedGuide,
				allowCoachToggle,
				isFetching,
				currentStep,
				canSubmit,
				getApptDurationInMinutes,
				setCurrentStep,
				handleCoachTypeSelection,
				handleTimeSelection,
				handleNoTimeSlotsRetryPress,
				handleSubmitPress,
			}}
		>
			{children}
		</FollowUpContext.Provider>
	);
}

function useFollowUpContext() {
	const context = React.useContext(FollowUpContext);
	if (context === undefined) {
		throw new Error('useFollowUpContext must be used within a FollowUpProvider');
	}

	return context;
}

export { FollowUpProvider, useFollowUpContext };
