import { client } from '../client';

export interface INotificationPreferencesResponse {
	isChatNotificationEnabled: boolean;
	isActivityNotificationEnabled: boolean;
	isAppointmentReminderEnabled: boolean;
}

export async function getNotificationPreferences() {
	const response = await client.get('push-notification-preferences');
	return response.data as INotificationPreferencesResponse;
}

export async function updateNotificationPreferences(
	preference: Partial<INotificationPreferencesResponse>
) {
	await client.patch<unknown>('push-notification-preferences', preference);
	return {};
}
