import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useButtonActive from '@mobe/utils/useButtonActive';
import * as React from 'react';
import {
	ActivityIndicator,
	Animated,
	Pressable,
	PressableProps,
	StyleProp,
	ViewStyle,
} from 'react-native';
import { Text } from '../text';
import useButtonStyles, { ButtonSizes, ButtonVariants } from './buttonStyles';

export interface ButtonProps extends PressableProps {
	title: string;
	accessibilityLabel?: string;
	disabled?: boolean;
	loading?: boolean;
	variant?: ButtonVariants;
	size?: ButtonSizes;
	style?: StyleProp<ViewStyle>;
	iconLeft?: JSX.Element;
}

function Button(
	{
		title = '',
		disabled = false,
		loading = false,
		variant = 'primary',
		size = 'regular',
		accessibilityLabel,
		style = {},
		iconLeft,
		...pressableProps
	}: ButtonProps,
	ref: any
) {
	const { active, handlePressIn, handlePressOut } = useButtonActive(disabled);
	const styles = useButtonStyles({ variant, size, disabled, active });
	const rules = useStyleRules();
	const animationRef = React.useRef(new Animated.Value(0)).current;
	const [hasAnimationRun, setHasAnimationRun] = React.useState(false);

	React.useEffect(() => {
		if (loading) {
			fadeAnimation();
		} else {
			resetAnimation();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	function fadeAnimation() {
		setHasAnimationRun(true);

		Animated.timing(animationRef, {
			useNativeDriver: true,
			toValue: 1,
			duration: 400,
		}).start();
	}

	function resetAnimation() {
		if (!hasAnimationRun) return;

		Animated.timing(animationRef, {
			useNativeDriver: true,
			toValue: 0,
			duration: 200,
		}).start();
	}

	const loaderOpacity = animationRef.interpolate({
		inputRange: [0, 0.2, 1],
		outputRange: [0, 0, 1],
	});

	const textOpacity = animationRef.interpolate({
		inputRange: [0, 0.8, 1],
		outputRange: [1, 0, 0],
	});

	return (
		<Pressable
			ref={ref}
			onPressIn={handlePressIn}
			onPressOut={handlePressOut}
			disabled={disabled || loading}
			style={[styles.button, style]}
			role="button"
			aria-label={accessibilityLabel || title}
			aria-disabled={disabled}
			aria-busy={loading}
			hitSlop={size === 'sm' ? 5 : 0}
			{...pressableProps}
		>
			{iconLeft ?? null}
			<Animated.View style={{ opacity: textOpacity }}>
				<Text style={styles.buttonText} numberOfLines={1}>
					{title}
				</Text>
			</Animated.View>
			<Animated.View style={[styles.loadingIndicator, { opacity: loaderOpacity }]}>
				<ActivityIndicator
					size="small"
					color={variant === 'primary' ? rules.colors.textInverted : rules.colors.primary}
				/>
			</Animated.View>
		</Pressable>
	);
}

export default React.forwardRef(Button);
