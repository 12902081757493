import BackButton from '@mobe/components/backButton/BackButton';
import Hr from '@mobe/components/hr/Hr';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useLayout from '@mobe/utils/styles/useLayout';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionList, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
	SettingsRootScreenNavigationProp,
	SettingsRootScreenRouteProp,
} from '../SettingsStackScreen';
import ActionSectionWithInstructions from '../components/ActionSectionWithInstructions';
import SectionHeader from '../components/SectionHeader';
import SettingsItem from '../components/SettingsItem';
import useSettingsRootScreenController from './useSettingsRootScreenController';

export interface ISettingsRootScreenProps {
	navigation: SettingsRootScreenNavigationProp;
	route: SettingsRootScreenRouteProp;
}

export default function SettingsRootScreen({ navigation, route }: ISettingsRootScreenProps) {
	const { settingsSectionsData } = useSettingsRootScreenController({ navigation, route });
	const safeInsets = useSafeAreaInsets();
	const styleHelpers = useStyleHelpers();
	const styleRules = useStyleRules();

	return (
		<View style={styleHelpers.fill}>
			<SectionList
				style={{
					backgroundColor: styleRules.colors.background,
				}}
				contentContainerStyle={{
					width: '100%',
					maxWidth: styleRules.spacing.maxWidth,
					alignSelf: 'center',
					flexGrow: 1,
					backgroundColor: styleRules.colors.cardBackground,
					paddingBottom: safeInsets.bottom,
				}}
				sections={settingsSectionsData}
				keyExtractor={(item) => item.label}
				stickySectionHeadersEnabled={false}
				ItemSeparatorComponent={() => (
					<View style={styleHelpers.wrapperHorizontal}>
						<Hr />
					</View>
				)}
				renderItem={({ item }) => (
					<View style={styleHelpers.wrapperHorizontal}>
						{item.type === 'buttonWithInstructions' ? (
							<ActionSectionWithInstructions {...item} />
						) : (
							<SettingsItem {...item} />
						)}
					</View>
				)}
				renderSectionHeader={({ section }) => {
					const index = settingsSectionsData.findIndex((s) => s.title === section.title);
					return <SectionHeader index={index} title={section.title} />;
				}}
			/>
		</View>
	);
}

export function useSettingsRootScreenOptions() {
	const { t } = useTranslation();
	const layout = useLayout();

	return ({ navigation }: ISettingsRootScreenProps): StackNavigationOptions => ({
		title: t('settings.screenTitle'),
		headerLeft: () => (layout.isWebDesktop ? null : <BackButton />),
	});
}
