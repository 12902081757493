import { useUnreadChatCountQuery } from '@mobe/api/chat/chatApiHooks';
import { CoachType } from '@mobe/api/guides/guidesApi';
import { useGuideQuery, usePharmacistQuery } from '@mobe/api/guides/guidesApiHooks';
import Avatar from '@mobe/components/avatar/Avatar';
import { GuideIntroGraphic, PharmacistIntroGraphic } from '@mobe/components/graphics';
import Icon from '@mobe/components/icon/Icon';
import Row from '@mobe/components/layout/Row';
import NumberPill from '@mobe/components/numberPill/NumberPill';
import { Text } from '@mobe/components/text';
import { useAccessibilityAutoFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View } from 'react-native';
import { ChatRootScreenNavigationProp } from '../ChatStackScreen';

interface IChatGuideCardProps {
	type: 'guide' | 'pharmacist';
	accessibilityAutoFocus?: boolean;
}

export default function ChatGuideCard({
	type,
	accessibilityAutoFocus = false,
}: IChatGuideCardProps) {
	const guideQuery = useGuideQuery();
	const pharmacistQuery = usePharmacistQuery();
	const navigation = useNavigation<ChatRootScreenNavigationProp>();
	const unreadChatCountQuery = useUnreadChatCountQuery();
	const styleRules = useStyleRules();
	const { constrain, vr } = useStyleHelpers();
	const { t } = useTranslation();
	const buttonRef = useAccessibilityAutoFocus(accessibilityAutoFocus);

	const unmatchedCopy =
		type === 'guide'
			? t('chat.selectionScreen.matchGuide', { returnObjects: true })
			: t('chat.selectionScreen.matchPharmacist', { returnObjects: true });

	let heading = unmatchedCopy.heading;
	let body = unmatchedCopy.body;
	let channelId = 0;

	if (type === 'guide' && guideQuery.data) {
		heading = guideQuery.data.preferredName || '';
		body = t('chat.selectionScreen.guideTitle');
		channelId = guideQuery.data.chatChannelId;
	}

	if (type === 'pharmacist' && pharmacistQuery.data) {
		heading = pharmacistQuery.data.preferredName || '';
		body = t('chat.selectionScreen.pharmacistTitle');
		channelId = pharmacistQuery.data.chatChannelId;
	}

	const unreadCount =
		unreadChatCountQuery.data?.numberOfUnreadMessagesByChannels.find(
			(channel) => channel.channelId === channelId
		)?.numberOfUnreadMessages || 0;

	function onPress() {
		if (type === 'guide') {
			if (guideQuery.data) {
				navigation.navigate('CHAT_DIRECT_MESSAGE_SCREEN', {
					channelId: guideQuery.data.chatChannelId,
				});
			} else {
				navigation.navigate('APPOINTMENT_SCREEN', { coachType: CoachType.GSM });
			}
		}

		if (type === 'pharmacist') {
			if (pharmacistQuery.data) {
				navigation.navigate('CHAT_DIRECT_MESSAGE_SCREEN', {
					channelId: pharmacistQuery.data.chatChannelId,
				});
			} else {
				navigation.navigate('APPOINTMENT_SCREEN', {
					coachType: CoachType.Pharmacist,
				});
			}
		}
	}

	function renderAvatar() {
		if (type === 'guide' && guideQuery.isSuccess) {
			return guideQuery.data ? (
				<Avatar
					imageSrc={guideQuery.data.avatarUrl ? { uri: guideQuery.data.avatarUrl } : undefined}
					letter={guideQuery.data.preferredName ? guideQuery.data.preferredName[0] : ''}
					size={70}
				/>
			) : (
				<View style={constrain(70)}>
					<GuideIntroGraphic />
				</View>
			);
		}

		if (type === 'pharmacist' && pharmacistQuery.isSuccess) {
			return pharmacistQuery.data ? (
				<Avatar
					imageSrc={
						pharmacistQuery.data.avatarUrl ? { uri: pharmacistQuery.data.avatarUrl } : undefined
					}
					letter={pharmacistQuery.data.preferredName ? pharmacistQuery.data.preferredName[0] : ''}
					backgroundColor="tertiary"
					size={70}
				/>
			) : (
				<View style={constrain(70)}>
					<PharmacistIntroGraphic />
				</View>
			);
		}
	}

	return (
		<Pressable
			ref={buttonRef}
			role="button"
			onPress={onPress}
			style={{
				justifyContent: 'center',
				minHeight: 125,
				paddingHorizontal: styleRules.spacing.appHorizontalMargin,
				paddingVertical: 24,
				backgroundColor: styleRules.colors.cardBackground,
				borderBottomColor: styleRules.colors.strokeLight,
				borderBottomWidth: 1,
			}}
		>
			<Row gutterSize={12}>
				<Row.Item>{renderAvatar()}</Row.Item>
				<Row.Item fill>
					<Text weight="medium" style={vr(1)}>
						{heading}
					</Text>
					<Text color="light">{body}</Text>
				</Row.Item>
				{unreadChatCountQuery.isSuccess ? (
					<Row.Item>
						{unreadCount > 0 ? (
							<NumberPill
								number={unreadCount}
								aria-label={t('chat.newChatAccessibilityLabel', { count: unreadCount })}
							/>
						) : null}
					</Row.Item>
				) : (
					<Row.Item />
				)}
				<Row.Item>
					<Icon name="right" />
				</Row.Item>
			</Row>
		</Pressable>
	);
}
