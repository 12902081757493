import { client } from '../client';

export interface IMedication {
	/** Date */
	reviewDate: string | null;
	reviewPdfLink: string;
}

export interface IProgress {
	callsWithGuide: number;
	/**
	 * Date
	 */
	lastGuideCall: string | null;
	callsWithPharmacist: number;
	/**
	 * Date
	 */
	lastPharmacistCall: string | null;
	activitiesThisWeek: number;
	activitiesAllTime: number;
	trackingEntries: number;
	goals: number;
	medications: IMedication[];
}

export async function getProgress() {
	const response = await client.get('progress');
	const data = response.data as IProgress;

	const processedData: IProgress = {
		activitiesAllTime: data.activitiesAllTime || 0,
		activitiesThisWeek: data.activitiesThisWeek || 0,
		callsWithGuide: data.callsWithGuide || 0,
		callsWithPharmacist: data.callsWithPharmacist || 0,
		goals: data.goals || 0,
		lastGuideCall: data.lastGuideCall || null,
		lastPharmacistCall: data.lastPharmacistCall || null,
		medications: data.medications || [],
		trackingEntries: data.trackingEntries || 0,
	};

	return processedData;
}
