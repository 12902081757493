import { client } from '../client';

export enum CelebrationsImageType {
	STGoalComplete = 'STGoalComplete',
	LTGoalComplete = 'LTGoalComplete',
	ActionStepComplete = 'ActionStepComplete',
}

export interface ICelebrations {
	title: string;
	message: string;
	imageType: CelebrationsImageType | null;
	hasConfetti: boolean;
	buttonTitle?: string;
	buttonPath?: string;
}

export async function getCelebrations() {
	const response = await client.get('celebrations');
	return response.data as ICelebrations;
}
