import { client } from '../client';

export enum Permissions {
	CanSelectGSMOrMSKLanguage = 'canSelectGsmOrMskLanguage',
	CanSelectGSMOrMSKGender = 'canSelectGsmOrMskGender',
	CanSelectGsmOrMskSpanishGender = 'canSelectGsmOrMskSpanishGender',
	CanSelectPharmacistLanguage = 'canSelectPharmacistLanguage',
	CanSelectPharmacistGender = 'canSelectPharmacistGender',
	HidePharmacistFeatures = 'hidePharmacistFeatures',
}

export async function getPermissions() {
	const response = await client.get('permissions');
	return response.data as Permissions[];
}
