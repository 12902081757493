import { useCongratsPopupStore } from '@mobe/components/congratsPopup/useCongratsPopupStore';
import { useIsFetching, useQuery } from '@tanstack/react-query';
import React from 'react';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import { CelebrationsImageType, getCelebrations } from './celebrationsService';

const celebrationsQueryKey = 'Celebrations';

export function useCelebrationsQuery(enabled: boolean) {
	const isAuthenticated = useIsAuthenticated();
	const congratsPopupStore = useCongratsPopupStore();
	const isFetching = useIsFetching();
	const [isDoneFetching, setIsDoneFetching] = React.useState(false);

	React.useEffect(() => {
		if (!isFetching) setIsDoneFetching(true);
	}, [isFetching]);

	const query = useQuery({
		queryKey: [celebrationsQueryKey],
		queryFn: getCelebrations,
		enabled: isAuthenticated && isDoneFetching && enabled,
		staleTime: Infinity,
		// Convert imageType to null if string value is unrecognized
		select: (messageData) => {
			return {
				...messageData,
				imageType:
					messageData.imageType !== null &&
					Object.values(CelebrationsImageType).includes(messageData.imageType)
						? messageData.imageType
						: null,
			};
		},
	});

	// Sync message data to congrats popup store when query data changes
	React.useEffect(() => {
		const messageData = query.data;

		if (messageData?.title && messageData.message) {
			congratsPopupStore.setMessage(messageData);
		}
	}, [query.data]);

	return query;
}
