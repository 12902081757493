import { useAppFeedback } from '@mobe/components/appFeedbackPopup/AppFeedbackProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import { ActivitiesQueryKeys } from './activitiesQueryKeys';
import {
	ActivityType,
	IActivityFull,
	getAllActivities,
	markActivityComplete,
	markActivityDismissed,
} from './activitiesService';

export interface IActivitiesData {
	activities: IActivityFull[];
	summary: {
		currentWeekCount: number;
		lifetimeCount: number;
	};
}

export function useAllActivitiesQuery() {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [ActivitiesQueryKeys.AllActivities],
		queryFn: getAllActivities,
		enabled: isAuthenticated,
		staleTime: 30 * 1000,
		// Filter out any unaccounted for activity types for backwards compatibility with API
		select: (data) => {
			return {
				activities: [...data.active, ...data.completed].filter((activity) =>
					Object.values(ActivityType).includes(activity.type)
				),
				summary: data.completedSummary,
			};
		},
	});
}

export function useRefreshAllActivities() {
	const queryClient = useQueryClient();

	return () => queryClient.invalidateQueries({ queryKey: [ActivitiesQueryKeys.AllActivities] });
}

export function useMarkActivityCompleteMutation() {
	const queryClient = useQueryClient();
	const appFeedback = useAppFeedback();

	return useMutation({
		mutationFn: markActivityComplete,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [ActivitiesQueryKeys.AllActivities] });
			appFeedback.show();
		},
	});
}

export function useMarkActivityDismissedMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: markActivityDismissed,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [ActivitiesQueryKeys.AllActivities] });
		},
	});
}
