import { useSessionQuery } from '@mobe/api/authentication/authApiHooks';
import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import { useHasDismissedTrackerCTAFromTrack } from '@mobe/utils/storage/appStateStorage';
import useTrackerContent from './useTrackerContent';

export default function useShouldShowConnectHealthData(trackerAbbreviation: TrackerAbbreviation) {
	const trackerContent = useTrackerContent();
	const tracker = trackerContent[trackerAbbreviation];
	const [hasDismissedTrackerCTAFromTrack] = useHasDismissedTrackerCTAFromTrack();
	const sessionQuery = useSessionQuery();

	if (!Object.values(TrackerAbbreviation).includes(trackerAbbreviation)) {
		return false;
	}

	return (
		tracker.showConnectHealthDataCTA &&
		!hasDismissedTrackerCTAFromTrack &&
		!sessionQuery.data?.validicInfo
	);
}
