import { TrackerSource } from '@mobe/api/track/trackService';
import env from '@mobe/env/env';
import { useQuery } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';
import Constants from 'expo-constants';
import { cloneDeep } from 'lodash';
import remoteConfig from './remoteConfig';

export const REMOTE_CONFIG_QUERY_KEY = 'remoteConfig';

const REMOTE_CONFIG_CACHE_TIME_IN_MILLISECONDS = env.isDev
	? minutesToMilliseconds(5)
	: minutesToMilliseconds(60);

/**
 * Keys for remote config parameters. Used in getting and setting.
 */
enum RemoteConfigParameterKeys {
	IsUnderConstruction = 'isUnderConstruction',
	LoginAlert = 'loginAlert',
	MaintenanceScreen = 'maintenanceScreen',
	FeatureIntegratedTrackers = 'featureIntegratedTrackers',
	AppSurveyLink = 'appSurveyLink',
	IntegratedTrackersOverview = 'integratedTrackersOverview',
	ContentLikesMinCountThreshold = 'contentLikesMinCountThreshold',
	MaxCallCountGuide = 'maxCallCountGuide',
	MaxCallCountPharmacist = 'maxCallCountPharmacist',
	ScheduleCallVariant = 'scheduleCallVariant',
	ReduceHomeComplexityVariant = 'reduceHomeComplexityVariant',
	EnableToast = 'enableToast',
	AppFeedback = 'appFeedback',
	FeedbackSurvey = 'feedbackSurvey',
	IncentiveDisclaimer = 'incentiveDisclaimer',
}

const isUnderConstructionDefault = false;

const loginAlertDefault = {
	enabled: false,
	heading: '',
	body: '',
	button: {
		label: '',
		iosURL: Constants.expoConfig?.ios?.appStoreUrl,
		androidURL: Constants.expoConfig?.android?.playStoreUrl,
	},
};

const maintenanceScreenDefault = {
	enabled: false,
	heading: '',
	body: '',
	subBody: '',
	button: {
		label: '',
		iosURL: Constants.expoConfig?.ios?.appStoreUrl,
		androidURL: Constants.expoConfig?.android?.playStoreUrl,
	},
};

const featureIntegratedTrackersDefault: {
	showGoogleFit: boolean;
	omittedSources: TrackerSource[];
} = {
	showGoogleFit: true,
	omittedSources: [],
};

const appSurveyLinkDefault = {
	enabled: false,
	url: '',
	label: '',
};

const integratedTrackersOverviewDefault: {
	heading: string;
	platforms: { [key: string]: { integrations: string[]; details: string } };
} = {
	heading: '',
	platforms: {
		...Object.fromEntries(
			Object.keys(TrackerSource).map((source) => [
				source,
				{
					integrations: [],
					details: '',
				},
			])
		),
	},
};

const contentLikesMinCountThresholdDefault = 9999;

const maxCallCountGuideDefault = 3;
const maxCallCountPharmacistDefault = 1;

const scheduleCallVariantDefault = false;
const reduceHomeComplexityVariantDefault = false;
const enableToastDefault = false;

const appFeedbackDefault = {
	minLoginCount: 20,
	maxPromptCount: 3,
	loginCountPromptInterval: 5,
	url: 'https://www.mobeforlife.com/',
	surveyID: -1,
};

const feedbackSurveyDefault = {
	enabled: false,
	label: '',
	surveyID: -1,
};

const incentiveDisclaimerDefault = '';

/**
 * Placeholder data for the query. Remote config defaults are derived from this.
 */
const placeholderData = {
	[RemoteConfigParameterKeys.IsUnderConstruction]: isUnderConstructionDefault,
	[RemoteConfigParameterKeys.LoginAlert]: cloneDeep(loginAlertDefault),
	[RemoteConfigParameterKeys.MaintenanceScreen]: cloneDeep(maintenanceScreenDefault),
	[RemoteConfigParameterKeys.FeatureIntegratedTrackers]: cloneDeep(
		featureIntegratedTrackersDefault
	),
	[RemoteConfigParameterKeys.AppSurveyLink]: cloneDeep(appSurveyLinkDefault),
	[RemoteConfigParameterKeys.IntegratedTrackersOverview]: cloneDeep(
		integratedTrackersOverviewDefault
	),
	[RemoteConfigParameterKeys.ContentLikesMinCountThreshold]: contentLikesMinCountThresholdDefault,
	[RemoteConfigParameterKeys.MaxCallCountGuide]: maxCallCountGuideDefault,
	[RemoteConfigParameterKeys.MaxCallCountPharmacist]: maxCallCountPharmacistDefault,
	[RemoteConfigParameterKeys.ScheduleCallVariant]: scheduleCallVariantDefault,
	[RemoteConfigParameterKeys.ReduceHomeComplexityVariant]: reduceHomeComplexityVariantDefault,
	[RemoteConfigParameterKeys.EnableToast]: enableToastDefault,
	[RemoteConfigParameterKeys.AppFeedback]: cloneDeep(appFeedbackDefault),
	[RemoteConfigParameterKeys.FeedbackSurvey]: cloneDeep(feedbackSurveyDefault),
	[RemoteConfigParameterKeys.IncentiveDisclaimer]: incentiveDisclaimerDefault,
};

/**
 * Default values for the app to use when accessing values from Firebase Remote Config.
 * This creates a new object from placeholderData and stringifies any objects.
 */
export const remoteConfigDefaults = Object.fromEntries(
	Object.entries(placeholderData).map(([key, value]) => [
		key,
		typeof value === 'object' ? JSON.stringify(value) : value,
	])
);

function useRemoteConfigQuery() {
	return useQuery({
		queryKey: [REMOTE_CONFIG_QUERY_KEY],
		queryFn: async () => {
			await remoteConfig.setDefaults(remoteConfigDefaults);
			await remoteConfig.fetchConfig(REMOTE_CONFIG_CACHE_TIME_IN_MILLISECONDS);

			return {
				[RemoteConfigParameterKeys.IsUnderConstruction]: remoteConfig
					.getValue(RemoteConfigParameterKeys.IsUnderConstruction)
					.asBoolean(),
				[RemoteConfigParameterKeys.LoginAlert]: JSON.parse(
					remoteConfig.getValue(RemoteConfigParameterKeys.LoginAlert).asString()
				) as typeof loginAlertDefault,
				[RemoteConfigParameterKeys.MaintenanceScreen]: JSON.parse(
					remoteConfig.getValue(RemoteConfigParameterKeys.MaintenanceScreen).asString()
				) as typeof maintenanceScreenDefault,
				[RemoteConfigParameterKeys.FeatureIntegratedTrackers]: JSON.parse(
					remoteConfig.getValue(RemoteConfigParameterKeys.FeatureIntegratedTrackers).asString()
				) as typeof featureIntegratedTrackersDefault,
				[RemoteConfigParameterKeys.AppSurveyLink]: JSON.parse(
					remoteConfig.getValue(RemoteConfigParameterKeys.AppSurveyLink).asString()
				) as typeof appSurveyLinkDefault,
				[RemoteConfigParameterKeys.IntegratedTrackersOverview]: JSON.parse(
					remoteConfig.getValue(RemoteConfigParameterKeys.IntegratedTrackersOverview).asString()
				) as typeof integratedTrackersOverviewDefault,
				[RemoteConfigParameterKeys.ContentLikesMinCountThreshold]: remoteConfig
					.getValue(RemoteConfigParameterKeys.ContentLikesMinCountThreshold)
					.asNumber(),
				[RemoteConfigParameterKeys.MaxCallCountGuide]: remoteConfig
					.getValue(RemoteConfigParameterKeys.MaxCallCountGuide)
					.asNumber(),
				[RemoteConfigParameterKeys.MaxCallCountPharmacist]: remoteConfig
					.getValue(RemoteConfigParameterKeys.MaxCallCountPharmacist)
					.asNumber(),
				[RemoteConfigParameterKeys.ScheduleCallVariant]: remoteConfig
					.getValue(RemoteConfigParameterKeys.ScheduleCallVariant)
					.asBoolean(),
				[RemoteConfigParameterKeys.ReduceHomeComplexityVariant]: remoteConfig
					.getValue(RemoteConfigParameterKeys.ReduceHomeComplexityVariant)
					.asBoolean(),
				[RemoteConfigParameterKeys.EnableToast]: remoteConfig
					.getValue(RemoteConfigParameterKeys.EnableToast)
					.asBoolean(),
				[RemoteConfigParameterKeys.AppFeedback]: JSON.parse(
					remoteConfig.getValue(RemoteConfigParameterKeys.AppFeedback).asString()
				) as typeof appFeedbackDefault,
				[RemoteConfigParameterKeys.FeedbackSurvey]: JSON.parse(
					remoteConfig.getValue(RemoteConfigParameterKeys.FeedbackSurvey).asString()
				) as typeof feedbackSurveyDefault,
				[RemoteConfigParameterKeys.IncentiveDisclaimer]: remoteConfig
					.getValue(RemoteConfigParameterKeys.IncentiveDisclaimer)
					.asString(),
			};
		},
		placeholderData,
		staleTime: Infinity,
	});
}

export default function useRemoteConfigData() {
	const remoteConfigQuery = useRemoteConfigQuery();
	return remoteConfigQuery.data || placeholderData;
}
