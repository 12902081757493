import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as SecureStore from 'expo-secure-store';

const SECURE_STORE_QUERY_KEY = 'secureStore';

export function useSecureStoreQuery<ValueType>(key: string) {
	return useQuery({
		queryKey: [SECURE_STORE_QUERY_KEY, key],
		queryFn: async () => {
			if (await SecureStore.isAvailableAsync()) {
				const value = await SecureStore.getItemAsync(key);

				if (value) {
					return JSON.parse(value) as ValueType;
				} else {
					return null;
				}
			} else {
				return null;
			}
		},
		staleTime: Infinity,
	});
}

export function useSecureStoreMutation<ValueType>(key: string) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ value }: { value: ValueType }) => {
			if (await SecureStore.isAvailableAsync()) {
				await SecureStore.setItemAsync(key, JSON.stringify(value));
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [SECURE_STORE_QUERY_KEY, key] });
		},
	});
}
