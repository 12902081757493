import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import merge from 'lodash/merge';
import { TextStyle, ViewStyle } from 'react-native';

export type ButtonVariants = 'primary' | 'secondary' | 'outline' | 'outlineInverted' | 'neutral';
export type ButtonSizes = 'regular' | 'sm';

interface IButtonComponents {
	button?: ViewStyle;
	buttonText?: TextStyle;
	loadingIndicator?: ViewStyle;
}

export default function useButtonStyles({
	disabled,
	active,
	variant,
	size,
}: {
	disabled: boolean;
	active: boolean;
	variant: ButtonVariants;
	size: ButtonSizes;
}): IButtonComponents {
	const rules = useStyleRules();

	const base: IButtonComponents = {
		button: {
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			alignSelf: 'center',
			width: '100%',
			maxWidth: rules.spacing.maxWidth / 2,
			paddingHorizontal: 25 / rules.settings.fontScale,
			borderWidth: 1,
			borderRadius: 50,
			opacity: active ? 0.8 : 1,
		},
		buttonText: {
			fontFamily: rules.fontFamilies.sansBold,
			fontSize: rules.fontSizes.md,
		},
		loadingIndicator: {
			position: 'absolute',
			height: '100%',
			justifyContent: 'center',
		},
	};

	// Color variants
	const variants: { [key in ButtonVariants]: IButtonComponents } = {
		primary: {
			button: {
				borderColor: rules.colors.primary,
				backgroundColor: rules.colors.primary,
			},
			buttonText: {
				color: rules.colors.textInverted,
			},
		},
		secondary: {
			button: {
				borderColor: rules.colors.primary,
			},
			buttonText: {
				color: rules.colors.primary,
			},
		},
		outline: {
			button: {
				borderColor: rules.colors.text,
			},
			buttonText: {
				color: rules.colors.text,
			},
		},
		outlineInverted: {
			button: {
				borderColor: rules.colors.textInverted,
			},
			buttonText: {
				color: rules.colors.textInverted,
			},
		},
		neutral: {
			button: {
				borderRadius: 5,
				borderColor: rules.colors.stroke,
				backgroundColor: rules.colors.cardBackground,
			},
			buttonText: {
				color: rules.colors.text,
				fontFamily: rules.fontFamilies.sans,
			},
		},
	};

	// Size variants
	const sizes: { [key in ButtonSizes]: IButtonComponents } = {
		regular: {
			button: {
				height: Math.min(50 * rules.settings.fontScale, 60),
			},
		},
		sm: {
			button: {
				alignSelf: 'flex-start',
				width: 'auto',
				height: Math.min(40 * rules.settings.fontScale, 55),
			},
		},
	};

	// State variants
	const disabledStyles: IButtonComponents = {
		button: {
			backgroundColor: rules.colors.disabled,
			borderColor: rules.colors.disabled,
		},
		buttonText: {
			color: rules.colors.textInverted,
		},

		...(variant === 'secondary' && {
			button: {
				opacity: 0.5,
			},
			buttonText: {},
		}),
	};

	return merge(
		{},
		base,
		variants[variant],
		sizes[size],
		disabled && variant !== 'neutral' && disabledStyles
	);
}
