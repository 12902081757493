import { useCoachesQuery } from '@mobe/api/guides/guidesApiHooks';
import { useLoginCount } from '@mobe/utils/storage/appStateStorage';
import { useStorageObject } from '@mobe/utils/storage/storage';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import * as React from 'react';
import { Platform } from 'react-native';
import * as AppFeedbackAnalytics from './analyticsEvents';

export const APP_FEEDBACK_KEY = 'appFeedback';

interface IAppFeedback {
	/** Number of times the PPT has been asked to provide feedback */
	promptCount: number;

	/** Login count at which the prompt was last displayed */
	lastLoginCount?: number;
}

const INITIAL_DATA: IAppFeedback = { promptCount: 0 };

function useAppFeedbackStorage() {
	return useStorageObject<IAppFeedback>(APP_FEEDBACK_KEY, INITIAL_DATA);
}

interface IAppFeedbackProviderProps {
	children: React.ReactNode;
}

interface IAppFeedbackProviderContextValue {
	show: (delay?: number) => void;
	dismiss: () => void;
	isFeedbackShown: boolean;
}

const AppFeedbackContext = React.createContext<IAppFeedbackProviderContextValue | undefined>(
	undefined
);

function AppFeedbackProvider({ children }: IAppFeedbackProviderProps) {
	const [isFeedbackShown, setIsFeedbackShown] = React.useState(false);

	function show(delay?: number) {
		AppFeedbackAnalytics.appFeedbackPrompt();

		setTimeout(() => {
			setIsFeedbackShown(true);
		}, delay ?? 7000);
	}

	function dismiss() {
		setIsFeedbackShown(false);
	}

	return (
		<AppFeedbackContext.Provider value={{ show, dismiss, isFeedbackShown }}>
			{children}
		</AppFeedbackContext.Provider>
	);
}

function useAppFeedback() {
	const [loginCount] = useLoginCount();
	const { appFeedback } = useRemoteConfigData();
	const [{ promptCount, lastLoginCount = 0 }, setAppFeedbackStorage] = useAppFeedbackStorage();
	const coachesQuery = useCoachesQuery();
	const context = React.useContext(AppFeedbackContext);
	const hasGuide = coachesQuery.data?.length;

	if (context === undefined) {
		throw new Error('useAppFeedback must be used within AppFeedbackProvider');
	}

	function feedbackProvided() {
		setAppFeedbackStorage({
			promptCount: appFeedback.maxPromptCount,
			lastLoginCount,
		});
	}

	function showPromptIfConditionsAreMet(delay?: number) {
		if (
			Platform.OS !== 'web' &&
			loginCount >= appFeedback.minLoginCount &&
			hasGuide &&
			promptCount < appFeedback.maxPromptCount &&
			loginCount >= lastLoginCount + appFeedback.loginCountPromptInterval
		) {
			context?.show(delay);
			setAppFeedbackStorage({
				promptCount: promptCount + 1,
				lastLoginCount: loginCount,
			});
		}
	}

	return {
		show: showPromptIfConditionsAreMet,
		dismiss: context.dismiss,
		feedbackProvided,
		isFeedbackShown: context.isFeedbackShown,

		/**
		 * This is only here for testing, do not use for a feature
		 */
		_forceShow: context.show,
	};
}

export { AppFeedbackProvider, useAppFeedback };
