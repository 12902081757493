import axios from 'axios';
import * as React from 'react';
import { client } from '../client';
import { useIsAuthenticated, useLogoutMutation } from './authApiHooks';
import { LogoutType } from './authenticationService';

export function useAuthSessionTimeoutInterceptor() {
	const isAuthenticated = useIsAuthenticated();
	const logoutMutation = useLogoutMutation();

	React.useEffect(() => {
		let interceptorId: number;

		if (isAuthenticated) {
			interceptorId = client.interceptors.response.use(undefined, (error) => {
				if (axios.isAxiosError(error)) {
					const isUnauthorized = error.response?.status === 401;
					const isCallToLogout = error.config?.url?.indexOf('logout') !== -1;

					if (isUnauthorized && !isCallToLogout) {
						logoutMutation.mutate({ logoutType: LogoutType.SessionExpired });
					}
				}

				return Promise.reject(error);
			});
		}

		return () => {
			if (interceptorId) {
				client.interceptors.response.eject(interceptorId);
			}
		};
	}, [isAuthenticated]);
}
