import { useAppFeedback } from '@mobe/components/appFeedbackPopup/AppFeedbackProvider';
import { useCongratsPopupStore } from '@mobe/components/congratsPopup/useCongratsPopupStore';
import useActionStepToast from '@mobe/components/toast/useActionStepToast';
import * as PlanAnalyticsEvents from '@mobe/features/plan/analyticsEvents';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import {
	keepPreviousData,
	useInfiniteQuery,
	useMutation,
	UseMutationOptions,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import { CelebrationsImageType } from '../celebrations/celebrationsService';
import {
	IPaginationRequest,
	metadataToPagination,
	paginationToMetadataRequest,
} from '../paginationUtils';
import { TrackerAbbreviation } from '../track/trackService';
import {
	ActionStepStatus,
	getActionStep,
	getActionSteps,
	getGoal,
	getGoals,
	GoalStatus,
	GoalType,
	IActionStep,
	IGetActionStepsResponse,
	IGoalFull,
	ISetActionStepStatus,
	setActionStepStatus,
	setGoalStatus,
} from './goalsService';

export enum GoalsQueryKeys {
	Goals = 'Goals',
	Goal = 'Goal',
	ActionSteps = 'ActionSteps',
	ActionStep = 'ActionStep',
}

interface IUseGoalsQuery extends IPaginationRequest {
	type: GoalType;
	status: GoalStatus;
}

export function useGoalsQuery({ limit = 6, page, status, type }: IUseGoalsQuery) {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [GoalsQueryKeys.Goals, type, status, limit, page],
		queryFn: () =>
			getGoals({
				type,
				status,
				...paginationToMetadataRequest({ limit, page }),
			}),
		enabled: isAuthenticated,
		staleTime: type === 'LongTerm' ? Infinity : 60 * 1000,
		placeholderData: keepPreviousData,
		select: (data) => ({
			pagination: metadataToPagination(data.metadata),
			goals: data.data,
		}),
	});
}

export function useInvalidateGoalsQuery() {
	const queryClient = useQueryClient();
	return (type?: GoalType, status?: GoalStatus) =>
		queryClient.invalidateQueries({
			queryKey: [GoalsQueryKeys.Goals, ...(type ? [type] : []), ...(status ? [status] : [])],
		});
}

export function useGoalDetailQuery(id: number) {
	return useQuery({
		queryKey: [GoalsQueryKeys.Goal, id],
		queryFn: () => getGoal(id),
		select: (data) => {
			if (!data.trackerAbbreviation) return data;
			return {
				...data,
				trackerAbbreviation: Object.values(TrackerAbbreviation).includes(data.trackerAbbreviation)
					? data.trackerAbbreviation
					: null,
			};
		},
	});
}

export function useSetGoalStatusMutation() {
	return useMutation({ mutationFn: setGoalStatus });
}

export function useInvalidateGoalDetailQuery() {
	const queryClient = useQueryClient();
	return (id: number) => queryClient.invalidateQueries({ queryKey: [GoalsQueryKeys.Goal, id] });
}

interface IUseActionStepsQuery extends IPaginationRequest {
	status: ActionStepStatus;
}

export function useActionStepsQuery(params: IUseActionStepsQuery) {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [GoalsQueryKeys.ActionSteps, params.status, params.limit, params.page],
		queryFn: () =>
			getActionSteps({
				status: params.status,
				...paginationToMetadataRequest(params),
			}),
		enabled: isAuthenticated,
		staleTime: 30 * 1000,
		placeholderData: keepPreviousData,
		select: (data) => ({
			pagination: metadataToPagination(data.metadata),
			weekTotal: data.metadata.thisWeekCount,
			actionSteps: data.data,
		}),
	});
}

export function useActionStepDetailQuery(id: number) {
	return useQuery({
		queryKey: [GoalsQueryKeys.ActionStep, id],
		queryFn: () => getActionStep(id),
		select: (data) => {
			if (!data.trackerAbbreviation) return data;
			return {
				...data,
				trackerAbbreviation: Object.values(TrackerAbbreviation).includes(data.trackerAbbreviation)
					? data.trackerAbbreviation
					: null,
			};
		},
	});
}

export function useActionStepsInfiniteQuery(params: IUseActionStepsQuery) {
	const isAuthenticated = useIsAuthenticated();

	return useInfiniteQuery({
		queryKey: [GoalsQueryKeys.ActionSteps, params.status, params.limit, params.page],
		initialPageParam: params.page,
		queryFn: ({ pageParam }) =>
			getActionSteps({
				status: params.status,
				...paginationToMetadataRequest({ ...params, page: pageParam }),
			}),
		enabled: isAuthenticated,
		staleTime: 30 * 1000,
		getNextPageParam: (lastPage) => {
			const pagination = metadataToPagination(lastPage.metadata);

			if (pagination.page * pagination.limit < pagination.total) {
				return pagination.page + 1;
			}
		},
		select: (data) => ({
			...data,
			pages: data.pages.map((actionsStepsPage) => actionsStepsPage.data),
		}),
	});
}

export function useSetSubStepStatusMutation() {
	return useMutation({ mutationFn: setActionStepStatus });
}

export function useSetSubStepOptions() {
	const genericErrorAlert = useGenericErrorAlert();
	const invalidateActionStepsQueries = useInvalidateActionStepsQueries();
	const invalidateActionStepQuery = useInvalidateActionStepQuery();
	const appFeedback = useAppFeedback();
	const actionStepToast = useActionStepToast();
	const congratsPopupStore = useCongratsPopupStore();
	const undoSubStepCompletion = useUndoSubStepCompletion();
	const { t } = useTranslation();

	return ({
		actionStep,
		onSuccess = noop,
		onLastStepSuccess = noop,
		onSuccessSettled = noop,
		onUndo = noop,
	}: {
		actionStep: IActionStep;
		onSuccess?: () => void;
		onLastStepSuccess?: () => void;
		onSuccessSettled?: () => void;
		onUndo?: () => void;
	}): UseMutationOptions<unknown, unknown, ISetActionStepStatus> => {
		const isLastSubStep = actionStep.subStepsComplete + 1 >= actionStep.subStepsTotal;
		return {
			onSuccess: () => {
				onSuccess();

				if (!isLastSubStep) {
					setTimeout(() => {
						onSuccessSettled();
						actionStepToast(actionStep, () => undoSubStepCompletion(actionStep, onUndo));
						invalidateActionStepsQueries();
						invalidateActionStepQuery(actionStep.id);
					}, 1000);
					return;
				}

				congratsPopupStore.setMessage({
					title: t('plan.congratsPopup.actionStepComplete.title'),
					message: t('plan.congratsPopup.actionStepComplete.message'),
					imageType: CelebrationsImageType.ActionStepComplete,
					hasConfetti: true,
					onClose: () => {
						onLastStepSuccess();
						onSuccessSettled();
						invalidateActionStepsQueries();
						invalidateActionStepQuery(actionStep.id);
						appFeedback.show();
					},
					onUndo: () => undoSubStepCompletion(actionStep, onUndo),
				});
			},
			onError: () => {
				genericErrorAlert();
				invalidateActionStepsQueries();
				invalidateActionStepQuery(actionStep.id);
			},
		};
	};
}

export function useUndoSubStepCompletion() {
	const optimisticallyUpdateActionStep = useOptimisticallyUpdateActionStep();
	const setSubStepStatusMutation = useSetSubStepStatusMutation();
	const invalidateActionStepQuery = useInvalidateActionStepQuery();
	const invalidateActionStepsQueries = useInvalidateActionStepsQueries();

	return (actionStep: IActionStep, onUndoSuccess?: () => void) => {
		PlanAnalyticsEvents.actionStepCompleteUndoPress();

		optimisticallyUpdateActionStep({
			id: actionStep.id,
			subStepsComplete: actionStep.subStepsComplete,
		});

		setSubStepStatusMutation.mutate(
			{
				actionStepId: actionStep.id,
				status: 'InProgress',
			},
			{
				onSuccess: () => {
					invalidateActionStepQuery(actionStep.id);
					invalidateActionStepsQueries();
					if (onUndoSuccess) onUndoSuccess();
				},
			}
		);
	};
}

export function useInvalidateActionStepQuery() {
	const queryClient = useQueryClient();
	return (actionStepId: number) =>
		queryClient.invalidateQueries({ queryKey: [GoalsQueryKeys.ActionStep, actionStepId] });
}

export function useInvalidateActionStepsQueries() {
	const queryClient = useQueryClient();
	return () => queryClient.invalidateQueries({ queryKey: [GoalsQueryKeys.ActionSteps] });
}

/**
 * Updates all inProgress action step queries and detail query with sub step count.
 * Updates goal detail query, and only goal detail query, if goalId is provided.
 */
export function useOptimisticallyUpdateActionStep() {
	const queryClient = useQueryClient();
	return ({
		id,
		subStepsComplete,
		goalId,
	}: {
		id: number;
		subStepsComplete: number;
		goalId?: number;
	}) => {
		// Goal query update
		if (goalId) {
			queryClient.setQueryData<Partial<IGoalFull> | undefined>(
				[GoalsQueryKeys.Goal, goalId],
				(oldData) => {
					return oldData
						? {
								...oldData,
								actions: oldData.actions?.map((actionStep) => {
									if (actionStep.id === id) {
										return {
											...actionStep,
											subStepsComplete,
										};
									}

									return actionStep;
								}),
						  }
						: undefined;
				}
			);

			return;
		}

		// Action step detail update
		queryClient.setQueryData<Partial<IActionStep> | undefined>(
			[GoalsQueryKeys.ActionStep, id],
			(oldData) => {
				return oldData ? { ...oldData, subStepsComplete } : undefined;
			}
		);

		// In progress action step queries update
		const inProgressStatus: ActionStepStatus = 'InProgress';
		queryClient.setQueriesData<Partial<IGetActionStepsResponse>>(
			{ queryKey: [GoalsQueryKeys.ActionSteps, inProgressStatus] },
			(oldData) => {
				// Searches through all existing Actions Steps queries for id and updates accordingly
				return {
					metadata: oldData?.metadata,
					data: oldData?.data?.map((actionStep) =>
						actionStep.id === id ? { ...actionStep, subStepsComplete } : actionStep
					),
				};
			}
		);
	};
}
