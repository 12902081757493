import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { LinearGradient, Path, Stop, SvgProps } from 'react-native-svg';

export default function SubStepsGradientGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(50, 50);
	const styleRules = useStyleRules();

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<LinearGradient
					id="actionStepGradient"
					x1={0}
					x2={50}
					y1={25}
					y2={25}
					gradientUnits="userSpaceOnUse"
				>
					<Stop offset={0} stopColor={styleRules.colors.cardBackground} />
					<Stop offset={1} stopColor={styleRules.colors.cardBackground} stopOpacity={0} />
				</LinearGradient>
				<Path fill="url(#actionStepGradient)" d="M0 0h50v50H0z" />
			</Svg>
		</View>
	);
}
