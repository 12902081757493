import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import Color from 'color';
import * as Haptics from 'expo-haptics';
import { kebabCase } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, useWindowDimensions, View } from 'react-native';
import { Text } from '../text';

interface ILikertScaleInputProps {
	value?: number;
	label: string;
	labelLeft: string;
	labelCenter?: string;
	labelRight: string;
	onPress: (value: number) => void;
	accessibilityAutoFocus?: boolean;
}

export default function LikertScaleInput({
	value,
	label,
	labelLeft,
	labelCenter,
	labelRight,
	onPress,
	accessibilityAutoFocus = false,
}: ILikertScaleInputProps) {
	const { t } = useTranslation();
	const styles = useStyles();
	const { vr, constrainText } = useStyleHelpers();
	const descriptionLabelId = kebabCase(label);

	// Between X and Y a11y labels
	const accessibilityLabels = [
		labelLeft,
		t('accessibility.betweenLabelStartAndLabelEnd', {
			labelStart: labelLeft,
			labelEnd: labelCenter,
		}),
		labelCenter,
		t('accessibility.betweenLabelStartAndLabelEnd', {
			labelStart: labelCenter,
			labelEnd: labelRight,
		}),
		labelRight,
	];

	function handlePress(value: number) {
		Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
		onPress(value);
	}

	return (
		<View style={styles.inputWrapper}>
			<Text
				id={descriptionLabelId}
				weight="medium"
				size="xl"
				align="center"
				style={[constrainText(300), vr(8)]}
				accessibilityAutoFocus={accessibilityAutoFocus}
			>
				{label}
			</Text>
			<View role="radiogroup" style={styles.input}>
				<View style={styles.inputLine}></View>
				{Array(5)
					.fill('')
					.map((empty, index) => {
						const optionValue = index + 1;
						const isSelected = value === optionValue;
						return (
							<Pressable
								key={index}
								role="radio"
								aria-checked={isSelected}
								aria-label={`${optionValue}, ${accessibilityLabels[index]}`}
								aria-describedby={descriptionLabelId}
								onPress={() => handlePress(optionValue)}
							>
								{isSelected && <View style={styles.inputOptionGlow}></View>}
								<View style={[styles.inputOption, isSelected && styles.inputOption_selected]}>
									<Text color={isSelected ? 'inverted' : 'light'} weight="semiBold">
										{optionValue}
									</Text>
								</View>
							</Pressable>
						);
					})}
			</View>
			{/* The visual labels are hidden from screen readers because they are redundant with the button aria-labels */}
			<View aria-hidden style={styles.inputRange}>
				<View style={styles.inputRangeLabel}>
					<Text align="center" color="light">
						{labelLeft}
					</Text>
				</View>
				{labelCenter && (
					<View style={[styles.inputRangeLabel, styles.inputRangeLabel_center]}>
						<Text align="center" color="light">
							{labelCenter}
						</Text>
					</View>
				)}
				<View style={styles.inputRangeLabel}>
					<Text align="center" color="light">
						{labelRight}
					</Text>
				</View>
			</View>
		</View>
	);
}

function useStyles() {
	const rules = useStyleRules();
	const rangeLabelWidth = 100 * rules.settings.fontScale;
	const optionWidth = 44;
	const firstOptionOffset = (rangeLabelWidth - optionWidth) / 2;
	const windowWidth = useWindowDimensions().width;

	return StyleSheet.create({
		inputWrapper: {
			marginTop: 'auto',
			marginBottom: 'auto',
			alignSelf: 'center',
			width: '100%',
			maxWidth: 500,
		},
		input: {
			paddingHorizontal: firstOptionOffset,
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: 4,
		},
		inputLine: {
			position: 'absolute',
			top: '50%',
			left: firstOptionOffset,
			right: firstOptionOffset,
			transform: [{ translateY: -2 }],
			height: 4,
			backgroundColor: rules.colors.strokeLight,
		},
		inputOption: {
			width: optionWidth,
			height: optionWidth,
			backgroundColor: rules.colors.cardBackground,
			borderRadius: optionWidth,
			borderColor: rules.colors.stroke,
			borderWidth: 3,
			justifyContent: 'center',
			alignItems: 'center',
		},
		inputOption_selected: {
			backgroundColor: rules.colors.primary,
			borderColor: rules.colors.primary,
		},
		inputOptionGlow: {
			position: 'absolute',
			top: -6,
			left: -6,
			right: -6,
			bottom: -6,
			backgroundColor: Color(rules.colors.primary).alpha(0.25).toString(),
			zIndex: -1,
			borderRadius: optionWidth,
		},
		inputRange: {
			marginTop: 8,
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		inputRangeLabel: {
			width: rangeLabelWidth,
		},
		inputRangeLabel_center: {
			// Hides the center label if it cannot fit between the other two
			display: rangeLabelWidth * 3 > windowWidth ? 'none' : 'flex',
		},
	});
}
