import { client } from '../client';

interface IAPIExploreContentCategory {
	/**
	 * Content items
	 */
	consumerSharedContents: IExploreContentItemSummary[];
	pathwayName: string;
}

export interface IExploreContentItemSummary {
	// Common properties for summary and full
	title: string;
	contentType: ExploreContentType;
	shareableContentId: number;
	sharedContentId: number;
	isFavorite: boolean;
	isViewed: boolean;
	imageUrl: string | null;

	/**
	 * Time in minutes
	 */
	timeToConsume: number | null;
}

export interface IExploreHelpfulResponse {
	sharedContentId: number;
	isHelpful: boolean;
}

interface IExploreContentItemDetailsResponse {
	sharedContentDetailsModel: IExploreContentItemFull;
}

export enum ExploreContentType {
	Article = 'Article',
	Video = 'Video',
	Recipe = 'Recipe',
	Audio = 'Audio',
	Habits = 'Habits',
}

export interface IExploreContentItemFull extends IExploreContentItemSummary {
	contentUrl: string;
	fileUrl: string;
	contentHtml: string;
	description: string;
	isHelpful: boolean | null;
	helpfulCount: number;
	callToActions: Array<'PharmacistProfile' | 'GuideProfile'>;
}

export interface IFeaturedSharedContentItemSummary extends IExploreContentItemSummary {
	contentUrl: string;
	contentHtml: string;
}

export interface IExploreContentCategory {
	id: string;
	items: IExploreContentItemSummary[];
}

export interface IExploreFavoriteResponse {
	sharedContentId: number;
	isFavorite: boolean;
}

export interface IContentModule {
	contentModuleId: number;
	title: string;
	screenTitle: string;
	shortDescription: string;
	longDescription: string;
	heroDescription: string;
	button: string;
	imageUrl: string;
	backgroundColor: string;
	sharedContent: IExploreContentItemSummary[];
}

export async function getAllContent() {
	const response = await client.get('shared-contents');
	const data = response.data as IAPIExploreContentCategory[];
	const mappedData: IExploreContentCategory[] = data.map((item) => ({
		id: item.pathwayName,
		items: item.consumerSharedContents,
	}));
	return mappedData;
}

export async function getAllFeaturedSharedContent() {
	const response = await client.get('featured-shared-contents');
	return response.data as IFeaturedSharedContentItemSummary[];
}

export interface ISetFavoriteStatus {
	sharedContentId: number;
	isFavorite: boolean;
}

export async function setFavoriteStatus({ sharedContentId, isFavorite }: ISetFavoriteStatus) {
	const response = await client.put(`shared-contents/${sharedContentId}/favorite`, {
		isFavorite,
	});
	return response.data as IExploreFavoriteResponse;
}

export interface ISetHelpfulStatus {
	sharedContentId: number;
	isHelpful: boolean;
}

export async function setHelpfulStatus({ sharedContentId, isHelpful }: ISetHelpfulStatus) {
	await client.patch(`shared-contents/${sharedContentId}`, {
		isHelpful,
	});
	return {};
}

export async function getContentDetail(sharedContentId: number | null) {
	const response = await client.get(`shared-contents/${sharedContentId}/details`);
	const data = response.data as IExploreContentItemDetailsResponse;
	return data.sharedContentDetailsModel;
}

export interface ISetViewTime {
	sharedContentId: number;
	viewTime: number;
}

export async function setViewTime({ sharedContentId, viewTime }: ISetViewTime) {
	await client.post(`shared-contents/${sharedContentId}/view-histories`, {
		viewTime,
	});
	return {};
}

export async function getContentModules() {
	const response = await client.get(`shared-contents/content-modules`);
	return response.data as IContentModule[];
}
