import IconButton from '@mobe/components/iconButton/IconButton';
import MobeImage from '@mobe/components/mobeImage/MobeImage';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, StyleSheet, useWindowDimensions, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface IImageModalProps {
	children: ({ show, hide }: { show: () => void; hide: () => void }) => React.ReactNode;
	imageId: number | null;
}

export default function ImageModal({ children, imageId }: IImageModalProps) {
	const [showModal, setShowModal] = React.useState(false);
	const windowDimensions = useWindowDimensions();
	const styles = useStyles();
	const { t } = useTranslation();

	function show() {
		if (typeof imageId !== 'number') return;
		setShowModal(true);
	}

	function hide() {
		if (typeof imageId !== 'number') return;
		setShowModal(false);
	}

	return (
		<>
			{typeof children === 'function' ? children({ show, hide }) : null}

			{imageId ? (
				<Modal
					animationType="fade"
					transparent
					statusBarTranslucent
					visible={showModal}
					onRequestClose={hide}
				>
					<View style={styles.modal}>
						<View
							style={styles.modalInner}
							aria-label={t('chat.directMessageScreen.fullSizeImageAccessibilityLabel')}
						>
							<MobeImage
								apiImageId={imageId}
								renderWidth={windowDimensions.width - styles.modal.padding * 2}
								sizesArray={[740, 788]}
								resizeMode="contain"
								style={styles.image}
							/>
						</View>
						<IconButton
							style={styles.buttonClose}
							name="close"
							onPress={hide}
							aria-label={t('accessibility.closeButtonLabel')}
						/>
					</View>
				</Modal>
			) : null}
		</>
	);
}

function useStyles() {
	const rules = useStyleRules();
	const insets = useSafeAreaInsets();

	return StyleSheet.create({
		modal: {
			width: '100%',
			height: '100%',
			backgroundColor: rules.colors.cardBackground,
			padding: 10,
			alignItems: 'center',
			justifyContent: 'center',
		},
		modalInner: {
			width: '100%',
			height: '100%',
			maxWidth: rules.spacing.modalMaxWidth,
			paddingTop: insets.top,
			paddingBottom: insets.bottom,
			alignItems: 'center',
			justifyContent: 'center',
		},
		image: {
			width: '100%',
			height: '100%',
		},
		buttonClose: {
			position: 'absolute',
			top: 10 + insets.top,
			right: 15,
			padding: 10,
			backgroundColor: rules.colors.cardBackground,
			borderRadius: 50,
		},
	});
}
