import { Button } from '@mobe/components/button';
import Heading from '@mobe/components/heading/Heading';
import MobeImage from '@mobe/components/mobeImage/MobeImage';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions, View } from 'react-native';
import { contentCardImageProps } from './ContentCard';
import './exploreContentViewStyles.css';
import GuideConnectCTA from './GuideConnectCTA';
import IsHelpfulModule from './IsHelpfulModule';
import { IMobeContentViewProps } from './MobeContentView';

export default function MobeContentView({
	contentItem,
	showGuideConnectCTA = true,
	showLikeDislike = false,
}: IMobeContentViewProps) {
	const { t } = useTranslation();
	const windowDimensions = useWindowDimensions();
	const styleRules = useStyleRules();
	const { wrapper } = useStyleHelpers();

	return (
		<ScreenTemplate noPadding>
			{(contentItem?.contentHtml || contentItem?.contentUrl) && (
				<>
					<View style={wrapper}>
						{contentItem.title && (
							<Heading level="h2" accessibilityAutoFocus>
								{contentItem.title}
							</Heading>
						)}
						{contentItem.timeToConsume && (
							<Text color="light">
								{t('explore.consumeTime', { minutes: contentItem.timeToConsume })}
							</Text>
						)}
					</View>
					{contentItem.imageUrl && (
						<View style={{ maxHeight: 400 }}>
							<MobeImage
								{...contentCardImageProps(contentItem.imageUrl, windowDimensions.width, 'regular')}
								style={{ width: '100%', aspectRatio: 1.5 }}
							/>
							<View
								style={{ position: 'absolute', width: '100%', height: '100%', aspectRatio: 1.5 }}
							>
								<MobeImage
									source={{ uri: contentItem.imageUrl }}
									renderWidth={Math.min(windowDimensions.width, styleRules.spacing.maxWidth)}
									style={{
										width: '100%',
										height: '100%',
									}}
									// Same sizesArray as native with addition of full width desktop
									sizesArray={[750, 780, 828, 896]}
								/>
							</View>
						</View>
					)}
					<View style={wrapper}>
						{contentItem.contentUrl && (
							<Button
								title={t('explore.openExternalLinkButton')}
								onPress={() => window.open(contentItem.contentUrl, '_blank')}
							/>
						)}
						{contentItem.contentHtml && (
							<div
								id="explore-content"
								className="mobe-content"
								dangerouslySetInnerHTML={{ __html: contentItem.contentHtml }}
							/>
						)}
					</View>
					<View style={{ marginTop: 'auto' }}>
						{showLikeDislike && <IsHelpfulModule contentId={contentItem.sharedContentId} />}
						{showGuideConnectCTA && (
							<GuideConnectCTA
								showGuide={contentItem.callToActions.includes('GuideProfile')}
								showPharmacist={contentItem.callToActions.includes('PharmacistProfile')}
							/>
						)}
					</View>
				</>
			)}
		</ScreenTemplate>
	);
}
