import { useAllActivitiesQuery } from '@mobe/api/activities/activitiesApiHooks';
import { useAllContentQuery } from '@mobe/api/explore/exploreApiHooks';
import { useActionStepsQuery, useGoalsQuery } from '@mobe/api/goals/goalsApiHooks';
import { useProgressQuery } from '@mobe/api/progress/progressApiHooks';
import { useTrackersQuery } from '@mobe/api/track/trackApiHooks';
import FastImage, { FastImageSource } from '@mobe/components/mobeImage/fastImage';
import { getImageSource } from '@mobe/components/mobeImage/utils';
import { contentCardImageProps } from '@mobe/features/explore/components/ContentCard';
import { useAppStateChange } from '@mobe/utils/useAppStateChange';
import useRemoteConfigData, { REMOTE_CONFIG_QUERY_KEY } from '@mobe/utils/useRemoteConfigQuery';
import { useQueryClient } from '@tanstack/react-query';
import { slice } from 'lodash';
import * as React from 'react';
import { useWindowDimensions } from 'react-native';
import { useIsAuthenticated } from './api/authentication/authApiHooks';

export default function AppDataPreloader() {
	const isAuthenticated = useIsAuthenticated();
	const [isEssentialDataLoaded, setIsEssentialDataLoaded] = React.useState(false);
	const queryClient = useQueryClient();
	useRemoteConfigData();

	useAppStateChange({
		onActive: React.useCallback(() => {
			// Force refetch of remote config
			queryClient.invalidateQueries({ queryKey: [REMOTE_CONFIG_QUERY_KEY] });
		}, []),
	});

	/**
	 * Observe essential app data loading
	 */
	React.useEffect(() => {
		if (isAuthenticated) {
			setIsEssentialDataLoaded(true);
		}
	}, [isAuthenticated]);

	return <>{isEssentialDataLoaded && <HookBasedPreloader />}</>;
}

function HookBasedPreloader() {
	const windowDimensions = useWindowDimensions();

	useProgressQuery();
	useAllActivitiesQuery();
	useTrackersQuery();
	useGoalsQuery({ type: 'LongTerm', status: 'Completed' });
	useGoalsQuery({ type: 'LongTerm', status: 'InProgress' });
	useGoalsQuery({ type: 'ShortTerm', status: 'Completed' });
	useGoalsQuery({ type: 'ShortTerm', status: 'InProgress' });
	useActionStepsQuery({ status: 'InProgress' });

	const exploreContentQuery = useAllContentQuery();

	// Preload high priority images
	React.useEffect(() => {
		if (exploreContentQuery.data) {
			// Flat array of first two content items of first two content categories
			const priorityImageSources = slice(exploreContentQuery.data, 0, 2)
				.flatMap((category) => slice(category.items, 0, 2))
				.map((item) => getImageSource(contentCardImageProps(item.imageUrl, windowDimensions.width)))
				// Filter out empty URIs, since those will crash Android
				.filter((source) => !(typeof source === 'object' && !source.uri)) as FastImageSource[];

			FastImage.preload(priorityImageSources);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exploreContentQuery.data]);

	return null;
}
