import IconButton from '@mobe/components/iconButton/IconButton';
import useModalStyleProps from '@mobe/utils/styles/navigation/useModalStyleProps';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { useAlert } from '@mobe/utils/useAlert';
import useTransitions from '@mobe/utils/useTransitions';
import {
	CompositeNavigationProp,
	getFocusedRouteNameFromRoute,
	RouteProp,
} from '@react-navigation/native';
import {
	createStackNavigator,
	StackNavigationOptions,
	StackNavigationProp,
} from '@react-navigation/stack';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	OnboardingStackScreenNavigationProp,
	OnboardingStackScreenRouteProp,
} from '../navigation/modal/types';
import * as OnboardingAnalyticsEvents from './analyticsEvents';
import { assessmentScreenSequence } from './assessmentScreenSequence';
import OnboardingConfirmationScreen from './screens/OnboardingConfirmationScreen';
import OnboardingEmotionalHealthScreen from './screens/OnboardingEmotionalHealthScreen';
import OnboardingIntroScreen from './screens/OnboardingIntroScreen';
import OnboardingSleepScreen from './screens/OnboardingSleepScreen';
import OnboardingStepsScreen from './screens/OnboardingStepsScreen';
import OnboardingStressManagementScreen from './screens/OnboardingStressManagementScreen';
import OnboardingWeightScreen from './screens/OnboardingWeightScreen';

export type OnboardingContext = 'initial' | 'activity';

export interface IOnboardingStackScreenParams {
	context: OnboardingContext;
}

export type OnboardingParamList = {
	ONBOARDING_INTRO_SCREEN: undefined;
	ONBOARDING_STRESS_MANAGEMENT_SCREEN: undefined;
	ONBOARDING_EMOTIONAL_HEALTH_SCREEN: undefined;
	ONBOARDING_WEIGHT_SCREEN: undefined;
	ONBOARDING_SLEEP_SCREEN: undefined;
	ONBOARDING_STEPS_SCREEN: undefined;
	ONBOARDING_CONFIRMATION_SCREEN: undefined;
};

type OnboardingStackRouteProps<T extends keyof OnboardingParamList> = RouteProp<
	OnboardingParamList,
	T
>;
type OnboardingStackNavigationProps<T extends keyof OnboardingParamList> = CompositeNavigationProp<
	StackNavigationProp<OnboardingParamList, T>,
	OnboardingStackScreenNavigationProp
>;

export type OnboardingScreenNavigationProp = OnboardingStackNavigationProps<
	keyof OnboardingParamList
>;

export type OnboardingIntroScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_INTRO_SCREEN'>;
export type OnboardingIntroScreenRouteProp = OnboardingStackRouteProps<'ONBOARDING_INTRO_SCREEN'>;

export type OnboardingStressManagementScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_STRESS_MANAGEMENT_SCREEN'>;
export type OnboardingStressManagementScreenRouteProp =
	OnboardingStackRouteProps<'ONBOARDING_STRESS_MANAGEMENT_SCREEN'>;

export type OnboardingEmotionalHealthScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_EMOTIONAL_HEALTH_SCREEN'>;
export type OnboardingEmotionalHealthScreenRouteProp =
	OnboardingStackRouteProps<'ONBOARDING_EMOTIONAL_HEALTH_SCREEN'>;

export type OnboardingWeightScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_WEIGHT_SCREEN'>;
export type OnboardingWeightScreenRouteProp = OnboardingStackRouteProps<'ONBOARDING_WEIGHT_SCREEN'>;

export type OnboardingSleepScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_SLEEP_SCREEN'>;
export type OnboardingSleepScreenRouteProp = OnboardingStackRouteProps<'ONBOARDING_SLEEP_SCREEN'>;

export type OnboardingStepsScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_STEPS_SCREEN'>;
export type OnboardingStepsScreenRouteProp = OnboardingStackRouteProps<'ONBOARDING_STEPS_SCREEN'>;

export type OnboardingConfirmationScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_CONFIRMATION_SCREEN'>;
export type OnboardingConfirmationScreenRouteProp =
	OnboardingStackRouteProps<'ONBOARDING_CONFIRMATION_SCREEN'>;

interface IOnboardingStackScreenProps {
	navigation: OnboardingStackScreenNavigationProp;
	route: OnboardingStackScreenRouteProp;
}

const Onboarding = createStackNavigator<OnboardingParamList>();

export default function OnboardingStackScreen({ navigation, route }: IOnboardingStackScreenProps) {
	const { t } = useTranslation();
	const { mobeAlert } = useAlert();
	const transitions = useTransitions();

	const activeRoute = (getFocusedRouteNameFromRoute(route) ||
		'ONBOARDING_INTRO_SCREEN') as keyof OnboardingParamList;
	const assessmentInProgress = assessmentScreenSequence
		.map((screen) => screen.screenName)
		.includes(activeRoute);

	React.useEffect(() => {
		if (assessmentInProgress) {
			navigation.setOptions({ headerTitle: t('onboarding.title') });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assessmentInProgress]);

	React.useEffect(() => {
		const unsubscribe = navigation.addListener('beforeRemove', (event) => {
			if (!assessmentInProgress) {
				handleRemoved();
				return;
			}

			event.preventDefault();

			mobeAlert(t('onboarding.exitAlert.title'), t('onboarding.exitAlert.description'), [
				{
					text: t('onboarding.exitAlert.leave'),
					onPress: () => {
						handleRemoved();
						navigation.dispatch(event.data.action);
					},
				},
				{
					text: t('onboarding.exitAlert.stay'),
					onPress: noop,
				},
			]);
		});

		return unsubscribe;
	});

	React.useEffect(() => {
		if (activeRoute === 'ONBOARDING_CONFIRMATION_SCREEN') {
			if (route.params?.context === 'initial') {
				OnboardingAnalyticsEvents.assessmentFirstSignInComplete();
			}

			if (route.params?.context === 'activity') {
				OnboardingAnalyticsEvents.assessmentActivityComplete();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeRoute]);

	/** Actions to perform when the screen is removed */
	function handleRemoved() {
		if (route.params?.context === 'initial' && activeRoute !== 'ONBOARDING_CONFIRMATION_SCREEN') {
			OnboardingAnalyticsEvents.assessmentFirstSignInSkip();
		}
	}

	return (
		<Onboarding.Navigator
			initialRouteName="ONBOARDING_INTRO_SCREEN"
			screenOptions={{
				...transitions.SlideFromRight,
				headerShown: false,
				title: t('onboarding.screenTitle'),
			}}
		>
			<Onboarding.Screen name="ONBOARDING_INTRO_SCREEN" component={OnboardingIntroScreen} />
			<Onboarding.Screen
				name="ONBOARDING_STRESS_MANAGEMENT_SCREEN"
				component={OnboardingStressManagementScreen}
			/>
			<Onboarding.Screen
				name="ONBOARDING_EMOTIONAL_HEALTH_SCREEN"
				component={OnboardingEmotionalHealthScreen}
			/>
			<Onboarding.Screen name="ONBOARDING_WEIGHT_SCREEN" component={OnboardingWeightScreen} />
			<Onboarding.Screen name="ONBOARDING_SLEEP_SCREEN" component={OnboardingSleepScreen} />
			<Onboarding.Screen name="ONBOARDING_STEPS_SCREEN" component={OnboardingStepsScreen} />
			<Onboarding.Screen
				name="ONBOARDING_CONFIRMATION_SCREEN"
				component={OnboardingConfirmationScreen}
			/>
		</Onboarding.Navigator>
	);
}

export function useOnboardingStackScreenOptions() {
	const { t } = useTranslation();
	const modalStyleProps = useModalStyleProps();
	const subScreenStyleProps = useSubScreenStyleProps();

	return ({ navigation, route }: IOnboardingStackScreenProps): StackNavigationOptions => ({
		title: t('onboarding.screenTitle'),
		...(route.params?.context === 'initial' ? subScreenStyleProps : modalStyleProps),
		headerTitle: '',
		headerShown: true,
		headerLeft: function HeaderBackIcon() {
			return (
				<IconButton
					name="close"
					aria-label={t('accessibility.closeButtonLabel')}
					onPress={() => {
						if (navigation.canGoBack()) {
							navigation.goBack();
						} else {
							navigation.replace('MENU_STACK');
						}
					}}
					style={{ marginBottom: route.params?.context === 'initial' ? 8 : 0 }}
				/>
			);
		},
	});
}
