import { compareAsc } from 'date-fns';
import qs from 'qs';
import { client } from '../client';
import { CoachType, ICoach } from '../guides/guidesApi';
import { IAppointment } from './appointmentApiHooks';

export enum GenderPreference {
	NoPreference = 'NoPreference',
	Female = 'Female',
	Male = 'Male',
}

export enum LanguagePreference {
	English = 'English',
	Spanish = 'Spanish',
}

export enum ContactOptions {
	Primary = 'Primary',
	Alternate = 'Alternate',
	Other = 'Other',
}

interface IAvailableTimeSlotResponse {
	utcDateTime: string;
	availableGuideDiKeys: string[];
}

export interface IAvailableTimeSlot extends IAvailableTimeSlotResponse {
	utcDateTimeObj: Date;
}

interface ITimeSlotsResponse {
	appointmentDurationInMinutes: number;
	availableDays: IAvailableTimeSlotResponse[];
}

export interface ITimeSlots {
	appointmentDurationInMinutes: number;
	availableDays: IAvailableTimeSlot[];
}

export interface IParticipantInterest {
	id: number;
	name: string;
}

export interface IMatchedGuide {
	guideDiKey: string;
	firstName: string;
	lastName: string;
	preferredName: string;
	bio: string;
	avatarUrl: string;
}

export interface IScheduledAppointmentResponse {
	isAppointmentScheduleSuccessful: boolean;
	appointmentUtcDateTime: string;
	appointmentConfirmationId: number | null;
}

interface IAppointmentResponse {
	avatarUrl: string | null;
	coachId: number;
	guideDIKey: string;
	preferredName: string;
	coachType: CoachType;
	coachTypeId: number;
	coachTypeDisplayName: string;
	isAssigned: boolean;
	confirmationId: string;
	appointmentStartDate: string;
	phoneNumber: string;
	durationInMinutes: number;
}

interface IAppointmentsResponse {
	appointments: IAppointmentResponse[];
}

function getTimeZoneOffsetInMinutes() {
	return -new Date(Date.now()).getTimezoneOffset();
}

function mapUtcDateTimeObject(timeSlots: ITimeSlotsResponse): ITimeSlots {
	return {
		appointmentDurationInMinutes: timeSlots.appointmentDurationInMinutes,
		availableDays: timeSlots.availableDays
			.map((slot) => ({
				utcDateTimeObj: new Date(slot.utcDateTime),
				...slot,
			}))
			.sort((a, b) => compareAsc(a.utcDateTimeObj, b.utcDateTimeObj)),
	};
}

export interface IGetFollowUpTimeSlots {
	guideDiKey: string;
	coachTypeId: number;
	desiredAppointmentMonthDateTime: string;
	confirmationId?: string;
}

export async function getFollowUpTimeSlots(params: IGetFollowUpTimeSlots) {
	const response = await client.get('appointment-types/follow-up/available-time-slots', {
		params: {
			GuideDiKey: params.guideDiKey,
			CoachTypeId: params.coachTypeId,
			AppointmentDateTime: params.desiredAppointmentMonthDateTime,
			ConfirmationId: params.confirmationId,
		},
	});
	return mapUtcDateTimeObject(response.data);
}

export async function getInitialPharmacistTimeSlots(desiredAppointmentMonthDateTime: string) {
	const response = await client.get(
		'guide-types/pharmacist/appointment-types/new-consultation/available-time-slots',
		{
			params: {
				AppointmentDateTime: desiredAppointmentMonthDateTime,
			},
		}
	);
	return mapUtcDateTimeObject(response.data);
}

export async function getAppointmentTimeSlots(
	guide: ICoach | IAppointment | null,
	isInitialPharmacistAppt?: boolean,
	ApptToRescheduleConfirmationId?: string
) {
	const today = new Date(Date.now());

	if (isInitialPharmacistAppt) {
		return await getInitialPharmacistTimeSlots(today.toISOString());
	}

	if (guide) {
		return await getFollowUpTimeSlots({
			guideDiKey: guide.guideDIKey,
			coachTypeId: guide.coachTypeId,
			desiredAppointmentMonthDateTime: today.toISOString(),
			confirmationId: ApptToRescheduleConfirmationId,
		});
	}

	throw new Error('A guide object must be supplied if not initial Rx appt');
}

export interface IGetInitialGuideTimeSlots {
	genderPreference: GenderPreference;
	languagePreference: LanguagePreference;
	desiredAppointmentMonthDateTime: string;
}

/**
 * Returns time slots for guides who match preferred gender and language.
 * This is only ever used during guide selection.
 */
export async function getInitialGuideTimeSlots(params: IGetInitialGuideTimeSlots) {
	const response = await client.get(
		'guide-types/guide/appointment-types/new-consultation/available-time-slots',
		{
			params: {
				GuideGenderPreference: params.genderPreference,
				Language: params.languagePreference,
				AppointmentDateTime: params.desiredAppointmentMonthDateTime,
			},
		}
	);
	return mapUtcDateTimeObject(response.data);
}

export async function getAllInterests() {
	const response = await client.get('interests/all');
	return response.data as IParticipantInterest[];
}

export interface IGetAvailableGuides {
	guideDiKeys: string[];
	preferredGender: GenderPreference;
	preferredLanguage: LanguagePreference;
	interestIds: number[];
}

/**
 * Returns a ranked list of guides to conclude the guide matching sequence.
 */
export async function getAvailableGuides(params: IGetAvailableGuides) {
	const response = await client.get('schedulings/new-consultation/available-guides', {
		params: {
			AvailableGuideDiKeys: params.guideDiKeys,
			GuideGender: params.preferredGender,
			Language: params.preferredLanguage,
			InterestIds: params.interestIds,
		},
		paramsSerializer: (params) => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return response.data as IMatchedGuide[];
}

// Different contexts/places in application you can schedule
export enum SchedulingContext {
	AppGuideMatchForm = 'app.guideMatchForm',
	AppGuideFollowUpForm = 'app.guideFollowUpForm',
	ConnectGuideMatchForm = 'connect.guideMatchForm',
	ConnectGuideFollowUpForm = 'connect.guideFollowUpForm',
	SchedulingForm = 'schedulingForm',
}

export interface IScheduleInitialAppointment {
	guideDiKey: string;
	appointmentDateTime: string;
	interestIds: number[];
	gender: GenderPreference;
	language: LanguagePreference;
	email?: string;
	phoneNumber: string;
	schedulingLocation: SchedulingContext;
}

export async function scheduleInitialAppointment(params: IScheduleInitialAppointment) {
	const response = await client.post(
		'schedulings/new-consultation/schedule-appointment-with-guide',
		{
			...params,
			timeZoneOffsetInMinutes: getTimeZoneOffsetInMinutes(),
		}
	);
	return response.data as IScheduledAppointmentResponse;
}

export interface IScheduleFollowUp {
	guideDiKey: string;
	appointmentDateTime: string;
	coachTypeId: number;
	phoneNumber: string;
	schedulingLocation: SchedulingContext;
}

// NOTE: error format for this endpoint is not consistent with the structure of the others for this API
// NOTE: if we need specific information about how the endpoint fails, this will need to be resolved
export async function scheduleFollowUp(params: IScheduleFollowUp) {
	const response = await client.post('schedulings/follow-up/schedule-appointment', {
		...params,
		timeZoneOffsetInMinutes: getTimeZoneOffsetInMinutes(),
	});
	return response.data as IScheduledAppointmentResponse;
}

export interface IScheduleInitialAppointmentWithPharmacist {
	guideDiKey: string;
	appointmentDateTime: string;
	phoneNumber: string;
	schedulingLocation: SchedulingContext;
}

export async function scheduleInitialAppointmentWithPharmacist(
	params: IScheduleInitialAppointmentWithPharmacist
) {
	const response = await client.post(
		'schedulings/new-consultation/schedule-appointment-with-pharmacist-guide',
		{
			...params,
			timeZoneOffsetInMinutes: getTimeZoneOffsetInMinutes(),
		}
	);
	return response.data as IScheduledAppointmentResponse;
}

interface IDeleteAppointment {
	confirmationId: string;
}

export async function deleteAppointment({ confirmationId }: IDeleteAppointment) {
	await client.delete(`appointments/${confirmationId}`);
	return {};
}

interface IUpdatedAppointmentPayload {
	newPhoneNumber?: string;
	startDate?: string;
	timeZoneOffsetInMinutes?: number;
	email?: string;
	isPhoneNumberTemporary?: boolean;
}

export interface IUpdateAppointment {
	confirmationId: string;
	updatedAppointmentPayload: IUpdatedAppointmentPayload;
}

export async function updateAppointment(params: IUpdateAppointment) {
	await client.patch(`appointments/${params.confirmationId}`, params.updatedAppointmentPayload);
	return {};
}

export async function getAppointments() {
	const response = await client.get('appointments');
	const { appointments } = response.data as IAppointmentsResponse;
	return appointments;
}

export interface ITrackConsumerSchedulingActivity {
	trackingSessionIdentifier: string;
	trackingSessionPageAccessedDate: string;
	firstName: string | null;
	preferredName: string | null;
	lastName: string | null;
	email: string | null;
	gender: string | null;
	hasCoach: boolean | null;
	isRegistered: boolean | null;
	deviceUsed: string | null;
	preferredLanguage: string | null;
	preferredGuideGender: string | null;
	interests: string | null; // comma sep string of names/labels... i.e. 'Overall nutrition and mindful eating,Motivation and mindset'
	selectedTimeSlot: string | null;
	selectedTimeZoneOffsetInMinutes: number | null;
	selectedGuideDIKey: string | null;
	droppedOffAtStep: string | null;
	hasUserCompletedSchedulingFlow: boolean | null;
	isAppointmentSuccessfullyScheduled: boolean | null;
	isSpineScheduling: boolean | null;
}

export async function trackConsumerSchedulingActivity(params: ITrackConsumerSchedulingActivity) {
	await client.post('scheduling-activity-tracks', {
		...params,
	});
	return {};
}
