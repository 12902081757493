import Button from '@mobe/components/button/Button';
import { Callout } from '@mobe/components/callout';
import { WarningGraphic } from '@mobe/components/graphics';
import Heading from '@mobe/components/heading/Heading';
import { InputText, useControlledInputProps } from '@mobe/components/input';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import Text from '@mobe/components/text/Text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { useFocusEffect } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import {
	SettingsEditEmailScreenNavigationProp,
	SettingsEditEmailScreenRouteProp,
} from '../SettingsStackScreen';
import useSettingsEditEmailScreenController from './useSettingsEditEmailScreenController';

export interface ISettingsEditEmailScreenProps {
	navigation: SettingsEditEmailScreenNavigationProp;
	route: SettingsEditEmailScreenRouteProp;
}

export default function SettingsEditEmailScreen({
	navigation,
	route,
}: ISettingsEditEmailScreenProps) {
	const controller = useSettingsEditEmailScreenController({ navigation, route });
	const { t } = useTranslation();

	const newEmailInputProps = useControlledInputProps({
		name: 'newEmail',
		control: controller.form.control,
	});

	const verifyEmailInputProps = useControlledInputProps({
		name: 'verifyEmail',
		control: controller.form.control,
	});

	// Focus first form field for a11y
	useFocusEffect(
		React.useCallback(() => {
			setTimeout(() => {
				controller.form.setFocus('newEmail');
			}, 500);
		}, [controller.form.setFocus])
	);

	return (
		<ScreenTemplateWithFooter
			preFooter={<EmailWarningDisplay />}
			footer={
				<Button
					title={t('settings.editEmail.submitButton')}
					disabled={!controller.form.formState.isValid}
					loading={controller.isFetching}
					onPress={controller.handleSubmitPress}
				/>
			}
		>
			{controller.errorMessage.length > 0 && (
				<Callout type="error" message={controller.errorMessage} />
			)}
			<InputText
				label={t('settings.editEmail.newEmailInputLabel')}
				type="email"
				enterKeyHint="next"
				onSubmitEditing={() => controller.form.setFocus('verifyEmail')}
				errorMessage={controller.form.formState.errors.newEmail?.message}
				{...newEmailInputProps}
			/>

			<InputText
				label={t('settings.editEmail.verifyEmailInputLabel')}
				type="email"
				enterKeyHint="go"
				onSubmitEditing={controller.handleSubmitPress}
				errorMessage={controller.form.formState.errors.verifyEmail?.message}
				{...verifyEmailInputProps}
			/>
		</ScreenTemplateWithFooter>
	);
}

export function useSettingsEditEmailScreenOptions() {
	const { t } = useTranslation();

	return ({ navigation }: ISettingsEditEmailScreenProps): StackNavigationOptions => ({
		title: t('settings.editEmail.screenTitle'),
		headerRight: undefined,
	});
}

function EmailWarningDisplay() {
	const styles = useEmailWarningDisplayStyles();
	const { t } = useTranslation();

	return (
		<View style={styles.container}>
			<View style={styles.iconContainer}>
				<WarningGraphic />
			</View>
			<Heading level="h4">{t('settings.editEmail.warningHeading')}</Heading>
			<Text>{t('settings.editEmail.warningBody')}</Text>
		</View>
	);
}

function useEmailWarningDisplayStyles() {
	const styleRules = useStyleRules();
	const styleHelpers = useStyleHelpers();
	const iconOffset = 30;

	return StyleSheet.create({
		container: {
			position: 'relative',
			backgroundColor: styleRules.colors.background,
			padding: styleRules.spacing.appHorizontalMargin,
			paddingTop: styleRules.spacing.appHorizontalMargin * 1.5,
			marginTop: iconOffset,
			...(Platform.OS === 'web' && {
				marginHorizontal: styleRules.spacing.appHorizontalMargin,
				marginTop: iconOffset * 1.5,
				borderRadius: 5,
				...styleHelpers.wrapperHorizontal,
			}),
		},
		iconContainer: {
			width: 60,
			height: 60,
			position: 'absolute',
			top: -iconOffset,
			left: styleRules.spacing.appHorizontalMargin,
		},
	});
}
