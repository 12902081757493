import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import { OnboardingParamList } from './OnboardingStackScreen';

interface IAssessmentScreen {
	screenName: keyof OnboardingParamList;
	trackerAbbreviation: TrackerAbbreviation;
}

export const assessmentScreenSequence: IAssessmentScreen[] = [
	{
		screenName: 'ONBOARDING_STRESS_MANAGEMENT_SCREEN',
		trackerAbbreviation: TrackerAbbreviation.StressManagement,
	},
	{
		screenName: 'ONBOARDING_EMOTIONAL_HEALTH_SCREEN',
		trackerAbbreviation: TrackerAbbreviation.EmotionalHealth,
	},
	{
		screenName: 'ONBOARDING_WEIGHT_SCREEN',
		trackerAbbreviation: TrackerAbbreviation.Weight,
	},
	{
		screenName: 'ONBOARDING_SLEEP_SCREEN',
		trackerAbbreviation: TrackerAbbreviation.Sleep,
	},
	{
		screenName: 'ONBOARDING_STEPS_SCREEN',
		trackerAbbreviation: TrackerAbbreviation.Steps,
	},
];
