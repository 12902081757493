import { client } from '@mobe/api/client';

// Not used on web
export async function getPDFData(url: string) {
	return null;
}

export async function getBlobUrl(url: string) {
	const response = await client.get(url, { responseType: 'blob' });
	return new Blob([response.data], { type: 'application/pdf' });
}
