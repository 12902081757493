import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SESSION_QUERY_KEY, useIsAuthenticated } from '../authentication/authApiHooks';
import { IAuthenticationResponse } from '../authentication/authenticationService';
import {
	changeEmail,
	changePassword,
	claimAccount,
	createValidicUser,
	deleteAccount,
	eligibilityCheck,
	forgotPassword,
	getEmailVerified,
	getProfile,
	resendWelcomeEmail,
	resetPassword,
	updateProfile,
	verifyEmail,
} from './accountService';

export enum AccountQueryKeys {
	Profile = 'account.profile',
}

export enum AccountMutationKeys {
	UpdateProfile = 'account.updateProfile',
}

export function useProfileQuery() {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [AccountQueryKeys.Profile],
		queryFn: getProfile,
		enabled: isAuthenticated,
		staleTime: Infinity,
	});
}

export function useUpdateProfileMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: [AccountMutationKeys.UpdateProfile],
		mutationFn: updateProfile,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [AccountQueryKeys.Profile] });
		},
	});
}

export function useEligibilityCheckMutation() {
	return useMutation({
		mutationFn: eligibilityCheck,
	});
}

export function useForgotPasswordMutation() {
	return useMutation({ mutationFn: forgotPassword });
}

export function useClaimAccountMutation() {
	return useMutation({ mutationFn: claimAccount });
}

export function useResendWelcomeEmailMutation() {
	return useMutation({ mutationFn: resendWelcomeEmail });
}

export function useResetPasswordMutation() {
	return useMutation({ mutationFn: resetPassword });
}

export function useChangeEmailMutation() {
	return useMutation({ mutationFn: changeEmail });
}

export function useChangePasswordMutation() {
	return useMutation({ mutationFn: changePassword });
}

export function useCreateValidicUserMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: createValidicUser,
		onSuccess: (data) => {
			const currentValidicData = queryClient.getQueryData<IAuthenticationResponse | null>([
				SESSION_QUERY_KEY,
			]);

			if (currentValidicData) {
				queryClient.setQueryData<IAuthenticationResponse>([SESSION_QUERY_KEY], {
					...currentValidicData,
					validicInfo: {
						...data,
					},
				});
			}
		},
	});
}

export function useGetEmailVerifiedMutation() {
	return useMutation({ mutationFn: getEmailVerified });
}

export function useVerifyEmailMutation() {
	return useMutation({ mutationFn: verifyEmail });
}

export function useDeleteAccountMutation() {
	return useMutation({ mutationFn: deleteAccount });
}
