import { yupResolver } from '@hookform/resolvers/yup';
import { useChangePasswordMutation } from '@mobe/api/account/accountApiHooks';
import { ChangePasswordErrorCode } from '@mobe/api/account/accountService';
import { getErrorCodeFromResponseError } from '@mobe/api/client';
import useUpdatePasswordToast from '@mobe/components/toast/useUpdatePasswordToast';
import { usePersistentState } from '@mobe/utils/usePersistentState';
import { validatePassword } from '@mobe/utils/validationUtils';
import * as React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ISettingsEditPasswordScreenProps } from './SettingsEditPasswordScreen';

function useValidationSchema() {
	const { t } = useTranslation();

	return Yup.object({
		currentPassword: Yup.string()
			.label(t('settings.editPassword.currentPasswordInputLabel'))
			.required(),
		newPassword: Yup.string()
			.label(t('settings.editPassword.newPasswordInputLabel'))
			.required()
			.test(
				'valid password',
				({ label }) => t('settings.editPassword.errors.passwordDoesNotMeetRequirements', { label }),
				(value) => validatePassword(value).valid
			)
			.notOneOf(
				[Yup.ref('currentPassword'), null],
				t('settings.editPassword.errors.newPasswordSameAsCurrent', {
					currentPasswordInputLabel: t('settings.editPassword.currentPasswordInputLabel'),
					newPasswordInputLabel: t('settings.editPassword.newPasswordInputLabel'),
				})
			),
	});
}

type FormSchema = Yup.InferType<ReturnType<typeof useValidationSchema>>;

export interface ISettingsEditPasswordScreenController {
	form: UseFormReturn<FormSchema>;
	isFetching: boolean;
	errorMessage: string;
	handleSubmitPress: () => void;
}

export default function useSettingsEditPasswordScreenController({
	navigation,
	route,
}: ISettingsEditPasswordScreenProps): ISettingsEditPasswordScreenController {
	const validationSchema = useValidationSchema();
	const persistentState = usePersistentState();
	const changePasswordMutation = useChangePasswordMutation();
	const updatePasswordToast = useUpdatePasswordToast();
	const { t } = useTranslation();

	const form = useForm<FormSchema>({
		mode: 'onTouched',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			currentPassword: '',
			newPassword: '',
		},
	});

	const [errorCode, setErrorCode] = React.useState(ChangePasswordErrorCode.None);

	let errorMessage = '';
	if (errorCode !== ChangePasswordErrorCode.None) {
		switch (errorCode) {
			case ChangePasswordErrorCode.InvalidNewPassword:
				errorMessage = t('settings.editPassword.errors.invalidNewPassword');
				break;
			case ChangePasswordErrorCode.InvalidOldPassword:
				errorMessage = t('settings.editPassword.errors.invalidOldPassword');
				break;
			case ChangePasswordErrorCode.PreviouslyUsedPassword:
				errorMessage = t('settings.editPassword.errors.previouslyUsedPassword');
				break;
			default:
				errorMessage = t('settings.editPassword.errors.default');
				break;
		}
	}

	async function performChangePassword({ currentPassword, newPassword }: FormSchema) {
		changePasswordMutation.mutate(
			{ oldPassword: currentPassword, newPassword },
			{
				onSuccess: () => {
					// Update saved password in persistent state, so bio auth still works after logout
					persistentState.setSavedPassword(newPassword);
					updatePasswordToast();
					navigation.navigate('SETTINGS_ROOT_SCREEN');
				},
				onError: (error) => {
					setErrorCode(getErrorCodeFromResponseError(error) as ChangePasswordErrorCode);
				},
			}
		);
	}

	function handleSubmitPress() {
		form.handleSubmit(performChangePassword)();
	}

	return {
		form,
		isFetching: changePasswordMutation.isPending,
		errorMessage,
		handleSubmitPress,
	};
}
