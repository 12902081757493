import { useTrackersAddEntryMutation } from '@mobe/api/track/trackApiHooks';
import { useGetTrackerIdFromAbbreviation } from '@mobe/api/track/trackApiUtils';
import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import useTrackerContent from '@mobe/features/track/useTrackerContent';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	OnboardingStressManagementScreenNavigationProp,
	OnboardingStressManagementScreenRouteProp,
} from '../OnboardingStackScreen';
import OnboardingScreenTemplate from '../components/OnboardingScreenTemplate';
import useNavigateToNextScreen from '../useNavigateToNextScreen';

interface IOnboardingStressManagementScreenProps {
	navigation: OnboardingStressManagementScreenNavigationProp;
	route: OnboardingStressManagementScreenRouteProp;
}

export default function OnboardingStressManagementScreen({
	route,
}: IOnboardingStressManagementScreenProps) {
	const { t } = useTranslation();
	const navigateToNextScreen = useNavigateToNextScreen(route.name);
	const trackerContent = useTrackerContent()[TrackerAbbreviation.StressManagement];
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const addEntry = useTrackersAddEntryMutation();
	const { form, renderForm } = trackerContent.useForm({
		tracker: trackerContent,
		staticDate: new Date(Date.now()),
		customInputLabel: t('onboarding.questionLabels.stressManagement'),
		onKeyboardSubmit: handleSubmit,
		isRequired: false,
	});

	function handleSubmit() {
		form.handleSubmit((data) => {
			if (!data.tracker) {
				navigateToNextScreen();
				return;
			}

			addEntry.mutate(
				{
					value: data.tracker,
					activityDateTime: data.date.toISOString(),
					trackerTypeId: getTrackerIdFromAbbreviation(TrackerAbbreviation.StressManagement),
				},
				{
					onSuccess: navigateToNextScreen,
				}
			);
		})();
	}

	return (
		<OnboardingScreenTemplate
			routeName={route.name}
			graphic={trackerContent.graphic}
			title={trackerContent.title}
			isLoading={addEntry.isPending}
			onNextPress={handleSubmit}
		>
			{renderForm()}
		</OnboardingScreenTemplate>
	);
}
