import {
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
	LogLevel,
} from '@microsoft/signalr';
import { useSessionQuery } from '@mobe/api/authentication/authApiHooks';
import { ChatQueryKeys } from '@mobe/api/chat/types';
import env from '@mobe/env/env';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

export enum ChatHubEvents {
	ConsumerSentMessage = 'consumerSentMessage',
	ConsumerReceivedGuideMessages = 'consumerReceivedGuideMessages',
	ConsumerReceivedMessage = 'consumerReceivedMessage',
	ConsumerChangedPresence = 'consumerChangedPresence',
	GuideSentMessage = 'guideSentMessage',
	GuideDeletedMessage = 'guideDeletedMessage',
	GuideIsCheckingConsumerPresence = 'guideIsCheckingConsumerPresence',
}

type ChatHubConnectionStatus = 'init' | 'connected' | 'disconnected' | 'reconnecting';

interface IChatHubConnectionContextValue {
	connection: HubConnection | undefined;
	status: ChatHubConnectionStatus;
}

const ChatHubConnectionContext = React.createContext<IChatHubConnectionContextValue | undefined>(
	undefined
);

const CONNECTION_RETRY_DELAY = 10000;

function ChatHubConnectionProvider({ children }: { children: React.ReactNode }) {
	const queryClient = useQueryClient();
	const connection = React.useRef<HubConnection | undefined>(undefined);
	const retryStartInterval = React.useRef<ReturnType<typeof setInterval> | undefined>(undefined);
	const [status, setStatus] = React.useState<ChatHubConnectionStatus>('init');
	const sessionQuery = useSessionQuery();

	// Try to create and start or stop connection based on use auth state
	React.useEffect(() => {
		if (sessionQuery.data?.token) {
			connection.current = createHubConnection(sessionQuery.data.token);
			startConnection();
		} else {
			stopConnection();
		}

		return () => {
			stopConnection();
		};
	}, [sessionQuery.data]);

	function createHubConnection(authToken: string) {
		const connectionBuilder = new HubConnectionBuilder()
			.withUrl(env.SIGNALR_URL, {
				accessTokenFactory: () => authToken,
			})
			.withAutomaticReconnect({
				// Keep retrying to connect indefinitely
				nextRetryDelayInMilliseconds: () => CONNECTION_RETRY_DELAY,
			});

		if (env.isDev) {
			connectionBuilder.configureLogging(LogLevel.Debug);
		}

		return connectionBuilder.build();
	}

	function addGlobalEventHandlers() {
		if (!connection.current) return;

		connection.current.onreconnecting(() => {
			setStatus('reconnecting');
		});

		connection.current.onreconnected(() => {
			setStatus('connected');
		});

		connection.current.onclose(() => {
			setStatus('disconnected');
		});

		connection.current.on(ChatHubEvents.GuideSentMessage, handleGuideSentMessage);
		connection.current.on(
			ChatHubEvents.GuideIsCheckingConsumerPresence,
			handleGuideIsCheckingConsumerPresence
		);
		connection.current.on(ChatHubEvents.GuideDeletedMessage, handleGuideDeletedMessage);
		connection.current.on(ChatHubEvents.ConsumerSentMessage, handleConsumerSentMessage);
	}

	function removeGlobalEventHandlers() {
		if (!connection.current) return;

		connection.current.off(ChatHubEvents.GuideSentMessage, handleGuideSentMessage);
		connection.current.off(
			ChatHubEvents.GuideIsCheckingConsumerPresence,
			handleGuideIsCheckingConsumerPresence
		);
		connection.current.off(ChatHubEvents.GuideDeletedMessage, handleGuideDeletedMessage);
		connection.current.off(ChatHubEvents.ConsumerSentMessage, handleConsumerSentMessage);
	}

	async function startConnection() {
		try {
			await connection.current?.start();
			addGlobalEventHandlers();
			signalConsumerIsOnline();
			setStatus('connected');
		} catch (error) {
			// If start doesn't work for some reason, we want to retry after a certain amount of time
			if (!retryStartInterval.current) {
				retryStartInterval.current = setInterval(() => startConnection(), CONNECTION_RETRY_DELAY);
			}
		}
	}

	async function stopConnection() {
		if (
			connection.current?.state === HubConnectionState.Disconnected ||
			connection.current?.state === HubConnectionState.Disconnecting
		)
			return;

		if (retryStartInterval.current) {
			clearInterval(retryStartInterval.current);
		}

		removeGlobalEventHandlers();
		await connection.current?.stop();
		setStatus('disconnected');
	}

	// Global event handlers

	function handleGuideIsCheckingConsumerPresence() {
		signalConsumerIsOnline();
	}

	function handleGuideSentMessage() {
		queryClient.invalidateQueries({ queryKey: [ChatQueryKeys.UnreadChatCount] });
	}

	function handleGuideDeletedMessage() {
		queryClient.invalidateQueries({ queryKey: [ChatQueryKeys.UnreadChatCount] });
	}

	function handleConsumerSentMessage() {
		queryClient.invalidateQueries({ queryKey: [ChatQueryKeys.UnreadChatCount] });
	}

	function signalConsumerIsOnline() {
		connection.current?.invoke(ChatHubEvents.ConsumerChangedPresence, { isConsumerOnline: true });
	}

	const value = React.useMemo(() => {
		return { connection: connection.current, status };
	}, [status]);

	return (
		<ChatHubConnectionContext.Provider value={value}>{children}</ChatHubConnectionContext.Provider>
	);
}

function useChatHubConnection() {
	const context = React.useContext(ChatHubConnectionContext);

	if (context === undefined) {
		throw new Error('useChatHubConnection must be used with a ChatHubConnectionProvider');
	}

	async function sendMessage(messageText: string, channelId: number) {
		return await context?.connection?.invoke(ChatHubEvents.ConsumerSentMessage, {
			message: messageText,
			channelId,
		});
	}

	// This only sends message to signalr, does not make db updates and so unread count will be unaffected
	async function consumerReceivedGuideMessages(channelId: number) {
		return await context?.connection?.invoke(ChatHubEvents.ConsumerReceivedGuideMessages, {
			channelId,
		});
	}

	// This makes db updates and will alter unread count
	async function consumerReceivedMessage(messageId: number, channelId: number) {
		return await context?.connection?.invoke(ChatHubEvents.ConsumerReceivedMessage, {
			messageId,
			channelId,
		});
	}

	return {
		...context,
		sendMessage,
		consumerReceivedGuideMessages,
		consumerReceivedMessage,
	};
}

export { ChatHubConnectionProvider, useChatHubConnection };
