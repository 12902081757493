import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { TextStyle } from 'react-native';

export type TextSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type TextWeight = 'regular' | 'medium' | 'semiBold' | 'bold';
export type TextColor =
	| 'regular'
	| 'light'
	| 'inverted'
	| 'error'
	| 'primary'
	| 'success'
	| 'successDark'
	| 'staticLight'
	| 'staticDark';
export type TextAlign = 'left' | 'center' | 'right';
export type TextLineHeight = 'regular' | 'tight';

export default function useTextStyles({
	size = 'md',
	weight = 'regular',
	color = 'regular',
	align = 'left',
	lineHeight = 'regular',
}: {
	size?: TextSizes;
	weight?: TextWeight;
	color?: TextColor;
	align?: TextAlign;
	lineHeight?: TextLineHeight;
} = {}) {
	const rules = useStyleRules();

	const textStyles: TextStyle = {
		color: rules.colors.text,
		fontFamily: rules.fontFamilies.sansMedium,
		textAlign: align,
	};

	const textSizes: { [key in TextSizes]?: TextStyle } = {
		xs: {
			fontSize: rules.fontSizes.xs,
			lineHeight: rules.fontSizes.xs * (lineHeight === 'tight' ? 1.3 : 1.45),
		},
		sm: {
			fontSize: rules.fontSizes.sm,
			lineHeight: rules.fontSizes.sm * (lineHeight === 'tight' ? 1.3 : 1.45),
		},
		md: {
			fontSize: rules.fontSizes.md,
			lineHeight: rules.fontSizes.md * (lineHeight === 'tight' ? 1.275 : 1.45),
		},
		lg: {
			fontSize: rules.fontSizes.lg,
			lineHeight: rules.fontSizes.lg * (lineHeight === 'tight' ? 1.25 : 1.4),
		},
		xl: {
			fontSize: rules.fontSizes.xl,
			lineHeight: rules.fontSizes.xl * (lineHeight === 'tight' ? 1.25 : 1.35),
		},
	};

	const textWeight: { [key in TextWeight]?: TextStyle } = {
		regular: { fontFamily: rules.fontFamilies.sans },
		medium: { fontFamily: rules.fontFamilies.sansMedium },
		semiBold: { fontFamily: rules.fontFamilies.sansSemiBold },
		bold: { fontFamily: rules.fontFamilies.sansBold },
	};

	const textColor: { [key in TextColor]?: TextStyle } = {
		regular: { color: rules.colors.text },
		light: { color: rules.colors.textLight },
		inverted: { color: rules.colors.textInverted },
		error: { color: rules.colors.error },
		primary: { color: rules.colors.primary },
		success: { color: rules.colors.success },
		successDark: { color: rules.colors.successDark },
		staticDark: { color: rules.colors.staticDark },
		staticLight: { color: rules.colors.staticLight },
	};

	return { ...textStyles, ...textSizes[size], ...textWeight[weight], ...textColor[color] };
}
