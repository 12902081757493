import AutoKeyboardAvoidingView from '@mobe/components/autoKeyboardAvoidingView/AutoKeyboardAvoidingView';
import Heading from '@mobe/components/heading/Heading';
import TextButton from '@mobe/components/textButton/TextButton';
import * as GlobalAnalyticsEvents from '@mobe/utils/analyticsEvents';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useLayout from '@mobe/utils/styles/useLayout';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, ScrollView, View } from 'react-native';
import HeaderGraphic from '../headerGraphic/HeaderGraphic';
import useAuthScreenTemplateStyles from './authScreenTemplateStyles';

interface IAuthScreenTemplateProps {
	heading?: string;
	header?: React.ReactNode;
	footer?: React.ReactNode;
	children: React.ReactNode;
	center?: boolean;
}

export default function AuthScreenTemplate({
	heading,
	header,
	footer,
	children,
	center = false,
}: IAuthScreenTemplateProps) {
	const { vr } = useStyleHelpers();
	const styles = useAuthScreenTemplateStyles(center);
	const { t } = useTranslation();
	const { isLandscape } = useLayout();

	function handleSupportPress() {
		GlobalAnalyticsEvents.supportNumberPress();
		Linking.openURL(`tel:${t('auth.callForHelpNumber')}`);
	}

	return (
		<AutoKeyboardAvoidingView>
			<ScrollView
				style={styles.scrollView}
				contentContainerStyle={styles.page}
				overScrollMode="never"
				bounces={false}
				bouncesZoom={false}
				keyboardShouldPersistTaps="handled"
			>
				<View style={styles.alertContainer}>
					{Platform.OS === 'web' ? (
						<View style={styles.graphicRow}>
							<View style={styles.graphicRowItem}>
								<HeaderGraphic />
							</View>
							<View style={styles.graphicRowItem}>
								<HeaderGraphic />
							</View>
							<View style={styles.graphicRowItem}>
								<HeaderGraphic />
							</View>
							<View style={styles.graphicRowItem}>
								<HeaderGraphic />
							</View>
						</View>
					) : Platform.OS === 'ios' && Platform.isPad && !isLandscape ? (
						<View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 30 }}>
							<View style={{ width: '60%' }}>
								<HeaderGraphic />
							</View>
							<View style={{ width: '60%' }}>
								<HeaderGraphic />
							</View>
						</View>
					) : Platform.OS === 'ios' && Platform.isPad && isLandscape ? (
						<View
							style={{
								flexDirection: 'row',
								justifyContent: 'center',
							}}
						>
							<View style={{ width: '25%' }}>
								<HeaderGraphic />
							</View>
							<View style={{ width: '25%' }}>
								<HeaderGraphic />
							</View>
							<View style={{ width: '25%' }}>
								<HeaderGraphic />
							</View>
							<View style={{ width: '25%' }}>
								<HeaderGraphic />
							</View>
						</View>
					) : (
						<HeaderGraphic />
					)}
				</View>
				<View style={styles.pageBody}>
					{header && <View style={vr(8)}>{header}</View>}

					{Boolean(heading) && (
						<Heading
							level="h1"
							accessibilityAutoFocus
							semanticLevel={1}
							align="center"
							style={vr(5)}
						>
							{heading}
						</Heading>
					)}
					{children}
				</View>
				<View style={styles.pageFooter}>
					{footer}
					<TextButton
						style={styles.pageFooterHelpButton}
						title={t('auth.callForHelp')}
						align="center"
						onPress={handleSupportPress}
					/>
				</View>
			</ScrollView>
		</AutoKeyboardAvoidingView>
	);
}
