import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function AnnualIncentiveBgGraphic(props: SvgProps) {
	const styleRules = useStyleRules();
	const isDarkMode = styleRules.settings.colorScheme === 'dark';

	return (
		<Svg width="100%" height="100%" viewBox="0 0 430 243" preserveAspectRatio="none" {...props}>
			<Path fill={isDarkMode ? '#222222' : '#F0F6F9'} d="M0 0h430v243H0z" />
			<Path
				fill={isDarkMode ? '#2B2E30' : '#DCEDF6'}
				d="M381.494 61.927c-11.7 20.966-25.109 41.061-42.291 58.395-17.03 17.109-36.425 31.541-56.609 45.136-46.784 31.229-97.236 57.216-150.351 77.542H430V0h-23.042a272.585 272.585 0 0 1-25.464 61.927z"
			/>
		</Svg>
	);
}
