import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import Color from 'color';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Animated,
	Platform,
	Pressable,
	PressableProps,
	StyleProp,
	StyleSheet,
	View,
	ViewStyle,
} from 'react-native';
import IconButton from '../iconButton/IconButton';

interface ICardButtonProps extends PressableProps {
	children: React.ReactNode;
	cardButtonLeft?: React.ReactNode;
	cardButtonRight?: React.ReactNode;
	cardButtonFooter?: React.ReactNode;
	style?: StyleProp<ViewStyle>;
	noPaddingWeb?: boolean;
	cardBodyStyle?: StyleProp<ViewStyle>;
	footerPressableProps?: PressableProps;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cardButtonFooterStyles?: any;

	/**
	 * If function is supplied, a close button will render in the upper left corner of the card.
	 */
	onDismissPress?: () => void;
}

function CardButton(
	{
		children,
		cardButtonLeft,
		cardButtonRight,
		cardButtonFooter,
		style,
		noPaddingWeb = false,
		cardBodyStyle,
		footerPressableProps,
		cardButtonFooterStyles,
		onDismissPress,
		...pressableProps
	}: ICardButtonProps,
	ref: any
) {
	const { t } = useTranslation();
	const styles = useCardButtonStyles(Boolean(pressableProps.disabled), noPaddingWeb);

	function renderCardButtonLeft(): React.ReactNode | null {
		if (cardButtonLeft) {
			return <View style={styles.cardBodyLeft}>{cardButtonLeft}</View>;
		}

		return null;
	}

	function renderCardButtonRight(): React.ReactNode | null {
		if (cardButtonRight) {
			return <View style={styles.cardBodyRight}>{cardButtonRight}</View>;
		}

		return null;
	}

	return (
		<View style={[styles.card, style]}>
			<Pressable
				ref={ref}
				role="button"
				style={[styles.cardBody, cardBodyStyle]}
				{...pressableProps}
			>
				{renderCardButtonLeft()}
				<View style={styles.cardBodyMain}>{children}</View>
				{renderCardButtonRight()}
			</Pressable>
			{cardButtonFooter && (
				<Pressable {...footerPressableProps} role="button">
					<Animated.View style={[styles.cardBodyFooter, cardButtonFooterStyles]}>
						{cardButtonFooter}
					</Animated.View>
				</Pressable>
			)}
			{onDismissPress && (
				<IconButton
					style={styles.dismiss}
					role="button"
					aria-label={t('accessibility.dismiss')}
					name="close"
					onPress={onDismissPress}
					size={10}
					hitSlop={12}
				/>
			)}
		</View>
	);
}

export default React.forwardRef(CardButton);

function useCardButtonStyles(isDisabled: boolean, noPaddingWeb: boolean) {
	const rules = useStyleRules();
	const { cardShadow } = useStyleHelpers();

	return StyleSheet.create({
		card: {
			borderRadius: rules.borderRadius,
			backgroundColor: rules.colors.cardBackground,

			...(isDisabled
				? {
						borderWidth: 1,
						borderColor: rules.colors.stroke,
				  }
				: cardShadow),
		},
		cardBody: {
			flexDirection: 'row',
			alignItems: 'center',
			paddingHorizontal: 12,
			paddingVertical: 16,
			minHeight: 80,
			borderTopRightRadius: rules.borderRadius,
			borderBottomRightRadius: rules.borderRadius,
			overflow: 'hidden',

			...(Platform.OS === 'web' &&
				!noPaddingWeb && {
					paddingVertical: '3.5%',
					paddingHorizontal: '3.5%',
				}),
		},
		cardBodyMain: {
			flexGrow: 1,
			flexShrink: 1,
		},
		cardBodyLeft: {
			alignSelf: 'stretch',
			justifyContent: 'center',
			marginRight: 10,
		},
		cardBodyRight: {
			alignSelf: 'stretch',
			justifyContent: 'center',
			marginLeft: 10,
		},
		cardBodyFooter: {
			alignItems: 'center',
			justifyContent: 'center',
			minHeight: 42,
			paddingVertical: 8,
			paddingHorizontal: 12,
			borderTopWidth: 1,
			borderTopColor: rules.colors.strokeLight,
		},
		dismiss: {
			position: 'absolute',
			top: 5,
			left: 5,
			alignItems: 'center',
			justifyContent: 'center',
			height: 24,
			width: 24,
			borderRadius: 12,
			backgroundColor: Color(rules.colors.strokeLight).alpha(0.5).string(),
		},
	});
}
