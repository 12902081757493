import env from '@mobe/env/env';
import HeaderGraphic from '@mobe/features/auth/components/headerGraphic/HeaderGraphic';
import * as GlobalAnalyticsEvents from '@mobe/utils/analyticsEvents';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Button } from '../button';
import { ErrorFallbackGraphic } from '../graphics';
import Heading from '../heading/Heading';
import Text from '../text/Text';
import TextButton from '../textButton/TextButton';

interface IErrorFallbackView {
	onPress?: () => void;
	isMaintenance?: boolean;
}

export default function ErrorFallbackView({ onPress, isMaintenance = false }: IErrorFallbackView) {
	const { constrain, vr } = useStyleHelpers();
	const { styles } = useStyles();
	const { t } = useTranslation();
	const remoteConfigData = useRemoteConfigData();

	const content = remoteConfigData.maintenanceScreen.enabled
		? {
				heading: remoteConfigData.maintenanceScreen.heading,
				subHeading: remoteConfigData.maintenanceScreen.body,
				subHeadingTwo: remoteConfigData.maintenanceScreen.subBody,
				callForHelp: t('maintenance.callForHelp'),
				callForHelpNumber: t('maintenance.callForHelpNumber'),
				buttonText: remoteConfigData.maintenanceScreen.button.label || null,
		  }
		: isMaintenance
		? {
				heading: t('maintenance.heading'),
				subHeading: t('maintenance.subHeading'),
				subHeadingTwo: t('maintenance.subHeadingTwo'),
				callForHelp: t('maintenance.callForHelp'),
				callForHelpNumber: t('maintenance.callForHelpNumber'),
				buttonText: null,
		  }
		: {
				heading: t('errorFallbackView.heading'),
				subHeading: t('errorFallbackView.subHeading'),
				subHeadingTwo: null,
				callForHelp: t('errorFallbackView.callForHelp'),
				callForHelpNumber: t('errorFallbackView.callForHelpNumber'),
				buttonText: t('errorFallbackView.buttonText'),
		  };

	const handleSupportPress = () => {
		GlobalAnalyticsEvents.supportNumberPress();
		Linking.openURL(`tel:${t('notFound.callForHelpNumber')}`);
	};

	function handleButtonPress() {
		if (remoteConfigData.maintenanceScreen.enabled) {
			const { iosURL, androidURL } = remoteConfigData.maintenanceScreen.button;
			const url = Platform.select({ ios: iosURL, android: androidURL, default: '' });

			Linking.canOpenURL(url).then(
				(supported) => {
					supported && Linking.openURL(url);
				},
				(error) => {
					if (env.isDev) {
						console.error(`ErrorFallbackView: Invalid url ${url}`);
					}
				}
			);
		}

		if (typeof onPress === 'function') {
			onPress();
		}
	}

	return (
		<View style={styles.main}>
			{Platform.OS === 'web' ? (
				<View style={styles.graphicRow}>
					<View style={styles.graphicRowItem}>
						<HeaderGraphic />
					</View>
					<View style={styles.graphicRowItem}>
						<HeaderGraphic />
					</View>
					<View style={styles.graphicRowItem}>
						<HeaderGraphic />
					</View>
					<View style={styles.graphicRowItem}>
						<HeaderGraphic />
					</View>
				</View>
			) : (
				<HeaderGraphic />
			)}
			<View style={styles.contentContainer}>
				<View style={[constrain(280), vr(8)]}>
					<ErrorFallbackGraphic />
				</View>

				<Heading level="h1" accessibilityAutoFocus align="center" style={vr(5)}>
					{content.heading}
				</Heading>

				<Text weight="medium" align="center" style={vr(6)}>
					{content.subHeading}
				</Text>
				{content.subHeadingTwo ? (
					<Text weight="medium" align="center" style={vr(6)}>
						{content.subHeadingTwo}
					</Text>
				) : null}
				{content.buttonText ? (
					<Button title={content.buttonText} style={vr(4)} onPress={handleButtonPress} />
				) : null}
			</View>

			<View style={styles.footer}>
				<TextButton
					style={styles.pageFooterHelpButton}
					title={t('errorFallbackView.callForHelp')}
					align="center"
					onPress={handleSupportPress}
				/>
			</View>
		</View>
	);
}

const useStyles = () => {
	const rules = useStyleRules();
	const safeAreaInsets = useSafeAreaInsets();

	const styles = StyleSheet.create({
		main: {
			minHeight: safeAreaInsets.top + 70,
			marginBottom: 15,
			maxWidth: rules.spacing.maxWidthNarrow,
			width: '100%',
			alignSelf: 'center',
			flex: 1,
			backgroundColor: rules.colors.cardBackground,
		},
		contentContainer: {
			paddingHorizontal: rules.spacing.appHorizontalMargin,
		},
		footer: {
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			marginTop: 'auto',
			paddingBottom: safeAreaInsets.bottom,
		},
		pageFooterHelpButton: {
			marginTop: 30,
		},

		// Web
		graphicRow: {
			flexDirection: 'row',
			justifyContent: 'center',
			marginBottom: 30,
		},
		graphicRowItem: {
			height: 100,
			alignItems: 'stretch',
			flexDirection: 'row',
		},
	});

	return { styles };
};
