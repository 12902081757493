import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { Keyboard, Platform, TextInput } from 'react-native';
import BaseInput, { IBaseInputProps } from './BaseInput';

interface IInputTextAreaProps extends IBaseInputProps {
	numberOfLines?: number;
}

export default function InputTextArea({
	numberOfLines = 6,
	...baseInputProps
}: IInputTextAreaProps) {
	const rules = useStyleRules();
	const lineHeight = rules.spacing.baseline * 6 * rules.settings.fontScale;
	const [scrollEnabled, setScrollEnabled] = React.useState(false);
	const inputRef = React.useRef<TextInput>(null);

	// Known issue on iOS prevents multiline-inputs with scrollEnabled from properly avoiding keyboard
	// https://github.com/facebook/react-native/issues/16826
	// Keyboard avoidance works as expected when scrollEnabled is set to false prior to keyboard showing
	React.useEffect(() => {
		if (Platform.OS !== 'ios') return;

		const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
			setScrollEnabled(true);
		});

		return keyboardDidShowListener.remove;
	}, []);

	function handleOnBlur() {
		setScrollEnabled(false);
	}

	function handleOnFocus() {
		setTimeout(() => {
			if (inputRef.current?.isFocused()) {
				setScrollEnabled(true);
			}
		}, 1000);
	}

	return (
		<BaseInput
			ref={inputRef}
			multiline
			scrollEnabled={Platform.OS === 'ios' ? scrollEnabled : true}
			onBlur={handleOnBlur}
			onFocus={handleOnFocus}
			style={{ height: 'auto' }}
			inputStyle={{
				height: lineHeight * numberOfLines + 10,
				paddingVertical: 5,
				lineHeight,
			}}
			textAlignVertical="top"
			autoCapitalize="sentences"
			numberOfLines={numberOfLines}
			{...baseInputProps}
		/>
	);
}
