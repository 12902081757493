import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useLayout from '@mobe/utils/styles/useLayout';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export default function useMenuDrawerStyles() {
	const rules = useStyleRules();
	const { top } = useSafeAreaInsets();
	const layout = useLayout();

	return StyleSheet.create({
		scrollView: {
			paddingHorizontal: 20,
			paddingTop: 0,

			...(layout.isWebDesktop && {
				paddingLeft: 40,
			}),
		},
		contentContainer: {
			justifyContent: 'space-between',
			flexGrow: 1,
			paddingTop: top + 20,
			paddingBottom: rules.spacing.appVerticalMargin,
		},
		drawerItemTop: {
			borderBottomWidth: 1,
			borderBottomColor: rules.colors.strokeLight,
			paddingVertical: 16,

			...(layout.isWebDesktop && {
				borderBottomWidth: 0,
				paddingVertical: 8,
				marginBottom: 4,
			}),
		},
		// Only rendered on web, where bottom tabs move into drawer
		drawerItemMainNav: {
			paddingVertical: 8,
			marginBottom: 15,
		},
		closeButton: {
			alignSelf: 'flex-start',
		},
		labelTag: {
			justifyContent: 'center',
			height: 24 * rules.settings.fontScale,
			backgroundColor: rules.colors.success,
			borderRadius: 12 * rules.settings.fontScale,
			overflow: 'hidden',
			marginLeft: 8,
			paddingHorizontal: 10,
		},
	});
}
