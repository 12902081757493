import env from '@mobe/env/env';
import { isObject } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { migrateFromAsyncStorage } from './storage/migrateFromAsyncStorage';
import { storage } from './storage/storage';
import useSecureStoreState, { SecureStoreState } from './useSecureStoreState';

const HAS_MIGRATED_TO_MMKV = 'hasMigratedToMMKV';

export interface IPersistentState {
	savedUsername: SecureStoreState<string>;
	savedPassword: SecureStoreState<string>;

	// TODO: Remove all of these after migration has occurred
	enableBiometricLogin: SecureStoreState<boolean>;
	rememberMe: SecureStoreState<boolean>;
	hasViewedAppIntro: SecureStoreState<boolean>;
	loginCount: SecureStoreState<number>;
	hasSeenBioAuthPrompt: SecureStoreState<boolean>;
	hasSeenEducationalNotice: SecureStoreState<boolean>;
	hasSeenWelcomeVideo: SecureStoreState<boolean>;
	hasDismissedGuideMatch: SecureStoreState<boolean>;
	hasDismissedPharmacistMatch: SecureStoreState<boolean>;
	hasDismissedTrackerCTAFromHome: SecureStoreState<boolean>;
	hasDismissedTrackerCTAFromTrack: SecureStoreState<boolean>;
	hasDismissedTrackerDataLagMessage: SecureStoreState<boolean>;
	loginAlertDismissedUntilDateString: SecureStoreState<string>;
	dismissedContentModuleIds: SecureStoreState<number[]>;
}

const PersistentStateContext = createContext<IPersistentState | undefined>({} as IPersistentState);

export function PersistentStateProvider({ children }: { children: React.ReactNode }) {
	const [allStateItemsLoaded, setAllStateItemsLoaded] = useState(false);
	const savedUsername = useSecureStoreState<string>('authentication.credentials.username');
	const savedPassword = useSecureStoreState<string>('authentication.credentials.password');

	// TODO: remove
	const enableBiometricLogin = useSecureStoreState<boolean>('settings.enableBiometricLogin');
	const rememberMe = useSecureStoreState<boolean>('settings.rememberMe');
	const hasViewedAppIntro = useSecureStoreState<boolean>('hasViewedAppIntro');
	const loginCount = useSecureStoreState<number>('persistent.loginCount');
	const hasSeenBioAuthPrompt = useSecureStoreState<boolean>('persistent.hasSeenBioAuthPrompt');
	const hasSeenEducationalNotice = useSecureStoreState<boolean>(
		'persistent.hasSeenEducationalNotice'
	);
	const hasSeenWelcomeVideo = useSecureStoreState<boolean>('persistent.hasSeenWelcomeVideo');
	const hasDismissedGuideMatch = useSecureStoreState<boolean>('persistent.hasDismissedGuideMatch');
	const hasDismissedPharmacistMatch = useSecureStoreState<boolean>(
		'persistent.hasDismissedPharmacistMatch'
	);
	const hasDismissedTrackerCTAFromHome = useSecureStoreState<boolean>(
		'persistent.hasDismissedTrackerCTAFromHome'
	);
	const hasDismissedTrackerCTAFromTrack = useSecureStoreState<boolean>(
		'persistent.hasDismissedTrackerCTAFromTrack'
	);
	const hasDismissedTrackerDataLagMessage = useSecureStoreState<boolean>(
		'persistent.hasDismissedTrackerDataLagMessage'
	);
	const loginAlertDismissedUntilDateString = useSecureStoreState<string>(
		'loginAlertDismissedUntilDateString'
	);
	const dismissedContentModuleIds = useSecureStoreState<number[]>(
		'persistent.dismissedContentModuleIds'
	);

	// delay until all persistent state items are loaded
	useEffect(() => {
		Promise.all([
			savedUsername.loadValue(),
			savedPassword.loadValue(),

			// TODO: Remove
			enableBiometricLogin.loadValue(),
			rememberMe.loadValue(),
			hasViewedAppIntro.loadValue(),
			loginCount.loadValue(),
			hasSeenBioAuthPrompt.loadValue(),
			hasSeenEducationalNotice.loadValue(),
			hasSeenWelcomeVideo.loadValue(),
			hasDismissedGuideMatch.loadValue(),
			hasDismissedPharmacistMatch.loadValue(),
			hasDismissedTrackerCTAFromHome.loadValue(),
			hasDismissedTrackerCTAFromTrack.loadValue(),
			hasDismissedTrackerDataLagMessage.loadValue(),
			loginAlertDismissedUntilDateString.loadValue(),
			dismissedContentModuleIds.loadValue(),
		]).then(() => {
			setAllStateItemsLoaded(true);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const contextValue: IPersistentState = {
		savedUsername,
		savedPassword,

		// TODO: Remove
		enableBiometricLogin,
		rememberMe,
		hasViewedAppIntro,
		loginCount,
		hasSeenBioAuthPrompt,
		hasSeenEducationalNotice,
		hasSeenWelcomeVideo,
		hasDismissedGuideMatch,
		hasDismissedPharmacistMatch,
		hasDismissedTrackerCTAFromHome,
		hasDismissedTrackerCTAFromTrack,
		hasDismissedTrackerDataLagMessage,
		loginAlertDismissedUntilDateString,
		dismissedContentModuleIds,
	};

	// TODO: Remove after migration
	useEffect(() => {
		if (allStateItemsLoaded && !storage.getBoolean(HAS_MIGRATED_TO_MMKV)) migrateToMMKV();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allStateItemsLoaded]);

	async function migrateToMMKV() {
		const migrationItems = [
			{
				key: 'settings.enableBiometricLogin',
				item: enableBiometricLogin,
				newKey: 'enableBiometricLogin',
			},
			{
				key: 'settings.rememberMe',
				item: rememberMe,
				newKey: 'rememberMe',
			},
			{
				key: 'hasViewedAppIntro',
				item: hasViewedAppIntro,
				newKey: 'hasViewedAppIntro',
			},
			{
				key: 'persistent.loginCount',
				item: loginCount,
				newKey: 'loginCount',
			},
			{
				key: 'persistent.hasSeenBioAuthPrompt',
				item: hasSeenBioAuthPrompt,
				newKey: 'hasSeenBioAuthPrompt',
			},
			{
				key: 'persistent.hasSeenEducationalNotice',
				item: hasSeenEducationalNotice,
				newKey: 'hasSeenEducationalNotice',
			},
			{
				key: 'persistent.hasSeenWelcomeVideo',
				item: hasSeenWelcomeVideo,
				newKey: 'hasSeenWelcomeVideo',
			},
			{
				key: 'persistent.hasDismissedGuideMatch',
				item: hasDismissedGuideMatch,
				newKey: 'hasDismissedGuideMatch',
			},
			{
				key: 'persistent.hasDismissedPharmacistMatch',
				item: hasDismissedPharmacistMatch,
				newKey: 'hasDismissedPharmacistMatch',
			},
			{
				key: 'persistent.hasDismissedTrackerCTAFromHome',
				item: hasDismissedTrackerCTAFromHome,
				newKey: 'hasDismissedTrackerCTAFromHome',
			},
			{
				key: 'persistent.hasDismissedTrackerCTAFromTrack',
				item: hasDismissedTrackerCTAFromTrack,
				newKey: 'hasDismissedTrackerCTAFromTrack',
			},
			{
				key: 'persistent.hasDismissedTrackerDataLagMessage',
				item: hasDismissedTrackerDataLagMessage,
				newKey: 'hasDismissedTrackerDataLagMessage',
			},
			{
				key: 'loginAlertDismissedUntilDateString',
				item: loginAlertDismissedUntilDateString,
				newKey: 'loginAlertDismissedUntilDateString',
			},
			{
				key: 'persistent.dismissedContentModuleIds',
				item: dismissedContentModuleIds,
				newKey: 'dismissedContentModuleIds',
			},
		];

		migrationItems.forEach(({ key, item, newKey }) => {
			try {
				if (item.value !== undefined) {
					storage.set(newKey, isObject(item.value) ? JSON.stringify(item.value) : item.value);

					item.removeItem();
				}
			} catch (error) {
				if (env.isDev) {
					console.error(`Failed to migrate key "${key}" from SecureStore to MMKV`, error);
				}
			}
		});

		await migrateFromAsyncStorage();

		storage.set(HAS_MIGRATED_TO_MMKV, true);
	}

	if (!allStateItemsLoaded) {
		return null;
	}

	return (
		<PersistentStateContext.Provider value={contextValue}>
			{children}
		</PersistentStateContext.Provider>
	);
}

export function usePersistentState() {
	const context = useContext(PersistentStateContext);

	if (!context) {
		throw new Error('usePersistentState must be used within a PersistentStateProvider');
	}

	return {
		savedUsername: context.savedUsername.value || '',
		setSavedUsername: (value: string) => {
			context.savedUsername.setValue(value);
		},
		savedPassword: context.savedPassword.value || '',
		setSavedPassword: (value: string) => {
			context.savedPassword.setValue(value);
		},
		enableBiometricLogin: context.enableBiometricLogin.value || false,
		setEnableBiometricLogin: (value: boolean) => {
			context.enableBiometricLogin.setValue(value);
		},
		toggleEnableBiometricLogin: () => {
			// Automatically turn on rememberMe when bio auth is toggled on
			if (!context.rememberMe.value && !context.enableBiometricLogin.value) {
				context.rememberMe.setValue(true);
			}
			context.enableBiometricLogin.setValue(!context.enableBiometricLogin.value);
		},
		rememberMe: context.rememberMe.value || false,
		setRememberMe: (value: boolean) => {
			context.rememberMe.setValue(value);
		},
		toggleRememberMe: () => {
			// Automatically turn off bio auth when remember me is toggled off
			if (context.rememberMe.value && context.enableBiometricLogin.value) {
				context.enableBiometricLogin.setValue(false);
			}
			context.rememberMe.setValue(!context.rememberMe.value);
		},
		resetSavedLoginCredentials: () => {
			context.savedUsername.setValue('');
			context.savedPassword.setValue('');
			context.rememberMe.setValue(false);
			context.enableBiometricLogin.setValue(false);
		},
		clearAll: () => {
			Object.keys(context).forEach((key) => context[key as keyof IPersistentState].removeItem());
		},
	};
}
