import { round } from 'lodash';
import { Platform } from 'react-native';
import Analytics from '../analytics';

export function fontScaleEvent(fontScale: number) {
	if (Platform.OS === 'web') return;
	Analytics.logEvent(`font_scale`, { fontScale: round(fontScale, 3).toString() });
}

export function colorSchemeEvent(colorScheme: string) {
	Analytics.logEvent(`color_scheme`, { colorScheme });
}
