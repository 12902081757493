import { useQuery } from '@tanstack/react-query';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import { getPermissions, Permissions } from './permissionsService';
import { PERMISSIONS_QUERY_KEY } from './types';

export function usePermissionsQuery(forceQueryToBeEnabled = false) {
	const isAuthenticated = useIsAuthenticated();

	// We use this query outside of being authenticated
	const enabled = forceQueryToBeEnabled ? true : isAuthenticated;

	return useQuery({
		queryKey: [PERMISSIONS_QUERY_KEY],
		queryFn: getPermissions,
		enabled,
		select: (data) => ({
			canSelectGsmOrMskLanguage: data.includes(Permissions.CanSelectGSMOrMSKLanguage),
			canSelectGsmOrMskGender: data.includes(Permissions.CanSelectGSMOrMSKGender),
			canSelectGsmOrMskSpanishGender: data.includes(Permissions.CanSelectGsmOrMskSpanishGender),
			canSelectPharmacistLanguage: data.includes(Permissions.CanSelectPharmacistLanguage),
			canSelectPharmacistGender: data.includes(Permissions.CanSelectPharmacistGender),
			hasMedServicesAccess: !data.includes(Permissions.HidePharmacistFeatures),
		}),
		staleTime: Infinity,
		refetchOnWindowFocus: false,
	});
}
