import { buildApiUrl, getClientHeaders, isApiUrl } from '@mobe/api/client';
import QueryString from 'qs';
import { PixelRatio } from 'react-native';
import { FastImageProps } from './fastImage';

const MAX_ALLOWED_DEVICE_PIXEL_RATIO = 2;

export type MobeImageSource = FastImageProps['source'];

export interface IMobeImagePropType extends Omit<FastImageProps, 'source'> {
	/**
	 * Provide apiImageId to build url (from id) and request image resource from the authenticated image/ endpoint
	 */
	apiImageId?: number;

	source?: MobeImageSource;

	/**
	 * RenderWidth is used to determine the width of image to request from the image service.
	 * This behaves like a simplified version of the `sizes` attribute for <img>.
	 */
	renderWidth?: number;

	/**
	 * sizesArray is very similar to `srcset` attribute on the web. Based on a supplied render dimension,
	 * the most appropriate value in the sizesArray will be used when requesting the image.
	 * The values in the array can represent either image height or width depending on which render dimension is supplied.
	 */
	sizesArray?: number[];

	/**
	 * If true, the intrinsic image dimensions are calculated onLoad and aspectRatio is explicitly set.
	 */
	useIntrinsicAspectRatio?: boolean;
}

/**
 * Returns the most appropriate size for the image request based on supplied sizes array and the
 * pixel ratio of the device. Max pixel ratio is locked down to keep images from being too large.
 */
function getPixelSizeForLayoutSize(
	layoutSize: number | undefined,
	sizesArray: number[] | undefined = undefined
) {
	if (layoutSize === undefined) {
		if (sizesArray === undefined) {
			return undefined;
		} else {
			return sizesArray[0];
		}
	}

	const pixelRatio = Math.min(PixelRatio.get(), MAX_ALLOWED_DEVICE_PIXEL_RATIO);
	const outputSize = Math.floor(layoutSize * pixelRatio);

	if (sizesArray === undefined) {
		return outputSize;
	}

	const sizes = [...sizesArray].sort((a, b) => a - b);
	const size = sizes.find((size) => size >= outputSize) || sizes[sizes.length - 1];

	return size;
}

/**
 * Function for constructing final image source. Adds headers and query params.
 */
export function getImageSource(props: IMobeImagePropType): MobeImageSource {
	const finalUrlQueryParamString = QueryString.stringify({
		width: getPixelSizeForLayoutSize(props.renderWidth, props.sizesArray),
	});

	let finalUrl = '';

	if (props.source) {
		if (typeof props.source === 'object' && 'uri' in props.source && props.source.uri) {
			finalUrl = props.source.uri;
		} else {
			return props.source;
		}
	} else if (typeof props.apiImageId === 'number') {
		finalUrl = buildApiUrl(`image/${props.apiImageId}`);
	} else {
		return {};
	}

	return {
		...(typeof props.source === 'object' ? props.source : {}),
		uri: finalUrlQueryParamString.length > 0 ? `${finalUrl}?${finalUrlQueryParamString}` : finalUrl,
		headers: isApiUrl(finalUrl) ? getClientHeaders() : undefined,
	};
}
