import { ResetPasswordErrorCode } from '@mobe/api/account/accountService';
import { getErrorCodeFromResponseError } from '@mobe/api/client';
import Button from '@mobe/components/button/Button';
import { Callout } from '@mobe/components/callout';
import Heading from '@mobe/components/heading/Heading';
import { InputPassword, useControlledInputProps } from '@mobe/components/input';
import { PasswordRequirementsList } from '@mobe/components/passwordRequirementsList';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	ResetPasswordScreenNavigationProp,
	ResetPasswordScreenRouteProp,
} from '../AuthenticationStackScreen';
import AuthScreenTemplate from '../components/authScreenTemplate/AuthScreenTemplate';
import useResetPasswordController from './resetPasswordScreenController';

export interface IResetPasswordScreenProps {
	navigation: ResetPasswordScreenNavigationProp;
	route: ResetPasswordScreenRouteProp;
}

export default function ResetPasswordScreen(props: IResetPasswordScreenProps) {
	const controller = useResetPasswordController(props);
	const { errors, isValid } = controller.form.formState;
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();

	const passwordInputProps = useControlledInputProps({
		name: 'password',
		control: controller.form.control,
	});

	let errorMessage = '';
	if (controller.resetPasswordMutation.isError) {
		const errorCode = getErrorCodeFromResponseError(
			controller.resetPasswordMutation.error
		) as ResetPasswordErrorCode;

		switch (errorCode) {
			case ResetPasswordErrorCode.ExistingPassword:
				errorMessage = t('auth.resetPassword.errors.existingPassword');
				break;
			case ResetPasswordErrorCode.InvalidPassword:
				errorMessage = t('auth.resetPassword.errors.invalidPassword');
				break;
			case ResetPasswordErrorCode.InvalidResetCode:
				errorMessage = t('auth.resetPassword.errors.invalidResetCode');
				break;
			default:
				errorMessage = t('auth.resetPassword.errors.default');
				break;
		}
	}

	return (
		<AuthScreenTemplate center>
			<View style={vr(2)}>
				<Heading level="h2" accessibilityAutoFocus align="center" style={vr(7)}>
					{t('auth.resetPassword.screenTitle')}
				</Heading>

				{controller.resetPasswordMutation.isError && (
					<Callout type="error" message={errorMessage} />
				)}

				<InputPassword
					label={t('auth.resetPassword.passwordInputLabel')}
					showVisibilityToggle
					textContentType="newPassword"
					collapseEmptyError
					errorMessage={errors.password?.message}
					enterKeyHint="go"
					onSubmitEditing={controller.handleSubmitPress}
					{...passwordInputProps}
				/>
			</View>

			<View style={vr(6)}>
				<PasswordRequirementsList password={passwordInputProps.value || ''} />
			</View>

			<Button
				title={t('auth.resetPassword.submitButton')}
				loading={controller.resetPasswordMutation.isPending}
				disabled={!isValid}
				onPress={controller.handleSubmitPress}
				style={vr(5)}
			/>
			<TextButton
				title={t('auth.resetPassword.returnButton')}
				align="center"
				onPress={controller.handleBackPress}
			/>
		</AuthScreenTemplate>
	);
}

export function useResetPasswordScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();

	return {
		title: t('auth.resetPassword.screenTitle'),
		headerShown: false,
	};
}
