import { yupResolver } from '@hookform/resolvers/yup';
import { useProfileQuery, useUpdateProfileMutation } from '@mobe/api/account/accountApiHooks';
import { IUpdatableUserProfile } from '@mobe/api/account/accountService';
import {
	AppointmentQueryKeys,
	useAllInterestsQuery,
	useScheduleInitialAppointmentMutation,
} from '@mobe/api/appointments/appointmentApiHooks';
import {
	ContactOptions,
	GenderPreference,
	LanguagePreference,
	SchedulingContext,
} from '@mobe/api/appointments/appointmentsService';
import { CoachesQueryKeys } from '@mobe/api/guides/guidesApi';
import { usePermissionsQuery } from '@mobe/api/permissions/permissionsApiHooks';
import useControlledCheckboxProps, {
	IControlledCheckboxProps,
} from '@mobe/components/checkbox/useControlledCheckboxProps';
import { useControlledInputProps } from '@mobe/components/input';
import { IControlledInputProps } from '@mobe/components/input/useControlledInputProps';
import StepIndicator from '@mobe/components/stepIndicator/StepIndicator';
import { useAlert } from '@mobe/utils/useAlert';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { validatePhoneNumber } from '@mobe/utils/validationUtils';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { UseControllerReturn, UseFormReturn, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import * as Yup from 'yup';
import { GuideMatchFormScreenNavigationProp } from '../../AppointmentStackScreen';
import * as AppointmentsAnalyticsEvents from '../../analyticsEvents';
import { GuideMatchFormScreen } from './GuideMatchStackScreen';
import { MAX_INTERESTS } from './constants';

export interface IPotentialGuideMatch {
	name: string;
	id: string;
	description: string;
}

function useValidationSchema() {
	const profileQuery = useProfileQuery();
	const { t } = useTranslation();

	return Yup.object({
		language: Yup.string().oneOf(Object.values(LanguagePreference)).required(),
		gender: Yup.string().oneOf(Object.values(GenderPreference)).required(),
		interestIds: Yup.array().of(Yup.number()).min(1).max(MAX_INTERESTS).required(),
		apptDate: Yup.date().required(),
		guideId: Yup.string().required(),
		contactOption: Yup.string().oneOf(Object.values(ContactOptions)).required(),
		primaryPhoneNumber: Yup.string()
			.label(t('appointments.contactOptionsView.primaryPhoneInputLabel'))
			.min(10, t('forms.errors.phone'))
			.max(10, t('forms.errors.phone'))
			.required()
			.test('valid-phone-if-no-default', t('forms.errors.phone'), (value) => {
				if (!profileQuery.data?.phoneNumber) {
					return validatePhoneNumber(value);
				}

				return true;
			}),
		otherPhoneNumber: Yup.string()
			.label(t('appointments.contactOptionsView.otherPhoneInputLabel'))
			.when('contactOption', {
				is: ContactOptions.Other,
				then: Yup.string().test('valid-phone-number', t('forms.errors.phone'), (value) =>
					validatePhoneNumber(value)
				),
			}),
		saveOtherAsAlternatePhoneNumber: Yup.boolean(),
	});
}

export type FormSchema = {
	language: LanguagePreference;
	gender: GenderPreference;
	interestIds: number[];
	apptDate: Date;
	guideId: string;
	contactOption: ContactOptions;
	primaryPhoneNumber: string;
	otherPhoneNumber: string;
	saveOtherAsAlternatePhoneNumber: boolean;
};

export interface IGuideMatchFormContext {
	handleNativeBackButtonPress: () => void;
	handleNavButtonPress: (screenIndex: number) => void;
	indexOfScreen: (screen: GuideMatchFormScreen) => number;
	currentStepIsLastStep: boolean;
	form: UseFormReturn<FormSchema>;
	languageControl: UseControllerReturn<FormSchema, 'language'>;
	genderControl: UseControllerReturn<FormSchema, 'gender'>;
	interestsControl: UseControllerReturn<FormSchema, 'interestIds'>;
	apptDateControl: UseControllerReturn<FormSchema, 'apptDate'>;
	guideIdControl: UseControllerReturn<FormSchema, 'guideId'>;
	contactOptionControl: UseControllerReturn<FormSchema, 'contactOption'>;
	primaryPhoneNumberProps: IControlledInputProps;
	otherPhoneNumberProps: IControlledInputProps;
	saveOtherAsAlternatePhoneNumberProps: IControlledCheckboxProps;
	isFetching: boolean;
	canSubmit: boolean;
	availableGuideDiKeys: string[];
	handleTimeSelection: (diKeys: string[]) => void;
}

const GuideMatchFormContext = React.createContext<IGuideMatchFormContext | undefined>(undefined);

function GuideMatchFormProvider({
	shouldShowLanguage,
	shouldShowGender,
	children,
}: {
	shouldShowLanguage: boolean;
	shouldShowGender: boolean;
	children: React.ReactNode;
}) {
	const navigation = useNavigation<GuideMatchFormScreenNavigationProp>();
	const [currentScreenIndex, setCurrentScreenIndex] = React.useState(0);
	const { t } = useTranslation();
	const genericErrorAlert = useGenericErrorAlert();

	const [isComplete, setIsComplete] = React.useState(false);

	const [availableGuideDiKeys, setAvailableGuideDiKeys] = React.useState<string[]>([]);

	const profileQuery = useProfileQuery();
	const updateProfileMutation = useUpdateProfileMutation();
	const validationSchema = useValidationSchema();
	const queryClient = useQueryClient();
	const permissionsQuery = usePermissionsQuery();

	const allInterests = useAllInterestsQuery();
	const scheduleInitialAppointment = useScheduleInitialAppointmentMutation();
	const { mobeAlert } = useAlert();

	const form = useForm<FormSchema>({
		mode: 'onChange',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			language: LanguagePreference.English,
			gender: GenderPreference.NoPreference,
			interestIds: [],
			apptDate: new Date(Date.now()),
			guideId: '',
			contactOption: ContactOptions.Primary,
			primaryPhoneNumber: profileQuery.data?.phoneNumber || '',
			otherPhoneNumber: '',
			saveOtherAsAlternatePhoneNumber: false,
		},
	});

	const languageControl = useController({ name: 'language', control: form.control });
	const genderControl = useController({ name: 'gender', control: form.control });
	const interestsControl = useController({ name: 'interestIds', control: form.control });
	const apptDateControl = useController({ name: 'apptDate', control: form.control });
	const guideIdControl = useController({ name: 'guideId', control: form.control });
	const contactOptionControl = useController({ name: 'contactOption', control: form.control });
	const primaryPhoneNumberProps = useControlledInputProps({
		name: 'primaryPhoneNumber',
		control: form.control,
	});
	const otherPhoneNumberProps = useControlledInputProps({
		name: 'otherPhoneNumber',
		control: form.control,
	});
	const saveOtherAsAlternatePhoneNumberProps = useControlledCheckboxProps({
		name: 'saveOtherAsAlternatePhoneNumber',
		control: form.control,
	});

	const canSubmit = form.formState.isValid;

	const isFetching =
		allInterests.isPending ||
		scheduleInitialAppointment.isPending ||
		updateProfileMutation.isPending;

	const shouldShowGenericErrorAlert =
		allInterests.isError || scheduleInitialAppointment.isError || updateProfileMutation.isError;

	const shouldShowAlertOnExit = !isComplete && (currentScreenIndex > 0 || form.formState.isDirty);

	const currentLanguageValue = languageControl.field.value;
	const shouldShowEnglishGender =
		shouldShowLanguage &&
		currentLanguageValue === LanguagePreference.English &&
		permissionsQuery.data?.canSelectGsmOrMskGender;
	const shouldShowSpanishGender =
		shouldShowLanguage &&
		currentLanguageValue === LanguagePreference.Spanish &&
		permissionsQuery.data?.canSelectGsmOrMskSpanishGender;

	// This array determines the screen sequence. Changing the order of this array will change the
	// order in which the screens appear while progressing through Guide Matching.
	const screenSequence: Array<GuideMatchFormScreen> = [
		...(shouldShowLanguage ? (['GUIDE_MATCH_LANGUAGE_SCREEN'] as Array<GuideMatchFormScreen>) : []),
		...(shouldShowEnglishGender || shouldShowSpanishGender
			? (['GUIDE_MATCH_GENDER_SCREEN'] as Array<GuideMatchFormScreen>)
			: []),
		'GUIDE_MATCH_SCHEDULE_SCREEN',
		'GUIDE_MATCH_INTERESTS_SCREEN',
		'GUIDE_MATCH_SELECTION_SCREEN',
		'GUIDE_MATCH_CONTACT_SCREEN',
	];

	const totalSteps = screenSequence.length;
	const currentStepIsLastStep = currentScreenIndex === screenSequence.length - 1;

	React.useEffect(() => {
		navigation.setOptions({
			headerBackground: () => (
				<StepIndicator totalSteps={totalSteps} currentStepIndex={currentScreenIndex} />
			),
		});
	}, [currentScreenIndex, totalSteps]);

	// If the user attempts to exit at any point during the Guide Matching process, given they've
	// made any progress, prompt an alert to confirm action.
	React.useEffect(() => {
		if (shouldShowGenericErrorAlert) {
			genericErrorAlert();
			return;
		}

		if (!shouldShowAlertOnExit) {
			return;
		}

		const removeListener = navigation.addListener('beforeRemove', (event) => {
			event.preventDefault();

			mobeAlert(
				t('appointments.guideMatch.notCompleteAlertTitle'),
				t('appointments.guideMatch.notCompleteAlertBody'),
				[
					{
						text: t('appointments.guideMatch.notCompleteAlertExitButton'),
						style: 'destructive',
						onPress: () => {
							AppointmentsAnalyticsEvents.guideMatchingExitConfirm();
							navigation.dispatch(event.data.action);
						},
					},
					{
						text: t('appointments.guideMatch.notCompleteAlertContinueButton'),
						style: 'cancel',
						onPress: () => AppointmentsAnalyticsEvents.guideMatchingExitCancel(),
					},
				]
			);
		});

		return removeListener;
	}, [navigation, shouldShowGenericErrorAlert, shouldShowAlertOnExit]);

	// Always unset saveOtherAsAlternatePhoneNumber when other not selected
	React.useEffect(() => {
		if (contactOptionControl.field.value !== ContactOptions.Other) {
			form.setValue('saveOtherAsAlternatePhoneNumber', false);
		}
	}, [contactOptionControl.field.value, form]);

	/**
	 * Handler for Android native back button.
	 */
	function handleNativeBackButtonPress() {
		if (currentScreenIndex > 0) {
			goToStep(currentScreenIndex - 1);
			return;
		}

		navigation.pop();
	}

	/**
	 * Handler for navigational UI events.
	 */
	function handleNavButtonPress(screenIndex: number) {
		goToStep(screenIndex);
	}

	/**
	 * Navigate to specific step via index of desired screen.
	 */
	async function goToStep(screenIndex: number) {
		if (screenIndex < 0) {
			return;
		}

		if (screenIndex >= screenSequence.length) {
			handleGuideMatchComplete();
			return;
		}

		navigation.dispatch(CommonActions.navigate(screenSequence[screenIndex]));
		setCurrentScreenIndex(screenIndex);
	}

	/**
	 * Returns index of specified screen.
	 */
	function indexOfScreen(screen: GuideMatchFormScreen) {
		return screenSequence.indexOf(screen);
	}

	/**
	 * Keep available guide di keys when users presses time button
	 */
	function handleTimeSelection(diKeys: string[]) {
		setAvailableGuideDiKeys(diKeys);
	}

	async function setAppt() {
		const formValues = form.getValues();

		const data = await scheduleInitialAppointment.mutateAsync({
			appointmentDateTime: formValues.apptDate.toISOString(),
			guideDiKey: formValues.guideId.toString(),
			interestIds: formValues.interestIds,
			gender: formValues.gender,
			language: formValues.language,
			phoneNumber: getPhoneNumberForAppt(formValues),
			schedulingLocation:
				Platform.OS === 'web'
					? SchedulingContext.ConnectGuideMatchForm
					: SchedulingContext.AppGuideMatchForm,
		});

		AppointmentsAnalyticsEvents.guideMatchSuccess();

		return data.appointmentConfirmationId?.toString();
	}

	/**
	 * Handler for the completion of the form. Invoked when the final step is completed.
	 */
	async function handleGuideMatchComplete() {
		form.handleSubmit(async (formValues: FormSchema) => {
			const apptID = await setAppt();

			await Promise.all([
				queryClient.invalidateQueries({ queryKey: [CoachesQueryKeys.Coaches] }),
				queryClient.invalidateQueries({ queryKey: [AppointmentQueryKeys.AllAppointments] }),

				// Update profile based on form inputs and invalidate profile data
				updateProfile(formValues),
			]);

			setIsComplete(true);

			navigation.navigate('APPT_CONFIRMATION_SCREEN', { apptID });
		})();
	}

	function getPhoneNumberForAppt(formValues: FormSchema) {
		let phoneNumber = profileQuery.data?.phoneNumber || formValues.primaryPhoneNumber;
		if (
			formValues.contactOption === ContactOptions.Alternate &&
			profileQuery.data?.alternatePhoneNumber
		) {
			phoneNumber = profileQuery.data.alternatePhoneNumber;
		}
		if (formValues.contactOption === ContactOptions.Other && formValues.otherPhoneNumber?.length) {
			phoneNumber = formValues.otherPhoneNumber;
		}
		return phoneNumber;
	}

	async function updateProfile(formValues: FormSchema) {
		const updatedProfileData: Partial<IUpdatableUserProfile> = {};

		if (formValues.primaryPhoneNumber) {
			updatedProfileData.phoneNumber = formValues.primaryPhoneNumber;
		}

		if (formValues.saveOtherAsAlternatePhoneNumber) {
			updatedProfileData.alternatePhoneNumber = formValues.otherPhoneNumber;
		}

		// Make updates if conditions to make updates above were met
		if (Object.keys(updatedProfileData).length > 0) {
			await updateProfileMutation.mutateAsync(updatedProfileData);
		}
	}

	return (
		<GuideMatchFormContext.Provider
			value={{
				handleNativeBackButtonPress,
				handleNavButtonPress,
				indexOfScreen,
				currentStepIsLastStep,
				form,
				languageControl,
				genderControl,
				interestsControl,
				apptDateControl,
				guideIdControl,
				contactOptionControl,
				primaryPhoneNumberProps,
				otherPhoneNumberProps,
				saveOtherAsAlternatePhoneNumberProps,
				isFetching,
				canSubmit,
				availableGuideDiKeys,
				handleTimeSelection,
			}}
		>
			{children}
		</GuideMatchFormContext.Provider>
	);
}

function useGuideMatchForm() {
	const context = React.useContext(GuideMatchFormContext);
	if (context === undefined) {
		throw new Error('useGuideMatchForm must be used within a GuideMatchFormProvider');
	}

	return context;
}

export { GuideMatchFormProvider, useGuideMatchForm };
