import { useAllContentQuery } from '@mobe/api/explore/exploreApiHooks';
import ChatButton from '@mobe/components/chatButton/ChatButton';
import VrArray from '@mobe/components/layout/VrArray';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	ExploreViewAllScreensNavigationProp,
	ExploreViewAllScreensRouteProp,
} from '../../navigation/modal/types';
import ContentCard from '../components/ContentCard';

export interface IExploreViewAllScreenParams {
	categoryId: string;
}

interface IExploreViewAllScreenProps {
	navigation: ExploreViewAllScreensNavigationProp;
	route: ExploreViewAllScreensRouteProp;
}

export default function ExploreViewAllScreen({ navigation, route }: IExploreViewAllScreenProps) {
	const allContent = useAllContentQuery();
	const styleRules = useStyleRules();
	const { t } = useTranslation();

	let categoryContent = allContent.data?.find((category) => category.id === route.params.categoryId)
		?.items;

	if (route.params.categoryId === t('explore.favoritesCategoryTitle')) {
		categoryContent = allContent.data
			?.flatMap((category) => category.items)
			.filter((item) => item.isFavorite);
	}

	return (
		<ScreenTemplate scrollViewBackgroundColor={styleRules.colors.background}>
			<VrArray increment={5}>
				{categoryContent?.map((item, i) => (
					<ContentCard
						key={`${i}_${item.sharedContentId}`}
						variant="compact"
						onPress={() =>
							navigation.navigate('EXPLORE_DETAIL_SCREEN', { contentId: item.sharedContentId })
						}
						contentItem={item}
					/>
				))}
			</VrArray>
		</ScreenTemplate>
	);
}

export function useExploreViewAllScreenOptions() {
	const subScreenStyleProps = useSubScreenStyleProps();
	const { t } = useTranslation();

	return (props: IExploreViewAllScreenProps): StackNavigationOptions => {
		const id = props.route.params.categoryId;
		const categoryTranslations = t('explore.categoryTitles', { returnObjects: true }) as Record<
			string,
			string
		>;
		const title = id in categoryTranslations ? categoryTranslations[id] : id;

		return {
			title,
			...subScreenStyleProps,
			headerRight: () => <ChatButton />,
		};
	};
}
