import { buildApiUrl, isApiUrl } from '@mobe/api/client';
import { useGetBlobUrlMutation } from './fileService/fileApiHooks';
import MobeLinking from './linking';

export interface IIdSlugProps {
	id?: number;
	documentSlug: 'shareable-content-file' | 'econsult-document-file';
}

export const usePdfContent = () => {
	const getBlobUrlMutation = useGetBlobUrlMutation();

	const getFinalUrl = (idSlugSource?: IIdSlugProps, url?: string) => {
		let finalUrl = '';

		const isUrlSource = typeof url === 'string';

		// process from id
		if (idSlugSource) {
			finalUrl = buildApiUrl('file', String(idSlugSource.id), idSlugSource.documentSlug);
		}
		// process from url
		else if (isUrlSource) {
			finalUrl = String(url);
		}

		return finalUrl;
	};

	const needsAuthorizationToken = (finalUrl: string) => {
		return isApiUrl(finalUrl);
	};

	const openPrivatePdfInNewTab = async (
		idSlugSource?: IIdSlugProps,
		url?: string,
		title?: string
	) => {
		const finalUrl = getFinalUrl(idSlugSource, url);
		const blob = await getBlobUrlMutation.mutateAsync(finalUrl);
		MobeLinking.openDocument(blob, title);
	};

	const openPdf = (idSlugSource?: IIdSlugProps, url?: string, title?: string) => {
		if (url) {
			const needsAuthorization = needsAuthorizationToken(url);
			needsAuthorization
				? openPrivatePdfInNewTab(undefined, url, title)
				: MobeLinking.openUrl(url, title);
			return;
		}
		openPrivatePdfInNewTab(idSlugSource, undefined, title);
	};

	return {
		getFinalUrl,
		needsAuthorizationToken,
		openPdf,
		isLoading: getBlobUrlMutation.isPending,
	};
};
