import {
	useTrackerEntriesInfiniteQuery,
	useTrackersQuery,
	useTrackerTypeSourcesQuery,
} from '@mobe/api/track/trackApiHooks';
import { useGetTrackerIdFromAbbreviation } from '@mobe/api/track/trackApiUtils';
import { ITrackerDetail, TrackerAbbreviation } from '@mobe/api/track/trackService';
import FetchingIndicator from '@mobe/components/fetchingIndicator/FetchingIndicator';
import InputDateInterval from '@mobe/components/input/InputDateInterval';
import InputDateRange from '@mobe/components/input/InputDateRange';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import { useHasDismissedTrackerDataLagMessage } from '@mobe/utils/storage/appStateStorage';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { View } from 'react-native';
import {
	TrackEntriesScreenNavigationProp,
	TrackEntriesScreenRouteProp,
} from '../../navigation/modal/types';
import ConnectedSourceLagNotice from '../components/ConnectedSourceLagNotice';
import useDateRangeSelect from '../useDateRangeSelect';
import useTrackerContent from '../useTrackerContent';

export interface ITrackEntriesScreenParams {
	trackerAbbreviation: TrackerAbbreviation;
}

export interface ITrackEntriesScreenProps {
	navigation: TrackEntriesScreenNavigationProp;
	route: TrackEntriesScreenRouteProp;
}

export default function TrackEntriesScreen({ navigation, route }: ITrackEntriesScreenProps) {
	const { vr } = useStyleHelpers();
	const trackerContent = useTrackerContent();
	const tracker = trackerContent[route.params.trackerAbbreviation];
	const [hasDismissedTrackerDataLagMessage] = useHasDismissedTrackerDataLagMessage();
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const trackerTypeId = getTrackerIdFromAbbreviation(route.params.trackerAbbreviation);
	const trackersQuery = useTrackersQuery();
	const trackerSourcesQuery = useTrackerTypeSourcesQuery(trackerTypeId);
	const mostRecentEntryDateString = trackersQuery.data?.find(
		(tracker) => tracker.trackerAbbreviation === route.params.trackerAbbreviation
	)?.lastEntry;
	const dateRangeSelect = useDateRangeSelect(mostRecentEntryDateString);
	const trackerEntriesQuery = useTrackerEntriesInfiniteQuery({
		trackerTypeId,
		startDate: dateRangeSelect.startDate,
		endDate: dateRangeSelect.endDate,
		page: 1,
		limit: 30,
	});
	const entries = trackerEntriesQuery.data?.pages.flatMap((page) => page.data) || [];

	function handleShowMoreEntries() {
		trackerEntriesQuery.fetchNextPage();
	}

	function handleEditEntryPress(trackerEntry: ITrackerDetail) {
		navigation.navigate('TRACK_ENTRY_MODAL_SCREEN', {
			trackerAbbreviation: route.params.trackerAbbreviation,
			trackerEntry,
		});
	}

	return (
		<ScreenTemplate>
			<InputDateInterval dateRangeSelect={dateRangeSelect} style={vr(4)} />
			<InputDateRange dateRangeSelect={dateRangeSelect} style={vr(6)} />
			{!hasDismissedTrackerDataLagMessage &&
				Boolean(trackerSourcesQuery.data && trackerSourcesQuery.data.length) && (
					<View style={vr(4)}>
						<ConnectedSourceLagNotice />
					</View>
				)}
			<FetchingIndicator isFetching={trackerEntriesQuery.isFetching}>
				{tracker.renderTable({
					onRowPress: handleEditEntryPress,
					onShowMorePress: handleShowMoreEntries,
					entries,
					unit: tracker.unit(0),
					displayShowMoreButton: trackerEntriesQuery.hasNextPage,
				})}
			</FetchingIndicator>
		</ScreenTemplate>
	);
}

export function useTrackEntriesScreenOptions() {
	const subScreenStyleProps = useSubScreenStyleProps();
	const trackerContent = useTrackerContent();

	return ({ route }: ITrackEntriesScreenProps): StackNavigationOptions => ({
		title: trackerContent[route.params.trackerAbbreviation].title,
		...subScreenStyleProps,
	});
}
