import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import Icon from '../icon/Icon';
import { createFlexibleGraphicProps } from './utils';

export interface IEnvelopeGraphic extends SvgProps {
	customWrapperStyles?: StyleProp<ViewStyle>;
	variant?: 'default' | 'success';
}

export default function EnvelopeGraphic({
	customWrapperStyles,
	variant = 'default',
	...SvgProps
}: IEnvelopeGraphic) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(218, 191);
	const styleRules = useStyleRules();

	const styles = StyleSheet.create({
		success: {
			alignItems: 'center',
			justifyContent: 'center',
			position: 'absolute',
			bottom: -20,
			right: -8,
			width: 76,
			height: 76,
			borderRadius: 38,
			borderWidth: 3,
			borderColor: styleRules.colors.cardBackground,
			backgroundColor: styleRules.colors.success,
		},
	});

	return (
		<View style={[wrapperStyles, customWrapperStyles]}>
			<Svg {...dimensionProps} {...SvgProps}>
				<Path fill="#E8AC4B" d="M218 60.8V191H0V60.8L111.3 0 218 60.8" />
				<Path
					d="M172.7 26H38.3C36 26 34 28 34 30.4V139h158V45.5L172.7 26"
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#F1F1F1"
				/>
				<Path
					d="M192 46.9l-17.5.1c-.5 0-.8-.2-1.2-.5-.3-.3-.4-.8-.4-1.2V28L192 46.9"
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E5E5E5"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FEC14D"
					d="M0 60l107.4 96.6L218 60v131H0V60"
				/>
				<Path fillRule="evenodd" clipRule="evenodd" fill="#FED586" d="M0 191l109.9-78L218 191H0" />
			</Svg>
			{variant === 'success' && (
				<View style={styles.success}>
					<Icon name="checkmark" color="cardBackground" size={35} />
				</View>
			)}
		</View>
	);
}
