import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { Text as RNText, StyleProp, View, ViewStyle } from 'react-native';
import { Text } from '../text';

interface Props {
	number: number;
	size?: 'regular' | 'small';
	style?: StyleProp<ViewStyle>;
}

export type NumberPillProps = Props & RNText['props'];

export default function NumberPill({
	number,
	style,
	size = 'regular',
	...textProps
}: NumberPillProps) {
	const rules = useStyleRules();
	const isSmall = size === 'small';

	return (
		<View
			style={[
				{
					backgroundColor: rules.colors.primary,
					borderRadius: 20,
					paddingHorizontal: isSmall ? 8 : 12,
				},
				style,
			]}
		>
			<Text weight="semiBold" color="inverted" size={isSmall ? 'sm' : 'md'} {...textProps}>
				{number}
			</Text>
		</View>
	);
}
