import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import ProgressTrophy from '../graphics/ProgressTrophy';
import { Text } from '../text';

interface IProgressCardProps {
	count: number;
	title: string;
	isTotalCompleted?: boolean;
}

export default function ProgressCard({
	count,
	title,
	isTotalCompleted = false,
}: IProgressCardProps) {
	const styles = useStyles();

	return (
		<View accessible style={styles.card}>
			<Text
				aria-label={`${count}`}
				weight="bold"
				color="success"
				style={[styles.count, count === 0 && styles.count_zero]}
			>
				{count ? count : '--'}
			</Text>
			<Text weight="semiBold" style={[styles.message, isTotalCompleted && styles.message_narrow]}>
				{title}
			</Text>
			{isTotalCompleted && (
				<View style={styles.graphicContainer}>
					<ProgressTrophy />
				</View>
			)}
		</View>
	);
}

function useStyles() {
	const styleRules = useStyleRules();

	return StyleSheet.create({
		card: {
			justifyContent: 'center',
			padding: 8,
			borderRadius: styleRules.borderRadius,
			minHeight: 130,
			backgroundColor: styleRules.colors.cardBackground,
		},
		count: {
			fontSize: 38,
			lineHeight: 48,
		},
		count_zero: {
			color: styleRules.colors.stroke,
		},
		message: {
			lineHeight: 20 * styleRules.settings.fontScale,
		},
		message_narrow: {
			maxWidth: 100 * styleRules.settings.fontScale,
		},
		graphicContainer: {
			width: 50,
			position: 'absolute',
			right: 10,
		},
	});
}
