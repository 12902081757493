import { IValidicData } from '../authentication/authenticationService';
import { client } from '../client';

export enum EligibilityUserStatus {
	RegisteredConsumer = 'RegisteredConsumer',
	NonRegisteredConsumer = 'NonRegisteredConsumer',
	NonRegisteredConsumerWithStoredPhone = 'NonRegisteredConsumerWithStoredPhone',
}

export interface IAccountEligibilityCheckResponse {
	consumerId: number;
	accessToken: string;
	eligibilityFlow: EligibilityUserStatus;
	phoneNumber: string;
	email: string;
	hasCoach: boolean;
}

export interface IUpdatableUserProfile {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	alternatePhoneNumber: string;
	preferredName: string;
	preferredPronouns: string;
	height: number; // in inches
	avatar?: string | File;
	visionStatement: string;
	visionImage?: string | File;
}

export interface IFullUserProfile extends Omit<IUpdatableUserProfile, 'avatar' | 'visionImage'> {
	userId: number;
	email: string;
	memberDiKey: number;
	avatarUrl: string;
	visionImageUrl: string;
}

interface IEligibilityCheck {
	firstName: string;
	lastName: string;
	dateOfBirth: string;
}

export async function eligibilityCheck({ firstName, lastName, dateOfBirth }: IEligibilityCheck) {
	const response = await client.post('account/eligibility', {
		firstName,
		lastName,
		dateOfBirth,
	});
	return response.data as IAccountEligibilityCheckResponse;
}

interface IClaimAccount {
	registrationToken: string;
	email: string;
	password: string;
}

export enum AccountRegistrationErrorCode {
	// app created
	None = 'NON_API_NONE',
	Unknown = 'NON_API_UNKNOWN',

	// API provided
	InvalidPassword = 'INVALID_PASSWORD',
	EmailAlreadyRegistered = 'EXISTING_EMAIL',
	AccountAlreadyRegistered = 'EXISTING_REGISTRATION',
	ServerError = 'SERVER_ERROR',
}

export async function claimAccount({ registrationToken, email, password }: IClaimAccount) {
	await client.post(
		'account/register',
		{
			email,
			password,
		},
		{
			headers: {
				Authorization: `Bearer ${registrationToken}`,
			},
		}
	);
	return {};
}

interface IResendWelcomeEmail {
	registrationToken: string;
}

export async function resendWelcomeEmail({ registrationToken }: IResendWelcomeEmail) {
	await client.post(
		'account/resend-welcome-email',
		{},
		{
			headers: {
				Authorization: `Bearer ${registrationToken}`,
			},
		}
	);
	return {};
}

interface IForgotPassword {
	email: string;
}

export async function forgotPassword({ email }: IForgotPassword) {
	await client.post('account/forgot-password', {
		email,
	});
	return {};
}

interface IResetPassword {
	resetToken: string;
	password: string;
}

export enum ResetPasswordErrorCode {
	// app created
	None = 'NON_API_NONE',
	Unknown = 'NON_API_UNKNOWN',

	// API provided
	InvalidPassword = 'INVALID_PASSWORD',
	InvalidResetCode = 'INVALID_RESET_CODE',
	ExistingPassword = 'EXISTING_PASSWORD',
}

export async function resetPassword({ resetToken, password }: IResetPassword) {
	await client.post('account/reset-password', {
		guid: resetToken,
		password,
	});
	return {};
}

interface IChangeEmail {
	newEmail: string;
}

export enum ChangeEmailErrorCode {
	// app created
	None = 'NON_API_NONE',
	Unknown = 'NON_API_UNKNOWN',

	// API provided
	UserDoesNotExist = 'USER_DOES_NOT_EXIST',
	IdenticalEmailProvided = 'IDENTICAL_EMAIL',
	EmailAlreadyUsed = 'EXISTING_EMAIL',
}

export async function changeEmail({ newEmail }: IChangeEmail) {
	await client.post('account/change-email', {
		newEmail: newEmail,
		verifyEmail: newEmail,
	});
	return {};
}

interface IGetEmailVerified {
	guid: string;
}

export async function getEmailVerified({ guid }: IGetEmailVerified) {
	await client.get(`account/verify-email-validation/${guid}`);
	return {};
}

interface IVerifyEmail {
	guid: string;
}

export async function verifyEmail({ guid }: IVerifyEmail) {
	await client.post(`account/email-validation/${guid}`);
	return {};
}

interface IChangePassword {
	oldPassword: string;
	newPassword: string;
}

export enum ChangePasswordErrorCode {
	// app created
	None = 'NON_API_NONE',
	Unknown = 'NON_API_UNKNOWN',

	// API provided
	InvalidNewPassword = 'INVALID_NEW_PASSWORD',
	InvalidOldPassword = 'INVALID_OLD_PASSWORD',
	PreviouslyUsedPassword = 'USED_PASSWORD',
}

export async function changePassword({ oldPassword, newPassword }: IChangePassword) {
	await client.post('account/change-password', {
		oldPassword,
		newPassword,
	});
	return {};
}

export async function getProfile() {
	const response = await client.get('account/profile');
	const data = response.data as IFullUserProfile;

	data.phoneNumber = data.phoneNumber || '';
	data.alternatePhoneNumber = data.alternatePhoneNumber || '';

	return data;
}

export async function updateProfile(updatedProfileData: Partial<IUpdatableUserProfile>) {
	const newData = { ...updatedProfileData };
	const formData = new FormData();

	// Below block assumes we are always dealing with an image path that is a string
	if (newData.avatar) {
		if (typeof newData.avatar === 'string') {
			formData.append('avatar', {
				name: 'avatar',
				type: 'image/jpeg',
				uri: newData.avatar,
			});
		} else {
			formData.append('avatar', newData.avatar);
		}
		delete newData.avatar;
	}

	// Below block assumes we are always dealing with an image path that is a string
	if (newData.visionImage) {
		if (typeof newData.visionImage === 'string') {
			formData.append('visionImage', {
				name: 'visionImage',
				type: 'image/jpeg',
				uri: newData.visionImage,
			});
		} else {
			formData.append('visionImage', newData.visionImage);
		}
		delete newData.visionImage;
	}

	// Add rest of non image updates to formData
	Object.entries(newData as Omit<IUpdatableUserProfile, 'avatar' | 'visionImage'>).forEach((pair) =>
		formData.append(pair[0], pair[1])
	);

	// There seems to be a bug with RN and axios, particularly android, FormData header is not getting
	// set automatically so we are explicitly adding it here
	await client.patch('account/profile', formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return {};
}

export async function createValidicUser() {
	const response = await client.put('tracking-connections/enable-integration');
	return response.data as IValidicData;
}

export async function deleteAccount() {
	await client.delete('account');
	return {};
}
