import Button from '@mobe/components/button/Button';
import EnvelopeGraphic from '@mobe/components/graphics/EnvelopeGraphic';
import Heading from '@mobe/components/heading/Heading';
import { InputText, useControlledInputProps } from '@mobe/components/input';
import Text from '@mobe/components/text/Text';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	ForgotPasswordScreenNavigationProp,
	ForgotPasswordScreenRouteProp,
} from '../AuthenticationStackScreen';
import AuthScreenTemplate from '../components/authScreenTemplate/AuthScreenTemplate';
import useForgotPasswordController from './forgotPasswordScreenController';

export interface IForgotPasswordScreenParams {
	context: 'forgotPassword' | 'lockedAccount';
}

export interface IForgotPasswordScreenProps {
	navigation: ForgotPasswordScreenNavigationProp;
	route: ForgotPasswordScreenRouteProp;
}

export default function ForgotPasswordScreen(props: IForgotPasswordScreenProps) {
	const controller = useForgotPasswordController(props);
	const { vr, constrainText, constrain } = useStyleHelpers();
	const { t } = useTranslation();
	const context = props.route.params?.context || 'forgotPassword';

	const emailInputProps = useControlledInputProps({
		name: 'email',
		control: controller.form.control,
	});

	function renderVerifyEmailPrompt() {
		return (
			<>
				<View style={[constrain(200), vr(10)]}>
					<EnvelopeGraphic />
				</View>
				<Heading level="h2" accessible accessibilityAutoFocus align="center" style={vr(5)}>
					{t('auth.forgotPassword.submissionHeading')}
				</Heading>
				<Text weight="medium" align="center" style={[constrainText(350), vr(8)]}>
					{t('auth.forgotPassword.submissionMessage')}
				</Text>
				<Button
					title={t('auth.forgotPassword.returnButton')}
					style={vr(5)}
					onPress={() => {
						props.navigation.navigate('LOGIN_SCREEN', {
							shouldShowForgotPasswordAlert: controller.forgotPasswordMutation.isSuccess
								? true
								: undefined,
						});
					}}
				/>
			</>
		);
	}

	return (
		<AuthScreenTemplate center>
			{controller.forgotPasswordMutation.isSuccess ? (
				renderVerifyEmailPrompt()
			) : (
				<>
					<Heading level="h2" accessibilityAutoFocus align="center" style={vr(7)}>
						{context === 'forgotPassword'
							? t('auth.forgotPassword.heading')
							: t('auth.forgotPassword.lockedAccountHeading')}
					</Heading>
					<Text size="lg" weight="medium" align="center" style={[vr(5), constrainText(300)]}>
						{t('auth.forgotPassword.description')}
					</Text>
					{controller.forgotPasswordMutation.isError && (
						<Text size="lg" color="error" style={vr(5)}>
							{t('auth.forgotPassword.errors.default')}
						</Text>
					)}
					<InputText
						label={t('auth.forgotPassword.emailInputLabel')}
						type="email"
						errorMessage={controller.form.formState.errors.email?.message}
						enterKeyHint="go"
						onSubmitEditing={controller.handleSubmitPress}
						{...emailInputProps}
					/>
					<Button
						title={t('auth.forgotPassword.submitButton')}
						loading={controller.forgotPasswordMutation.isPending}
						onPress={controller.handleSubmitPress}
						style={vr(5)}
					/>
					<TextButton
						title={t('auth.forgotPassword.returnButton')}
						align="center"
						onPress={controller.handleBackPress}
					/>
				</>
			)}
		</AuthScreenTemplate>
	);
}

export function useForgotPasswordScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();

	return {
		title: t('auth.forgotPassword.screenTitle'),
		headerShown: false,
	};
}
