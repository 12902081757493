import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { StyleSheet } from 'react-native';

export default function useProfileHeaderStyles() {
	const rules = useStyleRules();
	const { cardShadow } = useStyleHelpers();

	const styles = StyleSheet.create({
		container: {},
		header: {
			height: 142,
		},
		imageContainer: {
			width: 150,
			height: 150,
			aspectRatio: 1,
			marginTop: -78,
			marginLeft: rules.spacing.appHorizontalMargin,
			borderRadius: 75,
			...cardShadow,
		},
		imageCircleMask: {
			height: '100%',
			width: '100%',
			overflow: 'hidden',
			justifyContent: 'center',
			alignItems: 'center',
			borderWidth: 3,
			borderRadius: 75,
			borderColor: rules.colors.cardBackground,
			backgroundColor: rules.colors.cardBackground,
		},
		image: {
			height: '100%',
			width: '100%',
		},
		imageUploadContainer: {
			position: 'absolute',
			justifyContent: 'center',
			alignItems: 'center',
			bottom: 5,
			right: 0,
			width: 44,
			height: 44,
			aspectRatio: 1,
			borderRadius: 22,
			backgroundColor: rules.colors.primary,
		},
		imageUploadButton: {
			top: -1,
		},
		profilePlaceholderGraphic: {
			width: '40%',
		},
	});

	return styles;
}
