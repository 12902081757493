import { yupResolver } from '@hookform/resolvers/yup';
import { useProfileQuery, useUpdateProfileMutation } from '@mobe/api/account/accountApiHooks';
import {
	useAppointmentsQuery,
	useUpdateAppointmentMutation,
} from '@mobe/api/appointments/appointmentApiHooks';
import { Button } from '@mobe/components/button';
import { Callout } from '@mobe/components/callout';
import { InputText, useControlledInputProps } from '@mobe/components/input';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import useUpdateProfileToast from '@mobe/components/toast/useUpdateProfileToast';
import useUpdateUpcomingCallsToast from '@mobe/components/toast/useUpdateUpcomingCallsToast';
import { useAlert } from '@mobe/utils/useAlert';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { validatePhoneNumber } from '@mobe/utils/validationUtils';
import { useFocusEffect } from '@react-navigation/native';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ProfileEditScreenNavigationProp } from '../ProfileStackScreen';

function useValidationSchema() {
	const { t } = useTranslation();

	return Yup.object({
		// the 100 char max rule comes from database...
		preferredName: Yup.string()
			.label(t('profile.edit.preferredNameInputLabel'))
			.required()
			.max(100),
		primaryPhoneNumber: Yup.string()
			.label(t('profile.edit.primaryPhoneInputLabel'))
			.test(
				'empty-or-valid-phone-number',
				t('forms.errors.phone'),
				(value) => !value || validatePhoneNumber(value)
			),
		alternatePhoneNumber: Yup.string()
			.label(t('profile.edit.alternatePhoneInputLabel'))
			.test(
				'empty-or-valid-phone-number',
				t('forms.errors.phone'),
				(value) => !value || validatePhoneNumber(value)
			),
		personalPronouns: Yup.string().label(t('profile.edit.personalPronounsInputLabel')),
	});
}

type FormSchema = Yup.InferType<ReturnType<typeof useValidationSchema>>;

export default function ProfileEditForm({
	navigation,
}: {
	navigation: ProfileEditScreenNavigationProp;
}) {
	const profileQuery = useProfileQuery();
	const appointmentsQuery = useAppointmentsQuery();
	const futureAppointments = appointmentsQuery.data?.futureAppointments || [];
	const updateAppointmentMutation = useUpdateAppointmentMutation();
	const updateProfileMutation = useUpdateProfileMutation();
	const validationSchema = useValidationSchema();
	const { mobeAlert } = useAlert();
	const genericErrorAlert = useGenericErrorAlert();
	const { t } = useTranslation();
	const updateProfileToast = useUpdateProfileToast();
	const updateUpcomingCallsToast = useUpdateUpcomingCallsToast();

	const form = useForm<FormSchema>({
		mode: 'onTouched',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			preferredName: profileQuery.data?.preferredName || '',
			primaryPhoneNumber: profileQuery.data?.phoneNumber || '',
			alternatePhoneNumber: profileQuery.data?.alternatePhoneNumber || '',
			personalPronouns: profileQuery.data?.preferredPronouns || '',
		},
	});

	const preferredNameProps = useControlledInputProps({
		name: 'preferredName',
		control: form.control,
	});
	const primaryPhoneNumberProps = useControlledInputProps({
		name: 'primaryPhoneNumber',
		control: form.control,
	});
	const alternatePhoneNumberProps = useControlledInputProps({
		name: 'alternatePhoneNumber',
		control: form.control,
	});
	const personalPronounsProps = useControlledInputProps({
		name: 'personalPronouns',
		control: form.control,
	});

	// Focus first form field for a11y
	useFocusEffect(
		React.useCallback(() => {
			setTimeout(() => {
				form.setFocus('preferredName');
			}, 500);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [form.setFocus])
	);

	React.useEffect(() => {
		const unsubscribe = navigation.addListener('beforeRemove', (event) => {
			if (updateProfileMutation.isPending || updateProfileMutation.isSuccess) {
				return;
			}

			if (form.formState.isDirty) {
				event.preventDefault();

				mobeAlert(t('profile.edit.notCompleteAlertTitle'), t('profile.edit.notCompleteAlertBody'), [
					{
						text: t('profile.edit.notCompleteAlertDiscardButton'),
						style: 'destructive',
						onPress: () => navigation.dispatch(event.data.action),
					},
					{
						text: t('profile.edit.notCompleteAlertSaveButton'),
						onPress: handleSubmitPress,
					},
				]);
			}
		});

		return unsubscribe;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.formState.isDirty, updateProfileMutation.status]);

	async function submit(formData: FormSchema) {
		if (
			formData.primaryPhoneNumber !== undefined &&
			profileQuery.data?.phoneNumber !== formData.primaryPhoneNumber &&
			futureAppointments.length > 0
		) {
			await updatePhoneForUpcomingCalls(formData.primaryPhoneNumber);
		}

		updateProfileMutation.mutate(
			{
				preferredName: formData.preferredName,
				phoneNumber: formData.primaryPhoneNumber,
				alternatePhoneNumber: formData.alternatePhoneNumber,
				preferredPronouns: formData.personalPronouns,
			},
			{
				onSuccess: () => {
					navigation.navigate('PROFILE_ROOT_SCREEN');
					updateProfileToast();
				},
			}
		);
	}

	function handleSubmitPress() {
		form.handleSubmit(submit)();
	}

	/**
	 * Function to provide PPTs with the option to update all upcoming calls with new phone number
	 */
	async function updatePhoneForUpcomingCalls(newPhoneNumber: string) {
		return new Promise<void>((resolve, reject) => {
			mobeAlert(
				t('profile.edit.updateUpcomingCallsAlertTitle'),
				t('profile.edit.updateUpcomingCallsAlertBody'),
				[
					{
						style: 'cancel',
						text: t('profile.edit.updateUpcomingCallsAlertDecline'),
						onPress: () => resolve(),
					},
					{
						style: 'default',
						text: t('profile.edit.updateUpcomingCallsAlertUpdate'),
						onPress: () => {
							Promise.all(
								futureAppointments.map((appointment) =>
									updateAppointmentMutation.mutateAsync({
										confirmationId: appointment.confirmationId,
										updatedAppointmentPayload: {
											newPhoneNumber,
											isPhoneNumberTemporary: true,
										},
									})
								)
							)
								.then(() => {
									updateUpcomingCallsToast();
									resolve();
								})
								.catch(() => reject());
						},
					},
				]
			);
		}).catch(() => genericErrorAlert());
	}

	return (
		<ScreenTemplateWithFooter
			footer={
				<Button
					title={t('profile.edit.submitButton')}
					loading={updateProfileMutation.isPending || updateAppointmentMutation.isPending}
					onPress={handleSubmitPress}
				/>
			}
		>
			{updateProfileMutation.isError && (
				<Callout type="error" message={t('profile.edit.errors.default')} />
			)}
			<InputText
				label={t('profile.edit.preferredNameInputLabel')}
				textContentType="name"
				enterKeyHint="next"
				onSubmitEditing={() => form.setFocus('primaryPhoneNumber')}
				errorMessage={form.formState.errors.preferredName?.message}
				{...preferredNameProps}
				testID="editProfilePreferredNameInput"
			/>
			<InputText
				type="phone"
				label={t('profile.edit.primaryPhoneInputLabel')}
				enterKeyHint="next"
				onSubmitEditing={() => form.setFocus('alternatePhoneNumber')}
				errorMessage={form.formState.errors.primaryPhoneNumber?.message}
				{...primaryPhoneNumberProps}
				testID="editProfilePrimaryPhoneInput"
			/>
			<InputText
				type="phone"
				label={t('profile.edit.alternatePhoneInputLabel')}
				enterKeyHint="next"
				onSubmitEditing={() => form.setFocus('personalPronouns')}
				errorMessage={form.formState.errors.alternatePhoneNumber?.message}
				{...alternatePhoneNumberProps}
				testID="editProfileAlternatePhoneInput"
			/>
			<InputText
				label={t('profile.edit.personalPronounsInputLabel')}
				enterKeyHint="go"
				onSubmitEditing={handleSubmitPress}
				errorMessage={form.formState.errors.personalPronouns?.message}
				{...personalPronounsProps}
				testID="editProfilePronounsInput"
			/>
		</ScreenTemplateWithFooter>
	);
}
