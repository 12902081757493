import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { merge } from 'lodash';
import { IAssessment, IPatchAssessment, getAssessment, patchAssessment } from './AssessmentService';

export const ASSESSMENT_QUERY_KEY = 'assessment';

/**
 * Query for the "About You" questionnaire. Returns object representing PPT answers.
 */
export function useAssessmentQuery() {
	return useQuery({
		queryKey: [ASSESSMENT_QUERY_KEY],
		queryFn: getAssessment,
		staleTime: Infinity,
	});
}

/**
 * Mutation for patching the value of any or all of the questionnaire answers.
 * Utilizes optimistic update so that the query can effectively be the source of truth for the UI.
 * @param shouldDelay Applies a minimum resolution time to the mutation success
 */
export function useAssessmentMutation(shouldDelay?: boolean) {
	const queryClient = useQueryClient();
	const delay = shouldDelay ? 350 : 0;

	return useMutation({
		mutationFn: async (params: IPatchAssessment) => {
			await Promise.all([patchAssessment(params), new Promise((r) => setTimeout(r, delay))]);
		},

		onMutate: async (newValue) => {
			await queryClient.cancelQueries({ queryKey: [ASSESSMENT_QUERY_KEY] });
			const previousValue = queryClient.getQueryData<IAssessment>([ASSESSMENT_QUERY_KEY]) || {};
			queryClient.setQueryData([ASSESSMENT_QUERY_KEY], merge(previousValue, newValue));

			return { previousValue };
		},
		onError: (err, variables, context) => {
			queryClient.setQueryData<IAssessment>([ASSESSMENT_QUERY_KEY], context?.previousValue || {});
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [ASSESSMENT_QUERY_KEY] });
		},
	});
}
