import env from '@mobe/env/env';
import axios from 'axios';
import * as Device from 'expo-device';
import { Platform } from 'react-native';

declare global {
	interface FormData {
		append(name: string, value: FormDataValue, fileName?: string): void;
		append(name: string, value: string | number | boolean | null | undefined): void;
		set(name: string, value: FormDataValue, fileName?: string): void;
		set(name: string, value: string | number | boolean | null | undefined): void;
	}
}

const customDeviceHeaders = {
	// The slice() calls below was requested to make sure these strings are not longer than the allowed
	// char limit on the database table. Do not change unless you know the database settings have changed
	'X-Device-Platform': Platform.select({
		ios: 'iOS',
		android: 'Android',
		web: 'Web',
		default: 'Unknown Platform',
	}).slice(0, 20),
	'X-Device-OS-Version': `${Device.osName || 'Unknown OS'} ${
		Device.osVersion || 'Unknown Version'
	}`.slice(0, 100),
	'X-App-Version': env.APP_VERSION.slice(0, 20),
	'X-Device-Model': Device.modelName?.slice(0, 20) || 'Unknown',
};

export const client = axios.create({
	baseURL: env.MOBE_API_URL,
	headers: {
		...customDeviceHeaders,
	},
});

client.interceptors.response.use(
	(response) => response,
	(error) => {
		// Log client errors for development
		if (env.isDev) {
			console.error(`(!) API Client Error [${error.config?.url}]:`, error.message);
			console.error('(!) Params: ', error.config?.params);
			console.error('(!) Error response: ', error.response?.data);
		}

		return Promise.reject(error);
	}
);

export function setClientAuthToken(token?: string) {
	if (!token) {
		client.defaults.headers.common['Authorization'] = '';
		return;
	}
	client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function getClientAuthToken() {
	return client.defaults.headers.common['Authorization']?.toString() || '';
}

export function hasClientAuthToken() {
	return !!client.defaults.headers.common['Authorization'];
}

export function getClientHeaders() {
	return {
		...customDeviceHeaders,
		['Authorization']: getClientAuthToken(),
	};
}

export function getErrorCodeFromResponseError(error: Error) {
	if (axios.isAxiosError(error) && error.response && error.response.data) {
		const data = error.response.data;
		const code = (data.code || 'UNKNOWN').toUpperCase() as string;
		return code;
	}

	return 'UNKNOWN';
}

export function isApiUrl(url: string) {
	return url.indexOf(env.MOBE_API_URL) === 0;
}

export function buildApiUrl(...pathPieces: string[]) {
	return [env.MOBE_API_URL, ...pathPieces].join('/').replace(/([^:])\/+/g, '$1/');
}
