import Box from '@mobe/components/box/Box';
import Heading from '@mobe/components/heading/Heading';
import Row from '@mobe/components/layout/Row';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { format, startOfMonth, subDays } from 'date-fns';
import { round } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, ViewStyle } from 'react-native';

interface IMonthHighlightModuleProps {
	currentMonthValue?: number;
	previousMonthValue?: number;
	aggregateUnitString: string;
	minScale: number;
	dataConversionFactor?: number;
}

export default function MonthHighlightModule({
	currentMonthValue = 0,
	previousMonthValue = 0,
	aggregateUnitString,
	minScale,
	dataConversionFactor = 1,
}: IMonthHighlightModuleProps) {
	const { t } = useTranslation();
	const styles = useStyles();
	const { vr } = useStyleHelpers();

	const now = new Date(Date.now());
	const currentMonthString = format(now, 'MMM');
	const previousMonthString = format(subDays(startOfMonth(now), 1), 'MMM');

	const currentMonthValueString = round(
		currentMonthValue / dataConversionFactor,
		1
	).toLocaleString();
	const previousMonthValueString = round(
		previousMonthValue / dataConversionFactor,
		1
	).toLocaleString();

	const maxValue = Math.max(currentMonthValue, previousMonthValue, minScale);
	const currentMonthWidthRatio = currentMonthValue / maxValue;
	const previousMonthWidthRatio = previousMonthValue / maxValue;

	function barWidth(widthRatio: number): ViewStyle {
		return { width: `${100 * widthRatio}%` };
	}

	return (
		<Box variant="outline">
			<Heading level="h4" style={vr(3)}>
				{t('track.detail.monthlyHighlightTitle')}
			</Heading>

			<View style={styles.monthRow}>
				<View style={styles.monthTitle}>
					<Text weight="medium">{currentMonthString}</Text>
				</View>
				<View style={styles.monthValue}>
					<Row gutterSize={8}>
						<Row.Item>
							<Text size="lg" weight="bold">
								{currentMonthValueString}
							</Text>
						</Row.Item>
						<Row.Item fill>
							<Text size="sm" color="light" lineHeight="tight">
								{aggregateUnitString.toLowerCase()}
							</Text>
						</Row.Item>
					</Row>
					<View style={[styles.bar, barWidth(currentMonthWidthRatio)]}></View>
				</View>
			</View>

			{Boolean(previousMonthValue) && (
				<View style={styles.monthRow}>
					<View style={styles.monthTitle}>
						<Text weight="medium">{previousMonthString}</Text>
					</View>
					<View style={styles.monthValue}>
						<Row gutterSize={8}>
							<Row.Item>
								<Text size="lg" weight="bold">
									{previousMonthValueString}
								</Text>
							</Row.Item>
							<Row.Item fill>
								<Text size="sm" color="light" lineHeight="tight">
									{aggregateUnitString.toLowerCase()}
								</Text>
							</Row.Item>
						</Row>
						<View
							style={[styles.bar, styles.bar_secondary, barWidth(previousMonthWidthRatio)]}
						></View>
					</View>
				</View>
			)}
		</Box>
	);
}

function useStyles() {
	const rules = useStyleRules();

	return StyleSheet.create({
		bar: {
			height: 10,
			marginTop: 4,
			backgroundColor: rules.colors.primary,
			borderTopRightRadius: 3,
			borderBottomRightRadius: 3,
		},
		bar_secondary: {
			backgroundColor: rules.colors.primaryLight,
		},
		monthRow: {
			flexDirection: 'row',
			alignItems: 'center',
			marginVertical: 8,
		},
		monthTitle: {
			width: 50 * rules.settings.fontScale,
		},
		monthValue: {
			flexShrink: 1,
			flexGrow: 1,
			flexBasis: 0,
		},
	});
}
