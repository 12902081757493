import { yupResolver } from '@hookform/resolvers/yup';
import { useResetPasswordMutation } from '@mobe/api/account/accountApiHooks';
import { useLoginOptions } from '@mobe/utils/storage/authStateStorage';
import { validatePassword } from '@mobe/utils/validationUtils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard } from 'react-native';
import * as Yup from 'yup';
import { IResetPasswordScreenProps } from './ResetPasswordScreen';

function useValidationSchema() {
	const { t } = useTranslation();

	return Yup.object({
		password: Yup.string()
			.required()
			.label(t('auth.resetPassword.passwordInputLabel'))
			.test(
				'valid password',
				t('auth.resetPassword.errors.invalidPassword'),
				(value) => validatePassword(value).valid
			),
	});
}

type FormSchema = Yup.InferType<ReturnType<typeof useValidationSchema>>;

export default function useResetPasswordController(props: IResetPasswordScreenProps) {
	const validationSchema = useValidationSchema();
	const resetPasswordMutation = useResetPasswordMutation();
	const { resetSavedLoginCredentials } = useLoginOptions();

	const form = useForm<FormSchema>({
		mode: 'onTouched',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			password: '',
		},
	});

	async function submitResetPassword({ password }: FormSchema) {
		const resetPasswordToken = props.route.params?.resetToken;

		if (!resetPasswordToken) {
			return;
		}

		resetPasswordMutation.mutate(
			{ resetToken: resetPasswordToken, password },
			{
				onSuccess: () => {
					resetSavedLoginCredentials();
					props.navigation.navigate('LOGIN_SCREEN', { shouldShowResetPasswordAlert: true });
				},
			}
		);
	}

	function handleSubmitPress() {
		Keyboard.dismiss();

		if (resetPasswordMutation.isPending) {
			return;
		}

		form.handleSubmit(submitResetPassword)();
	}

	function handleBackPress() {
		Keyboard.dismiss();
		props.navigation.navigate('LOGIN_SCREEN');
	}

	return {
		form,
		resetPasswordMutation,
		handleSubmitPress,
		handleBackPress,
	};
}
