import { useSessionQuery } from '@mobe/api/authentication/authApiHooks';
import { DrawerNavigationOptions } from '@react-navigation/drawer';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	ConnectHealthDataScreenNavigationProp,
	ConnectHealthDataScreenRouteProp,
} from '../navigation/menu/types';
import ConnectHealthDataIntroView from './components/ConnectHealthDataIntroView';
import ConnectTrackersView from './components/ConnectTrackersView';

interface IConnectHealthDataScreenProps {
	navigation: ConnectHealthDataScreenNavigationProp;
	route: ConnectHealthDataScreenRouteProp;
}

export default function ConnectHealthDataScreen({ navigation }: IConnectHealthDataScreenProps) {
	const sessionQuery = useSessionQuery();

	return (
		<>
			{!sessionQuery.data?.validicInfo && <ConnectHealthDataIntroView navigation={navigation} />}
			{sessionQuery.data?.validicInfo && <ConnectTrackersView />}
		</>
	);
}

export function useConnectHealthDataScreenOptions(): DrawerNavigationOptions {
	const { t } = useTranslation();

	return {
		title: t('connectHealthData.screenTitle'),
	};
}
