import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addMinutes, compareAsc, isAfter, minutesToMilliseconds } from 'date-fns';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import { CoachType, ICoach } from '../guides/guidesApi';
import {
	GenderPreference,
	IGetAvailableGuides,
	IGetInitialGuideTimeSlots,
	LanguagePreference,
	deleteAppointment,
	getAllInterests,
	getAppointmentTimeSlots,
	getAppointments,
	getAvailableGuides,
	getInitialGuideTimeSlots,
	scheduleFollowUp,
	scheduleInitialAppointment,
	scheduleInitialAppointmentWithPharmacist,
	trackConsumerSchedulingActivity,
	updateAppointment,
} from './appointmentsService';

export interface IOrderedGuidePriorityArgs {
	guideDiKeys: string[];
	preferredGender: GenderPreference;
	preferredLanguage: LanguagePreference;
	interestIds: number[];
}

export enum AppointmentQueryKeys {
	AllAppointments = 'appointments.allAppointments',
	Interests = 'appointments.interests',
	GuideMatchTimeSlots = 'appointments.guideMatchTimeSlots',
	AvailableGuides = 'appointments.availableGuides',
	FollowUpTimeSlots = 'appointments.followUpTimeSlotsForCalendar',
}

export enum AppointmentMutationKeys {
	InitialAppointment = 'appointments.initialAppointment',
}

export interface IAppointment extends Omit<ICoach, 'bio' | 'displayOrder' | 'chatChannelId'> {
	confirmationId: string;
	appointmentStartDate: string;
	phoneNumber: string | null;
	durationInMinutes: number;
	isGuide: boolean;
	isPharmacist: boolean;
}

export function useGuideMatchTimeSlotsQuery(params: IGetInitialGuideTimeSlots) {
	return useQuery({
		queryKey: [AppointmentQueryKeys.GuideMatchTimeSlots],
		queryFn: () => getInitialGuideTimeSlots(params),
		refetchOnWindowFocus: false,
		refetchInterval: minutesToMilliseconds(5),
	});
}

export function useAllInterestsQuery() {
	return useQuery({
		queryKey: [AppointmentQueryKeys.Interests],
		queryFn: getAllInterests,
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	});
}

export function useAvailableGuidesQuery(params: IGetAvailableGuides) {
	return useQuery({
		queryKey: [AppointmentQueryKeys.AvailableGuides],
		queryFn: () => getAvailableGuides(params),
		refetchOnWindowFocus: false,
	});
}

export function useScheduleFollowUpMutation() {
	return useMutation({ mutationFn: scheduleFollowUp });
}

export function useAppointmentTimeSlotsQuery(
	guide: ICoach | IAppointment | null,
	isInitialPharmacistAppt?: boolean,
	ApptToRescheduleConfirmationId?: string
) {
	return useQuery({
		queryKey: [AppointmentQueryKeys.FollowUpTimeSlots, guide?.guideDIKey],
		queryFn: () =>
			getAppointmentTimeSlots(guide, isInitialPharmacistAppt, ApptToRescheduleConfirmationId),

		// Disabled if guide is null and it's not the initial Rx appt.
		// This is useful in dependent query scenarios.
		enabled: Boolean(guide) || isInitialPharmacistAppt,
	});
}

export interface IAppointments {
	allAppointments: IAppointment[];
	futureAppointments: IAppointment[];
	pastAppointments: IAppointment[];
}

export function useAppointmentsQuery() {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [AppointmentQueryKeys.AllAppointments],
		queryFn: getAppointments,
		select: (data): IAppointments => {
			const allAppointments = data
				.map((appointment) => ({
					...appointment,
					avatarUrl: appointment.avatarUrl ? appointment.avatarUrl : '',
					isGuide: appointment.coachType !== CoachType.Pharmacist,
					isPharmacist: appointment.coachType === CoachType.Pharmacist,
				}))
				.sort((a, b) =>
					compareAsc(new Date(a.appointmentStartDate), new Date(b.appointmentStartDate))
				);

			const futureAppointments = allAppointments.filter((appointment) =>
				isAfter(new Date(appointment.appointmentStartDate), new Date(Date.now()))
			);

			const pastAppointments = allAppointments.filter((appointment) =>
				isAfter(
					new Date(Date.now()),
					addMinutes(new Date(appointment.appointmentStartDate), appointment.durationInMinutes)
				)
			);

			return {
				allAppointments,
				futureAppointments,
				pastAppointments,
			};
		},
		staleTime: 30 * 1000,
		enabled: isAuthenticated,
	});
}

export function useScheduleInitialAppointmentMutation() {
	return useMutation({
		mutationKey: [AppointmentMutationKeys.InitialAppointment],
		mutationFn: scheduleInitialAppointment,
	});
}

export function useScheduleInitialAppointmentWithPharmacistMutation() {
	return useMutation({ mutationFn: scheduleInitialAppointmentWithPharmacist });
}

export function useDeleteAppointmentMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: deleteAppointment,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [AppointmentQueryKeys.AllAppointments] });
		},
	});
}

export function useUpdateAppointmentMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: updateAppointment,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [AppointmentQueryKeys.AllAppointments] });
		},
	});
}

export function useTrackConsumerSchedulingActivityMutation() {
	return useMutation({
		mutationFn: trackConsumerSchedulingActivity,
	});
}
