import { useTrackersAddEntryMutation } from '@mobe/api/track/trackApiHooks';
import { useGetTrackerIdFromAbbreviation } from '@mobe/api/track/trackApiUtils';
import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import useHeightForm from '@mobe/features/track/useHeightForm';
import useTrackerContent from '@mobe/features/track/useTrackerContent';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	OnboardingWeightScreenNavigationProp,
	OnboardingWeightScreenRouteProp,
} from '../OnboardingStackScreen';
import OnboardingScreenTemplate from '../components/OnboardingScreenTemplate';
import useNavigateToNextScreen from '../useNavigateToNextScreen';

interface IOnboardingWeightScreenProps {
	navigation: OnboardingWeightScreenNavigationProp;
	route: OnboardingWeightScreenRouteProp;
}

export default function OnboardingStressScreen({ route }: IOnboardingWeightScreenProps) {
	const { t } = useTranslation();
	const { vr } = useStyleHelpers();
	const navigateToNextScreen = useNavigateToNextScreen(route.name);
	const trackerContent = useTrackerContent()[TrackerAbbreviation.Weight];
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const addEntry = useTrackersAddEntryMutation();
	const { form, renderForm } = trackerContent.useForm({
		tracker: trackerContent,
		staticDate: new Date(Date.now()),
		customInputLabel: t('onboarding.questionLabels.weight'),
		onKeyboardSubmit: handleSubmit,
		isRequired: false,
	});
	const heightForm = useHeightForm({ customInputLabel: t('onboarding.questionLabels.height') });

	function handleSubmit() {
		form.handleSubmit((data) => {
			heightForm.handleSubmit();

			if (!data.tracker) {
				navigateToNextScreen();
				return;
			}

			addEntry.mutate(
				{
					value: data.tracker,
					activityDateTime: data.date.toISOString(),
					trackerTypeId: getTrackerIdFromAbbreviation(TrackerAbbreviation.Weight),
				},
				{
					onSuccess: navigateToNextScreen,
				}
			);
		})();
	}

	return (
		<OnboardingScreenTemplate
			routeName={route.name}
			graphic={trackerContent.graphic}
			title={trackerContent.title}
			isLoading={addEntry.isPending}
			onNextPress={handleSubmit}
		>
			<View style={vr(4)}>{heightForm.renderForm('addEntry')}</View>
			{renderForm()}
		</OnboardingScreenTemplate>
	);
}
