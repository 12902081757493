import env from '@mobe/env/env';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { isObject } from 'lodash';
import { storage } from './storage';

// TODO: Remove all of this after migrations have occurred
export async function migrateFromAsyncStorage(): Promise<void> {
	const migrationKeys = ['colorSchemePreference', 'appFeedback', 'hasDismissedVisionImage'];

	for (const key of migrationKeys) {
		try {
			const value = await AsyncStorage.getItem(key);

			if (value !== null) {
				if (['true', 'false'].includes(value)) {
					storage.set(key, value === 'true');
				} else {
					storage.set(key, isObject(JSON.parse(value)) ? value : JSON.parse(value));
				}

				AsyncStorage.removeItem(key);
			}
		} catch (error) {
			if (env.isDev) {
				console.error(`Failed to migrate key "${key}" from AsyncStorage to MMKV`, error);
			}
		}
	}
}
