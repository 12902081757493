import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import Heading from '../heading/Heading';

interface IBulletListItemProps extends ViewProps {
	children: React.ReactNode;
	index?: number;
	style?: StyleProp<ViewStyle>;
}

export default function OrderedListItem({ children, index, style }: IBulletListItemProps) {
	const styleRules = useStyleRules();
	const styles = StyleSheet.create({
		container: {
			flexDirection: 'row',
		},
		bullet: {
			height: 24 * styleRules.settings.fontScale,
			width: 24 * styleRules.settings.fontScale,
			justifyContent: 'center',
			alignItems: 'center',
			marginRight: 10,
			borderRadius: 12 * styleRules.settings.fontScale,
			backgroundColor: styleRules.colors.primary,
		},
		bullet_hidden: {
			backgroundColor: 'transparent',
		},
		text: {
			flexGrow: 1,
			flexShrink: 1,
		},
	});

	return (
		<View accessible style={[styles.container, style]}>
			<View style={[styles.bullet, index === undefined && styles.bullet_hidden]}>
				{index !== undefined && (
					<Heading color="inverted" level="h4">
						{index + 1}
					</Heading>
				)}
			</View>
			<View style={styles.text}>{children}</View>
		</View>
	);
}
