import { useAppointmentsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import { IAvailableTimeSlot } from '@mobe/api/appointments/appointmentsService';
import Box from '@mobe/components/box/Box';
import { Button } from '@mobe/components/button';
import { Callout } from '@mobe/components/callout';
import MobeParsedText from '@mobe/components/mobeParsedText/MobeParsedText';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useLocalizationSettings from '@mobe/utils/useLocalizationSettings';
import { format, isSameDay } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, View } from 'react-native';
import ApptCalendar from './ApptCalendar';
import ApptTime from './ApptTime';

interface IScheduleApptViewProps {
	apptDates: IAvailableTimeSlot[];
	apptDurationMinutes: number;

	/** Controlled value for tracking the date selection */
	selectedApptDate: Date | undefined;

	/** If true, selectedApptDate is initial value, otherwise initial value will be soonest appt */
	useInitialDate?: boolean;
	onApptSelection: (value: Date | null) => void;
	onTimeSelection?: (availableGuideDiKeys: string[]) => void;
	onRetry?: () => void;
}

export default function ScheduleApptView({
	apptDates,
	apptDurationMinutes,
	selectedApptDate,
	useInitialDate = false,
	onApptSelection,
	onTimeSelection = noop,
	onRetry = noop,
}: IScheduleApptViewProps) {
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();
	const appointmentsQuery = useAppointmentsQuery();
	const appointments = appointmentsQuery.data?.allAppointments || [];
	const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(getInitialCalendarDate);
	const localizationSettings = useLocalizationSettings();

	// Reset apptDate selection upon going back and returning to schedule screen.
	React.useEffect(() => {
		onApptSelection(null);
	}, []); // eslint-disable-line

	/**
	 * Return first available calendar date if any dates exist, or initial date if designated.
	 */
	function getInitialCalendarDate(): Date | undefined {
		if (useInitialDate && selectedApptDate) {
			return selectedApptDate;
		}

		if (apptDates.length) {
			return apptDates[0].utcDateTimeObj;
		}

		return undefined;
	}

	/**
	 * Return all dates that fall on the same calendar date as the selectedDate.
	 */
	function getDatesWithinSelectedDay(apptDates: IAvailableTimeSlot[]) {
		if (!selectedDate) {
			return [];
		}

		return apptDates.filter((appt) => isSameDay(appt.utcDateTimeObj, selectedDate));
	}

	function handleDateSelect(date: Date) {
		setSelectedDate(date);
		onApptSelection(null);
	}

	function handleTimeSelect(date: Date, availableGuideDiKeys: string[]) {
		onApptSelection(date);
		onTimeSelection(availableGuideDiKeys);
	}

	function handleMonthChange() {
		onApptSelection(null);
	}

	function handleRetry() {
		onRetry();
	}

	if (selectedDate) {
		return (
			<>
				<View style={vr(5)} testID="apptCalendar">
					<ApptCalendar
						dates={apptDates.map((appt) => appt.utcDateTimeObj)}
						dotDates={appointments.map((appt) => new Date(appt.appointmentStartDate))}
						selectedDate={selectedDate}
						onDateSelect={handleDateSelect}
						onMonthChange={handleMonthChange}
					/>
				</View>
				<Box style={vr(5)}>
					<View>
						<Text role="heading" aria-level="3" weight="semiBold" align="center">
							{t('appointments.scheduleApptView.availableTimesTitle', {
								date: format(selectedDate, 'EEEE, MMM d'),
							})}
						</Text>
						<Text size="sm" align="center">
							{t('appointments.scheduleApptView.availableTimesTimezone', {
								timezone: localizationSettings.timezoneDisplayLabel,
							})}
						</Text>
					</View>
				</Box>
				<ApptTime
					appts={getDatesWithinSelectedDay(apptDates)}
					apptDurationMinutes={apptDurationMinutes}
					selectedTime={selectedApptDate}
					onTimeSelect={handleTimeSelect}
				/>
			</>
		);
	}

	if (apptDates.length === 0) {
		return <NoResultsCallout onRetryPress={handleRetry} />;
	}

	return null;
}

function NoResultsCallout({ onRetryPress }: { onRetryPress: () => void }) {
	const styleRules = useStyleRules();
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();

	return (
		<Callout>
			<Text style={vr(4)}>
				<MobeParsedText
					parse={[
						{
							type: 'phone',
							style: {
								color: styleRules.colors.text,
								fontFamily: styleRules.fontFamilies.sansBold,
							},
							onPress: (phone: string) => Linking.openURL(`tel:${phone}`),
						},
					]}
				>
					{t('appointments.scheduleApptView.noResultsMessage')}
				</MobeParsedText>
			</Text>
			<Button
				variant="secondary"
				size="sm"
				title={t('appointments.scheduleApptView.retryButtonLabel')}
				onPress={onRetryPress}
			/>
		</Callout>
	);
}
