import { ASSESSMENT_QUERY_KEY } from '@mobe/api/assessment/AssessmentApiHooks';
import { getAssessment } from '@mobe/api/assessment/AssessmentService';
import { Button } from '@mobe/components/button';
import Heading from '@mobe/components/heading/Heading';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import Text from '@mobe/components/text/Text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useFocusEffect } from '@react-navigation/native';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	OnboardingIntroScreenNavigationProp,
	OnboardingIntroScreenRouteProp,
} from '../OnboardingStackScreen';
import OnboardingIntroGraphic from '../components/graphics/OnboardingIntroGraphic';
import useNavigateToNextScreen from '../useNavigateToNextScreen';

interface IOnboardingIntroScreenProps {
	navigation: OnboardingIntroScreenNavigationProp;
	route: OnboardingIntroScreenRouteProp;
}

export default function OnboardingIntroScreen({ navigation }: IOnboardingIntroScreenProps) {
	const { constrainText, constrain, vr } = useStyleHelpers();
	const { t } = useTranslation();
	const parentNavigation = navigation.getParent();
	const queryClient = useQueryClient();
	const navigateToNextScreen = useNavigateToNextScreen();

	useFocusEffect(
		React.useCallback(() => {
			queryClient.prefetchQuery({
				queryKey: [ASSESSMENT_QUERY_KEY],
				queryFn: getAssessment,
				staleTime: Infinity,
			});

			if (!parentNavigation) {
				return;
			}

			parentNavigation.setOptions({
				headerBackground: undefined,
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	function handleContinuePress() {
		navigateToNextScreen();
	}

	return (
		<ScreenTemplateWithFooter
			fixedPositionFooter
			centered
			footer={
				<Button title={t('onboarding.introScreen.startButton')} onPress={handleContinuePress} />
			}
		>
			<View style={[constrain(225), vr(8)]}>
				<OnboardingIntroGraphic />
			</View>
			<Heading accessibilityAutoFocus level="h1" align="center" style={[constrainText(300), vr(8)]}>
				{t('onboarding.introScreen.heading')}
			</Heading>
			<View style={constrainText(350)}>
				<Text size="lg" weight="medium" align="center" style={vr(4)}>
					{t('onboarding.introScreen.descriptionLineOne')}
				</Text>
				<Text size="lg" weight="medium" align="center">
					{t('onboarding.introScreen.descriptionLineTwo')}
				</Text>
			</View>
		</ScreenTemplateWithFooter>
	);
}
