import MobePdf from '@mobe/components/mobePdf/MobePdf';
import { Text } from '@mobe/components/text';
import TextButton from '@mobe/components/textButton/TextButton';
import env from '@mobe/env/env';
import linking from '@mobe/utils/linking';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useModalStyleProps from '@mobe/utils/styles/navigation/useModalStyleProps';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { useAccessibilityInfo } from '@mobe/utils/useAccessibilityInfo';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Platform, StyleSheet, View } from 'react-native';
import {
	TermsAndConditionsScreenNavigationProp,
	TermsAndConditionsScreenRouteProp,
} from '../AuthenticationStackScreen';

interface ITermsAndConditionsScreenProps {
	navigation: TermsAndConditionsScreenNavigationProp;
	route: TermsAndConditionsScreenRouteProp;
}

export default function TermsAndConditionsScreen({
	navigation,
	route,
}: ITermsAndConditionsScreenProps) {
	const {
		params: { view },
	} = route;
	const styleRules = useStyleRules();
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();
	const { screenReaderEnabled } = useAccessibilityInfo();

	const urlMap = {
		termsAndConditions: env.TERMS_AND_CONDITIONS_URL,
		privacyPolicy: env.PRIVACY_POLICY_URL,
	};

	const styles = StyleSheet.create({
		container: {
			flex: 1,
			justifyContent: 'flex-start',
			alignItems: 'center',
			backgroundColor: styleRules.colors.cardBackground,
		},
		alternateContentInfoContainer: {
			width: Dimensions.get('window').width,
			padding: styleRules.spacing.appHorizontalMargin,
			backgroundColor: styleRules.colors.cardBackground,
		},
		alternateContentButtonsContainer: {
			flexDirection: 'row',
		},
		pdf: {
			flex: 1,
			width: Dimensions.get('window').width,
			height: Dimensions.get('window').height,
		},
	});

	// TODO: Figure out how to not do workaround below
	// Adding trust all certs because of issue on android where pdf doesn't render
	// https://github.com/wonday/react-native-pdf/issues/648
	return (
		<View style={styles.container}>
			{screenReaderEnabled ? (
				<View style={styles.alternateContentInfoContainer}>
					<Text accessibilityAutoFocus size="sm" style={vr(4)}>
						{t('auth.termsAndConditions.alternateContentInfoHeading')}
					</Text>
					<View style={styles.alternateContentButtonsContainer}>
						<TextButton
							title={t('auth.termsAndConditions.termsAndConditions')}
							style={{ marginRight: 20 }}
							onPress={() => linking.openUrl(urlMap.termsAndConditions)}
						/>
						<TextButton
							title={t('auth.termsAndConditions.privacyPolicy')}
							onPress={() => linking.openUrl(urlMap.privacyPolicy)}
						/>
					</View>
				</View>
			) : null}

			<MobePdf
				trustAllCerts={Platform.OS === 'android' ? false : true}
				url={urlMap[view]}
				style={styles.pdf}
			/>
		</View>
	);
}

export function useTermsAndConditionsScreenOptions() {
	const { t } = useTranslation();
	const modalStyleProps = useModalStyleProps();

	return ({ navigation, route }: ITermsAndConditionsScreenProps): StackNavigationOptions => {
		const {
			params: { view },
		} = route;

		const titleMap = {
			privacyPolicy: t('auth.termsAndConditions.privacyPolicy'),
			termsAndConditions: t('auth.termsAndConditions.termsAndConditions'),
		};

		return {
			title: titleMap[view],
			...modalStyleProps,
		};
	};
}
