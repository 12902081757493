import {
	IExploreContentItemFull,
	IExploreContentItemSummary,
} from '@mobe/api/explore/exploreService';
import Heading from '@mobe/components/heading/Heading';
import IconButton from '@mobe/components/iconButton/IconButton';
import Row from '@mobe/components/layout/Row';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useLayout from '@mobe/utils/styles/useLayout';
import { useIntersection } from '@mobe/utils/useIntersection';
import Color from 'color';
import { clamp, noop } from 'lodash';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import {
	GestureResponderEvent,
	ScrollView,
	StyleProp,
	StyleSheet,
	View,
	ViewStyle,
	useWindowDimensions,
} from 'react-native';
import { useModalNavigation } from '../../navigation/modal/ModalNavigationProvider';
import ContentCard from './ContentCard';

const MAX_CARDS = 10;
const cardMaxWidth = 202;
const gapWidth = 20;
const paddingBuffer = 16;
const initBufferWidth = cardMaxWidth / 2;

interface IHorizontalContentViewProps {
	onCardPress: (contentId: number, event: GestureResponderEvent) => void;
	onViewAllPress?: () => void;
	alwaysShowViewAll?: boolean;
	id: string;
	items: Array<IExploreContentItemFull | IExploreContentItemSummary>;
	gradientColor?: string;
	style?: StyleProp<ViewStyle>;
}

export default function HorizontalContentView({
	onCardPress = noop,
	onViewAllPress,
	alwaysShowViewAll = false,
	id,
	items,
	gradientColor,
	style,
}: IHorizontalContentViewProps) {
	const navigation = useModalNavigation();
	const { vr, wrapperHorizontal, popupShadow } = useStyleHelpers();
	const { t } = useTranslation();
	const rules = useStyleRules();
	const windowDimensions = useWindowDimensions();
	const { bottomTabScreenWidth } = useLayout();
	const maxItems = items.length >= MAX_CARDS ? MAX_CARDS : items.length;
	const scrollViewRef = useRef<ScrollView>(null);
	const endRef = useRef<HTMLDivElement>(null);
	const startRef = useRef<HTMLDivElement>(null);
	const [index, setIndex] = useState(0);
	const [currentXPosition, setCurrentXPosition] = useState(0);
	const [bufferWidth, setBufferWidth] = useState(initBufferWidth);
	const endInViewport = useIntersection(endRef, { threshold: 0.2 });
	const startInViewport = useIntersection(startRef, { threshold: 0.2 });
	const gradientHex = gradientColor ? gradientColor : rules.colors.background;

	const categoryTranslations = t('explore.categoryTitles', { returnObjects: true }) as Record<
		string,
		string
	>;
	const title = id in categoryTranslations ? categoryTranslations[id] : id;

	const onViewAllButtonPress = () => {
		if (onViewAllPress) {
			onViewAllPress();
			return;
		}

		navigation.navigate('EXPLORE_VIEW_ALL_SCREEN', { categoryId: id });
	};

	const styles = StyleSheet.create({
		wrapper: {
			position: 'relative',
			maxWidth: rules.spacing.maxWidth + initBufferWidth * 2,
			left: '50%',
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			transform: [{ translateX: '-50%' as any }],
		},
		button: {
			position: 'absolute',
			top: '50%',
			transform: [{ translateY: -23 }],
			height: 46,
			width: 46,
			zIndex: 100,
			backgroundColor: 'white',
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 100,
			...popupShadow,
		},
		leftButton: {
			left: 0,
		},
		rightButton: {
			right: 0,
		},
		scrollViewContainer: {
			display: 'flex',
			flexDirection: 'row',
		},
	});

	const gradientStyles = {
		width: bufferWidth,
		height: '100%',
		position: 'absolute',
		zIndex: 50,
	};

	const webStyles = {
		leftToRightGradient: {
			...gradientStyles,
			left: 0,
			background: `linear-gradient(to right, ${Color(gradientHex).rgb()}, rgba(255,255,255,0))`,
		} as CSSProperties,
		rightToLeftGradient: {
			...gradientStyles,
			right: 0,
			top: 0,
			background: `linear-gradient(to right, rgba(255,255,255,0), ${Color(gradientHex).rgb()})`,
		} as CSSProperties,
		buffer: {
			minWidth: bufferWidth,
			height: '100%',
			marginTop: 'auto',
			marginBottom: 'auto',
		} as CSSProperties,
	};

	const handlePrevious = () => {
		const idx = clamp(index - 1, 0, maxItems);
		setIndex(idx);

		if (idx === 0) {
			setCurrentXPosition(0);
			scrollViewRef.current?.scrollTo({ x: 0 });
		} else {
			const xPos = idx * (cardMaxWidth + gapWidth);
			setCurrentXPosition(xPos);
			scrollViewRef.current?.scrollTo({ x: xPos });
		}
	};

	const handleNext = () => {
		const idx = clamp(index + 1, 0, maxItems);
		setIndex(idx);

		const xPos = idx * (cardMaxWidth + gapWidth);
		setCurrentXPosition(xPos);

		scrollViewRef.current?.scrollTo({ x: xPos });
	};

	useEffect(() => {
		const index = Math.floor(currentXPosition / (cardMaxWidth + gapWidth));

		setIndex(index);
	}, [currentXPosition]);

	useEffect(() => {
		const maxWidth = rules.spacing.maxWidth + initBufferWidth * 2;

		if (bottomTabScreenWidth < maxWidth) {
			const subtractWidth = maxWidth - bottomTabScreenWidth;

			const newBufferWidth = initBufferWidth - subtractWidth / 2;

			setBufferWidth(newBufferWidth <= 0 ? 1 : newBufferWidth);
		} else {
			setBufferWidth(initBufferWidth);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowDimensions]);

	return (
		<View style={style}>
			<View style={wrapperHorizontal}>
				<Row style={vr(3)}>
					<Row.Item fill>
						<Heading level="h2">{title}</Heading>
					</Row.Item>
					{(items.length > 2 || alwaysShowViewAll) && (
						<Row.Item>
							<TextButton title={t('explore.viewAllButton')} onPress={onViewAllButtonPress} />
						</Row.Item>
					)}
				</Row>
			</View>
			<View style={styles.wrapper}>
				<div style={webStyles.leftToRightGradient}>
					{isMobile ? null : !startInViewport ? (
						<IconButton
							name="left"
							color="primary"
							size={26}
							style={[styles.button, styles.leftButton]}
							onPress={handlePrevious}
						/>
					) : null}
				</div>
				<ScrollView
					ref={scrollViewRef}
					horizontal
					onScroll={(event) => setCurrentXPosition(event.nativeEvent.contentOffset.x)}
					scrollEventThrottle={16}
					contentContainerStyle={styles.scrollViewContainer}
					showsHorizontalScrollIndicator={false}
				>
					<div ref={startRef} style={webStyles.buffer} />
					{items.map(
						(item, i) =>
							i < MAX_CARDS && (
								<View
									key={item.sharedContentId}
									style={{
										maxWidth: cardMaxWidth,
										minWidth: cardMaxWidth,
										marginLeft: i ? gapWidth : paddingBuffer,
										marginRight: i + 1 === maxItems ? paddingBuffer : 0,
										paddingBottom: 4,
									}}
								>
									<ContentCard
										onPress={(event) => onCardPress(item.sharedContentId, event)}
										contentItem={item}
									/>
								</View>
							)
					)}
					<div ref={endRef} style={webStyles.buffer} />
				</ScrollView>
				<div style={webStyles.rightToLeftGradient}>
					{isMobile ? null : !endInViewport ? (
						<IconButton
							name="right"
							color="primary"
							size={26}
							style={[styles.button, styles.rightButton]}
							onPress={handleNext}
						/>
					) : null}
				</div>
			</View>
		</View>
	);
}
