import {
	useAllActivitiesQuery,
	useMarkActivityCompleteMutation,
} from '@mobe/api/activities/activitiesApiHooks';
import { ActivityType } from '@mobe/api/activities/activitiesService';
import { useTrackersQuery } from '@mobe/api/track/trackApiHooks';
import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { useNavigation } from '@react-navigation/native';
import { assessmentScreenSequence } from './assessmentScreenSequence';
import { OnboardingParamList, OnboardingScreenNavigationProp } from './OnboardingStackScreen';

export default function useNavigateToNextScreen(routeName?: keyof OnboardingParamList) {
	const trackersQuery = useTrackersQuery();
	const navigation = useNavigation<OnboardingScreenNavigationProp>();
	const markActivityCompleteMutation = useMarkActivityCompleteMutation();
	const activitiesQuery = useAllActivitiesQuery();
	const routeIndex = assessmentScreenSequence.findIndex(
		(screen) => screen.screenName === routeName
	);
	const nextScreenWithoutData = assessmentScreenSequence
		.slice(routeIndex + 1)
		.find((screen) => !trackerHasData(screen.trackerAbbreviation))?.screenName;
	const genericErrorAlert = useGenericErrorAlert();

	function trackerHasData(trackerAbbreviation: TrackerAbbreviation) {
		return (
			typeof trackersQuery.data?.find(
				(tracker) => tracker.trackerAbbreviation === trackerAbbreviation
			)?.lastEntry === 'string'
		);
	}

	async function submitAssessment() {
		const activity = activitiesQuery.data?.activities.find(
			(activity) => activity.type === ActivityType.Assessment
		);

		if (!activity) {
			genericErrorAlert();
			return;
		}

		await markActivityCompleteMutation.mutateAsync({ activityId: activity?.id });

		navigation.navigate('ONBOARDING_CONFIRMATION_SCREEN');
	}

	function navigateToNextScreen() {
		if (nextScreenWithoutData) {
			navigation.navigate(nextScreenWithoutData);
		} else {
			submitAssessment();
		}
	}

	return navigateToNextScreen;
}
