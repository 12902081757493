import { client } from '../client';
import { ExploreContentType } from '../explore/exploreService';
import { TrackerAbbreviation } from '../track/trackService';

export interface IGetActivitiesResponse {
	active: IActivityFull[];
	completed: IActivityFull[];
	completedSummary: {
		currentWeekCount: number;
		lifetimeCount: number;
	};
}

export enum ActivityType {
	MedSummary = 'MedSummary',
	SharedContent = 'SharedContent',
	Survey = 'Survey',
	System = 'System',
	GuideMatch = 'GuideMatch',
	Assessment = 'About',
	Track = 'Tracker',
	ClientProgramReferral = 'ClientProgramReferral',
}

/**
 * The shared interface between dashboard activities and regular activities
 */
export interface IActivity {
	id: number;
	title: string;
	type: ActivityType;
	sharedContentType: ExploreContentType | null;
	timeToConsumeInMinutes: number | null;

	/**
	 * Overloaded property used as contentId for shared content, id for PDF, or id for survey
	 */
	activityTargetId: string;

	/**
	 * Property represents either the URL for a survey or tracker abbreviation for a tracker activity
	 */
	activityTargetUrl: string | TrackerAbbreviation | null;
	description: string | null;
	note: string | null;
}

/**
 * The full activity interface is applicable to all activities other than what comes from the dashboard
 */
export interface IActivityFull extends IActivity {
	/**
	 * Date string
	 */
	dateCompleted: string;
	isCompleted: boolean;
}

export async function getAllActivities() {
	const response = await client.get('activities');
	return response.data as IGetActivitiesResponse;
}

interface IMarkActivityComplete {
	activityId: number;
}

export async function markActivityComplete({ activityId }: IMarkActivityComplete) {
	await client.post(`activities/${activityId}/complete`);
	return {};
}

interface IMarkActivityDismissed {
	activityId: number;
}

export async function markActivityDismissed({ activityId }: IMarkActivityDismissed) {
	await client.post(`activities/${activityId}/dismiss`);
	return {};
}
