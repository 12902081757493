import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { Pressable, StyleSheet, View, useWindowDimensions } from 'react-native';
import { Text } from '../text';

export interface IInputRangeOptions {
	label: string;
	answerId: number;
}

export interface IInputRangeProps {
	id?: string;
	labelLeft?: string;
	labelCenter?: string;
	labelRight?: string;
	options: IInputRangeOptions[];
	value?: number | string;
	onValueChange: (value: number) => void;
}

export default function InputRange({
	id,
	labelLeft,
	labelCenter,
	labelRight,
	options,
	value,
	onValueChange,
	...viewProps
}: IInputRangeProps) {
	const styles = useStyles();
	const leftLabelId = `${id}-left-label`;
	const centerLabelId = `${id}-center-label`;
	const rightLabelId = `${id}-right-label`;

	function handleOptionPress(answerId: number) {
		onValueChange(answerId);
	}

	return (
		<>
			<View style={styles.container} role="radiogroup" {...viewProps}>
				{options.map((option, i) => {
					const selected = option.answerId === value;

					return (
						<Pressable
							key={option.answerId}
							id={`${id}-option-${i}`}
							role="radio"
							style={[
								styles.option,
								i === 0 && styles.option_first,
								selected && styles.option_selected,
							]}
							onPress={() => handleOptionPress(option.answerId)}
							aria-checked={selected}
							aria-describedby={`${leftLabelId} ${centerLabelId} ${rightLabelId}`}
						>
							<Text align="center" weight="bold" color={selected ? 'inverted' : 'regular'}>
								{option.label}
							</Text>
						</Pressable>
					);
				})}
			</View>
			<View style={styles.rangeLabelContainer}>
				<View style={styles.rangeLabel}>
					<Text id={leftLabelId} style={styles.rangeLabelText} color="light" align="left">
						{labelLeft}
					</Text>
				</View>
				<View style={[styles.rangeLabel, styles.rangeLabel_center]}>
					<Text id={centerLabelId} style={styles.rangeLabelText} color="light" align="center">
						{labelCenter}
					</Text>
				</View>
				<View style={[styles.rangeLabel, styles.rangeLabel_right]}>
					<Text id={rightLabelId} style={styles.rangeLabelText} color="light" align="right">
						{labelRight}
					</Text>
				</View>
			</View>
		</>
	);
}

function useStyles() {
	const rules = useStyleRules();
	const rangeLabelWidth = 120 * rules.settings.fontScale;
	const windowWidth = useWindowDimensions().width;

	return StyleSheet.create({
		container: {
			overflow: 'hidden',
			flexDirection: 'row',
			borderWidth: 1,
			borderColor: rules.colors.stroke,
			borderRadius: rules.borderRadius,
			marginBottom: 10,
		},
		option: {
			minHeight: 36,
			flexBasis: 0,
			flexGrow: 1,
			justifyContent: 'center',
			borderLeftWidth: 1,
			borderColor: rules.colors.stroke,
		},
		option_first: {
			borderLeftWidth: 0,
		},
		option_selected: {
			borderColor: rules.colors.primary,
			backgroundColor: rules.colors.primary,
		},
		rangeLabelContainer: {
			width: '100%',
			flexDirection: 'row',
			alignSelf: 'center',
			justifyContent: 'space-between',
		},
		rangeLabel: {
			flexBasis: 0,
			flexGrow: 1,
		},
		rangeLabel_right: {
			alignItems: 'flex-end',
		},
		rangeLabel_center: {
			alignItems: 'center',

			// Hides the center label if it cannot fit between the other two
			display: rangeLabelWidth * 3 > windowWidth ? 'none' : 'flex',
		},
		rangeLabelText: {
			maxWidth: rangeLabelWidth,
		},
	});
}
