import env from '@mobe/env/env';
import { useAuthStore } from '@mobe/features/auth/useAuthStore';
import useBioAuthService from '@mobe/features/auth/useBioAuthService';
import Analytics from '@mobe/utils/analytics';
import { ASYNC_STORAGE_KEY } from '@mobe/utils/asyncStorage';
import messaging from '@mobe/utils/messaging';
import sessionStorage from '@mobe/utils/sessionStorage';
import { useLoginCount } from '@mobe/utils/storage/appStateStorage';
import { useLoginOptions } from '@mobe/utils/storage/authStateStorage';
import { useAppStateChange } from '@mobe/utils/useAppStateChange';
import useRedirectLinkTo from '@mobe/utils/useRedirectLinkTo';
import { REMOTE_CONFIG_QUERY_KEY } from '@mobe/utils/useRemoteConfigQuery';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { Platform } from 'react-native';
import { setClientAuthToken } from '../client';
import { ExploreQueryKeys } from '../explore/exploreQueryKeys';
import { getAllFeaturedSharedContent } from '../explore/exploreService';
import { CoachesQueryKeys, fetchCoaches } from '../guides/guidesApi';
import { getPermissions } from '../permissions/permissionsService';
import { PERMISSIONS_QUERY_KEY } from '../permissions/types';
import { getAllTrackerTypes } from '../track/trackService';
import { TrackQueryKeys } from '../track/types';
import {
	IAuthenticationResponse,
	login,
	logout,
	LogoutType,
	sendVerificationCode,
	setDeviceToken,
	verifyCode,
} from './authenticationService';

export let shouldShowBioAuthPrompt = true;
let lastActivityTime = -1;

const SESSION_ALIVE_POLL_TIME_IN_MS = 5000;

export function resetIdleTimeout() {
	lastActivityTime = Date.now();
}

export function resetLastActivityTime() {
	lastActivityTime = -1;
}

function isSessionExpired() {
	const timeSinceLastActivity = Date.now() - lastActivityTime;
	return timeSinceLastActivity > env.INACTIVITY_TIMEOUT_IN_MS;
}

// Only use this hook once in the app
export function useAuthIdleTimeout() {
	const isAuthenticated = useIsAuthenticated();
	const logoutMutation = useLogoutMutation();

	// Reset idle timeout when app comes to foreground
	useAppStateChange({
		onActive: () => {
			// Check if the session is expired first
			if (isSessionExpired()) {
				return;
			}

			resetIdleTimeout();
		},
	});

	React.useEffect(() => {
		function checkIdleTimeout() {
			if (isSessionExpired()) {
				triggerSessionExpiration();
			}
		}

		function triggerSessionExpiration() {
			logoutMutation.mutate(
				{ logoutType: LogoutType.SessionExpired },
				{
					onSuccess: () => {
						lastActivityTime = -1;
					},
				}
			);
		}

		let interval: NodeJS.Timeout;

		if (isAuthenticated) {
			interval = setInterval(() => {
				checkIdleTimeout();
			}, SESSION_ALIVE_POLL_TIME_IN_MS);
		}

		return () => clearInterval(interval);
	}, [isAuthenticated]);
}

export const SESSION_QUERY_KEY = 'session';

// IMPORTANT
// This query will primarily always be manually set by login and logout mutation
// to get data from the login response
export function useSessionQuery() {
	return useQuery<IAuthenticationResponse | null>({
		queryKey: [SESSION_QUERY_KEY],
		queryFn: () => {
			// Get existing web session data for same browser session refresh
			if (Platform.OS === 'web') {
				const existingSessionData = JSON.parse(
					sessionStorage.getItem(SESSION_QUERY_KEY)
				) as IAuthenticationResponse;

				if (existingSessionData) {
					// IMPORTANT: Authenticate the api client
					setClientAuthToken(existingSessionData.token);

					return existingSessionData;
				} else {
					return null;
				}
			}

			return null;
		},
		staleTime: Infinity,
	});
}

export function useIsAuthenticated() {
	const sessionQuery = useSessionQuery();
	return Boolean(sessionQuery.data?.token);
}

function usePrefetchImportantQueries() {
	const queryClient = useQueryClient();

	return () =>
		Promise.all([
			queryClient.prefetchQuery({
				queryKey: [ExploreQueryKeys.AllFeaturedSharedContent],
				queryFn: getAllFeaturedSharedContent,
			}),
			queryClient.prefetchQuery({
				queryKey: [CoachesQueryKeys.Coaches],
				queryFn: fetchCoaches,
			}),
			queryClient.prefetchQuery({
				queryKey: [PERMISSIONS_QUERY_KEY],
				queryFn: getPermissions,
			}),
			queryClient.prefetchQuery({
				queryKey: [TrackQueryKeys.AllTrackers],
				queryFn: getAllTrackerTypes,
			}),
		]);
}

export function useLoginMutation() {
	const queryClient = useQueryClient();
	const [loginCount, setLoginCount] = useLoginCount();
	const { setFirstTimeUsername } = useAuthStore();
	const { setEnableBiometricLogin, setRememberMe, updateSavedLoginCredentials } = useLoginOptions();
	const prefetchImportantQueries = usePrefetchImportantQueries();
	const redirectTo = useRedirectLinkTo();

	return useMutation({
		mutationFn: login,
		onSuccess: async (data, variables) => {
			let shouldEnableRememberMe = variables.rememberMe;
			let shouldEnableBioAuth = variables.enableBiometricLogin;

			if (variables.bioAuthPrompt !== undefined) {
				shouldEnableBioAuth = await variables.bioAuthPrompt();
				shouldEnableRememberMe = shouldEnableBioAuth || variables.rememberMe;
			}

			// IMPORTANT: Authenticate the api client
			setClientAuthToken(data.token);

			// IMPORTANT: Prefetch important queries concurrently here before session query is populated
			await prefetchImportantQueries();

			resetIdleTimeout();
			shouldShowBioAuthPrompt = false;

			// IMPORTANT: Set session data to session query
			// This is what triggers actual authentication in the app
			// useIsAuthenticated hook looks for the token in this query
			queryClient.setQueryData([SESSION_QUERY_KEY], data);

			// Set session data to session storage for web
			sessionStorage.setItem(SESSION_QUERY_KEY, JSON.stringify(data));

			setLoginCount(loginCount + 1);

			if (shouldEnableBioAuth !== null) {
				setEnableBiometricLogin(shouldEnableBioAuth);
			}

			if (shouldEnableRememberMe !== null) {
				setRememberMe(shouldEnableRememberMe);
			}

			updateSavedLoginCredentials({
				username: variables.username,
				password: variables.password,
				rememberMe: shouldEnableRememberMe,
			});

			setFirstTimeUsername('');

			Analytics.logLogin({ method: 'app' });

			// Send device token to backend
			await messaging.requestUserPermission();
			const token = await messaging.getToken();

			if (token) {
				await setDeviceToken({ deviceToken: token });
			}

			// Force refetch of remote config
			queryClient.invalidateQueries({ queryKey: [REMOTE_CONFIG_QUERY_KEY] });

			// This handles forwarding to the redirectTo screen path on login screen
			// Pushing this to the end of the event cycle to fix issue with web not redirecting
			setTimeout(() => {
				if (variables.redirectTo) {
					redirectTo(variables.redirectTo);
				}
			}, 0);
		},
	});
}

export function useLogoutMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: logout,

		// Using on settled here cause we want logout no matter if we get error or success
		onSettled: (data, error, variables) => {
			// IMPORTANT: Remove session data from session query
			// This is what triggers actual authentication in the app
			queryClient.setQueryData([SESSION_QUERY_KEY], null);

			// Remove session data to session storage for web
			sessionStorage.removeItem(SESSION_QUERY_KEY);

			// IMPORTANT: Clear authentication header token from the api client
			// This is needed to prevent the api client from sending requests with the old token
			setClientAuthToken('');

			// Reset idle timeout activity time
			resetLastActivityTime();

			// Remove queries from RQ cache
			// Omit the below queries, we want to keep these queries cached even between different users
			const omittedQueryKeys = [ASYNC_STORAGE_KEY, REMOTE_CONFIG_QUERY_KEY];

			queryClient.removeQueries({
				predicate: (query) => {
					const queryKey = query.queryKey;

					// Some queries are arrays of values
					if (Array.isArray(queryKey)) {
						return !queryKey.some((value) => omittedQueryKeys.includes(value));
					}

					if (typeof queryKey === 'string') {
						return !omittedQueryKeys.includes(queryKey);
					}

					return false;
				},
			});
		},
	});
}

export function useBioAuthLoginMutation() {
	const { attemptBioAuth, isBioAuthAvailable } = useBioAuthService();
	const loginMutation = useLoginMutation();
	const { savedLoginCredentials } = useLoginOptions();

	return useMutation({
		mutationFn: async ({ redirectTo }: { redirectTo?: string }) => {
			if (isBioAuthAvailable) {
				const { username, password } = savedLoginCredentials;

				if (!username || !password) {
					throw new Error('No saved login credentials found');
				}

				if (await attemptBioAuth()) {
					return await loginMutation.mutateAsync({
						username,
						password,
						rememberMe: null,
						enableBiometricLogin: null,
						redirectTo,
					});
				} else {
					throw new Error('Bio auth login failed');
				}
			} else {
				throw new Error('Bio auth is not available');
			}
		},
	});
}

const MESSAGING_TOKEN_QUERY_KEY = 'messagingToken';

export function useMessagingTokenQuery() {
	return useQuery({ queryKey: [MESSAGING_TOKEN_QUERY_KEY], queryFn: messaging.getToken });
}

export function useSendVerificationCodeMutation() {
	return useMutation({ mutationFn: sendVerificationCode });
}

export function useVerifyCodeMutation() {
	return useMutation({ mutationFn: verifyCode });
}
