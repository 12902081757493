import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function MyGuideGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(264, 275);
	const rules = useStyleRules();

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={rules.colors.strokeLight}
					d="M121.2 169.316c-1.4 2.9-2.7 5.8-4.1 8.6 1.3.5 2.3.9 3 1.2l-.1.1c2.3.2 4.5.4 6.7.8-2-3.7-3.8-7.2-5.5-10.7z"
				/>
				<Path
					fill={rules.colors.strokeLight}
					d="M167.2 26.716c-2.1.4-4.2.6-6.3.5-4.8-.2-9.6-.7-14.4-1.4-4.8-.5-6.7.5-7.5 2.1.8-1.6 2.8-2.6 7.5-2.1 4.8.7 9.5 1.2 14.4 1.4 2.6 9.1.3 14.2-3.3 21.8.9-.1 1.9 0 2.7.5 1.4.8 2.3 2.2 2.5 3.7.4 3.6-1.4 7.1-4.6 8.8-1.4.7-3.1.8-4.5.1-.1-.1-.2-.1-.3-.2-.7 1.9-2.3 4.2-6 7.8-1.5 1.4-3 2.8-4.5 4.3-.8.8-1.7 1.7-2.4 2.6-.8 1.2-1.8 2.3-3 3-2.5 1.4-5.5 1.1-9.2-1.1-1.9-1.2-3.7-2.5-5.4-4-3.8-3.6-6.6-7.1-8.5-10.4 1.9 3.4 4.7 6.8 8.5 10.5 1.7 1.5 3.5 2.9 5.4 4 3.8 2.2 6.8 2.6 9.2 1.1-2.1 9.3-6.7 18.3-11.7 26.4 5-8.1 9.7-17 11.7-26.4 1.2-.8 2.3-1.8 3-3 .8-.9 1.6-1.7 2.4-2.6 1.1 1.5 2.1 3.1 2.9 4.8 4.1 8.2 4.6 17.3 2.7 26.8-.2 29.9-5.6 36.4 9 71.1l6.1 5.6h.1c6.8-1.7 7.8-1.3 16 6.4v.357c2.8-.9 4.474-.787 5.474.213.9 1.2 1.426 2.63 1.026 4.13-.3 1.5-.9 2.9-1.6 4.2.7-1.3 1.3-2.7 1.6-4.2 19.2 1.9 33.2 3.3 42 4.2h23.1c-1 2.1-2 4.2-3.1 6.2-.3.6-.6 1.2-1 1.8-22.4 40.8-65.7 68.5-115.5 68.5h.1c72.9 0 132-59.1 132-132 0-60.1-40.1-110.7-95-126.7 3.9 4 6.3 9.4-1.6 11.2z"
				/>
				<Path
					fill={rules.colors.strokeLight}
					d="M156.502 50.715c.2-.1.299-.1.499-.2l-.899 1.797c-1.797 3.794-1.799 5.89-2.298 8.187h-.13c.2.1.456.35.655.45 1.098.499 2.49.528 3.488-.071 2.696-1.398 4.075-4.572 3.775-7.568-.1-1.198-.799-2.196-1.797-2.795-.899-.399-1.897-.499-2.795-.1-.198.2-.398.2-.498.3zM0 142.116v.1c0-28.1 8.8-54.1 23.8-75.5.615-.282 1.304-.827 1.704-1.427 7.5-5.7 16.17-9.43 25.67-10.73 16.8-2.2 30.525-3.843 38.225-4.243-.5.7-1 1.4-1.5 2.3-4.1 7.7-5.8 20.4-5.3 38.1.2 5.4.1 6.8.8 6.7-.8.2-.6-1.2-.8-6.7-.5-17.7 1.2-30.4 5.3-38.1.4-.8.9-1.155 1.5-1.955 2.7-3.7 7.048-5.907 11.648-6.107-.1-.3-.348-1.238-.448-1.538-.9-4-1.4-8.2-1.5-12.3-.1-6.6 1.1-12.9 4.5-17.5C44.4 26.116 0 78.916 0 142.116z"
				/>
				<Path
					fill="#5078A2"
					d="M121.2 169.316c1.7 3.5 3.5 7 5.5 10.7 3 .5 6 1.1 8.9 2-1.3 1.2-2.3 2.5-3.2 4.1-.4.7-.7 1.3-1 2.1.3-.7.6-1.2 1-1.9l10.3 2h.1c9-5.1 11.7-6.4 16.6-5 .4.1.7.3 1.1.4v-.1c-.4-.1-.7-.2-1.1-.4l-10.8-9.9c-.6-.5-.6-1.4-.1-2l.4-.5c.5-.6 1.4-.6 2-.1l6.6 6c-14.6-34.7-9.2-41.2-9-71.1-1.9 9.9-6.4 20.4-12 30.8-3.1 5.8-9 19.4-15.3 32.9z"
				/>
				<Path
					fill="#305B85"
					d="M30.5 66.916c11.73 1.784 17.651 16.993 14 43-1.259 8.971-2.3 16.1-2.9 22-.4 3.6-.6 7.2-.5 10.8.3 7 3.131 8.415 9.131 10.715 5.6 2.1 52.769 18.785 66.969 24.385 1.4-2.8 2.7-5.7 4.1-8.6 6.3-13.5 12.2-27 15.3-32.9 5.6-10.4 10.1-20.9 12-30.8 1.8-9.5 1.4-18.6-2.7-26.8-.8-1.7-1.8-3.3-2.9-4.8-.8.8-1.7 1.7-2.4 2.6-.8 1.2-1.8 2.3-3 3-2.1 9.3-6.7 18.3-11.7 26.4-.1.1-.1.2-.2.3-.1.2-.2.4-.3.5-.6 1-.9 1.3-1.1 1.1l-.1-.1c-.3-.6-.3-2.5-1.4-4.6-.4-.7-.8-1.4-1.3-2-3.5-4.1-9.8-4.2-14.7-.1l.8.3c1.3.5 2 1.7 2.1 3 0 .4-.1.9-.2 1.3l-3.4 8.7-8.7 22.2c-.5 1.2-1.6 2-2.9 2h-1c-.2 0-.3-.1-.5-.1l-1.3-.5-1.7-.7c-.4-.2-.8-.4-1.1-.7-.9-.9-1.3-2.3-.8-3.6l12.2-30.9v-.1c.1-.2.2-.3.3-.5l.1-.1c.1-.1.2-.3.3-.4l.1-.1c.1-.1.2-.2.4-.3.1 0 .1-.1.2-.1.1-.1.2-.1.4-.2.1 0 .2-.1.2-.1.1 0 .2-.1.3-.1h1.3c.1 0 .3.1.4.1.1 0 .1 0 .2.1l.4.2c-2.3-3.1-5.8-5.1-9.6-5.8-3.3-.6-6.6 0-9.5 1.6-.4.2-.8.5-1.2.7-.5.3-.8.5-1.1.6-.8.2-.7-1.2-.8-6.7-.5-17.7 1.2-30.4 5.3-38.1.4-.8.9-1.6 1.5-2.4-7.7.4-21.4 1.7-38.3 4-9.5 1.2-18.802 5.017-26.402 10.717-.4.6-.819 1.283-1.219 1.883 2.1-.7 4.628-.349 6.921 0zM49.3 197.516c-21.1-8.2-39.3-19-47.3-32.5 2 11.4 5.4 22.3 10.1 32.5h37.2z"
				/>
				<Path
					fill="#70BFE0"
					d="M247.3 205.616c-1 .9-2.6 2-4.9 3.2-2.3 1.2-4.5 2.3-6.6 3.3-.1.2-.3.4-.5.5l-23.1 12.9c-.7.3-1.6.2-2.1-.4l-12.6 6.4c-3.7 1.9-6.5 1.7-11.7 1.1-20.4-2.1-36.7-3.8-48.8-5.1-1.7-.2-3.3-.3-4.9-.5-14.9-1.6-22.3-2.3-22.3-2.3-1-.1-4.788-.616-4.888-2.416-.1-1-.112-1.584-.212-1.984v-.3a.615.615 0 0 1 .001-.096c.126-1.306 4.505-3.355 10.848-5.404-.7 0-3.449.4-4.149.4 0-.1-.1-1.196-.1-1.296-19.4-3.6-41.077-8.304-61.077-16.104H12.1c20.9 45.2 66.6 76.6 119.6 76.6h.1c49.9-.1 93.2-27.7 115.5-68.5z"
				/>
				<Path
					fill="#70BFE0"
					d="M180.7 202.616c-7.1 6.8-17 9.7-26.6 7.7-2.6-.5-5.2-1.2-7.7-2.2l-16.4.3h-.2.1c4.5-.1 10-.2 16.4-.3 2.5 1 5 1.7 7.7 2.2-6.3 2.4-9.3 3.7-9.3 3.7-2.6.8-4.3 1.5-5.4 2.1 1.1-.6 2.8-1.3 5.4-2.1 0 0 3-1.3 9.3-3.7 9.6 1.9 19.5-.9 26.6-7.7l4.2 3.9c.1.1.3.2.5.3-.2-.1-.3-.2-.5-.3l-4.2-3.9c.9-.8 1.7-1.6 2.4-2.6l40.5 4.1c1.5.1 3 .3 4.4.8l-22.9 12.8c-.5.2-.7.9-.5 1.4.1.1.1.2.2.3-.4-.4-.4-1 0-1.4.1-.1.2-.2.3-.2l22.9-12.8.2-.1c.7-.4 1.6-.2 2.1.4l4.7 4.9.8.8-.8-.8c2.4-1.2 4.8-2.4 7.4-3.8 3.6-1.9 5.7-3.5 5.8-4.7 0 .3.177 1.193.277 2.293 1.183-1.978 2.231-4.151 3.178-6.293H228.3c7.5.8 11.3 1.1 11.3 1.1 5.9.5 8.5 1.4 8.6 2.7v.2c-.1 1.2-2.3 2.8-5.8 4.7-2.6 1.3-5.1 2.6-7.4 3.8l-4.7-4.9c-.6-.6-1.4-.7-2.1-.4l-.2.1c-1.4-.5-2.9-.7-4.4-.8l-40.6-4.2c.6-.8 1.2-1.6 1.6-2.5-.4.9-1 1.7-1.6 2.5-.7.9-1.5 1.8-2.3 2.6z"
				/>
				<Path
					fill="#262626"
					d="m235.8 210.916-.8-.8-4.6-5c-.6-.6-1.4-.7-2.1-.4l-.2.1-23.1 12.9c-.1.1-.2.1-.3.2-.4.4-.4 1 0 1.4l3.8 4.1 1.6 1.7c.6.6 1.4.7 2.1.4l23.3-12.9c.2-.1.5-.5.5-.5.3-.4.2-.9-.2-1.2z"
				/>
				<Path d="M242.3 206.316c-2.6 1.3-5.1 2.6-7.4 3.8l.8.8c.3.3.4.8.3 1.2 2.1-1.1 4.3-2.2 6.6-3.3 2.2-1.2 4-2.327 5-3.227.337-.604.663-1.111.933-1.755-.1-1.1-.27-2.096-.27-2.396-.3 1.3-2.463 2.978-5.963 4.878z" />
				<Path
					fill="#B3B2B3"
					d="m109.566 222.44 76.234 9.667c5.2.6 8.241-.599 11.941-2.499 0 0 3.899-2.092 11.399-5.792l-4.34-4.5c-5.2 2.6-7.9 4-7.9 4-2.4 1.2-4.1 1-7.4.7-.6-.1-1.2-.1-1.7-.2-27.2-2.7-42.5-4.3-46.1-4.8h-.2c-3.2-.5-4.3-1.4-2-2.8 1.1-.6 2.8-1.3 5.4-2.1 0 0 3-1.3 9.3-3.7-2.6-.5-5.2-1.2-7.7-2.2-6.4.1-11.9.2-16.4.3-.1 0-.1.1-.2.1.2 1.3.3 2.6.6 4 .1.4-5.2 1.4-11.4 1.9.2.4.3.8.5 1.3-1.253-.198-2.229-.773-3.482-1-.647-.117-1.571.136-2.218 0-6.5 2.1-9.4 3.8-9.3 5.1-.034.067.091.696.537 1.014.215.154.437.362.739.509.935.455 2.285.8 3.69 1.001z"
				/>
				<Path d="M186.9 206.416c-.3.3-.6.4-1 .4h-.1c.6.4 1.1.8 1.7 1.1l2.5 1.6c.3.2.6.3.9.5.2.1.4.2.5.1 0 0 0-.1.1-.1 0-.1 0-.2-.2-.5l-.6-.9c-.6-.8-1.2-1.6-1.8-2.3-.4-.5-.9-1.1-1.3-1.6 0 .4-.1.8-.4 1.1l-.3.6z" />
				<Path
					fill="#20160B"
					d="M185.9 206.916c.4 0 .8-.2 1-.4l.4-.5c.3-.3.4-.7.4-1.1 0-.4-.2-.7-.5-.9l-4.2-4c-.7.9-1.5 1.8-2.3 2.6l4.2 3.9c.1.1.3.2.5.3.2.1.3.1.5.1z"
				/>
				<Path
					fill="#B3B2B3"
					d="M223.5 204.116c1.5.1 3 .3 4.4.8l.2-.1c.7-.4 1.6-.2 2.1.4l4.7 4.9c2.4-1.2 4.8-2.4 7.4-3.8 3.6-1.9 5.867-3.525 5.966-4.725v-.2c-.099-1.3-2.866-2.175-8.766-2.675 0 0-3.8-.4-11.3-1.1-8.8-.9-22.8-2.3-42-4.2-.3 1.5-.9 2.9-1.6 4.2-.5.9-1 1.7-1.6 2.5l40.5 4z"
				/>
				<Path
					fill="#FAB790"
					d="M174.9 188.716c-2.2-.4-4.3-1.1-6.3-1.9-.3-.1-.6-.2-.8-.3-.3-.1-.5-.2-.8-.3-1.3-.6-2.5-1.1-3.6-1.5-.9-.4-1.8-.7-2.8-1-.4-.1-.7-.3-1.1-.4-4.8-1.4-7.6-.2-16.6 4.9 0 0-.1 0-.1.1l-10.3-2.1c-.4.7-.7 1.3-1 2.1-1.6 3.9-2.3 8.9-2.1 14.9.1 1.8.2 3.6.4 5.6H130.1l16.4-.3c2.5 1 5 1.7 7.7 2.2 9.6 1.9 19.5-.9 26.6-7.7.8-.8 1.6-1.7 2.3-2.6.6-.8 1.2-1.6 1.6-2.5.7-1.3 1.3-2.7 1.6-4.2.4-1.5.1-3.1-.9-4.3-.9-1-2.7-1.3-5.5-.4h-.1c-1.7 0-3.3.1-4.9-.3z"
				/>
				<Path
					fill="#E0906D"
					d="M174.9 188.716c-3.3-2.9-5.2-3.7-8-3.4l1.6 1.5c2.1.8 4.2 1.5 6.4 1.9z"
				/>
				<Path
					fill="#FAB790"
					d="M174.9 188.716v.078c1.6.3 3.614.581 5.214.181-8.2-7.7-9.614-8.459-16.314-6.759h-.209l3.309 3.1c2.8-.3 4.6.6 8 3.4z"
				/>
				<Path
					fill="#20160B"
					d="m148.9 170.816-.4.5c-.5.6-.5 1.5.1 2l10.8 10c.3.1.652.255 1.1.4.1 0 1.9.6 2.9 1 1.1.4 2.2.9 3.6 1.5.3.1.5.2.8.3s.5.2.8.3l-1.6-1.5-3.3-3-6.1-5.6-6.6-6c-.6-.6-1.5-.5-2.1.1z"
				/>
				<Path
					fill="#F8B89B"
					d="M157 50.516c-.2.1-.4.1-.5.2l-.6.3c-.1.1-.3.2-.4.3-.2.1-.3.2-.4.3-.1.1-.3.2-.4.4-.5.5-.9 1.1-1.3 1.8-.7 1.3-1 2.8-.8 4.3 0 .91.517 1.858 1.217 2.458.6-2.2.583-4.458 2.283-8.258l.9-1.8z"
				/>
				<Path
					fill="#F8AD83"
					d="M152.8 52.416c-.2.1-.6.2-.8.4-.1.6-.2 1.2-.3 1.7.1-.4.453-.789.653-1.189.2-.301.247-.611.447-.911z"
				/>
				<Path
					fill="#F8B89B"
					d="M136.9 39.516c-1.8 1.4-4.2 1.8-6.4 3.1l9.1 4.7c.9-.9 1.9-1.6 3-2.1.3-.1.5-.2.8-.3.1 0 .1 0 .2-.1 3-.9 6.3.5 7.7 3.4.1.1.1.2.1.3 0 .1.1.2.1.3.1.2.1.3.1.5 0 .1 0 .2.1.3 0 .2.1.3.1.5.1.5.2 1.1.2 1.6.3-.1.6-.3.9-.4.3-.1.6-.2.9-.2.681-.494 1.3-1.1 2-1.4h.1c.6-.3 1.1-.4 1.7-.5 3.6-7.6 5.9-12.7 3.3-21.8-4.8-.2-9.6-.7-14.4-1.4-4.7-.5-6.7.5-7.5 2.1-1.3 2.7.7 7.1-1 10.2-.2.3-.6.7-1.1 1.2z"
				/>
				<Path
					fill="#70BFE0"
					d="M111.351 214.406c0 .1.049.41.049.51.8 0 1.755-.091 2.455-.091 0-.001-1.704-.319-2.504-.419z"
				/>
				<Path
					fill="#88A9CE"
					d="M130.5 212.616s-.324-2.755-.6-4c0 0-.5-3.9-.5-5.7-.2-6 .55-10.978 2.25-14.878.3-.7.736-1.429 1.136-2.129a14.76 14.76 0 0 1 3.084-3.993c-2.9-.8-6.071-1.601-9.071-2.101-2.2-.4-4.5-.6-6.7-.8-3.659 2.938-5.036 10.011-4.663 17.7.299 6.181 1.801 13.043 3.672 17.945 6.101-.299 11.492-1.644 11.392-2.044z"
				/>
				<Path
					fill="#E97F75"
					d="M129.8 208.616c.1 0 .1 0 .2-.1 0 0-.1 0-.2.1.1-.1 0-.1 0 0 0-.1 0-.1 0 0z"
				/>
				<Path
					fill="#F8B89B"
					d="M151.146 50.41c0-.313-.104-.626-.209-.939 0-.104-.104-.209-.104-.313-.104-.209-.209-.313-.209-.522-.104-.209-.209-.313-.313-.522-.104-.104-.209-.313-.313-.417-.104-.104-.209-.313-.313-.417-.104-.104-.209-.209-.417-.313-.104-.104-.313-.209-.417-.313-.104-.104-.313-.209-.417-.313-.209-.104-.313-.104-.522-.209-.209-.104-.313-.104-.522-.209-.209 0-.313-.104-.522-.104-.209 0-.313-.104-.522-.104H145.198c-.209 0-.417 0-.626.104-.104 0-.209 0-.313.104l-.939.313c-3.756 1.773-5.946 5.738-5.216 9.91 0 .209.104.522.209.73.313.939.835 1.878 1.669 2.504.104.104.313.313.522.417.209.104.417.313.626.417.104.104.209.104.313.104.104 0 .209.104.313.104 1.356.417 2.817.417 4.173-.209 3.338-1.565 5.32-4.799 5.32-8.346.105-.414.001-.935-.103-1.457z"
				/>
				<Path fill="#79A1CA" d="M128.1 78.716c-1.4 2.1-3 4-4.8 5.8 1.9-1.7 3.5-3.7 4.8-5.8z" />
				<Path
					fill="#6E91B8"
					d="m107.512 101.266-.712-.25h-.4l-.275-.2-1.215-.476-.41-.124c-.1 0-.1 0-.2-.1-.1 0-.3-.1-.4-.1h-1.3c-.1 0-.45.15-.5.2-.1.1-.261.05-.4.2-.1 0-.44.25-.6.4l-.1.1c-.1.1-.2.3-.3.4l-.1.1c-.1.2-.2.3-.3.5v.1l-12.3 30.9c-.5 1.3-.1 2.7.8 3.6.3.3.7.5 1.1.7l1.7.7 1.3.5c.2.1 1.3.1 1.5.1h1c1.2-.1 2.4-.8 2.9-2l7.8-22.1 3.253-8.5c.2-.4.347-1.2.347-1.6 0-1.51-.988-2.55-2.188-3.05z"
				/>
				<Path
					fill="#E0906D"
					d="M111.957 50.016c-6.947 19.263-1.263 31.263 6.632 36.526.842.526 1.684 1.053 2.526 1.474 1.158-1.053 2-1.789 2.737-2.526l-.211.211.211-.211.211-.211c1.895-1.895 3.579-3.895 5.053-6.105 0-.105.105-.211.211-.211-2-1.263-3.895-2.632-5.684-4.211-4-3.789-6.947-7.474-8.947-11.053-2.634-4.631-3.476-9.157-2.739-13.683z"
				/>
				<Path
					fill="#79A1CA"
					d="M128.476 78.62c-1.328 2.146-2.963 4.19-4.905 5.927l-.204.204-.204.204c-2.044 2.146-4.394 4.292-6.54 6.233-4.598 4.19-9.19 7.727-10.723 10.077l.607.142c5.007-4.19 11.445-4.087 15.021.102.511.613.92 1.328 1.328 2.044 1.022 2.146 1.124 4.19 1.431 4.701 0 .102.102.102.102.102.204.204.511-.102 1.124-1.124.102-.204.204-.307.307-.511.102-.102.102-.204.204-.307 5.109-8.277 9.912-17.371 11.956-26.977-2.35 1.635-5.416 1.328-9.299-1.022a.22.22 0 0 1-.205.205z"
				/>
				<Path d="M208.803 223.328c-5 2.5-8.903 4.488-11.403 5.788-3.7 1.9-6.5 1.7-11.7 1.1-48.6-5-73.9-7.6-76-7.9-2.8-.4-4.567-1-4.967-1.9-.067-.177-.133-.303-.133-.403v.403c0 .4.1 1 .2 2 .1 1.7 4.1 2.2 5.1 2.4 0 0 7.4.8 22.3 2.3 1.5.2 3.2.3 4.9.5 12.2 1.3 28.4 3 48.8 5.1 5.2.6 8 .8 11.7-1.1l12.6-6.4-1.397-1.888z" />
				<Path
					fill="#503619"
					d="M119 41.716c0 .4-.1.7-.1 1.1 0 .1 0 .2-.1.3 0 .2-.1.5-.1.7l-.1.4c-.1.2-.1.4-.2.6 0 .1-.1.2-.1.4-.1.2-.1.4-.2.6 0 .1-.1.2-.1.3v.1s-1-.1-.9-.1c0 .1.9.2.9.2.6 3.6 1.6 6 2.7 7.1 1.6 1.5 4.6.2 5.8-4.7.4-2 1.532-3.756 2.932-5.156L119 38.816c0 .9.1 1.9 0 2.9z"
				/>
				<Path fill="#FAB790" d="M118.9 46.316c0-.1.1-.2.1-.2s0 .1-.1.1v.1z" />
				<Path
					fill="#5977B9"
					d="M153.9 51.016c-.3 0-.6.1-.9.2-.3.1-.6.2-.9.4 0-.5-.1-1.1-.2-1.6 0-.2-.1-.3-.1-.5 0-.1 0-.2-.1-.3 0-.2-.1-.3-.1-.5 0-.1-.1-.2-.1-.3s-.1-.2-.1-.3c-1.4-2.9-4.7-4.3-7.7-3.4-.1 0-.1 0-.2.1-.3.1-.5.2-.8.3-1.1.5-2.2 1.2-3 2.1l-9.1-4.7-11.525-5.8c.2.6.275.987.375 1.587l.05.838 9.512 4.29v.184l9.588 4.701c-.3.4-.5.8-.7 1.2-.8 1.5-1.2 3.1-1.2 4.8 0 .6.1 1.1.2 1.7.1.6.3 1.2.6 1.8 1.6 3.3 5.4 4.6 8.7 3.1 2.1-.9 3.7-2.5 4.7-4.5.3-.5.5-1.1.7-1.7-.4 1.2-.5 2.4-.3 3.6.2 1.4 1 2.7 2.2 3.5.1.1.2.1.3.2 1.4.7 3.1.7 4.5-.1 3.2-1.7 5.1-5.2 4.6-8.8-.2-1.6-1.1-3-2.5-3.7-.8-.4-1.8-.6-2.7-.5-.6.2-1.2.4-1.8.7-.8.4-1.4.9-2 1.4zm-3.1.7c0 3.4-2 6.5-5.1 8-.5.2-1 .4-1.5.5-.9.1-1.7 0-2.5-.3-.1 0-.2-.1-.3-.1-.1 0-.2-.1-.3-.1-.2-.1-.4-.2-.6-.4-.2-.1-.3-.2-.5-.4-.7-.6-1.3-1.5-1.6-2.4-.1-.2-.1-.4-.2-.7-.7-3.9 1.4-7.8 5-9.5l.9-.3c.1 0 .2 0 .3-.1.1 0 .2-.1.4-.1h1.3c.2 0 .3 0 .5.1.2 0 .3.1.5.1s.3.1.5.2.3.1.5.2.3.2.4.3c.1.1.3.2.4.3.1.1.3.2.4.3.1.1.2.2.3.4.1.1.2.3.3.4.1.2.2.3.3.5.1.2.2.3.2.5 0 .1.1.2.1.3.1.3.2.6.2.9 0 .4.1.9.1 1.4zm.9 2.8c.2-.6.3-1.1.4-1.7.2-.1.5-.3.7-.4-.2.3-.3.5-.5.8-.3.4-.5.8-.6 1.3zm8.1-3.9c1 .6 1.7 1.6 1.8 2.8.3 3-1.2 5.9-3.9 7.4-1 .6-2.3.6-3.3.1-.2-.1-.3-.2-.5-.3-.7-.6-1.2-1.5-1.3-2.4-.2-1.5.1-3 .8-4.3.3-.6.8-1.2 1.3-1.8.1-.1.2-.3.4-.4.1-.1.3-.2.4-.3s.3-.2.4-.3l.6-.3c.2-.1.3-.1.5-.2.9-.4 1.9-.4 2.8 0z"
				/>
				<Path
					fill="#F8B89B"
					d="M98.1 53.616c-3.2 13 9.4 38.3 7.9 47.2l.3.1c1.956-1.992 5.823-5.727 10.323-9.727 2.2-1.9 2.318-1.854 4.318-3.954.1-.1 2.059-2.419 2.159-2.519-.7.7-1.159 1.519-2.159 2.519-.8-.4-2.041-.919-2.841-1.519-7.5-5-12.9-16.5-6.3-34.7-.7 4.4.1 8.6 2.5 13 1.9 3.4 4.7 6.8 8.5 10.4 1.7 1.5 3.776 3.104 5.676 4.204 3.8 2.2 6.624 2.596 9.024 1.096 1.2-.8 2.3-1.9 3-3.1.8-.9 1.6-1.6 2.4-2.5 1.5-1.5 2.9-3.1 4.4-4.5 3.7-3.6 5.5-5.8 6.2-7.8-1.2-.8-2.007-2.251-2.207-3.651-.2-1.2-.093-2.249.307-3.449-.2.6-.6 1.2-.9 1.7-1 2-2.7 3.6-4.7 4.5-3.297 1.45-6.773.183-8.5-3.1-.3-.6-.5-1.2-.6-1.8-.1-.5-.2-1.1-.2-1.7 0-1.7.345-3.193 1.145-4.693.2-.4.555-.907.755-1.307l-9.6-4.9c-1.4 1.4-2.4 3.2-2.8 5.1-1.3 4.9-3.2 6.3-4.8 4.7-1.1-1.1-2.1-3.5-2.7-7.1l-.1.1v-.1l.1-.3c.1-.1.1-.2.1-.3.1-.2.1-.4.2-.6 0-.1.1-.2.1-.4.1-.2.1-.4.2-.6l.1-.4c0-.2.1-.5.1-.7 0-.1 0-.2.1-.3.1-.3.1-.7.1-1.1.1-1 0-2-.2-3-.1-.7-.3-1.3-.5-1.9-.6-2-1.9-3.6-3.7-4.7-.2-.1-.4-.2-.7-.3-.5-.2-.9-.3-1.4-.3H112.3c-.1 0-.3 0-.4.1-.1 0-.2 0-.3.1-.1 0-.3.1-.4.1-.1 0-.2.1-.3.1s-.1 0-.2.1c-2.8 1.2-4.9 4.6-5.2 8.8-.1 1.3 0 2.7.4 4-.4.5-.8.9-1.2 1.2-.7.6-1.3.8-1.8.8-2.5 1.7-4.1 4.1-4.8 7.1z"
				/>
				<Path
					fill="#79A1CA"
					d="M85.8 96.216c2.9-1.6 6.2-2.1 9.5-1.6 3.8.6 6.7 2.9 9.5 5.7l1.2.5c1.5-8.9-11.1-34.2-7.9-47.2.7-3 2.3-5.3 5.1-6.7l.296-.1c-1 0-1.987-.9-2.487-2.7-4.6.2-8.909 2.5-11.609 6.2-.5.7-1 1.5-1.5 2.4-4.1 7.7-5.8 20.4-5.3 38.1.2 5.4.1 6.8.8 6.7.3-.1.628-.043 1.128-.343.4-.401.872-.757 1.272-.957z"
				/>
				<Path
					fill="#5078A2"
					d="M49.3 197.516c20 7.8 42.6 13.3 62.1 16.9.8.1 1.6.3 2.4.4 1.9.3 4 .7 5.8 1-.2-.4-.36-1.1-.56-1.6-4.2-11.1-5.64-29.8.96-35.1l.1-.1c-.7-.3-1.6-.7-2.9-1.2-14.2-5.6-61.4-22.5-66.9-24.6-6-2.3-8.9-3.6-9.2-10.5-.1-3.6.1-7.2.5-10.8.6-5.9 1.6-13.2 2.9-22 3.8-26-2.2-41.4-14.1-43-2.3-.3-4.5-.8-6.7-.2-14.9 21.4-23.7 47.4-23.7 75.5v.1c0 7.7.7 15.3 2 22.7 8 13.5 26.2 24.3 47.3 32.5z"
				/>
				<Path
					fill="#503619"
					d="M99.1 30.716c.1 4.1.6 8.3 1.5 12.3.1.4.2.7.3 1 .5 1.8 1.3 2.8 2.3 2.8.6 0 1.2-.2 1.8-.8.4-.3.8-.7 1.2-1.2-.3-1.3-.5-2.7-.4-4 .3-4.2 2.4-7.6 5.2-8.8.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1s.3-.1.4-.1.2 0 .3-.1c.1 0 .3 0 .4-.1H113.5c.5 0 1 .1 1.4.3.2.1.4.2.7.3 1.8 1 2.875 2.6 3.475 4.6l11.581 5.83c2.223-1.23 4.344-1.53 6.244-3.03.5-.4.9-.9 1.2-1.4 1.7-3-.2-7.5 1-10.2.8-1.6 2.8-2.6 7.5-2.1 4.8.7 9.5 1.2 14.4 1.4 2.1.1 4.2-.1 6.3-.5 7.9-1.8 5.5-7.2 1.8-11.3-1.1-1.2-2.2-2.3-3.3-3.1-11.8-9.7-36.3-18.7-48.7-6.2-6.4.6-10.7 3.2-13.5 7-3.4 4.7-4.6 11-4.5 17.6z"
				/>
			</Svg>
		</View>
	);
}
