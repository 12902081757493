import IconButton from '@mobe/components/iconButton/IconButton';
import { Text } from '@mobe/components/text';
import { useHasDismissedTrackerDataLagMessage } from '@mobe/utils/storage/appStateStorage';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

export default function ConnectedSourceLagNotice() {
	const [, setHasDismissedTrackerDataLagMessage] = useHasDismissedTrackerDataLagMessage();
	const { t } = useTranslation();
	const styles = useStyles();

	return (
		<View style={styles.box}>
			<View style={styles.body}>
				<Text size="sm">{t('track.detail.connectSourceDataLagAlert')}</Text>
			</View>
			<View style={styles.closeButton}>
				<IconButton
					name="close"
					color="primary"
					onPress={() => setHasDismissedTrackerDataLagMessage(true)}
					size={10}
					hitSlop={20}
				/>
			</View>
		</View>
	);
}

function useStyles() {
	const rules = useStyleRules();

	return StyleSheet.create({
		box: {
			width: '100%',
			backgroundColor: rules.colors.backgroundPrimary,
			paddingHorizontal: 16,
			paddingVertical: 12,
			flexDirection: 'row',
			alignItems: 'center',
			borderRadius: rules.borderRadius,
		},
		body: {
			flexGrow: 1,
			flexShrink: 1,
			marginRight: 12,
		},
		closeButton: {
			padding: 5,
			alignSelf: 'flex-start',
			alignItems: 'center',
			justifyContent: 'center',
		},
	});
}
