import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { StyleSheet } from 'react-native';

export function useDataCardStyles() {
	const rules = useStyleRules();

	return StyleSheet.create({
		wrapper: {
			borderRadius: 5,
			backgroundColor: rules.colors.cardBackground,
			flexGrow: 1,
			flexShrink: 1,
		},
		card: {
			flexDirection: 'row',
			alignItems: 'stretch',
		},
		card_restrict: {
			// maxHeight: 90,
		},
		card_block: {
			maxHeight: 'auto',
		},
		primaryContainer: {
			flexGrow: 1,
			flexShrink: 1,
			zIndex: 1,
		},
		bodyContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			paddingHorizontal: 0,
			paddingVertical: 20,
			flexGrow: 1,
		},
		bodyContainer_block: {
			paddingLeft: 15,
			flexDirection: 'column',
			alignItems: 'flex-start',
			// maxWidth: 130,
		},
		detailContainer: {
			flexShrink: 1,
		},
		secondaryContainerImage: {
			width: '30%',
			flexShrink: 0,
			flexGrow: 0,
			alignItems: 'center',
			justifyContent: 'flex-end',
		},
		secondaryContainerImage_block: {
			justifyContent: 'center',
			position: 'relative',
			right: 10, // Side overlap is intentional
		},
		secondaryContainerCta: {
			paddingRight: 20,
			paddingLeft: 5,
			flexShrink: 0,
			flexGrow: 0,
			alignItems: 'center',
			justifyContent: 'center',
		},
		countContainer: {
			minWidth: 70,
			flexGrow: 0,
		},
		countContainer_block: {
			alignItems: 'flex-start',
		},
		count: {
			color: rules.colors.success,
			fontFamily: rules.fontFamilies.sansBold,
			fontSize: Math.min(rules.fontSizes.xxxl, 36),
			lineHeight: Math.min(rules.fontSizes.xxxl, 36),
			textAlign: 'center',
		},
		count_null: {
			color: rules.colors.stroke,
		},
		noteContainer: { paddingHorizontal: 20, paddingBottom: 20, marginTop: -10 },
		footer: { paddingHorizontal: 20, paddingBottom: 20, paddingTop: 0 },
	});
}
