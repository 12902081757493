import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import { getIncentive } from './incentiveService';

const INCENTIVE_QUERY_KEY = 'incentive';

export function useIncentiveQuery() {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [INCENTIVE_QUERY_KEY],
		queryFn: getIncentive,
		enabled: isAuthenticated,
		staleTime: Infinity,
		select: (data) => (isEmpty(data) ? undefined : data),
	});
}
