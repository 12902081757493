import { useGuideQuery, usePharmacistQuery } from '@mobe/api/guides/guidesApiHooks';
import Button from '@mobe/components/button/Button';
import Heading from '@mobe/components/heading/Heading';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { CommonActions, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import * as ExploreAnalyticsEvents from '../analyticsEvents';

interface IGuideConnectCTAProps {
	showGuide?: boolean;
	showPharmacist?: boolean;
}

export default function GuideConnectCTA({
	showGuide = true,
	showPharmacist = true,
}: IGuideConnectCTAProps) {
	const { t } = useTranslation();
	const safeInsets = useSafeAreaInsets();
	const styleRules = useStyleRules();
	const { vr, constrainText, wrapperHorizontal } = useStyleHelpers();
	const navigation = useNavigation();
	const guideQuery = useGuideQuery();
	const pharmacistQuery = usePharmacistQuery();
	const hasGuide = guideQuery.isSuccess && Boolean(guideQuery.data);
	const hasPharmacist = pharmacistQuery.isSuccess && Boolean(pharmacistQuery.data);

	function handlePharmacistConnectPress() {
		ExploreAnalyticsEvents.pharmacistConnectPress();
		navigation.dispatch(CommonActions.navigate('MY_PHARMACIST_SCREEN'));
	}

	function handleGuideConnectPress() {
		ExploreAnalyticsEvents.guideConnectPress();
		navigation.dispatch(CommonActions.navigate('MY_GUIDE_SCREEN'));
	}

	if (!hasGuide && !hasPharmacist) {
		return null;
	}

	if (!showGuide && !showPharmacist) {
		return null;
	}

	return (
		<View
			style={[
				wrapperHorizontal,
				{
					paddingBottom: Math.max(safeInsets.bottom, 24),
					paddingTop: 24,
					marginTop: 'auto',
					borderTopColor: styleRules.colors.stroke,
					borderTopWidth: 1,
					backgroundColor: styleRules.colors.background,
				},
			]}
		>
			<Heading align="center" level="h3" style={vr(2)}>
				{t('explore.connectWithGuideCTA.title')}
			</Heading>
			<View style={constrainText(250)}>
				<Text align="center" weight="medium" style={vr(8)}>
					{t('explore.connectWithGuideCTA.body')}
				</Text>
			</View>
			{showGuide && (
				<Button
					title={
						hasGuide
							? t('explore.connectWithGuideCTA.connectedToGuideButton')
							: t('explore.connectWithGuideCTA.notConnectedToGuideButton')
					}
					onPress={handleGuideConnectPress}
					style={[vr(5), constrainText(300)]}
				/>
			)}
			{showPharmacist && (
				<Button
					title={
						hasPharmacist
							? t('explore.connectWithGuideCTA.connectedToPharmacistButton')
							: t('explore.connectWithGuideCTA.notConnectedToPharmacistButton')
					}
					onPress={handlePharmacistConnectPress}
					style={constrainText(300)}
				/>
			)}
		</View>
	);
}
