import { SurveyQuestion } from '../../components/survey/Survey';
import { client } from '../client';

interface SurveyData {
	token: string;
	questions: SurveyQuestion[];
}

export async function getSurvey(token: string) {
	const response = await client.get(`surveys/${token}/survey-questions`);
	return response.data as SurveyData;
}

interface ISurveyResponseWithId {
	questionId: number;
	answerId: number;
}

interface ISurveyResponseWithValue {
	questionId: number;
	answerValue: string;
}

export type SurveyResponses = (ISurveyResponseWithId | ISurveyResponseWithValue)[];

interface ISurveySubmission {
	token: string;
	responses: SurveyResponses;
}

export async function submitSurvey({ token, responses }: ISurveySubmission) {
	await client.post(`surveys/${token}/answers`, responses);
}

export async function assignSurvey(surveyId: string) {
	const response = await client.post(`surveys/${surveyId}/assign`);
	return response.data as SurveyData;
}
