import useHeadingStyles from '@mobe/components/heading/headingStyles';
import { IconNames } from '@mobe/components/icon/Icon';
import IconButton from '@mobe/components/iconButton/IconButton';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, DimensionValue, Platform, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useTransitions from '../../useTransitions';
import useStyleHelpers from '../helpers/styleHelpers';
import { useStyleRules } from '../styleRules/useStyleRules';
import useLayout from '../useLayout';
import useNavigationTitleMaxWidth from './useNavigationTitleMaxWidth';

export default function useModalStyleProps(backIcon: IconNames = 'close'): StackNavigationOptions {
	const { top: topInset } = useSafeAreaInsets();
	const { headerShadow } = useStyleHelpers();
	const layout = useLayout();
	const rules = useStyleRules();
	const headerTitleStyle = useHeadingStyles({ level: 'h3' });
	const headerTitleMaxWidth = useNavigationTitleMaxWidth();
	const transitions = useTransitions();
	const { t } = useTranslation();
	const webModalMaxHeight = 900;
	const webModalVerticalMargin = 30;

	const modalStyleProps: StackNavigationOptions = {
		...transitions.ModalTransition,
		presentation: 'transparentModal',
		headerTitleAlign: 'center',
		headerLeft: (props) => (
			<IconButton
				aria-label={
					backIcon === 'close'
						? t('accessibility.closeButtonLabel')
						: t('accessibility.backButtonLabel')
				}
				name={backIcon}
				onPress={props.onPress}
			/>
		),
		headerTitleStyle: [headerTitleStyle, { textAlign: 'center' }],
		headerTitleAllowFontScaling: false,
		headerTitleContainerStyle: {
			maxWidth: headerTitleMaxWidth,
		},
		headerBackTitleVisible: false,
		headerStatusBarHeight: 0,
		headerLeftContainerStyle: {
			paddingLeft: rules.spacing.appHorizontalMargin,
		},
		headerRightContainerStyle: {
			paddingRight: rules.spacing.appHorizontalMargin,
		},
		headerStyle: {
			height: 60,
			backgroundColor: rules.colors.cardBackground,
			...(Platform.OS !== 'web' && {
				borderTopRightRadius: rules.borderRadiusModal,
				borderTopLeftRadius: rules.borderRadiusModal,
				borderBottomColor: rules.colors.strokeLight,
				borderBottomWidth: StyleSheet.hairlineWidth,
				...headerShadow,
			}),
		},
		cardStyle: {
			backgroundColor: 'transparent',
			paddingTop: topInset,
		},
		cardOverlayEnabled: true,
		cardOverlay: (props) => (
			<Animated.View
				// eslint-disable-next-line react/prop-types
				style={[StyleSheet.absoluteFill, { backgroundColor: '#00000088' }, props.style]}
			/>
		),
		gestureEnabled: false,

		...(Platform.OS === 'web' && {
			cardStyle: {
				overflow: 'hidden',
				height: '100%',
				minHeight: 'auto',
				width: '100%',
				alignSelf: 'center',
				borderRadius: layout.isWebDesktop ? rules.borderRadius : 0,
				maxWidth: layout.isWebDesktop ? rules.spacing.modalMaxWidth : undefined,
				maxHeight: layout.isWebDesktop
					? (`min(${webModalMaxHeight}px, calc(100% - ${
							webModalVerticalMargin * 2
					  }px))` as DimensionValue)
					: undefined,
				marginVertical: 'auto',
			},
		}),
	};

	return modalStyleProps;
}
