import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
	INotificationPreferencesResponse,
	getNotificationPreferences,
	updateNotificationPreferences,
} from './notificationsService';

const NOTIFICATIONS_PREFERENCES_QUERY_KEY = 'notifications.getNotificationPreferences';

export function useNotificationsPreferencesQuery() {
	return useQuery({
		queryKey: [NOTIFICATIONS_PREFERENCES_QUERY_KEY],
		queryFn: getNotificationPreferences,
	});
}

export function useUpdateNotificationsPreferenceMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: updateNotificationPreferences,
		onMutate: async (preference) => {
			await queryClient.cancelQueries({ queryKey: [NOTIFICATIONS_PREFERENCES_QUERY_KEY] });

			const previousPreferences = queryClient.getQueryData<INotificationPreferencesResponse>([
				NOTIFICATIONS_PREFERENCES_QUERY_KEY,
			]);

			if (previousPreferences) {
				queryClient.setQueryData<INotificationPreferencesResponse>(
					[NOTIFICATIONS_PREFERENCES_QUERY_KEY],
					{
						...previousPreferences,
						...preference,
					}
				);
			}

			return { previousPreferences };
		},
		onError: (error, variable, context) => {
			if (context?.previousPreferences) {
				queryClient.setQueryData(
					[NOTIFICATIONS_PREFERENCES_QUERY_KEY],
					context.previousPreferences
				);
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [NOTIFICATIONS_PREFERENCES_QUERY_KEY] });
		},
	});
}
