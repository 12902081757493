import { CoachType } from '@mobe/api/guides/guidesApi';
import { useGuideQuery, usePharmacistQuery } from '@mobe/api/guides/guidesApiHooks';
import IconButton from '@mobe/components/iconButton/IconButton';
import useModalStyleProps from '@mobe/utils/styles/navigation/useModalStyleProps';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import {
	StackNavigationOptions,
	StackNavigationProp,
	TransitionPresets,
	createStackNavigator,
} from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ApptStackScreenNavigationProp, ApptStackScreenRouteProp } from '../navigation/modal/types';
import ApptConfirmationScreen, {
	IApptConfirmationScreenParams,
} from './screens/AppointmentConfirmationScreen';
import GuideIntroScreen from './screens/GuideIntroScreen';
import PharmacistIntroScreen from './screens/PharmacistIntroScreen';
import FollowUpStackScreen, {
	IFollowUpStackScreenParams,
} from './screens/followUp/FollowUpStackScreen';
import GuideMatchFormScreen, {
	IGuideMatchScreenParams,
} from './screens/guideMatch/GuideMatchStackScreen';

export interface IApptStackScreenParams {
	coachType: CoachType;
	allowCoachToggle?: boolean;
}

export type ApptStackParamList = {
	APPT_PHARMACIST_INTRO_SCREEN: undefined;
	APPT_GUIDE_MATCH_INTRO_SCREEN: undefined;
	APPT_GUIDE_MATCH_SCREEN: IGuideMatchScreenParams;
	APPT_FOLLOW_UP_SCREEN: IFollowUpStackScreenParams;
	APPT_CONFIRMATION_SCREEN: IApptConfirmationScreenParams;
};

type ApptStackStackRouteProps<T extends keyof ApptStackParamList> = RouteProp<
	ApptStackParamList,
	T
>;
type ApptStackStackNavigationProps<T extends keyof ApptStackParamList> = CompositeNavigationProp<
	StackNavigationProp<ApptStackParamList, T>,
	ApptStackScreenNavigationProp
>;

export type ApptFollowUpStackScreenNavigationProp =
	ApptStackStackNavigationProps<'APPT_FOLLOW_UP_SCREEN'>;
export type ApptFollowUpStackScreenRouteProp = ApptStackStackRouteProps<'APPT_FOLLOW_UP_SCREEN'>;

export type PharmacistIntroScreenNavigationProp =
	ApptStackStackNavigationProps<'APPT_PHARMACIST_INTRO_SCREEN'>;

export type GuideMatchIntroScreenNavigationProp =
	ApptStackStackNavigationProps<'APPT_GUIDE_MATCH_INTRO_SCREEN'>;

export type GuideMatchFormScreenNavigationProp =
	ApptStackStackNavigationProps<'APPT_GUIDE_MATCH_SCREEN'>;
export type GuideMatchFormScreenRouteProp = ApptStackStackRouteProps<'APPT_GUIDE_MATCH_SCREEN'>;

export type ApptConfirmationScreenNavigationProp =
	ApptStackStackNavigationProps<'APPT_CONFIRMATION_SCREEN'>;
export type ApptConfirmationScreenRouteProp = ApptStackStackRouteProps<'APPT_CONFIRMATION_SCREEN'>;

interface IApptStackScreenProps {
	navigation: ApptStackScreenNavigationProp;
	route: ApptStackScreenRouteProp;
}

const ApptStack = createStackNavigator<ApptStackParamList>();

export default function ApptStackScreen({ route, navigation }: IApptStackScreenProps) {
	const guide = useGuideQuery().data;
	const pharmacist = usePharmacistQuery().data;
	const { t } = useTranslation();
	const modalStyleProps = useModalStyleProps();

	const isPharmacistAppt = route.params.coachType === CoachType.Pharmacist;
	const isGuideAppt = !isPharmacistAppt;
	const isInitialAppt = React.useMemo(
		() => (isPharmacistAppt && !pharmacist) || (isGuideAppt && !guide),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	let initialRouteName: keyof ApptStackParamList = 'APPT_FOLLOW_UP_SCREEN';
	let title = t('appointments.followUp.screenTitle');

	if (isInitialAppt && isGuideAppt) {
		initialRouteName = 'APPT_GUIDE_MATCH_INTRO_SCREEN';
		title = t('appointments.guideMatch.screenTitle');
	}

	if (isInitialAppt && isPharmacistAppt) {
		initialRouteName = 'APPT_PHARMACIST_INTRO_SCREEN';
		title = t('appointments.pharmacistMatch.screenTitle');
	}

	return (
		<ApptStack.Navigator
			initialRouteName={initialRouteName}
			screenOptions={{
				title,
				...modalStyleProps,
				...TransitionPresets.ModalFadeTransition,
				cardStyle: {},
				headerLeft: function HeaderBackIcon() {
					return <IconButton name="close" onPress={() => navigation.pop()} />;
				},
			}}
		>
			<ApptStack.Screen name="APPT_PHARMACIST_INTRO_SCREEN" component={PharmacistIntroScreen} />
			<ApptStack.Screen name="APPT_GUIDE_MATCH_INTRO_SCREEN" component={GuideIntroScreen} />
			<ApptStack.Screen
				name="APPT_GUIDE_MATCH_SCREEN"
				component={GuideMatchFormScreen}
				initialParams={{ shouldShowLanguage: false, shouldShowGender: false }}
			/>
			<ApptStack.Screen
				name="APPT_FOLLOW_UP_SCREEN"
				component={FollowUpStackScreen}
				initialParams={{
					coachType: route.params.coachType,
					allowCoachToggle: route.params.allowCoachToggle,
				}}
			/>
			<ApptStack.Screen
				name="APPT_CONFIRMATION_SCREEN"
				component={ApptConfirmationScreen}
				initialParams={{ isInitialAppt }}
			/>
		</ApptStack.Navigator>
	);
}

export function useApptStackScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();
	const modalStyleProps = useModalStyleProps();
	const { top: topInset } = useSafeAreaInsets();
	const styleRules = useStyleRules();

	return {
		title: t('appointments.guideMatch.screenTitle'),
		...modalStyleProps,
		headerShown: false,

		// Card must be rounded on the top since the standard header is hidden.
		// This behavior is required due to nested nav and child screens controlling headers.
		...(Platform.OS !== 'web' && {
			cardStyle: {
				marginTop: topInset,
				borderTopLeftRadius: styleRules.borderRadiusModal,
				borderTopRightRadius: styleRules.borderRadiusModal,
			},
		}),
	};
}
