import { client } from '../client';

export interface IAssessment {
	physicalActivity?: number;
	nutrition?: number;
	sleep?: number;
	stress?: number;
	medicationSatisfaction?: number;
	additionalNotes?: string;
}

export async function getAssessment() {
	const response = await client.get('assessments');
	return response.data as IAssessment;
}

export type IPatchAssessment = Partial<IAssessment>;

export async function patchAssessment(params: IPatchAssessment) {
	await client.patch('assessments', params);
	return {};
}
