import { isEmpty } from 'lodash';
import { client } from '../client';

export interface IIncentiveStep {
	dateCompleted: string | null; // date string
	label: string | null;
}

export interface IIncentive {
	dateCompleted: string | null; // date string
	title: string;
	description: string;
	url: string;
	steps: IIncentiveStep[];
}

export async function getIncentive() {
	const response = await client.get(`incentive-program`);

	// Server returns empty body in the scenario that PPT isn't enrolled in incentive program
	if (isEmpty(response.data) || typeof response.data === 'string') {
		return null;
	}

	return response.data as IIncentive;
}
