import { yupResolver } from '@hookform/resolvers/yup';
import { useProfileQuery, useUpdateProfileMutation } from '@mobe/api/account/accountApiHooks';
import { InputText, useControlledInputProps } from '@mobe/components/input';
import InputDualUnitNumeric from '@mobe/components/input/InputDualUnitNumeric';
import { Text } from '@mobe/components/text';
import TextButton from '@mobe/components/textButton/TextButton';
import { emptyStringToNull } from '@mobe/utils/i18n';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import * as Yup from 'yup';
import { IAuxiliaryForm } from './useTrackerContent';

interface IUseHeightFormProps {
	customInputLabel?: string;
}

export default function useHeightForm({
	customInputLabel,
}: IUseHeightFormProps = {}): IAuxiliaryForm {
	const profileQuery = useProfileQuery();
	const updateProfileMutation = useUpdateProfileMutation();
	const { t } = useTranslation();
	const { vr, constrainText } = useStyleHelpers();

	const height = profileQuery.data?.height || 0;
	const [canEdit, setCanEdit] = React.useState(false);

	const validationSchema = Yup.object({
		height: Yup.number()
			.label(t('profile.updateHeight.inputLabel'))
			.nullable()
			.transform(emptyStringToNull)
			.typeError(t('forms.errors.invalidType')),
	});

	const form = useForm<Yup.InferType<typeof validationSchema>>({
		mode: 'all',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			height: height > 0 ? height : null,
		},
	});

	const trackerInputProps = useControlledInputProps({
		name: 'height',
		control: form.control,
	});

	function handleSubmit() {
		if (!form.formState.isDirty) {
			return;
		}

		form.handleSubmit((data) => {
			if (data.height) {
				updateProfileMutation.mutate({ height: data.height });
			}
		})();
	}

	return {
		renderForm: (screen) => {
			if (screen !== 'addEntry') {
				return;
			}

			return (
				<View>
					{customInputLabel && (
						<Text weight="medium" size="xl" align="center" style={[constrainText(300), vr(7)]}>
							{customInputLabel}
						</Text>
					)}

					{height && !canEdit ? (
						<InputText
							label={t('profile.updateHeight.inputLabel')}
							value={`${Math.floor(height / 12)}${t('profile.updateHeight.feet')} ${height % 12}${t(
								'profile.updateHeight.inches'
							)}`}
							disabled
							collapseEmptyError
							inputRight={() => (
								<TextButton
									title={t('profile.updateHeight.update')}
									onPress={() => setCanEdit(true)}
								/>
							)}
						/>
					) : (
						<InputDualUnitNumeric
							{...trackerInputProps}
							primaryUnit={t('profile.updateHeight.feet')}
							secondaryUnit={t('profile.updateHeight.inches')}
							label={t('profile.updateHeight.inputLabel')}
							errorMessage={form.formState.errors.height?.message}
							conversionFactor={12}
							convertValueToSecondaryUnit
						/>
					)}
				</View>
			);
		},
		isDirty: form.formState.isDirty,
		isValid: form.formState.isValid,
		handleSubmit,
	};
}
