import { useStyleRules } from './styleRules/useStyleRules';

export default function useHTMLStyles() {
	const rules = useStyleRules();
	const fontScale = rules.settings.fontScale;

	return `
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
:root {
	--color-background: ${rules.colors.cardBackground};

	--color-text: ${rules.colors.text};
	--color-text-light: ${rules.colors.textLight};
	--color-primary: ${rules.colors.primary};

	--font-size-sm: ${rules.fontSizes.sm}px;
	--font-size-md: ${rules.fontSizes.md}px;
	--font-size-lg: ${rules.fontSizes.lg}px;
	--font-size-xl: ${rules.fontSizes.xl}px;
}
html, body {
	padding: 0;
	margin: 0;
	color: var(--color-text);
	background-color: var(--color-background)
}
body {
	font-family: 'Inter', sans-serif;
	font-size: var(--font-size-lg);
	line-height: 1.5;
}
a {
	overflow-wrap: break-word;
	color: var(--color-primary);
	text-decoration: underline;
}
img {
	max-width: 100%;
}
p {
	margin: 0 0 30px 0;
	padding: 0;
}
h1, h2, h3, h4, h5, h6 {
	margin: 0 0 20px 0;
	padding: 0;
	font-weight: 700;
	font-size: var(--font-size-xl);
}
h6 {
	margin-bottom: 10px;
	font-weight: normal;
	font-size: var(--font-size-md);
	color: var(--color-text-light);
}
footer {
	font-size: var(--font-size-sm);
	color: var(--color-text-light);
}
ol {
	counter-reset: ol-counter;
	list-style: none;
	margin: 0;
	padding: 0;
}
ol li {
	counter-increment: ol-counter;
	position: relative;
	padding-left: calc(2 * ${fontScale}rem);
}
ol li::before {
	content: counter(ol-counter) ". ";
	position: absolute;
	top: 0;
	left: 0;
	margin-right: 10px;
}
li + li {
	margin-top: 20px;
}
ol,
ul {
	margin-bottom: 30px;
}
`;
}
