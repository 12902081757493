import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { TextStyle } from 'react-native';

export type HeadingLevels = 'h1' | 'h2' | 'h3' | 'h4';
export type HeadingColors = 'regular' | 'inverted' | 'staticLight' | 'staticDark';

export default function useHeadingStyles({
	level,
	color = 'regular',
}: {
	level: HeadingLevels;
	color?: HeadingColors;
}) {
	const rules = useStyleRules();

	const headingStyles: TextStyle = {
		fontFamily: rules.fontFamilies.serif,
	};

	const headingLevels: { [key in HeadingLevels]?: TextStyle } = {
		h1: {
			fontSize: rules.fontSizes.xxxl,
			lineHeight: rules.fontSizes.xxxl * 1.05,
		},
		h2: {
			fontSize: rules.fontSizes.xxl,
			lineHeight: rules.fontSizes.xxl * 1.15,
		},
		h3: {
			fontSize: rules.fontSizes.xl,
			lineHeight: rules.fontSizes.xl * 1.2,
		},
		h4: {
			fontSize: rules.fontSizes.md,
			lineHeight: rules.fontSizes.md * 1.25,
		},
	};

	const headingColors: { [key in HeadingColors]?: TextStyle } = {
		regular: { color: rules.colors.text },
		inverted: { color: rules.colors.textInverted },
		staticDark: { color: rules.colors.staticDark },
		staticLight: { color: rules.colors.staticLight },
	};

	return { ...headingStyles, ...headingLevels[level], ...headingColors[color] };
}
