import { client } from '../client';

interface IVersionResponse {
	build: string;
	date: string;
}

export async function fetchAPIVersion() {
	const response = await client.get('version');
	return response.data as IVersionResponse;
}
