import { useChatHubConnection } from '@mobe/features/chat/chatHubConnectionContext';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import { getUnreadMessageInformation, loadMessages, sendAttachmentAndText } from './chatService';
import { ChatQueryKeys } from './types';

export function useChatsQuery(paginationStartDate: string, channelId: number) {
	return useInfiniteQuery({
		queryKey: [ChatQueryKeys.Chats, paginationStartDate, channelId],
		initialPageParam: paginationStartDate,
		queryFn: ({ pageParam }) => {
			return loadMessages(pageParam, channelId);
		},
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, allPages) =>
			lastPage.hasMoreMessages ? lastPage.nextPageDate : undefined,
	});
}

export function useUnreadChatCountQuery() {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [ChatQueryKeys.UnreadChatCount],
		queryFn: getUnreadMessageInformation,
		enabled: isAuthenticated,
		// Gets invalidated on logout and heavily by signalr events
		staleTime: Infinity,
	});
}

interface ISendChatMessageMutationVariables {
	messageText: string;
}

export function useSendChatMessageMutation(channelId: number) {
	const { sendMessage } = useChatHubConnection();

	return useMutation({
		mutationFn: ({ messageText }: ISendChatMessageMutationVariables) =>
			sendMessage(messageText, channelId),
	});
}

// Mark message read by user in guide portal
export function useConsumerReceivedGuideMessagesMutation(channelId: number) {
	const { consumerReceivedGuideMessages } = useChatHubConnection();

	return useMutation({
		mutationFn: () => consumerReceivedGuideMessages(channelId),
	});
}

interface IConsumerReceivedMessageMutationVariables {
	messageId: number;
}

// Mark message read by user, this affects database
export function useConsumerReceivedMessageMutation(channelId: number) {
	const queryClient = useQueryClient();
	const { consumerReceivedMessage } = useChatHubConnection();

	return useMutation({
		mutationFn: ({ messageId }: IConsumerReceivedMessageMutationVariables) =>
			consumerReceivedMessage(messageId, channelId),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [ChatQueryKeys.UnreadChatCount] });
		},
	});
}

interface ISendChatAttachmentMessageMutationVariables {
	attachment: string | File;
	messageText: string;
}

export function useSendChatAttachmentMessageMutation(channelId: number) {
	return useMutation({
		mutationFn: ({ attachment, messageText }: ISendChatAttachmentMessageMutationVariables) =>
			sendAttachmentAndText({ attachment, textMessage: messageText, channelId }),
	});
}
