import { Button } from '@mobe/components/button';
import Heading from '@mobe/components/heading/Heading';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import StepIndicator from '@mobe/components/stepIndicator/StepIndicator';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { OnboardingParamList, OnboardingScreenNavigationProp } from '../OnboardingStackScreen';
import { assessmentScreenSequence } from '../assessmentScreenSequence';

export default function OnboardingScreenTemplate({
	children,
	graphic,
	routeName,
	title,
	isLoading,
	onNextPress,
}: {
	children: React.ReactNode;
	graphic?: React.ReactNode;
	routeName: keyof OnboardingParamList;
	title: string;
	isLoading?: boolean;
	onNextPress: () => void;
}) {
	const { constrain, vr, wrapperNarrow } = useStyleHelpers();
	const navigation = useNavigation<OnboardingScreenNavigationProp>();
	const parentNavigation = navigation.getParent();
	const routeIndex = assessmentScreenSequence.findIndex(
		(screen) => screen.screenName === routeName
	);
	const isLastScreen = routeIndex + 1 === assessmentScreenSequence.length;
	const { t } = useTranslation();

	useFocusEffect(
		React.useCallback(() => {
			if (!parentNavigation) {
				return;
			}

			parentNavigation.setOptions({
				headerBackground: () => (
					<StepIndicator
						totalSteps={assessmentScreenSequence.length}
						currentStepIndex={routeIndex}
					/>
				),
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	return (
		<ScreenTemplateWithFooter
			noPadding
			footer={
				<Button
					title={isLastScreen ? t('onboarding.submitButton') : t('onboarding.nextButton')}
					onPress={onNextPress}
					loading={isLoading}
				/>
			}
		>
			<View
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: 40,
					marginBottom: 30,
				}}
			>
				{graphic && <View style={[constrain(100), vr(5)]}>{graphic}</View>}
				<Heading level="h2" accessibilityAutoFocus style={vr(6)}>
					{title}
				</Heading>
			</View>
			<View style={wrapperNarrow}>{children}</View>
		</ScreenTemplateWithFooter>
	);
}
