import * as React from 'react';
import { Platform } from 'react-native';
import { colorSchemeEvent, fontScaleEvent } from './analyticEvents';
import { useStyleRules } from './styleRules/useStyleRules';

function setCSSVariable(variable: string, value: string) {
	if (Platform.OS === 'web') {
		document.documentElement.style.setProperty(variable, value);
	}
}

export default function useGlobalStyleConfig() {
	const { colors, settings } = useStyleRules();

	React.useEffect(() => fontScaleEvent(settings.fontScale), [settings.fontScale]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(() => colorSchemeEvent(settings.colorScheme), []);

	React.useEffect(() => {
		setCSSVariable('--color-text', colors.text);
		setCSSVariable('--color-text-light', colors.textLight);
		setCSSVariable('--color-primary', colors.primary);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings.colorScheme]);
}
