import { yupResolver } from '@hookform/resolvers/yup';
import {
	useGetEmailVerifiedMutation,
	useVerifyEmailMutation,
} from '@mobe/api/account/accountApiHooks';
import {
	shouldShowBioAuthPrompt,
	useBioAuthLoginMutation,
	useIsAuthenticated,
	useLoginMutation,
} from '@mobe/api/authentication/authApiHooks';
import { LoginAPIErrorCode } from '@mobe/api/authentication/authenticationService';
import { getErrorCodeFromResponseError } from '@mobe/api/client';
import env from '@mobe/env/env';
import { useHasSeenBioAuthPrompt, useLoginCount } from '@mobe/utils/storage/appStateStorage';
import { useLoginOptions } from '@mobe/utils/storage/authStateStorage';
import { useAlert } from '@mobe/utils/useAlert';
import { useFocusEffect } from '@react-navigation/native';
import axios from 'axios';
import { noop } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard, Platform } from 'react-native';
import * as Yup from 'yup';
import { getPathAndSearchParamsFromURL, isURLAuthScreenURL } from '../../navigation/linkingConfig';
import * as AuthAnalyticsEvents from '../analyticsEvents';
import { useAuthStore } from '../useAuthStore';
import { useBioAuthContent } from '../useBioAuthContent';
import useBioAuthService from '../useBioAuthService';
import { ILoginScreenProps } from './LoginScreen';

// For web, get the initial location href so we can use this for redirecting to screens that need auth...
let initialWebURL = Platform.OS === 'web' ? location.href : '';

function useValidationSchema() {
	const { t } = useTranslation();

	return Yup.object({
		email: Yup.string().email().trim().required().label(t('auth.login.emailInputLabel')),
		password: Yup.string().required().label(t('auth.login.passwordInputLabel')),
		rememberMe: Yup.boolean(),
		useBiometricLogin: Yup.boolean(),
	});
}

type FormSchema = Yup.InferType<ReturnType<typeof useValidationSchema>>;

export default function useLoginController({ navigation, route }: ILoginScreenProps) {
	const loginMutation = useLoginMutation();
	const bioAuthLoginMutation = useBioAuthLoginMutation();
	const getEmailVerifiedMutation = useGetEmailVerifiedMutation();
	const verifyEmailMutation = useVerifyEmailMutation();
	const [hasSeenBioAuthPrompt, setHasSeenBioAuthPrompt] = useHasSeenBioAuthPrompt();
	const [loginCount] = useLoginCount();
	const { firstTimeUsername } = useAuthStore();
	const { rememberMe, enableBiometricLogin, savedLoginCredentials } = useLoginOptions();
	const isAuthenticated = useIsAuthenticated();
	const bioAuthService = useBioAuthService();
	const { t } = useTranslation();
	const [canUseBioAuth, setCanUseBioAuth] = React.useState(false);
	const [shouldShowBioAuthSwitch, setShouldShowBioAuthSwitch] = React.useState(false);
	const emailVerificationGuid = route.params?.emailVerificationGuid || '';
	const bioAuthContent = useBioAuthContent();
	const { mobeAlert } = useAlert();

	// Reset login mutation when screen is focused as it drives alerts on this screen
	useFocusEffect(
		React.useCallback(() => {
			loginMutation.reset();
		}, [])
	);

	// Try to verify email on open
	const tryToVerifyEmail = React.useCallback(async () => {
		if (!emailVerificationGuid) {
			return;
		}

		// If this request succeeds we can try to post to verify
		getEmailVerifiedMutation.mutate(
			{ guid: emailVerificationGuid },
			{
				onSuccess: () => {
					verifyEmailMutation.mutate(
						{ guid: emailVerificationGuid },
						{
							onSuccess: () => {
								navigation.navigate('VERIFIED_EMAIL_SCREEN', { success: true });
							},
							onError: () => {
								navigation.navigate('VERIFIED_EMAIL_SCREEN', { success: false });
							},
						}
					);
				},
				onError: () => {
					navigation.setParams({ emailVerificationGuid: undefined });
				},
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailVerificationGuid]);

	React.useEffect(() => {
		tryToVerifyEmail();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailVerificationGuid]);

	// Web redirectTo functionality
	React.useEffect(() => {
		if (Platform.OS !== 'web') return;

		if (initialWebURL && !isURLAuthScreenURL(getPathAndSearchParamsFromURL(initialWebURL))) {
			try {
				// Do not use react-native-url-polyfill for URL here
				const url = new URL(initialWebURL);
				const redirectPath = `${url.pathname}${url.search}`;
				if (redirectPath === '/') return;
				navigation.setParams({ redirectTo: redirectPath });
				initialWebURL = '';
			} catch (error) {
				console.warn('error setting initial web redirect url', error);
			}
		}
	}, []);

	/**
	 * Validation setup
	 */

	const validationSchema = useValidationSchema();

	let email = '';

	if (env.DEFAULT_LOGIN_USERNAME) {
		email = env.DEFAULT_LOGIN_USERNAME;
	} else if (firstTimeUsername) {
		email = firstTimeUsername;
	} else if (rememberMe && savedLoginCredentials.username) {
		email = savedLoginCredentials.username;
	}

	const form = useForm<FormSchema>({
		mode: 'onTouched',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			email,
			password: env.DEFAULT_LOGIN_PASSWORD || '',
			rememberMe,
			useBiometricLogin: enableBiometricLogin,
		},

		// Slight error delay for web to allow forgot password link to be clickable
		delayError: Platform.OS === 'web' ? 250 : undefined,
	});

	// Screen blur handler
	React.useEffect(() => {
		const unsubscribe = navigation.addListener('blur', () => {
			// Reset the password field after any blur of login screen
			setTimeout(() => {
				form.resetField('password');
			}, 1000);
		});

		return unsubscribe;
	}, [navigation]);

	// Set form values based on that which is stored in settings
	React.useEffect(() => {
		if (!env.DEFAULT_LOGIN_USERNAME && savedLoginCredentials.username) {
			form.setValue('email', savedLoginCredentials.username);
		}

		form.setValue('rememberMe', rememberMe);
	}, [savedLoginCredentials.username]);

	// If initial username needs to be gotten from auth context after form has already been instantiated,
	// then field must be updated manually rather than via the initial input default
	React.useEffect(() => {
		if (firstTimeUsername) {
			form.setValue('email', firstTimeUsername);

			// If email has an error, revalidate to ensure there's no stale error after field population
			if (form.formState.errors.email !== undefined) {
				form.trigger('email');
			}
		}
	}, [firstTimeUsername]);

	/**
	 * Login
	 **/
	function attemptLogin({ email, password, rememberMe, useBiometricLogin }: FormSchema) {
		loginMutation.mutate(
			{
				username: String(email),
				password,
				rememberMe: Boolean(rememberMe),
				enableBiometricLogin: Boolean(useBiometricLogin),
				redirectTo: route.params?.redirectTo,
				bioAuthPrompt: () => {
					return new Promise<boolean>((resolve) => {
						// Prompt PPT to enable bio auth if they've signed in at least once but haven't turned on bio auth
						if (
							bioAuthService.isBioAuthAvailable &&
							!useBiometricLogin &&
							loginCount > 0 &&
							!hasSeenBioAuthPrompt
						) {
							setHasSeenBioAuthPrompt(true);
							mobeAlert(
								t('auth.biometricAlert.title'),
								t('auth.biometricAlert.message', {
									biometricMessage: bioAuthContent.settingsLabel,
								}),
								[
									{
										text: t('auth.biometricAlert.decline'),
										onPress: () => resolve(false),
									},
									{
										text: t('auth.biometricAlert.enable'),
										onPress: () => resolve(true),
									},
								]
							);
						} else {
							resolve(Boolean(useBiometricLogin));
						}
					});
				},
			},
			{
				onSuccess: (data) => {
					// IMPORTANT: Right now this callback won't work because login mutation here is unmounted by
					// global success handler when session query is set and this won't happen. Do not use.
				},
				onError: (error) => {
					if (axios.isAxiosError(error)) {
						const loginErrorCode = getErrorCodeFromResponseError(error) as LoginAPIErrorCode;

						switch (loginErrorCode) {
							case LoginAPIErrorCode.Mismatch:
								mobeAlert(
									'',
									t('auth.login.errors.mismatch', {
										remainingLoginAttempts: Number(error.response?.data.remaining),
									}),
									[
										{
											text: t('forms.genericServerErrorAlert.confirm'),
											onPress: noop,
										},
									]
								);
								break;
							case LoginAPIErrorCode.PasswordExpired:
								mobeAlert('', t('auth.login.errors.passwordExpired'), [
									{
										text: t('forms.genericServerErrorAlert.confirm'),
										onPress: noop,
									},
								]);
								beginResetExpiredPasswordProcess(error.response?.data.data.resetPasswordGuid || '');
								break;
							case LoginAPIErrorCode.AccountDisabledOnServer:
								mobeAlert('', String(error.response?.data.message), [
									{
										text: t('forms.genericServerErrorAlert.confirm'),
										onPress: noop,
									},
								]);
								break;
							case LoginAPIErrorCode.AccountLocked:
								mobeAlert('', t('auth.login.errors.accountLocked'), [
									{
										text: t('forms.genericServerErrorAlert.confirm'),
										onPress: () => {
											navigation.navigate('FORGOT_PASSWORD_SCREEN', { context: 'lockedAccount' });
										},
									},
								]);
								break;
							case LoginAPIErrorCode.NeedsValidation:
								navigation.navigate('VERIFY_EMAIL_PROMPT_SCREEN', {
									accessToken: error.response?.data.data.token ?? '',
								});
								break;
							case LoginAPIErrorCode.Unknown:
								mobeAlert('', t('auth.login.errors.unknown'), [
									{
										text: t('forms.genericServerErrorAlert.confirm'),
										onPress: noop,
									},
								]);
								break;
						}
					}
				},
			}
		);
	}

	/**
	 * Bio-auth related functionality
	 */

	/**
	 * Update canUseBioAuth
	 */
	React.useEffect(() => {
		setCanUseBioAuth(bioAuthService.isBioAuthAvailable && enableBiometricLogin);
		setShouldShowBioAuthSwitch(bioAuthService.isBioAuthAvailable && !enableBiometricLogin);
	}, [bioAuthService.isBioAuthAvailable, enableBiometricLogin]);

	/**
	 * BioAuth Login
	 */
	function attemptBioAuthLogin() {
		if (!canUseBioAuth) {
			return;
		}

		bioAuthLoginMutation.mutate({
			redirectTo: route.params?.redirectTo,
		});
	}

	/**
	 * Attempt to raise a bio-auth prompt automatically on app-launch
	 */
	React.useEffect(() => {
		// only run this on the first time this controller runs, which would be when the app first launches
		if (canUseBioAuth && shouldShowBioAuthPrompt && !isAuthenticated) {
			attemptBioAuthLogin();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canUseBioAuth, shouldShowBioAuthPrompt]);

	function beginResetExpiredPasswordProcess(resetToken: string) {
		navigation.navigate('RESET_PASSWORD_SCREEN', { resetToken });

		// Reset form password value so when the user comes back from the reset password screen
		// the old value will be cleared
		setTimeout(() => {
			form.setValue('password', '');
		}, 1000);
	}

	function initiateSignUp() {
		Keyboard.dismiss();

		// Pushed to end of event cycle to avoid keyboard offset issue on Android (DIG-684)
		setTimeout(() => {
			navigation.navigate('ELIGIBILITY_CHECK_SCREEN');
		}, 0);
	}

	function handleSubmitPress() {
		Keyboard.dismiss();

		if (loginMutation.isPending) {
			return;
		}

		form.handleSubmit(attemptLogin)();
	}

	function handleBioAuthLoginPress() {
		attemptBioAuthLogin();
	}

	function handleOnChangeRememberMe() {
		const { rememberMe } = form.getValues();

		if (rememberMe === false) {
			form.setValue('useBiometricLogin', false);
		}

		AuthAnalyticsEvents.toggleRememberMe(rememberMe || false);
	}

	function onPressChangeEmail() {
		form.setValue('email', '');
		navigation.setParams({
			shouldObfuscate: false,
			shouldDisable: false,
		});

		form.setValue('useBiometricLogin', false);
		form.setValue('rememberMe', false);
	}

	function handleOnChangeBioAuth() {
		const { useBiometricLogin } = form.getValues();

		if (useBiometricLogin === true) {
			form.setValue('rememberMe', true);
		}

		AuthAnalyticsEvents.toggleBioAuth(useBiometricLogin || false);
	}

	function handleForgotPasswordPress() {
		Keyboard.dismiss();

		// Pushed to end of event cycle to avoid keyboard offset issue on Android (DIG-1362)
		setTimeout(() => {
			navigation.navigate('FORGOT_PASSWORD_SCREEN');
		}, 0);
	}

	const controller = {
		initiateSignUp,
		handleSubmitPress,
		handleBioAuthLoginPress,
		handleForgotPasswordPress,
		handleOnChangeRememberMe,
		handleOnChangeBioAuth,
		onPressChangeEmail,
		canUseBioAuth,
		shouldShowBioAuthSwitch,
		form,
		isFetching: loginMutation.isPending || bioAuthLoginMutation.isPending,
	};

	return controller;
}
