import { client } from '../client';
import { IMetadataPagination } from '../paginationUtils';
import { TrackerAbbreviation } from '../track/trackService';

export type GoalType = 'LongTerm' | 'ShortTerm';
export type GoalStatus = 'Completed' | 'InProgress';
export type ActionStepStatus = 'Completed' | 'InProgress';
export enum Pathway {
	Eat = 'Eat',
	Move = 'Move',
	Sleep = 'Sleep',
	Smile = 'Smile',
	Medication = 'Medication',
	Motivate = 'Motivate',
}

export interface IActionStep {
	id: number;
	status: ActionStepStatus;
	title: string;
	pathwayName: Pathway | null;
	startDate: string;
	endDate: string;
	trackerAbbreviation: TrackerAbbreviation | null;
	subStepsComplete: number;
	subStepsTotal: number;
}

interface IActionStepsMetadata extends IMetadataPagination {
	thisWeekCount: number;
}

export interface IGetActionStepsResponse {
	metadata: IActionStepsMetadata;
	data: IActionStep[];
}

export interface IGoal {
	id: number;
	title: string;
	pathwayName: Pathway | null;
	goalType: GoalType;
	goalStatus: GoalStatus;
	startDate: string;
	endDate: string;
	trackerAbbreviation: TrackerAbbreviation | null;
	isViewed: boolean;
}

export interface IGoalFull extends IGoal {
	actions: IActionStep[];
}

export interface IGetGoalsResponse {
	metadata: IMetadataPagination;
	data: IGoal[];
}

export interface IGetGoals {
	type: GoalType;
	status: GoalStatus;
	skip?: number;
	take?: number;
}

export async function getGoals(params: IGetGoals) {
	const response = await client.get<IGetGoalsResponse>(
		`strategy-goal-types/${params.type}/strategy-goals`,
		{ params }
	);
	return response.data as IGetGoalsResponse;
}

export async function getGoal(id: number) {
	const response = await client.get(`strategy-goals/${id}`);
	return response.data as IGoalFull;
}

export interface ISetGoalStatus {
	goalId: number;
	goalStatus: GoalStatus;
}

export async function setGoalStatus({ goalId, goalStatus }: ISetGoalStatus) {
	await client.patch(`strategy-goals/${goalId}`, { goalStatus });
	return {};
}

export interface IGetActionSteps {
	status: ActionStepStatus;
	skip?: number;
	take?: number;
}

export async function getActionSteps(params: IGetActionSteps) {
	const response = await client.get('strategy-actions', { params });
	return response.data as IGetActionStepsResponse;
}

export async function getActionStep(id: number) {
	const response = await client.get(`strategy-actions/${id}`);
	return response.data as IActionStep;
}

export interface ISetActionStepStatus {
	actionStepId: number;

	/**
	 * Status of the current sub step. This will not effect the status of the action step unless
	 * there are no remaining sub steps. The status essentially increments/decrements sub steps.
	 */
	status: ActionStepStatus;
}

export async function setActionStepStatus({ actionStepId, status }: ISetActionStepStatus) {
	await client.patch(`strategy-actions/${actionStepId}`, { status });
	return {};
}
