import { Platform, useWindowDimensions } from 'react-native';
import { useStyleRules } from './styleRules/useStyleRules';

/**
 * Returns web only breakpoint booleans for mobile and desktop.
 * Values will always be false for non-web devices.
 */
export default function useLayout() {
	const { width, height } = useWindowDimensions();
	const styleRules = useStyleRules();
	const isWeb = Platform.OS === 'web';
	const isWebMobile = isWeb && width < styleRules.breakpoints.webDesktop;
	const isWebDesktop = isWeb && width >= styleRules.breakpoints.webDesktop;
	const isLargeDisplay = width >= styleRules.breakpoints.large;
	const isLargeFontScale =
		Math.min(width, styleRules.spacing.maxWidth) / styleRules.settings.fontScale < 300;

	return {
		isWebMobile,
		isWebDesktop,
		isLargeDisplay,
		bottomTabScreenWidth: isWebDesktop ? width - styleRules.spacing.webNavWidth : width,
		isLandscape: width > height,
		isLargeFontScale,
	};
}
