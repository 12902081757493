import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { useAuthIdleTimeout, useIsAuthenticated } from '@mobe/api/authentication/authApiHooks';
import { useAuthSessionTimeoutInterceptor } from '@mobe/api/authentication/authSessionTimeoutInterceptor';
import { Announce } from '@mobe/components/announce/Announce';
import AppFeedbackPopup from '@mobe/components/appFeedbackPopup/AppFeedbackPopup';
import { AppFeedbackProvider } from '@mobe/components/appFeedbackPopup/AppFeedbackProvider';
import { ConfettiProvider } from '@mobe/components/confetti/ConfettiProvider';
import CongratsPopup from '@mobe/components/congratsPopup/CongratsPopup';
import ErrorFallbackView from '@mobe/components/errorFallbackView/ErrorFallbackView';
import { ToastProvider } from '@mobe/components/toast/Toast';
import analytics from '@mobe/utils/analytics';
import crashlytics from '@mobe/utils/crashlytics';
import dynamicLinks from '@mobe/utils/dynamicLinks';
import sessionStorage from '@mobe/utils/sessionStorage';
import { useNavigationTheme } from '@mobe/utils/styles/navigation/navigationStyles';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import useTransitions from '@mobe/utils/useTransitions';
import {
	NavigationContainer,
	NavigationContainerRef,
	NavigationState,
	NavigatorScreenParams,
} from '@react-navigation/native';
import { StackNavigationOptions, createStackNavigator } from '@react-navigation/stack';
import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { AlertModal, ModalProvider } from '../alertModal';
import AuthenticationStackScreen, {
	AuthenticationStackParamList,
} from '../auth/AuthenticationStackScreen';
import MaintenanceScreen from '../auth/maintenance/MaintenanceScreen';
import NoConnectionWarning from './NoConnectionWarning';
import NotFoundScreen from './NotFoundScreen';
import getLinkingConfig from './linkingConfig';
import { ModalStackScreen } from './modal/ModalStackScreen';
import { ModalStackParamList } from './modal/types';
import useNavigationHelper from './navigationHelper';

const NAVIGATION_STATE_KEY = 'NAVIGATION_STATE';

export type RootStackParamList = {
	AUTH_STACK: NavigatorScreenParams<AuthenticationStackParamList> | undefined;
	MODAL_STACK: NavigatorScreenParams<ModalStackParamList> | undefined;
	MAINTENANCE_SCREEN: undefined;

	// Not capitalizing below as the screen name is used as url slug for not found screen
	['not-found']: undefined;
};

const RootStack = createStackNavigator<RootStackParamList>();

export default function Navigation() {
	const navigationRef = React.useRef<NavigationContainerRef<RootStackParamList>>(null);
	const theme = useNavigationTheme();
	const remoteConfigData = useRemoteConfigData();
	const isAuthenticated = useIsAuthenticated();
	const navigationHelper = useNavigationHelper();
	const { t } = useTranslation();
	const transitions = useTransitions();
	const [isReady, setIsReady] = React.useState(false);
	const [initialState, setInitialState] = React.useState<NavigationState | undefined>(undefined);
	const [screenTitle, setScreenTitle] = React.useState('');

	// Start idle timeout
	useAuthIdleTimeout();

	// Start auth session timeout interceptor
	useAuthSessionTimeoutInterceptor();

	// Create linking config for navigation container
	const linkingConfig = React.useMemo(() => getLinkingConfig(isAuthenticated), [isAuthenticated]);

	// Hydrate entire navigation state from sessionStorage on web reload
	React.useEffect(() => {
		if (Platform.OS !== 'web') {
			setIsReady(true);
			return;
		}

		const restoreState = async () => {
			const initialDynamicLink = await dynamicLinks.getInitialLink();

			if (initialDynamicLink?.url) {
				setIsReady(true);
				return;
			}

			const savedStateString = sessionStorage.getItem(NAVIGATION_STATE_KEY);
			const state: NavigationState | undefined = savedStateString
				? JSON.parse(savedStateString)
				: undefined;

			if (state !== undefined) {
				setInitialState(state);
			}

			setIsReady(true);
		};

		if (!isReady) {
			restoreState();
		}
	}, [isReady]);

	function handleNavigationReady() {
		// Timeout fixes flash of white background between hiding splash and rendering the app on Android
		if (Platform.OS === 'android') {
			setTimeout(() => {
				SplashScreen.hideAsync();
			}, 100);
		} else {
			SplashScreen.hideAsync();
		}

		navigationHelper.updateCurrentRoute(navigationRef);
	}

	async function handleNavigationStateChange(state: NavigationState | undefined) {
		const currentScreenOptions =
			navigationRef.current?.getCurrentOptions() as StackNavigationOptions;

		if (currentScreenOptions && currentScreenOptions.title) {
			// Add screen title to state for web/aria-live alert
			setScreenTitle(currentScreenOptions.title);
		}

		if (Platform.OS === 'web') {
			sessionStorage.setItem(NAVIGATION_STATE_KEY, JSON.stringify(state));
		}

		navigationHelper.updateCurrentRoute(navigationRef);
	}

	function handleError(error: Error) {
		crashlytics.recordError(error, 'navigation');
		analytics.logEvent('error_screen_view');
	}

	// root navigator state
	// this logic controls the fork of our top level navigation between the intro, login, and protected routes
	// NOTE: only one of these should be truthy at any particular time
	const shouldShowMaintenanceScreen =
		remoteConfigData.maintenanceScreen.enabled || remoteConfigData.isUnderConstruction;

	const shouldShowAuthScreen = !isAuthenticated && !shouldShowMaintenanceScreen;
	const shouldShowAppContent = isAuthenticated && !shouldShowMaintenanceScreen;

	if (!isReady) {
		return null;
	}

	return (
		<NavigationContainer
			ref={navigationRef}
			initialState={initialState}
			theme={theme}
			linking={linkingConfig}
			onReady={handleNavigationReady}
			onStateChange={handleNavigationStateChange}
		>
			<ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
				<BottomSheetModalProvider>
					<ModalProvider>
						<ConfettiProvider>
							<AppFeedbackProvider>
								<ToastProvider>
									<NoConnectionWarning />

									<RootStack.Navigator
										screenOptions={{
											headerShown: false,
											gestureEnabled: false,
											...transitions.DefaultTransition,
										}}
									>
										{shouldShowAuthScreen ? (
											<RootStack.Screen name="AUTH_STACK" component={AuthenticationStackScreen} />
										) : shouldShowAppContent ? (
											<RootStack.Screen name="MODAL_STACK" component={ModalStackScreen} />
										) : shouldShowMaintenanceScreen ? (
											<RootStack.Screen
												name="MAINTENANCE_SCREEN"
												component={MaintenanceScreen}
												options={{ title: t('maintenance.screenTitle') }}
											/>
										) : null}
										<RootStack.Screen
											name="not-found"
											component={NotFoundScreen}
											options={{ title: t('notFound.screenTitle') }}
										/>
									</RootStack.Navigator>

									<AppFeedbackPopup />
									<CongratsPopup />
									<AlertModal />

									<Announce message={screenTitle} />
								</ToastProvider>
							</AppFeedbackProvider>
						</ConfettiProvider>
					</ModalProvider>
				</BottomSheetModalProvider>
			</ErrorBoundary>
		</NavigationContainer>
	);
}

function ErrorFallback({ resetErrorBoundary }: FallbackProps) {
	return <ErrorFallbackView onPress={resetErrorBoundary} />;
}
