import { useProfileQuery, useUpdateProfileMutation } from '@mobe/api/account/accountApiHooks';
import Heading from '@mobe/components/heading/Heading';
import ImageSelectSheet from '@mobe/components/imageSelectSheet/ImageSelectSheet';
import { formatPhoneNumber } from '@mobe/components/input';
import InputReadOnly from '@mobe/components/input/InputReadOnly';
import ProfileHeader from '@mobe/components/profileHeader/ProfileHeader';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import TextButton from '@mobe/components/textButton/TextButton';
import { useAccessibilityFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { ProfileRootScreenNavigationProp, ProfileRootScreenRouteProp } from '../ProfileStackScreen';

interface IProfileRootScreenProps {
	navigation: ProfileRootScreenNavigationProp;
	route: ProfileRootScreenRouteProp;
}

export default function ProfileRootScreen({ navigation }: IProfileRootScreenProps) {
	const profileQuery = useProfileQuery();
	const maskedPhoneNumber = formatPhoneNumber(profileQuery.data?.phoneNumber || '');
	const maskedAlternatePhoneNumber = formatPhoneNumber(
		profileQuery.data?.alternatePhoneNumber || ''
	);

	const updateProfileMutation = useUpdateProfileMutation();
	const { wrapperHorizontal, vr } = useStyleHelpers();
	const { editButton } = useEditButtonStyles();

	const [isPopupOpen, setIsPopupOpen] = React.useState(false);
	const { t } = useTranslation();
	const [imageUploadButtonRef, setFocusToImageUploadButton] = useAccessibilityFocus();

	function processSelection(avatar: string | File) {
		updateProfileMutation.mutate({ avatar });
	}

	return (
		<ScreenTemplate noPadding>
			<ProfileHeader
				headerColor="secondary"
				imageSrc={profileQuery.data?.avatarUrl ? { uri: profileQuery.data.avatarUrl } : undefined}
				showImageUploadButton
				imageUploadButtonRef={imageUploadButtonRef}
				onImageUploadPress={() => setIsPopupOpen(true)}
				isUploading={updateProfileMutation.isPending}
				style={vr(5)}
			/>
			<View style={wrapperHorizontal}>
				<Heading level="h3" accessibilityAutoFocus style={vr(7)}>
					{`${profileQuery.data?.firstName} ${profileQuery.data?.lastName}`}
				</Heading>
				<TextButton
					style={editButton}
					title={t('profile.preferredNameEditLabel')}
					onPress={() => navigation.navigate('PROFILE_EDIT_SCREEN')}
				/>
				<InputReadOnly
					label={t('profile.preferredNameLabel')}
					value={profileQuery.data?.preferredName || ''}
				/>
				<InputReadOnly label={t('profile.primaryPhoneLabel')} value={maskedPhoneNumber} />
				<InputReadOnly
					label={t('profile.alternatePhoneLabel')}
					value={maskedAlternatePhoneNumber}
				/>
				<InputReadOnly
					label={t('profile.personalPronounsLabel')}
					value={profileQuery.data?.preferredPronouns || ''}
				/>
			</View>
			<ImageSelectSheet
				isOpen={isPopupOpen}
				onClose={() => {
					setIsPopupOpen(false);
					setFocusToImageUploadButton();
				}}
				heading={t('profile.updateProfilePictureHeading')}
				maxWidth={600}
				onSelect={processSelection}
				cropToSquare
			/>
		</ScreenTemplate>
	);
}

export function useProfileRootScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();
	return {
		title: t('profile.screenTitle'),
	};
}

/**
 * One-off styles for inlining the Edit button beside the uppermost label.
 * Consider refactoring if this pattern is ever used again.
 */
function useEditButtonStyles() {
	const rules = useStyleRules();

	return StyleSheet.create({
		editButton: {
			alignSelf: 'flex-end',
			marginBottom: 0 - rules.spacing.baseline * 6 * rules.settings.fontScale,
			zIndex: 1,
		},
	});
}
