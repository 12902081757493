import ChatButton from '@mobe/components/chatButton/ChatButton';
import Heading from '@mobe/components/heading/Heading';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import HeaderLeft from './HeaderLeft';

export interface IBottomTabScreenTemplateProps {
	children: React.ReactNode;
	screenTitle?: string;
}

export default function BottomTabScreenTemplate({
	children,
	screenTitle,
}: IBottomTabScreenTemplateProps) {
	const safeAreaInsets = useSafeAreaInsets();
	const styleRules = useStyleRules();
	const { headerShadow, fill, background } = useStyleHelpers();
	const headerHeight = Platform.OS === 'android' ? 56 : 44;

	return (
		<View style={[fill, background]}>
			<View
				style={{
					height: headerHeight + safeAreaInsets.top,
					paddingTop: safeAreaInsets.top,
					paddingHorizontal: styleRules.spacing.appHorizontalMargin,
					backgroundColor: styleRules.colors.cardBackground,
					borderBottomColor: styleRules.colors.strokeLight,
					borderBottomWidth: StyleSheet.hairlineWidth,
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					zIndex: 1,
					...StyleSheet.flatten(headerShadow),

					...Platform.select({
						web: { height: 63 },
					}),
				}}
			>
				<HeaderLeft />
				{screenTitle ? (
					<Heading semanticLevel={1} style={{ marginHorizontal: 'auto' }}>
						{screenTitle}
					</Heading>
				) : null}
				<ChatButton />
			</View>
			{children}
		</View>
	);
}
