import { useQuery } from '@tanstack/react-query';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import { getClientProgramReferrals } from './clientProgramReferralsService';
import { CLIENT_PROGRAM_REFERRALS_QUERY_KEY } from './queryKeys';

export function useClientProgramReferralsQuery() {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [CLIENT_PROGRAM_REFERRALS_QUERY_KEY],
		queryFn: getClientProgramReferrals,
		enabled: isAuthenticated,
		staleTime: 30 * 1000,
		refetchOnWindowFocus: false,
	});
}
