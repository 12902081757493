import {
	MMKV,
	useMMKVBoolean,
	useMMKVNumber,
	useMMKVObject,
	useMMKVString,
} from 'react-native-mmkv';

export const storage = new MMKV();

type UseStorageBooleanReturn<T> = T extends boolean
	? [boolean, (value: boolean) => void]
	: [boolean | undefined, (value: boolean) => void];

export function useStorageBoolean<T extends boolean | undefined>(
	storageKey: string,
	initialValue?: T
): UseStorageBooleanReturn<T> {
	const [value, setValue] = useMMKVBoolean(storageKey);
	if (value === undefined && initialValue !== undefined) {
		setValue(initialValue);
		return [initialValue, setValue];
	}
	return [value, setValue] as UseStorageBooleanReturn<T>;
}

type UseStorageNumberReturn<T> = T extends number
	? [number, (value: number) => void]
	: [number | undefined, (value: number) => void];

export function useStorageNumber<T extends number | undefined>(
	storageKey: string,
	initialValue?: T
): UseStorageNumberReturn<T> {
	const [value, setValue] = useMMKVNumber(storageKey);
	if (value === undefined && initialValue !== undefined) {
		setValue(initialValue);
		return [initialValue, setValue];
	}
	return [value, setValue] as UseStorageNumberReturn<T>;
}

type UseStorageStringReturn<T> = T extends string
	? [string, (value: string) => void]
	: [string | undefined, (value: string) => void];

export function useStorageString<T extends string | undefined>(
	storageKey: string,
	initialValue?: T
): UseStorageStringReturn<T> {
	const [value, setValue] = useMMKVString(storageKey);
	if (value === undefined && initialValue !== undefined) {
		setValue(initialValue);
		return [initialValue, setValue];
	}
	return [value, setValue] as UseStorageStringReturn<T>;
}

export function useStorageObject<T>(storageKey: string, initialValue: T): [T, (value: T) => void] {
	const [value, setValue] = useMMKVObject<T>(storageKey);
	if (value === undefined) {
		setValue(initialValue);
		return [initialValue, setValue];
	}
	return [value, setValue];
}
