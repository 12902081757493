import { useAllActivitiesQuery } from '@mobe/api/activities/activitiesApiHooks';
import { ActivityType } from '@mobe/api/activities/activitiesService';
import ChatButton from '@mobe/components/chatButton/ChatButton';
import Heading from '@mobe/components/heading/Heading';
import Blocks from '@mobe/components/layout/Blocks';
import ProgressCard from '@mobe/components/progressCard/ProgressCard';
import { useRestoreFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useLayout from '@mobe/utils/styles/useLayout';
import useRefetchStaleQueryOnFocusEffect from '@mobe/utils/useRefetchStaleQueryOnFocusEffect';
import { StackNavigationOptions } from '@react-navigation/stack';
import { FlashList } from '@shopify/flash-list';
import { differenceInMilliseconds } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import ActivityCard from '../components/ActivityCard';

export default function ActivitiesHistoryScreen() {
	const { t } = useTranslation();
	const { wrapperHorizontal, vr, background, fill } = useStyleHelpers();
	const { isLargeFontScale } = useLayout();
	const styleRules = useStyleRules();
	const { storeFocusTarget } = useRestoreFocus();
	const allActivitiesQuery = useAllActivitiesQuery();
	const activityData = allActivitiesQuery.data;

	// Completed activities filtered and sorted by most recent completion date
	const completedActivities = React.useMemo(
		() =>
			activityData?.activities
				.filter((activity) => activity.isCompleted)
				.sort((a, b) =>
					differenceInMilliseconds(new Date(b.dateCompleted), new Date(a.dateCompleted))
				) || [],
		[activityData]
	);
	const activitiesCompletedWeek = activityData?.summary.currentWeekCount || 0;
	const activitiesCompletedAllTime = activityData?.summary.lifetimeCount || 0;

	useRefetchStaleQueryOnFocusEffect(allActivitiesQuery);

	return completedActivities.length > 0 ? (
		<View style={[fill, background]}>
			<FlashList
				data={completedActivities}
				keyExtractor={(item) => item.id.toString()}
				contentContainerStyle={{
					paddingVertical: styleRules.spacing.appVerticalMargin,
				}}
				estimatedItemSize={110}
				ListHeaderComponent={
					<View style={wrapperHorizontal}>
						<Heading level="h2" style={vr(5)}>
							{t('activities.history.totalsHeading')}
						</Heading>
						<Blocks blocksPerRow={isLargeFontScale ? 1 : 2} style={vr(8)}>
							<ProgressCard
								count={activitiesCompletedWeek}
								title={t('activities.history.weeklyCompleted')}
							/>
							<ProgressCard
								count={activitiesCompletedAllTime}
								title={t('activities.history.totalCompleted')}
								isTotalCompleted
							/>
						</Blocks>
						<Heading level="h2" style={vr(5)}>
							{t('activities.history.completedHeading')}
						</Heading>
					</View>
				}
				renderItem={({ item: activity }) => (
					<View style={wrapperHorizontal}>
						<ActivityCard
							key={activity.id}
							activityData={activity}
							completed={true}
							remainActiveWhenCompleted={activity.type === ActivityType.SharedContent}
							onPress={activity.type === ActivityType.SharedContent ? storeFocusTarget : noop}
						/>
					</View>
				)}
			/>
		</View>
	) : null;
}

export function useActivitiesHistoryScreenOptions() {
	const { t } = useTranslation();
	const subScreenStyleProps = useSubScreenStyleProps();

	return (): StackNavigationOptions => ({
		title: t('activities.history.screenTitle'),
		headerRight: () => <ChatButton />,
		...subScreenStyleProps,
	});
}
