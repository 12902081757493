import { useHasSeenEducationalNotice } from '@mobe/utils/storage/appStateStorage';
import useTransitions from '@mobe/utils/useTransitions';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ActivitiesDetailScreen, {
	useActivitiesDetailScreenOptions,
} from '../../activities/screens/ActivitiesDetailScreen';
import ActivitiesHistoryScreen, {
	useActivitiesHistoryScreenOptions,
} from '../../activities/screens/ActivitiesHistoryScreen';
import ApptStackScreen, {
	useApptStackScreenOptions,
} from '../../appointments/AppointmentStackScreen';
import AppointmentRescheduleScreen, {
	useAppointmentRescheduleScreenOptions,
} from '../../appointments/appointmentReschedule/AppointmentRescheduleScreen';
import GuideAppointmentDetailScreen, {
	useGuideAppointmentDetailScreenOptions,
} from '../../appointments/guideAppointmentDetail/GuideAppointmentDetailScreen';
import EducationalNoticeScreen from '../../auth/educationalNotice/EducationalNoticeScreen';
import TermsAndConditionsScreen, {
	useTermsAndConditionsScreenOptions,
} from '../../auth/termsAndConditions/TermsAndConditionsScreen';
import ChatStackScreen, { useChatStackScreenOptions } from '../../chat/ChatStackScreen';
import ClientProgramReferralsDetailScreen, {
	useClientProgramReferralsDetailScreenOptions,
} from '../../client-program-referrals/ClientProgramReferralsDetailScreen';
import MarketplaceDetailScreen, {
	useMarketplaceDetailScreenOptions,
} from '../../connectHealthData/screens/MarketplaceDetailScreen';
import ExploreContentModuleScreen, {
	useExploreContentModuleScreenOptions,
} from '../../explore/screens/ExploreContentModuleScreen';
import ExploreDetailScreen, {
	useExploreDetailScreenOptions,
} from '../../explore/screens/ExploreDetailScreen';
import ExploreViewAllScreen, {
	useExploreViewAllScreenOptions,
} from '../../explore/screens/ExploreViewAllScreen';
import FileViewerScreen, { useFileViewerScreenOptions } from '../../fileViewer/FileViewerScreen';
import OnboardingStackScreen, {
	useOnboardingStackScreenOptions,
} from '../../onboarding/OnboardingStackScreen';
import ActionStepDetailScreen, {
	useActionStepDetailScreenOptions,
} from '../../plan/screens/ActionStepDetailScreen';
import ActionStepsHistoryScreen, {
	useActionStepsHistoryScreenOptions,
} from '../../plan/screens/ActionStepsHistoryScreen';
import GoalDetailScreen, { useGoalDetailScreenOptions } from '../../plan/screens/GoalDetailScreen';
import GoalHistoryScreen, {
	useGoalHistoryScreenOptions,
} from '../../plan/screens/GoalHistoryScreen';
import AssignSurveyScreen, { useAssignSurveyScreenOptions } from '../../surveys/AssignSurveyScreen';
import TrackDetailScreen, {
	useTrackDetailScreenOptions,
} from '../../track/screens/TrackDetailScreen';
import TrackEntriesScreen, {
	useTrackEntriesScreenOptions,
} from '../../track/screens/TrackEntriesScreen';
import TrackEntryScreen, { useTrackEntryScreenOptions } from '../../track/screens/TrackEntryScreen';
import TrackGoalScreen, { useTrackGoalScreenOptions } from '../../track/screens/TrackGoalScreen';
import { MenuStackScreen } from '../menu/MenuStackScreen';
import { ModalStackParamList } from './types';

const ModalStack = createStackNavigator<ModalStackParamList>();

export function ModalStackScreen() {
	const { t } = useTranslation();
	const [hasSeenEducationalNotice] = useHasSeenEducationalNotice();
	const transitions = useTransitions();

	const chatStackScreenOptions = useChatStackScreenOptions();
	const apptScreenOptions = useApptStackScreenOptions();
	const appointmentRescheduleOptions = useAppointmentRescheduleScreenOptions();
	const guideAppointmentDetailScreenOptions = useGuideAppointmentDetailScreenOptions();
	const trackScreenOptions = useTrackEntryScreenOptions();
	const trackGoalScreenOptions = useTrackGoalScreenOptions();
	const trackDetailScreenOptions = useTrackDetailScreenOptions();
	const trackEntriesScreenOptions = useTrackEntriesScreenOptions();
	const exploreDetailScreenOptions = useExploreDetailScreenOptions();
	const exploreContentModuleScreenOptions = useExploreContentModuleScreenOptions();
	const exploreViewAllScreenOptions = useExploreViewAllScreenOptions();
	const FileViewerScreenOptions = useFileViewerScreenOptions();
	const termsAndConditionsScreenOptions = useTermsAndConditionsScreenOptions();
	const activitiesDetailScreenOptions = useActivitiesDetailScreenOptions();
	const activitiesHistoryScreenOptions = useActivitiesHistoryScreenOptions();
	const marketplaceDetailScreenOptions = useMarketplaceDetailScreenOptions();
	const onboardingScreenOptions = useOnboardingStackScreenOptions();
	const goalDetailScreenOptions = useGoalDetailScreenOptions();
	const goalHistoryScreenOptions = useGoalHistoryScreenOptions();
	const actionStepsHistoryScreenOptions = useActionStepsHistoryScreenOptions();
	const actionStepDetailScreenOptions = useActionStepDetailScreenOptions();
	const clientProgramReferralsDetailScreenOptions = useClientProgramReferralsDetailScreenOptions();
	const assignSurveyScreenOptions = useAssignSurveyScreenOptions();

	return (
		<ModalStack.Navigator
			screenOptions={() => ({
				presentation: 'modal',
			})}
			initialRouteName={hasSeenEducationalNotice ? 'MENU_STACK' : 'EDUCATIONAL_NOTICE_SCREEN'}
		>
			<ModalStack.Screen
				name="MENU_STACK"
				component={MenuStackScreen}
				options={{
					headerShown: false,
					...transitions.SlideFromRight,
				}}
			/>
			<ModalStack.Screen
				name="EDUCATIONAL_NOTICE_SCREEN"
				component={EducationalNoticeScreen}
				options={{
					headerShown: false,
					...transitions.DefaultTransition,
					title: t('appIntro.educationalNotice.screenTitle'),
				}}
			/>
			<ModalStack.Screen
				name="CHAT_SCREEN"
				component={ChatStackScreen}
				options={chatStackScreenOptions}
			/>
			<ModalStack.Screen
				name="APPOINTMENT_SCREEN"
				component={ApptStackScreen}
				options={apptScreenOptions}
			/>
			<ModalStack.Screen
				name="APPOINTMENT_RESCHEDULE_SCREEN"
				component={AppointmentRescheduleScreen}
				options={appointmentRescheduleOptions}
			/>
			<ModalStack.Screen
				name="GUIDE_APPOINTMENT_DETAIL_MODAL_SCREEN"
				component={GuideAppointmentDetailScreen}
				options={guideAppointmentDetailScreenOptions}
			/>
			<ModalStack.Screen
				name="TRACK_ENTRY_MODAL_SCREEN"
				component={TrackEntryScreen}
				options={trackScreenOptions}
			/>
			<ModalStack.Screen
				name="TRACK_GOAL_MODAL_SCREEN"
				component={TrackGoalScreen}
				options={trackGoalScreenOptions}
			/>
			<ModalStack.Screen
				name="TRACK_DETAIL_SCREEN"
				component={TrackDetailScreen}
				options={trackDetailScreenOptions}
			/>
			<ModalStack.Screen
				name="TRACK_ENTRIES_SCREEN"
				component={TrackEntriesScreen}
				options={trackEntriesScreenOptions}
			/>
			<ModalStack.Screen
				name="EXPLORE_VIEW_ALL_SCREEN"
				component={ExploreViewAllScreen}
				options={exploreViewAllScreenOptions}
			/>
			<ModalStack.Screen
				name="EXPLORE_DETAIL_SCREEN"
				component={ExploreDetailScreen}
				options={exploreDetailScreenOptions}
			/>
			<ModalStack.Screen
				name="EXPLORE_CONTENT_MODULE_SCREEN"
				component={ExploreContentModuleScreen}
				options={exploreContentModuleScreenOptions}
			/>
			<ModalStack.Screen
				name="FILE_VIEWER_SCREEN"
				component={FileViewerScreen}
				options={FileViewerScreenOptions}
			/>
			<ModalStack.Screen
				name="TERMS_AND_CONDITIONS_MODAL_SCREEN"
				component={TermsAndConditionsScreen}
				options={termsAndConditionsScreenOptions}
			/>
			<ModalStack.Screen
				name="ACTIVITIES_DETAIL_SCREEN"
				component={ActivitiesDetailScreen}
				options={activitiesDetailScreenOptions}
			/>
			<ModalStack.Screen
				name="ACTIVITIES_HISTORY_SCREEN"
				component={ActivitiesHistoryScreen}
				options={activitiesHistoryScreenOptions}
			/>
			<ModalStack.Screen
				name="MARKETPLACE_DETAIL_SCREEN"
				component={MarketplaceDetailScreen}
				options={marketplaceDetailScreenOptions}
			/>
			<ModalStack.Screen
				name="ONBOARDING_SCREEN"
				component={OnboardingStackScreen}
				options={onboardingScreenOptions}
				initialParams={{ context: 'activity' }}
			/>
			<ModalStack.Screen
				name="GOAL_DETAIL_SCREEN"
				component={GoalDetailScreen}
				options={goalDetailScreenOptions}
			/>
			<ModalStack.Screen
				name="GOAL_HISTORY_SCREEN"
				component={GoalHistoryScreen}
				options={goalHistoryScreenOptions}
			/>
			<ModalStack.Screen
				name="ACTION_STEPS_HISTORY_SCREEN"
				component={ActionStepsHistoryScreen}
				options={actionStepsHistoryScreenOptions}
			/>
			<ModalStack.Screen
				name="ACTION_STEP_DETAIL_SCREEN"
				component={ActionStepDetailScreen}
				options={actionStepDetailScreenOptions}
			/>
			<ModalStack.Screen
				name="CLIENT_PROGRAM_REFERRALS_DETAIL_SCREEN"
				component={ClientProgramReferralsDetailScreen}
				options={clientProgramReferralsDetailScreenOptions}
			/>
			<ModalStack.Screen
				name="ASSIGN_SURVEY_SCREEN"
				component={AssignSurveyScreen}
				options={assignSurveyScreenOptions}
			/>
		</ModalStack.Navigator>
	);
}
