import { useAllActivitiesQuery } from '@mobe/api/activities/activitiesApiHooks';
import { ActivitiesQueryKeys } from '@mobe/api/activities/activitiesQueryKeys';
import { ActivityType, getAllActivities } from '@mobe/api/activities/activitiesService';
import { Button } from '@mobe/components/button';
import Heading from '@mobe/components/heading/Heading';
import Text from '@mobe/components/text/Text';
import { useHasSeenEducationalNotice } from '@mobe/utils/storage/appStateStorage';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useNavigation } from '@react-navigation/native';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { EducationalNoticeScreenNavigationProp } from '../../navigation/modal/types';
import AuthScreenTemplate from '../components/authScreenTemplate/AuthScreenTemplate';

export default function EducationalNoticeScreen() {
	const [, setHasSeenEducationalNotice] = useHasSeenEducationalNotice();
	const activitiesQuery = useAllActivitiesQuery();
	const queryClient = useQueryClient();
	const navigation = useNavigation<EducationalNoticeScreenNavigationProp>();
	const [isLoading, setIsLoading] = React.useState(false);
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();

	async function handleOnPress() {
		setIsLoading(true);

		// awaits Activities if data isn't already in cache, otherwise instantaneous
		await queryClient.fetchQuery({
			queryKey: [ActivitiesQueryKeys.AllActivities],
			queryFn: getAllActivities,
			staleTime: Infinity,
		});

		const assessmentActivityExists = activitiesQuery.data?.activities
			.filter((activity) => !activity.isCompleted)
			.some((activity) => activity.type === ActivityType.Assessment);

		setHasSeenEducationalNotice(true);

		if (assessmentActivityExists && Platform.OS !== 'web') {
			navigation.replace('ONBOARDING_SCREEN', { context: 'initial' });
		} else {
			navigation.replace('MENU_STACK');
		}
	}

	return (
		<AuthScreenTemplate center>
			<View style={vr(5)}>
				<Heading level="h2" accessibilityAutoFocus align="center" style={vr(5)}>
					{t('auth.notice.title')}
				</Heading>
				<Text weight="medium" style={vr(6)}>
					{t('auth.notice.description')}
				</Text>
			</View>
			<Button
				title={t('auth.notice.continueButton')}
				style={vr(5)}
				onPress={handleOnPress}
				loading={isLoading}
			/>
		</AuthScreenTemplate>
	);
}
