import { useIsAuthenticated } from '@mobe/api/authentication/authApiHooks';
import { useAnnounceForAccessibilityEffect } from '@mobe/utils/a11y';
import messaging from '@mobe/utils/messaging';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useLayout from '@mobe/utils/styles/useLayout';
import useRedirectLinkTo from '@mobe/utils/useRedirectLinkTo';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import { FirebaseMessagingTypes } from '@react-native-firebase/messaging';
import Color from 'color';
import { uniqueId } from 'lodash';
import * as React from 'react';
import { Animated, Pressable, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Icon from '../icon/Icon';
import Row from '../layout/Row';
import { Text } from '../text';

interface IToastConfig {
	message: string;
	toastLeft?: React.ReactNode;
	toastRight?: React.ReactNode | ((dismissToast: () => void) => React.ReactNode);
	onPress?: () => void;
	delayDuration?: number;
}

type ShowToastParams = string | IToastConfig;

/**
 * Toast item for the provider's toast array. ID is not relevant outside this context.
 */
interface IToastItem extends IToastConfig {
	id: string;
}

/**
 * Props for toast component. onClose is just for communication between provider and component.
 */
interface IToastProps extends IToastConfig {
	delayDuration?: number;
	onClose: () => void;
}

function Toast({
	message,
	onClose,
	onPress,
	toastLeft,
	toastRight,
	delayDuration = 500,
}: IToastProps) {
	const styleRules = useStyleRules();
	const { cardShadow } = useStyleHelpers();
	const fadeAnimation = React.useRef(new Animated.Value(0)).current;
	useAnnounceForAccessibilityEffect(message);

	// TODO: Could pass more config to have longer delays or none at all..
	React.useEffect(() => {
		Animated.sequence([
			Animated.timing(fadeAnimation, {
				toValue: 1,
				useNativeDriver: true,
				duration: 250,
				delay: delayDuration,
			}),
			Animated.timing(fadeAnimation, {
				toValue: 2,
				useNativeDriver: true,
				duration: 200,
				delay: 5000,
			}),
		]).start(onClose);
	}, []);

	const translateY = fadeAnimation.interpolate({
		inputRange: [0, 1, 2],
		outputRange: [5, 0, 0],
	});

	const opacity = fadeAnimation.interpolate({
		inputRange: [0, 1, 2],
		outputRange: [0, 0.97, 0],
	});

	function handlePress() {
		if (typeof onPress === 'function') {
			onClose();
			onPress();
		}
	}

	function renderToast() {
		return (
			<Animated.View
				style={{
					...cardShadow,
					backgroundColor: Color(styleRules.colors.primary).darken(0.25).hex(),
					borderRadius: styleRules.borderRadius,
					marginHorizontal: styleRules.spacing.appHorizontalMargin / 2,
					paddingHorizontal: styleRules.spacing.appHorizontalMargin,
					paddingVertical: 24,
					opacity,
					transform: [{ translateY }],
				}}
			>
				<Row>
					{Boolean(toastLeft) && <Row.Item>{toastLeft}</Row.Item>}
					<Row.Item fill>
						<Text weight="medium" color="inverted">
							{message}
						</Text>
					</Row.Item>
					{Boolean(toastRight) && (
						<Row.Item>
							{typeof toastRight === 'function' ? toastRight(onClose) : toastRight}
						</Row.Item>
					)}
				</Row>
			</Animated.View>
		);
	}

	if (typeof onPress === 'function') {
		return (
			<Pressable aria-label={message} onPress={handlePress}>
				{renderToast()}
			</Pressable>
		);
	}

	return renderToast();
}

interface IToastProviderContextValue {
	show: (params: ShowToastParams) => void;
}

interface IToastProviderProps {
	children: React.ReactNode;
}

const ToastContext = React.createContext<IToastProviderContextValue | undefined>(undefined);

function ToastProvider({ children }: IToastProviderProps) {
	const insets = useSafeAreaInsets();
	const styleRules = useStyleRules();
	const layout = useLayout();
	const remoteConfigData = useRemoteConfigData();
	const isToastEnabled = remoteConfigData.enableToast;
	const isAuthenticated = useIsAuthenticated();
	const redirectTo = useRedirectLinkTo();
	const [toasts, setToasts] = React.useState<IToastItem[]>([]);

	React.useEffect(() => {
		function handleForegroundMessage(remoteMessage: FirebaseMessagingTypes.RemoteMessage) {
			// Don't show toasts for non authenticated users
			if (!isAuthenticated) {
				return;
			}

			const message = remoteMessage.notification?.title;
			const redirectToPath = remoteMessage.data?.redirectToPath;

			if (message) {
				show({
					message,
					toastRight: redirectToPath ? <Icon name="right" color="textInverted" /> : null,
					onPress:
						typeof redirectToPath === 'string' ? () => redirectTo(redirectToPath) : undefined,
				});
			}
		}

		// Subscribe to foreground messages
		const unsubscribe = messaging.initForegroundMessageHandler(handleForegroundMessage);

		return unsubscribe;
	}, [isAuthenticated]);

	function show(params: ShowToastParams) {
		if (!isToastEnabled) return;

		const toastProps = typeof params === 'string' ? { message: params } : params;

		setToasts((currentToasts) => {
			return [...currentToasts, { id: uniqueId('toast'), ...toastProps }];
		});
	}

	function hide(id: string) {
		setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id));
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const contextValue = React.useMemo(() => ({ show }), [isToastEnabled]);

	return (
		<ToastContext.Provider value={contextValue}>
			{children}

			<View
				aria-live="assertive"
				style={
					layout.isWebDesktop
						? {
								position: 'absolute',
								width: 500,
								bottom: 20,
								left: styleRules.spacing.webNavWidth + 10,
						  }
						: {
								position: 'absolute',
								left: 0,
								right: 0,
								bottom: Math.max(insets.bottom, 10) + styleRules.spacing.bottomTabBarHeight,
						  }
				}
			>
				{toasts.map((toast, index) => (
					<View key={toast.id} style={{ marginTop: index > 0 ? 10 : undefined }}>
						<Toast
							onClose={() => hide(toast.id)}
							delayDuration={index > 0 ? 0 : undefined}
							{...toast}
						/>
					</View>
				))}
			</View>
		</ToastContext.Provider>
	);
}

function useToast() {
	const context = React.useContext(ToastContext);

	if (context === undefined) {
		throw new Error('useToast must be used with a ToastProvider');
	}

	return context;
}

export { ToastProvider, useToast };
