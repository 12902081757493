import { AppointmentQueryKeys } from '@mobe/api/appointments/appointmentApiHooks';
import { getAppointments } from '@mobe/api/appointments/appointmentsService';
import BackButton from '@mobe/components/backButton/BackButton';
import ChatButton from '@mobe/components/chatButton/ChatButton';
import { ScreenFocusInertToggle } from '@mobe/components/focusWatchingInertToggle/FocusWatchingInertToggle';
import useHeadingStyles from '@mobe/components/heading/headingStyles';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useNavigationTitleMaxWidth from '@mobe/utils/styles/navigation/useNavigationTitleMaxWidth';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useLayout from '@mobe/utils/styles/useLayout';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import CallsScreen, { useCallsScreenOptions } from '../../calls/CallsScreen';
import ConnectHealthDataScreen, {
	useConnectHealthDataScreenOptions,
} from '../../connectHealthData/ConnectHealthDataScreen';
import DevToolsScreen, { devToolsScreenOptions } from '../../devTools/DevToolsScreen';
import MyGuideScreen, { useMyGuideScreenOptions } from '../../myGuide/MyGuideScreen';
import MyPharmacistScreen, {
	useMyPharmacistScreenOptions,
} from '../../myPharmacist/MyPharmacistScreen';
import ProfileScreen, { useProfileScreenOptions } from '../../profile/ProfileStackScreen';
import SettingsStackScreen, {
	settingsStackScreenOptions,
} from '../../settings/SettingsStackScreen';
import MainContentView from '../MainContentView';
import BottomTabStackScreen, {
	useBottomTabStackScreenOptions,
} from '../bottomTabs/BottomTabStackScreen';
import { ModalNavigationProvider } from '../modal/ModalNavigationProvider';
import { MenuStackScreenNavigationProp, MenuStackScreenRouteProp } from '../modal/types';
import MenuDrawer from './components/menuDrawer/MenuDrawer';
import { MenuParamList } from './types';

interface IMenuStackScreenProps {
	route: MenuStackScreenRouteProp;
	navigation: MenuStackScreenNavigationProp;
}

const Menu = createDrawerNavigator<MenuParamList>();

/**
 *	Screen for the Menu Navigator. This screen is a child of the Root Navigator.
 */
export function MenuStackScreen({ navigation }: IMenuStackScreenProps) {
	const headerTitleStyles = useHeadingStyles({ level: 'h3' });
	const { headerShadow } = useStyleHelpers();
	const windowDimensions = useWindowDimensions();
	const layout = useLayout();
	const styleRules = useStyleRules();
	const headerTitleMaxWidth = useNavigationTitleMaxWidth();

	const profileScreenOptions = useProfileScreenOptions();
	const myGuideScreenOptions = useMyGuideScreenOptions();
	const myPharmacistScreenOptions = useMyPharmacistScreenOptions();
	const callsScreenOptions = useCallsScreenOptions();
	const bottomTabStackScreenOptions = useBottomTabStackScreenOptions();

	const queryClient = useQueryClient();

	// Prefetch data for screens in the menu stack
	React.useEffect(() => {
		queryClient.prefetchQuery({
			queryKey: [AppointmentQueryKeys.AllAppointments],
			queryFn: getAppointments,
		});
	}, []);

	return (
		<ScreenFocusInertToggle>
			<ModalNavigationProvider value={navigation}>
				<MainContentView>
					<Menu.Navigator
						initialRouteName="BOTTOM_TAB_STACK"
						backBehavior={Platform.OS !== 'web' ? 'initialRoute' : undefined}
						drawerContent={(props) => <MenuDrawer closeDrawer={props.navigation.closeDrawer} />}
						screenOptions={{
							drawerStyle: {
								width: layout.isWebDesktop
									? styleRules.spacing.webNavWidth
									: Math.min(windowDimensions.width * 0.9, 450),
							},
							drawerPosition: 'left',
							swipeEdgeWidth: 0,
							headerShown: true,
							swipeEnabled: !layout.isWebDesktop,
							drawerType: layout.isWebDesktop ? 'permanent' : 'front',
							gestureHandlerProps: { enabled: !layout.isWebDesktop },
							unmountOnBlur: true,
							headerTitleAllowFontScaling: false,
							headerTitleContainerStyle: {
								maxWidth: headerTitleMaxWidth,

								...Platform.select({
									android: {
										// See comment in subScreenStyleProps
										flexGrow: 100,
									},
								}),
							},
							headerTitleStyle: [headerTitleStyles, { textAlign: 'center' }],
							headerTitleAlign: 'center',
							headerLeft: layout.isWebDesktop ? () => null : () => <BackButton />,
							headerRight: () => <ChatButton />,
							headerRightContainerStyle: {
								paddingRight: styleRules.spacing.appHorizontalMargin,
							},
							headerLeftContainerStyle: {
								paddingLeft: styleRules.spacing.appHorizontalMargin,
							},
							headerStyle: {
								...headerShadow,
							},
						}}
					>
						<Menu.Screen
							name="BOTTOM_TAB_STACK"
							component={BottomTabStackScreen}
							options={bottomTabStackScreenOptions}
						/>
						<Menu.Screen
							name="PROFILE_SCREEN"
							component={ProfileScreen}
							options={profileScreenOptions}
						/>
						<Menu.Screen
							name="MY_GUIDE_SCREEN"
							component={MyGuideScreen}
							options={myGuideScreenOptions}
						/>
						<Menu.Screen
							name="MY_PHARMACIST_SCREEN"
							component={MyPharmacistScreen}
							options={myPharmacistScreenOptions}
						/>
						<Menu.Screen name="CALLS_SCREEN" component={CallsScreen} options={callsScreenOptions} />
						<Menu.Screen
							name="SETTINGS_SCREEN"
							component={SettingsStackScreen}
							options={settingsStackScreenOptions}
						/>
						<Menu.Screen
							name="DEV_TOOLS_SCREEN"
							component={DevToolsScreen}
							options={devToolsScreenOptions()}
						/>
						<Menu.Screen
							name="CONNECT_HEALTH_DATA_SCREEN"
							component={ConnectHealthDataScreen}
							options={useConnectHealthDataScreenOptions}
						/>
					</Menu.Navigator>
				</MainContentView>
			</ModalNavigationProvider>
		</ScreenFocusInertToggle>
	);
}
