import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { Pressable, PressableProps, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { Radio } from '../radio';

interface IBoxButtonProps extends PressableProps {
	children: React.ReactNode;
	style?: StyleProp<ViewStyle>;
	selected?: boolean;
	showRadio?: boolean;
}

export default function BoxButton({
	children,
	style,
	selected,
	showRadio,
	...pressableProps
}: IBoxButtonProps) {
	const styles = useBoxButtonStyles({ selected, disabled: Boolean(pressableProps.disabled) });

	return (
		<Pressable style={[styles.box, style]} aria-checked={selected} {...pressableProps}>
			{showRadio && (
				<View style={styles.radio}>
					<Radio checked={selected ?? false} color="stroke" displayOnly />
				</View>
			)}
			<View style={styles.boxContent}>{children}</View>
		</Pressable>
	);
}

function useBoxButtonStyles({
	selected = false,
	disabled = false,
}: {
	selected?: boolean;
	disabled?: boolean;
}) {
	const rules = useStyleRules();
	const paddingHorizontal = 16;
	const paddingVertical = 12;

	return StyleSheet.create({
		radio: { marginRight: 12 },
		box: {
			flexDirection: 'row',
			alignItems: 'center',
			minHeight: 60,
			paddingHorizontal,
			paddingVertical,
			borderWidth: 1,
			borderColor: rules.colors.stroke,
			borderRadius: rules.borderRadius,
			backgroundColor: disabled ? rules.colors.strokeLight : rules.colors.cardBackground,

			...(selected && {
				borderColor: rules.colors.primary,
				borderWidth: 2,

				// subtract 1 to prevent content shift as a result of increased border width
				paddingHorizontal: paddingHorizontal - 1,
				paddingVertical: paddingVertical - 1,
			}),
		},
		boxContent: {
			flexGrow: 1,
			flexShrink: 1,
		},
	});
}
