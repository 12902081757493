import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { UseQueryResult } from '@tanstack/react-query';
import React from 'react';

export default function useRefetchStaleQueryOnFocusEffect(
	query: UseQueryResult | UseQueryResult[],
	condition?: boolean
) {
	const isFocused = useIsFocused();
	const isConditionDefinedAndTrue = condition ?? true;

	useFocusEffect(
		React.useCallback(() => {
			if (!isFocused || !isConditionDefinedAndTrue) {
				return;
			}

			if (Array.isArray(query)) {
				query.forEach((query) => {
					if (query.isStale) {
						query.refetch();
					}
				});
			} else {
				if (query.isStale) {
					query.refetch();
				}
			}

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isFocused, isConditionDefinedAndTrue])
	);
}
