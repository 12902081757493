import { useSubmitSurveyMutation, useSurveyQuery } from '@mobe/api/survey/surveyApiHooks';
import { SurveyResponses } from '@mobe/api/survey/surveyService';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import MobeLogoGraphic from '@mobe/components/graphics/MobeLogoGraphic';
import Heading from '@mobe/components/heading/Heading';
import LoadingOverlay from '@mobe/components/loadingOverlay/LoadingOverlay';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import Survey, { SurveyErrorView } from '@mobe/components/survey/Survey';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	UnauthenticatedSurveyScreenNavigationProp,
	UnauthenticatedSurveyScreenRouteProp,
} from '../auth/AuthenticationStackScreen';

export interface IUnauthenticatedSurveyScreenParams {
	token: string; // guid
}

interface UnauthenticatedSurveyScreenProps {
	navigation: UnauthenticatedSurveyScreenNavigationProp;
	route: UnauthenticatedSurveyScreenRouteProp;
}

export default function UnauthenticatedSurveyScreen({
	navigation,
	route,
}: UnauthenticatedSurveyScreenProps) {
	const { vr, constrainText } = useStyleHelpers();
	const { t } = useTranslation();
	const token = route.params.token;
	const surveyQuery = useSurveyQuery(token);
	const submitSurveyMutation = useSubmitSurveyMutation();
	const genericErrorAlert = useGenericErrorAlert();

	// Simple state machine to manage views
	const [currentView, setCurrentView] = React.useState<'survey' | 'complete'>('survey');

	function handleOnSubmit(answerData: SurveyResponses) {
		submitSurveyMutation.mutate(
			{ token, responses: answerData },
			{
				onSuccess: () => {
					setCurrentView('complete');
				},
				onError: () => {
					genericErrorAlert();
				},
			}
		);
	}

	return (
		<>
			{currentView === 'survey' && (
				<DeferredLoadingIndicator isLoading={surveyQuery.isLoading}>
					{surveyQuery.isSuccess ? (
						<Survey surveyData={surveyQuery.data?.questions} onSubmit={handleOnSubmit} />
					) : (
						<SurveyErrorView />
					)}
				</DeferredLoadingIndicator>
			)}
			{currentView === 'complete' && (
				<ScreenTemplate id="EndOfSurvey">
					<Heading align="center" style={vr(5)}>
						{t('survey.webSurveyComplete.title')}
					</Heading>
					<Text size="lg" align="center" style={constrainText(600)}>
						{t('survey.webSurveyComplete.description')}
					</Text>
				</ScreenTemplate>
			)}
			<LoadingOverlay visible={submitSurveyMutation.isPending} />
		</>
	);
}

export function useUnauthenticatedSurveyScreenOptions(): StackNavigationOptions {
	const subScreenStyleProps = useSubScreenStyleProps();
	const { constrain } = useStyleHelpers();
	const { t } = useTranslation();

	return {
		title: t('unauthenticatedSurvey.screenTitle'),
		headerTitle: '',
		...subScreenStyleProps,

		// One-off tweaks for this standalone survey screen
		headerTitleContainerStyle: {
			maxWidth: 'auto',
			flexShrink: 1,
		},
		headerLeft: () => (
			<View style={constrain(120, false)}>
				<MobeLogoGraphic />
			</View>
		),
	};
}
