import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { usePdfContent } from '@mobe/utils/usePdfContent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import Button from '../button/Button';
import { IMobePdfPropType } from './MobePdf';

export default function MobePdf({ title, idSlugSource, url }: IMobePdfPropType) {
	const { t } = useTranslation();
	const { openPdf, isLoading } = usePdfContent();
	const { wrapper } = useStyleHelpers();

	return (
		<View style={wrapper}>
			<Button
				title={t('fileViewer.openPdfButtonTitle')}
				loading={isLoading}
				onPress={() => {
					openPdf(idSlugSource, url, title);
				}}
			/>
		</View>
	);
}
