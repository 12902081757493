import { client } from '../client';

interface IChannelUnreadChatMessagesCount {
	channelId: number;
	numberOfUnreadMessages: number;
}

interface IUnreadChatMessagesResponse {
	mostRecentUnreadMessageCreatedDate: string;
	numberOfUnreadMessages: number;
	numberOfUnreadMessagesByChannels: IChannelUnreadChatMessagesCount[];
}

export interface IChatResponse {
	hasMoreMessages: boolean;
	nextPageDate: string | null;
	chatMessages: IServerChatMessage[];
}

export enum ChatMessageType {
	Message = 'Message',
	Attachment = 'Attachment',
	AttachmentText = 'AttachmentText',
	// NOTE: we aren't concerned with any other message types at this time
}

export interface IServerChatMessage {
	channelId: number | null;
	dateRead: string | null;
	fromUserId: number | null;
	toUserId: number | null;
	id: number;
	imageAttachmentFileId: number | null;
	imageAttachmentFileName: string | null;
	isIncoming: boolean;
	isReceived: boolean;
	isSentByCoach: boolean;
	messageDate: string;
	messageText: string | null;
	messageType: ChatMessageType;

	// NOTE: these are relics of the past usage of the chat and not used at this time
	consumerSurvey: null;
	eConsultDocument: null;
	sharedContent: null;
}

export async function loadMessages(paginationStartDate: string, channelId: number) {
	// Page url path will always need to be zero for this endpoint to work with a date pagination
	// this is not noted in the swagger docs
	const response = await client.get(`chat/${0}`, {
		params: {
			pageStartDate: paginationStartDate,
			channelId,
		},
	});
	return response.data as IChatResponse;
}

interface ISendAttachment {
	attachment: string | File;
	channelId: number;
}

export async function sendAttachment({ attachment, channelId }: ISendAttachment) {
	const formData = new FormData();

	// Below block assumes we are always dealing with an image path that is a string
	if (typeof attachment === 'string') {
		formData.append('attachment', {
			name: 'attachment',
			type: 'image/jpeg',
			uri: attachment,
		});
	} else {
		formData.append('attachment', attachment);
	}

	formData.append('channelId', channelId);

	// There seems to be a bug with RN and axios, particularly android, FormData header is not getting
	// set automatically so we are explicitly adding it here
	await client.post('chat/attachment', formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return {};
}

interface ISendAttachmentAndText {
	attachment: string | File;
	textMessage: string;
	channelId: number;
}

export async function sendAttachmentAndText({
	attachment,
	textMessage,
	channelId,
}: ISendAttachmentAndText) {
	const formData = new FormData();

	// Below block assumes we are always dealing with an image path that is a string
	if (typeof attachment === 'string') {
		formData.append('attachment', {
			name: 'attachment',
			type: 'image/jpeg',
			uri: attachment,
		});
	} else {
		formData.append('attachment', attachment);
	}

	formData.append('textMessage', textMessage);
	formData.append('channelId', channelId);

	// There seems to be a bug with RN and axios, particularly android, FormData header is not getting
	// set automatically so we are explicitly adding it here
	await client.post('chat/attachment-and-text', formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return {};
}

export async function getUnreadMessageInformation() {
	const response = await client.get('chat/unread-chat-messages-info');
	return response.data as IUnreadChatMessagesResponse;
}
