import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import React from 'react';
import { Animated, Easing, Image, ImageSourcePropType, StyleSheet, View } from 'react-native';

interface IConnectTrackerGraphicProps {
	logoSource?: ImageSourcePropType;
}

export default function ConnectTrackerGraphic({ logoSource }: IConnectTrackerGraphicProps) {
	const { vr, vrTop } = useStyleHelpers();
	const rules = useStyleRules();

	const firstCircleAnimation = React.useRef(new Animated.Value(0)).current;
	const secondCircleAnimation = React.useRef(new Animated.Value(0)).current;
	const thirdCircleAnimation = React.useRef(new Animated.Value(0)).current;

	const circleOpacityInterpolation = {
		inputRange: [0, 1],
		outputRange: [0, 1],
	};

	const circleScaleInterpolation = {
		inputRange: [0.5, 1],
		outputRange: [0, 1],
	};

	const firstCircleOpacity = firstCircleAnimation.interpolate(circleOpacityInterpolation);
	const secondCircleOpacity = secondCircleAnimation.interpolate(circleOpacityInterpolation);
	const thirdCircleOpacity = thirdCircleAnimation.interpolate(circleOpacityInterpolation);
	const firstCircleScale = firstCircleAnimation.interpolate(circleScaleInterpolation);
	const secondCircleScale = secondCircleAnimation.interpolate(circleScaleInterpolation);
	const thirdCircleScale = thirdCircleAnimation.interpolate(circleScaleInterpolation);

	React.useEffect(() => {
		animateCircles();
	}, []);

	const animateCircles = () => {
		const circleFadeInConfig = {
			useNativeDriver: true,
			toValue: 1,
			duration: 250,
			easing: Easing.out(Easing.quad),
		};

		const circleFadeOutConfig = {
			useNativeDriver: true,
			toValue: 0,
			duration: 250,
			easing: Easing.in(Easing.quad),
		};

		Animated.loop(
			Animated.sequence([
				Animated.timing(firstCircleAnimation, circleFadeInConfig),
				Animated.timing(secondCircleAnimation, circleFadeInConfig),
				Animated.timing(thirdCircleAnimation, circleFadeInConfig),
				Animated.timing(firstCircleAnimation, circleFadeOutConfig),
				Animated.timing(secondCircleAnimation, circleFadeOutConfig),
				Animated.timing(thirdCircleAnimation, circleFadeOutConfig),
			]),
			{ iterations: -1 }
		).start();
	};

	const styles = React.useMemo(
		() =>
			StyleSheet.create({
				circleLarge: {
					width: 24,
					height: 24,
					borderRadius: 12,
					borderColor: rules.colors.strokeLight,
					borderWidth: 4,
				},
				circleSmall: {
					width: 15,
					height: 15,
					borderRadius: 7.5,
					borderColor: rules.colors.strokeLight,
					borderWidth: 3,
				},
				container: {
					flexDirection: 'row',
					width: '100%',
					justifyContent: 'space-between',
					alignItems: 'center',
				},
				logo: { height: 75, width: 100 },
			}),
		[rules]
	);

	return (
		<View style={[styles.container, vr(20), vrTop(20)]}>
			{logoSource && <Image style={styles.logo} resizeMode="contain" source={logoSource} />}
			<Animated.View
				style={[
					styles.circleSmall,
					{ opacity: firstCircleOpacity, transform: [{ scale: firstCircleScale }] },
				]}
			></Animated.View>
			<Animated.View
				style={[
					styles.circleLarge,
					{ opacity: secondCircleOpacity, transform: [{ scale: secondCircleScale }] },
				]}
			></Animated.View>
			<Animated.View
				style={[
					styles.circleSmall,
					{ opacity: thirdCircleOpacity, transform: [{ scale: thirdCircleScale }] },
				]}
			></Animated.View>
			<Image
				style={styles.logo}
				resizeMode="contain"
				source={require('@mobe/assets/images/splash.png')}
			/>
		</View>
	);
}
