import { ITrackerDetail } from '@mobe/api/track/trackService';
import Icon from '@mobe/components/icon/Icon';
import { Text } from '@mobe/components/text';
import TextButton from '@mobe/components/textButton/TextButton';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { capitalize, noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

interface ITableRow {
	columns: React.ReactNode[];
	entry: ITrackerDetail;
}

export interface ITrackerTableProps {
	tableColumnHeadings: string[];
	tableRows?: ITableRow[];
	columnStyles?: StyleProp<ViewStyle>[];
	displayShowMoreButton?: boolean;
	onRowPress?: (entry: ITrackerDetail) => void;
	onShowMorePress?: () => void;
}

export default function TrackerTable({
	tableColumnHeadings,
	tableRows = [],
	columnStyles = [],
	displayShowMoreButton = false,
	onRowPress = noop,
	onShowMorePress = noop,
}: ITrackerTableProps) {
	const styleRules = useStyleRules();
	const { t } = useTranslation();

	const styles = StyleSheet.create({
		table: {
			borderColor: styleRules.colors.stroke,
			borderWidth: StyleSheet.hairlineWidth,
			borderRadius: styleRules.borderRadius,
		},
		tableHeader: {
			flexDirection: 'row',
			padding: 5,
			paddingHorizontal: 10,
		},
		tableRow: {
			flexDirection: 'row',
			alignItems: 'center',
			padding: 10,
			borderColor: styleRules.colors.stroke,
			borderTopWidth: StyleSheet.hairlineWidth,
		},
		tableCol: { flex: 1 },
		tableShowMoreButton: {
			padding: 10,
			borderColor: styleRules.colors.stroke,
			borderTopWidth: StyleSheet.hairlineWidth,
		},
		tableRowArrowContainer: {
			width: 15,
		},
	});

	return (
		<View style={styles.table} testID="TrackerTable">
			<View style={styles.tableHeader}>
				{tableColumnHeadings.map((heading, i) => (
					<View key={heading} style={columnStyles[i] ? columnStyles[i] : styles.tableCol}>
						<Text size="sm">{capitalize(heading)}</Text>
					</View>
				))}
				{/* Empty tableRowArrowContainer rendered here to ensure columns line up with entry rows */}
				<View style={styles.tableRowArrowContainer}></View>
			</View>

			{tableRows.map((row) => (
				<Pressable
					role="button"
					key={row.entry.consumerTrackerId}
					onPress={() => onRowPress(row.entry)}
				>
					<View style={styles.tableRow}>
						{row.columns.map((column, i) => (
							<Text
								key={`${row.entry.consumerTrackerId}-${i}`}
								style={columnStyles[i] ? columnStyles[i] : styles.tableCol}
							>
								{column}
							</Text>
						))}
						<View style={styles.tableRowArrowContainer}>
							<Icon name="right" size={10} />
						</View>
					</View>
				</Pressable>
			))}

			{displayShowMoreButton && (
				<View style={styles.tableShowMoreButton}>
					<TextButton
						align="center"
						title={t('track.detail.showMoreButton')}
						onPress={onShowMorePress}
					/>
				</View>
			)}
		</View>
	);
}
