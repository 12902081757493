import { SurveyResponses } from '@mobe/api/survey/surveyService';
import { announceForAccessibility, useAccessibilityFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useLayout from '@mobe/utils/styles/useLayout';
import { useAccessibilityInfo } from '@mobe/utils/useAccessibilityInfo';
import usePageTransition from '@mobe/utils/usePageTransition';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Platform, ScrollView, StyleSheet, View } from 'react-native';
import BoxButton from '../boxButton/BoxButton';
import { Button } from '../button';
import { Callout } from '../callout';
import { BaseInput } from '../input';
import InputRange, { IInputRangeOptions } from '../input/InputRange';
import InputTextArea from '../input/InputTextArea';
import VrArray from '../layout/VrArray';
import ScreenTemplate from '../screenTemplate/ScreenTemplate';
import ScreenTemplateWithFooter from '../screenTemplate/ScreenTemplateWithFooter';
import StepIndicator from '../stepIndicator/StepIndicator';
import { Text } from '../text';
import TextWithBoldParsing from '../text/TextWithBoldParsing';

type inputValue = string | number | undefined;

interface IBaseSurveyQuestion {
	isRequired?: boolean;
	questionId?: number;
	dependentAnswerIds?: inputValue[];
}

interface ISurveyRadio extends IBaseSurveyQuestion {
	type: 'radio';
	label: string;
	options: {
		label: string;
		answerId: number;
	}[];
}

interface ISurveyRange extends IBaseSurveyQuestion {
	type: 'range';
	label: string;
	labelLeft: string;
	labelCenter: string;
	labelRight: string;
	options: IInputRangeOptions[];
}

interface ISurveyTextInput extends IBaseSurveyQuestion {
	type: 'textInput';
	label: string;
}

interface ISurveyTextArea extends IBaseSurveyQuestion {
	type: 'textArea';
	label: string;
}

interface ITextBlock extends IBaseSurveyQuestion {
	type: 'textBlock';
	label: string;
}

interface IPageBreak extends IBaseSurveyQuestion {
	type: 'pageBreak';
}

export type SurveyQuestion =
	| ISurveyRadio
	| ISurveyRange
	| ISurveyTextInput
	| ISurveyTextArea
	| ITextBlock
	| IPageBreak;

type SurveyPages = SurveyQuestion[][];
type SurveyData = SurveyQuestion[];

interface IInputs {
	questionId?: number;
	value: inputValue;
}

/**
 * Transform flat questions array into a more convenient page-based structure.
 */
function createPagesFromQuestions(surveyData: SurveyData) {
	// Every survey will have at least one page
	const surveyPages: SurveyPages = [[]];
	let page = 0;

	surveyData.forEach((question) => {
		// Each 'pageBreak' will add a new surveyQuestions array to the pages array
		if (question.type === 'pageBreak') {
			page++;
			surveyPages.push([]);
		} else {
			surveyPages[page].push(question);
		}
	});

	return surveyPages;
}

interface ISurveyProps {
	surveyData?: SurveyData;
	onSubmit: (responses: SurveyResponses) => void;
}

export default function Survey({ surveyData = [], onSubmit }: ISurveyProps) {
	const { vr, vrTop, background, cardBackground, fill, notLastChild, wrapperHorizontal } =
		useStyleHelpers();
	const { startFadeOutAnimation, startFadeInAnimation, animatedViewStyles } = usePageTransition();
	const styles = useStyles();
	const { t } = useTranslation();
	const [refForFirstError, setFocusFirstError] = useAccessibilityFocus(0);
	const { screenReaderEnabled } = useAccessibilityInfo();
	const [pageNumber, setPageNumber] = React.useState(0);
	const surveyPages = createPagesFromQuestions(surveyData);
	const [shouldValidate, setShouldValidate] = React.useState(false);
	const [inputValues, setInputValues] = React.useState<IInputs[]>(
		surveyData
			.filter((question) => question.questionId !== undefined)
			.map((question) => ({ questionId: question.questionId, value: undefined }))
	);
	const shouldShowNextButton = React.useMemo(
		() => indexOfNextPageWithQuestions() > 0,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[inputValues, pageNumber]
	);
	const scrollViewRef = React.useRef<ScrollView | null>(null);
	const [questionScrollTops, setQuestionScrollTops] = React.useState<Record<number, number>>({});

	const pageInvalidQuestions = surveyPages[pageNumber].filter((question) =>
		isQuestionValid(question)
	);

	const pageInvalid = pageInvalidQuestions.length > 0;

	const indexOfFirstErroredField = React.useMemo(() => {
		return surveyPages[pageNumber].findIndex((question) => isQuestionValid(question));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValues, pageNumber]);

	function isQuestionValid(question: SurveyQuestion) {
		return (
			question.isRequired &&
			!getInput(question.questionId)?.value &&
			!dependentConditionNotMet(question)
		);
	}

	function scrollToTop() {
		if (scrollViewRef.current) {
			scrollViewRef.current.scrollTo({ y: 0, animated: false });
		}
	}

	function dependentConditionNotMet(question: SurveyQuestion) {
		const hasAnswerDependencies = Boolean(
			question.dependentAnswerIds && question.dependentAnswerIds.length > 0
		);
		const conditionMet = inputValues.some(
			(input) => question.dependentAnswerIds?.includes(input.value)
		);

		return hasAnswerDependencies && !conditionMet;
	}

	function getInput(questionId?: number) {
		return inputValues.find((input) => input.questionId === questionId);
	}

	function handleValueChange(newValue: number | string, questionId?: number) {
		if (typeof questionId !== 'number') return;

		const newInputs = [...inputValues].map((input) =>
			input.questionId === questionId ? { ...input, value: newValue } : input
		);

		setInputValues(newInputs);
	}

	function focusFirstErroredField() {
		if (pageInvalidQuestions.length > 0) {
			const firstInvalidQuestion = pageInvalidQuestions[0];

			if (Platform.OS !== 'web') {
				// Announce global errors exist
				announceForAccessibility(t('survey.formError'));

				// Scroll question into view
				if (firstInvalidQuestion.questionId) {
					scrollViewRef.current?.scrollTo({
						y: questionScrollTops[firstInvalidQuestion.questionId] - 10,
						animated: !screenReaderEnabled,
					});
				}
			}

			setFocusFirstError();
		}
	}

	/**
	 * Returns index of next page with visible/active questions. Skips empty pages.
	 */
	function indexOfNextPageWithQuestions() {
		return surveyPages.findIndex(
			(page, i) => i > pageNumber && page.some((question) => !dependentConditionNotMet(question))
		);
	}

	function goToNextPage() {
		setPageNumber(indexOfNextPageWithQuestions);
		setShouldValidate(false);
		scrollToTop();
		startFadeInAnimation();
	}

	function handleNextButtonPress() {
		if (pageInvalid) {
			setShouldValidate(true);
			focusFirstErroredField();
			return;
		}

		if (shouldShowNextButton) {
			startFadeOutAnimation(goToNextPage);
		}
	}

	function handleSubmitButtonPress() {
		if (pageInvalid) {
			setShouldValidate(true);
			focusFirstErroredField();
			return;
		}

		const answerData: SurveyResponses = inputValues
			.filter((input) => !(input.value === undefined || input.questionId === undefined))
			.map((input) => ({
				questionId: input.questionId as number,
				...(typeof input.value === 'number'
					? { answerId: input.value }
					: { answerValue: input.value as string }),
			}));

		onSubmit(answerData);
	}

	// The error view is best handled by the parent component, but is included here as a fallback
	if (!surveyData.length) {
		return <SurveyErrorView />;
	}

	return (
		<View style={[background, fill]}>
			{surveyPages.length > 1 && (
				<StepIndicator
					totalSteps={surveyPages.length}
					currentStepIndex={pageNumber}
					variant="standalone"
				/>
			)}
			<ScreenTemplateWithFooter
				ref={scrollViewRef}
				preFooter={
					shouldValidate &&
					pageInvalid && (
						<View style={[wrapperHorizontal, cardBackground]}>
							<Callout
								message={t('survey.formError')}
								type="error"
								aria-live="assertive"
								style={vrTop(10)}
							/>
						</View>
					)
				}
				footer={
					<View style={styles.footerButton}>
						{shouldShowNextButton ? (
							<Button title={t('survey.nextPageButton')} onPress={handleNextButtonPress} />
						) : (
							<Button title={t('survey.submitButton')} onPress={handleSubmitButtonPress} />
						)}
					</View>
				}
			>
				<Animated.View style={[animatedViewStyles, styles.wrapper]}>
					{surveyPages[pageNumber].map((question, i) => {
						const input = getInput(question.questionId);
						const labelId = `${question.questionId}-label`;
						const fieldId = `${question.questionId}-field`;
						const fieldErrorMessageId = `${question.questionId}-field-error`;
						const hasError = Boolean(!input?.value && question.isRequired && shouldValidate);
						const isFirstErroredField = indexOfFirstErroredField === i && shouldValidate;

						if (dependentConditionNotMet(question)) {
							return null;
						}

						return (
							<View
								key={`${question.type}${i}`}
								style={notLastChild(surveyPages[pageNumber].length, i, vr(10))}
								onLayout={(event) => {
									const scrollTopPos = event.nativeEvent.layout.y;

									setQuestionScrollTops((prevState) => {
										return {
											...prevState,
											[question.questionId as number]: scrollTopPos,
										};
									});
								}}
							>
								{question.type !== 'pageBreak' && (
									// Question label
									<TextWithBoldParsing
										ref={isFirstErroredField ? refForFirstError : undefined}
										accessible={isFirstErroredField || undefined}
										accessibilityAutoFocus={i === 0}
										id={labelId}
										size="lg"
										text={question.label}
										style={vr(4)}
									/>
								)}

								{question.type === 'radio' && (
									<VrArray
										increment={3}
										role="radiogroup"
										aria-labelledby={labelId}
										aria-describedby={fieldErrorMessageId}
									>
										{question.options.map((option, index) => (
											<BoxButton
												id={`${fieldId}-option-${index}`}
												key={option.answerId}
												selected={option.answerId === input?.value}
												onPress={() => handleValueChange(option.answerId, input?.questionId)}
												role="radio"
												showRadio
												aria-label={option.label}
												aria-labelledby={`${fieldId}-option-${index}-answer ${labelId}`}
												aria-describedby={fieldErrorMessageId}
											>
												<Text id={`${fieldId}-option-${index}-answer`} size="lg" weight="medium">
													{option.label}
												</Text>
											</BoxButton>
										))}
									</VrArray>
								)}

								{question.type === 'textInput' && (
									<BaseInput
										id={fieldId}
										maxLength={100}
										value={input?.value?.toString()}
										onChangeText={(text) => handleValueChange(text, question.questionId)}
										hasError={hasError}
										aria-labelledby={labelId}
										aria-describedby={fieldErrorMessageId}
										aria-required={question.isRequired}
									/>
								)}

								{question.type === 'textArea' && (
									<InputTextArea
										id={fieldId}
										maxLength={1500}
										value={input?.value?.toString()}
										onChangeText={(text) => handleValueChange(text, question.questionId)}
										hasError={hasError}
										aria-labelledby={labelId}
										aria-describedby={fieldErrorMessageId}
										aria-required={question.isRequired}
									/>
								)}

								{question.type === 'range' && (
									<InputRange
										id={fieldId}
										labelLeft={question.labelLeft}
										labelCenter={question.labelCenter}
										labelRight={question.labelRight}
										options={question.options}
										value={input?.value}
										onValueChange={(value) => handleValueChange(value, question.questionId)}
										aria-labelledby={labelId}
										aria-describedby={fieldErrorMessageId}
									/>
								)}

								{hasError && (
									<>
										<View style={styles.errorBar} />
										<Text style={vrTop(2)} id={fieldErrorMessageId} color="error" weight="medium">
											{t('survey.questionError')}
										</Text>
									</>
								)}
							</View>
						);
					})}
				</Animated.View>
			</ScreenTemplateWithFooter>
		</View>
	);
}

export function SurveyErrorView() {
	const { t } = useTranslation();

	return (
		<ScreenTemplate>
			<Text align="center" size="lg">
				{t('survey.surveyError')}
			</Text>
		</ScreenTemplate>
	);
}

function useStyles() {
	const rules = useStyleRules();
	const layout = useLayout();

	return StyleSheet.create({
		wrapper: {
			width: '100%',
			maxWidth: rules.spacing.modalMaxWidth,
			alignSelf: 'center',
		},
		surveyProgress: {
			width: '100%',
			maxWidth: rules.spacing.maxWidth,
			alignSelf: 'center',
		},
		footerButton: {
			...(layout.isLargeDisplay && {
				width: 150,
				alignSelf: 'flex-end',
			}),
		},
		errorBar: {
			position: 'absolute',
			top: -8,
			bottom: -8,
			left: -rules.spacing.appHorizontalMargin,
			backgroundColor: rules.colors.error,
			width: 3,
		},
	});
}
