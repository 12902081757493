import { useEligibilityCheckMutation } from '@mobe/api/account/accountApiHooks';
import { EligibilityUserStatus } from '@mobe/api/account/accountService';
import Box from '@mobe/components/box/Box';
import Button from '@mobe/components/button/Button';
import { InputText, useControlledInputProps } from '@mobe/components/input';
import ExpandedDateInput, {
	IExpandedDateInputMethods,
} from '@mobe/components/input/ExpandedDateInput';
import MobeParsedText from '@mobe/components/mobeParsedText/MobeParsedText';
import Text from '@mobe/components/text/Text';
import useTextStyles from '@mobe/components/text/textStyles';
import TextButton from '@mobe/components/textButton/TextButton';
import env from '@mobe/env/env';
import MobeLinking from '@mobe/utils/linking';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useAddMetaDescription from '@mobe/utils/useAddMetaDescription';
import { useAlert } from '@mobe/utils/useAlert';
import { convertMMDDYYToServerString } from '@mobe/utils/validationUtils';
import { noop } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, Platform } from 'react-native';
import {
	EligibilityCheckScreenNavigationProp,
	EligibilityCheckScreenRouteProp,
} from '../AuthenticationStackScreen';
import * as AuthAnalyticsEvents from '../analyticsEvents';
import AuthScreenTemplate from '../components/authScreenTemplate/AuthScreenTemplate';
import { useAuthStore } from '../useAuthStore';
import useEligibilityCheckForm, { EligibilityCheckFormSchema } from './useEligibilityCheckForm';

export interface IEligibilityCheckScreenProps {
	navigation: EligibilityCheckScreenNavigationProp;
	route: EligibilityCheckScreenRouteProp;
}

export default function EligibilityCheckScreen({ navigation }: IEligibilityCheckScreenProps) {
	const { setFirstTimeUsername } = useAuthStore();
	const form = useEligibilityCheckForm();
	const eligibilityCheckMutation = useEligibilityCheckMutation();
	const { mobeAlert } = useAlert();
	const inlineButtonStyles = useTextStyles({ color: 'primary', size: 'sm' });
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();
	const expandedDateInputRef = React.useRef<IExpandedDateInputMethods | null>(null);
	useAddMetaDescription(t('auth.eligibilityCheck.webMetaScreenDescription'));

	const firstNameInputProps = useControlledInputProps({
		name: 'firstName',
		control: form.control,
	});
	const lastNameNameInputProps = useControlledInputProps({
		name: 'lastName',
		control: form.control,
	});
	const dateOfBirthInputProps = useControlledInputProps({
		name: 'dateOfBirth',
		control: form.control,
	});

	function performEligibilityCheck({
		firstName,
		lastName,
		dateOfBirth,
	}: EligibilityCheckFormSchema) {
		eligibilityCheckMutation.mutate(
			{ firstName, lastName, dateOfBirth: convertMMDDYYToServerString(dateOfBirth) },
			{
				onSuccess: (data) => {
					if (data.eligibilityFlow === EligibilityUserStatus.RegisteredConsumer) {
						setFirstTimeUsername(data.email);

						navigation.navigate('LOGIN_SCREEN', {
							shouldObfuscate: true,
							shouldDisable: false,
						});
					} else if (data.eligibilityFlow === EligibilityUserStatus.NonRegisteredConsumer) {
						navigation.navigate('CLAIM_ACCOUNT_SCREEN', {
							accessToken: data.accessToken,
						});
					} else {
						// NOTE: this should never happen
						console.error(`performEligibilityCheck(): unhandled status: ${data.eligibilityFlow}`);
					}
				},
				onError: () => {
					AuthAnalyticsEvents.eligibilityCheckError();

					mobeAlert('', t('auth.eligibilityCheck.errors.default'), [
						{
							text: t('forms.genericServerErrorAlert.confirm'),
							onPress: noop,
						},
					]);
				},
			}
		);
	}

	function handleSubmitPress() {
		Keyboard.dismiss();
		form.handleSubmit(performEligibilityCheck)();
	}

	function handleHaveAccountPress() {
		Keyboard.dismiss();
		setTimeout(() => {
			navigation.navigate('LOGIN_SCREEN');
		}, 0);
	}

	function handleTermsAndConditionsPress() {
		if (Platform.OS === 'web') {
			MobeLinking.openUrl(env.TERMS_AND_CONDITIONS_URL);
			return;
		}

		navigation.navigate('TERMS_AND_CONDITIONS_MODAL_SCREEN', { view: 'termsAndConditions' });
	}

	function handlePrivacyPolicyPress() {
		if (Platform.OS === 'web') {
			MobeLinking.openUrl(env.PRIVACY_POLICY_URL);
			return;
		}

		navigation.navigate('TERMS_AND_CONDITIONS_MODAL_SCREEN', { view: 'privacyPolicy' });
	}

	return (
		<AuthScreenTemplate
			heading={t('auth.eligibilityCheck.heading')}
			header={
				<Box row>
					<Text>{t('auth.eligibilityCheck.haveAccount')} </Text>
					<TextButton
						onPress={handleHaveAccountPress}
						title={t('auth.eligibilityCheck.haveAccountButton')}
					></TextButton>
				</Box>
			}
		>
			<Text weight="medium" align="center" style={vr(6)}>
				{t('auth.eligibilityCheck.description')}
			</Text>

			<InputText
				label={t('auth.eligibilityCheck.firstNameInputLabel')}
				autoCapitalize="sentences"
				errorMessage={form.formState.errors.firstName?.message}
				enterKeyHint="next"
				onSubmitEditing={() => form.setFocus('lastName')}
				{...firstNameInputProps}
			/>

			<InputText
				label={t('auth.eligibilityCheck.lastNameInputLabel')}
				autoCapitalize="sentences"
				errorMessage={form.formState.errors.lastName?.message}
				enterKeyHint="next"
				onSubmitEditing={() => expandedDateInputRef.current?.focus()}
				{...lastNameNameInputProps}
			/>

			<ExpandedDateInput
				ref={expandedDateInputRef}
				label={t('auth.eligibilityCheck.dateOfBirthInputLabel')}
				hasError={dateOfBirthInputProps.hasError}
				errorMessage={form.formState.errors.dateOfBirth?.message}
				yearInputProps={{ enterKeyHint: 'go', onSubmitEditing: handleSubmitPress }}
				onBlur={dateOfBirthInputProps.onBlur}
				onDateEntered={(dateString) => {
					dateOfBirthInputProps.onChangeText(dateString || '');
				}}
				onToolbarSubmit={handleSubmitPress}
			/>

			<Text size="sm" align="center" style={vr(6)}>
				<MobeParsedText
					parse={[
						{
							pattern: new RegExp(
								`\\b${t('auth.eligibilityCheck.privacyPolicyButton')}(?!.*${t(
									'auth.eligibilityCheck.privacyPolicyButton'
								)})`,
								'gi'
							),
							style: inlineButtonStyles,
							onPress: () => handlePrivacyPolicyPress(),
						},
						{
							pattern: new RegExp(
								`\\b${t('auth.eligibilityCheck.termsAndConditionsButton')}(?!.*${t(
									'auth.eligibilityCheck.termsAndConditionsButton'
								)})`,
								'gi'
							),
							style: inlineButtonStyles,
							onPress: () => handleTermsAndConditionsPress(),
						},
					]}
				>
					{t('auth.eligibilityCheck.termsOfService')}
				</MobeParsedText>
			</Text>

			<Button
				title={t('auth.eligibilityCheck.submitButton')}
				disabled={eligibilityCheckMutation.isPending}
				loading={eligibilityCheckMutation.isPending}
				onPress={handleSubmitPress}
			/>
		</AuthScreenTemplate>
	);
}
