import { useQueryClient } from '@tanstack/react-query';
import { ITrackerType, TrackerAbbreviation } from './trackService';
import { TrackQueryKeys } from './types';

export function useGetTrackerIdFromAbbreviation() {
	const queryClient = useQueryClient();

	// Falling back to a number makes using this function much more convenient.
	// In all practical scenarios, it is completely impossible to get this fallback.
	const fallbackValue = 0;

	return (trackerAbbreviation: TrackerAbbreviation) => {
		const trackersQueryData = queryClient.getQueryData<ITrackerType[]>([
			TrackQueryKeys.AllTrackers,
		]);

		if (!trackersQueryData) {
			return fallbackValue;
		}

		return (
			trackersQueryData.find((tracker) => tracker.trackerAbbreviation === trackerAbbreviation)
				?.trackerTypeId || fallbackValue
		);
	};
}

export function useGetTrackerAbbreviationFromId() {
	const queryClient = useQueryClient();

	return (trackerId: number) => {
		const trackersQueryData = queryClient.getQueryData<ITrackerType[]>([
			TrackQueryKeys.AllTrackers,
		]);

		if (!trackersQueryData) {
			return undefined;
		}

		return trackersQueryData.find((tracker) => tracker.trackerTypeId === trackerId)
			?.trackerAbbreviation;
	};
}
