import { client } from '../client';

export type ClientProgramReferral = {
	id: number;
	programName: string;
	contentHtml: string;
	url: string | null;
	phone: string | null;
	fileUrl: string | null;
	imageUrl: string;
	logoUrl: string;
};

export type ClientProgramReferralsResponse = ClientProgramReferral[];

export async function getClientProgramReferrals() {
	const response = await client.get('client-program-referrals');
	return response.data as ClientProgramReferralsResponse;
}
