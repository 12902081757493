import { CoachType } from '@mobe/api/guides/guidesApi';
import CardButton from '@mobe/components/cardButton/CardButton';
import Dismissible from '@mobe/components/dismissible/Dismissible';
import GuideMatchGraphic from '@mobe/components/graphics/GuideMatchGraphic';
import Heading from '@mobe/components/heading/Heading';
import { Text } from '@mobe/components/text';
import { useHasDismissedGuideMatch } from '@mobe/utils/storage/appStateStorage';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useColorScheme from '@mobe/utils/useColorScheme';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, View, ViewStyle } from 'react-native';
import { HomeScreenNavigationProp } from '../../navigation/bottomTabs/types';
import * as HomeAnalyticsEvents from '../analyticsEvents';

export default function GuideMatchCta({ style }: { style?: StyleProp<ViewStyle> }) {
	const styleRules = useStyleRules();
	const colorScheme = useColorScheme();
	const { constrain } = useStyleHelpers();
	const { t } = useTranslation();
	const navigation = useNavigation<HomeScreenNavigationProp>();
	const [, setHasDismissedGuideMatch] = useHasDismissedGuideMatch();
	const cardBackgroundColor =
		colorScheme === 'light' ? '#A7D6E3' : styleRules.colors.cardBackground;
	const graphicBackgroundColor =
		colorScheme === 'light' ? styleRules.colors.cardBackground : styleRules.colors.background;

	function handlePress() {
		HomeAnalyticsEvents.guideMatchCtaPress();
		HomeAnalyticsEvents.guideOrPharmacistMatchCtaPress();
		navigation.navigate('APPOINTMENT_SCREEN', { coachType: CoachType.GSM });
	}

	function handleDismissPress() {
		HomeAnalyticsEvents.guideMatchCtaDismiss();
		setHasDismissedGuideMatch(true);
	}

	return (
		<Dismissible onAnimationComplete={handleDismissPress}>
			{(dismiss) => (
				<CardButton
					onPress={handlePress}
					style={[{ backgroundColor: cardBackgroundColor }, style]}
					onDismissPress={dismiss}
					cardButtonLeft={
						<View style={constrain(78)}>
							<GuideMatchGraphic backgroundColor={graphicBackgroundColor} />
						</View>
					}
				>
					<Heading level="h4">{t('home.guideMatchCTA.heading')}</Heading>
					<Text>{t('home.guideMatchCTA.body')}</Text>
				</CardButton>
			)}
		</Dismissible>
	);
}
