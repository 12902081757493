import { GoalType, IGoal } from '@mobe/api/goals/goalsService';
import { Button } from '@mobe/components/button';
import Blocks from '@mobe/components/layout/Blocks';
import Row from '@mobe/components/layout/Row';
import { ModuleHeading } from '@mobe/components/moduleHeading/ModuleHeading';
import { Text } from '@mobe/components/text';
import { useAccessibilityFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useLayout from '@mobe/utils/styles/useLayout';
import { useNavigation } from '@react-navigation/native';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GestureResponderEvent, StyleProp, View, ViewStyle } from 'react-native';
import { PlanScreenNavigationProp } from '../../navigation/bottomTabs/types';
import ConnectBox from './ConnectBox';
import GoalCard from './GoalCard';
import LTGoalGraphic from './graphics/LTGoalGraphic';
import LTGoalSimpleGraphic from './graphics/LTGoalSimpleGraphic';
import STGoalGraphic from './graphics/STGoalGraphic';
import STGoalSimpleGraphic from './graphics/STGoalSimpleGraphic';

interface IGoalModuleProps {
	type: GoalType;
	goals: IGoal[];
	hasCompletedGoals: boolean;
	style?: StyleProp<ViewStyle>;
	onShowMorePress?: () => void;
	onCardPress?: (event: GestureResponderEvent) => void;
	total?: number;
}

export default function GoalModule({
	type,
	goals,
	hasCompletedGoals,
	style,
	onShowMorePress = noop,
	onCardPress = noop,
	total = 0,
}: IGoalModuleProps) {
	const { vr, vrTop, constrain, constrainText } = useStyleHelpers();
	const { t } = useTranslation();
	const { isLargeDisplay, isLargeFontScale } = useLayout();
	const navigation = useNavigation<PlanScreenNavigationProp>();
	const visualState = goals.length
		? 'hasActiveGoals'
		: hasCompletedGoals
		? 'allGoalsCompleted'
		: 'noGoals';

	const initialGoalCount = React.useRef(goals.length);
	const [ref, focusRef] = useAccessibilityFocus();

	return (
		<View style={style}>
			<ModuleHeading
				title={
					type === 'LongTerm' ? t('plan.longTermGoalsHeading') : t('plan.shortTermGoalsHeading')
				}
				buttonTitle={t('plan.viewHistoryButton')}
				onButtonPress={() => navigation.navigate('GOAL_HISTORY_SCREEN')}
				showButton={hasCompletedGoals}
				style={vr(visualState !== 'noGoals' ? 2 : 5)}
			/>
			{visualState !== 'noGoals' && (
				<Text style={vr(5)}>
					{type === 'LongTerm'
						? t('plan.longTermGoalsSubHeading')
						: t('plan.shortTermGoalsSubHeading')}
				</Text>
			)}
			{visualState === 'hasActiveGoals' && (
				<>
					<Blocks blocksPerRow={isLargeDisplay ? 3 : isLargeFontScale ? 1 : 2}>
						{goals.map((goal, index) => (
							<View key={goal.id} style={{ flexGrow: 1 }}>
								<GoalCard
									goalData={goal}
									onPress={(event) => {
										onCardPress(event);
										navigation.navigate('GOAL_DETAIL_SCREEN', { goalId: goal.id });
									}}
								/>
								{index === initialGoalCount.current - 1 && <View ref={ref} accessible focusable />}
							</View>
						))}
					</Blocks>
					{total > goals.length && (
						<Button
							title={t('plan.viewAllButton')}
							variant="secondary"
							style={[vrTop(6), { alignSelf: 'center' }]}
							onPress={() => {
								focusRef();
								onShowMorePress();
							}}
							size="sm"
						/>
					)}
				</>
			)}
			{visualState === 'allGoalsCompleted' && (
				<ConnectBox
					title={
						type === 'LongTerm'
							? t('plan.connectBox.longTermGoal')
							: t('plan.connectBox.shortTermGoal')
					}
					graphic={type === 'LongTerm' ? <LTGoalSimpleGraphic /> : <STGoalSimpleGraphic />}
				/>
			)}
			{visualState === 'noGoals' && (
				<Row>
					<Row.Item>
						<View style={constrain(isLargeDisplay ? 100 : 70)}>
							{type === 'LongTerm' ? <LTGoalGraphic /> : <STGoalGraphic />}
						</View>
					</Row.Item>
					<Row.Item fill>
						<Text
							color="light"
							style={[!isLargeDisplay && constrainText(250), { alignSelf: 'flex-start' }]}
						>
							{type === 'LongTerm'
								? t('plan.longTermGoalDescription')
								: t('plan.shortTermGoalDescription')}
						</Text>
					</Row.Item>
				</Row>
			)}
		</View>
	);
}
