import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Heading from '../heading/Heading';
import { HeadingLevels } from '../heading/headingStyles';
import Row, { RowAlign } from '../layout/Row';
import NumberPill from '../numberPill/NumberPill';
import TextButton from '../textButton/TextButton';

interface IModuleHeadingProps {
	title: string;
	count?: number;
	buttonTitle?: string;

	/**
	 * Optional display condition for the button.
	 * By default, the button will show as long as the title and callback are provided.
	 */
	showButton?: boolean;
	headingLevel?: HeadingLevels;
	semanticLevel?: 1 | 2 | 3 | 4 | 5 | 6;
	rowAlign?: RowAlign;
	onButtonPress?: () => void;
	style?: StyleProp<ViewStyle>;
	autoFocusHeading?: boolean;
}

export function ModuleHeading({
	title,
	count,
	buttonTitle,
	showButton = true,
	headingLevel = 'h2',
	semanticLevel = 2,
	rowAlign = 'middle',
	onButtonPress,
	style,
	autoFocusHeading = false,
}: IModuleHeadingProps) {
	const shouldShowButton = Boolean(showButton && buttonTitle && onButtonPress);

	return (
		<Row style={style} align={rowAlign}>
			<Row.Item fill>
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					<Heading
						level={headingLevel}
						semanticLevel={semanticLevel}
						accessibilityAutoFocus={autoFocusHeading}
						style={{ flexShrink: 1 }}
					>
						{title}
					</Heading>
					{Boolean(count) && (
						<NumberPill number={count || 0} size="small" style={{ marginLeft: 8 }} />
					)}
				</View>
			</Row.Item>
			{shouldShowButton && (
				<Row.Item>
					<TextButton title={buttonTitle || ''} onPress={onButtonPress} />
				</Row.Item>
			)}
		</Row>
	);
}
