import * as React from 'react';

const Context = React.createContext(false);

function EligibilityGuideMatchProvider({ children }: { children: React.ReactNode }) {
	return <Context.Provider value={true}>{children}</Context.Provider>;
}

function useIsEligibilityGuideMatchContext() {
	return React.useContext(Context);
}

export { EligibilityGuideMatchProvider, useIsEligibilityGuideMatchContext };
