import { ActivityType, IActivityFull } from '@mobe/api/activities/activitiesService';
import { useGuideQuery } from '@mobe/api/guides/guidesApiHooks';
import { useHasDismissedGuideMatch } from '@mobe/utils/storage/appStateStorage';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import { useTranslation } from 'react-i18next';

export default function useInAppActivities(): IActivityFull[] {
	const { t } = useTranslation();
	const [hasDismissedGuideMatch] = useHasDismissedGuideMatch();
	const remoteConfigData = useRemoteConfigData();
	const guideQuery = useGuideQuery();

	// Activity is hidden (marked as complete) if the Guide match CTA has not been dismissed
	const shouldHideGuideMatch =
		!hasDismissedGuideMatch && remoteConfigData.reduceHomeComplexityVariant;

	return [
		{
			id: -1,
			title: t('activities.activityCard.inAppActivities.guideMatch'),
			type: ActivityType.GuideMatch,
			sharedContentType: null,
			timeToConsumeInMinutes: 2,
			activityTargetId: '',
			activityTargetUrl: null,
			description: '',
			dateCompleted: '',

			// In app activities are never displayed in history, so it's safe to use isCompleted as a
			// simple visibility boolean without concern over actual completion status
			isCompleted: Boolean(guideQuery.data) || shouldHideGuideMatch,
			note: null,
		},
	];
}
