import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { Pressable, PressableProps, StyleSheet, View } from 'react-native';
import Text from '../text/Text';

enum RadioColors {
	Stroke = 'stroke',
	Primary = 'primary',
}

type RadioColor = `${RadioColors}`;

interface IRadioProps extends PressableProps {
	checked: boolean;
	label?: string | (() => React.ReactNode);
	disabled?: boolean;

	/**
	 * Only use 'stroke' if additional visual context is provided around Radio,
	 * otherwise Radio lacks sufficient color contrast.
	 */
	color?: RadioColor;

	/**
	 * Make this control behave as a visual element
	 */
	displayOnly?: boolean;
}

export default function Radio({
	checked,
	label = undefined,
	disabled = false,
	color = 'primary',
	displayOnly = false,
	...pressableProps
}: IRadioProps) {
	const styles = useRadioStyles({ checked, disabled, color });

	function renderControl() {
		return (
			<View style={styles.radioOuter}>
				<View style={styles.radioInner}></View>
			</View>
		);
	}

	function renderLabel() {
		return (
			label && (
				<View style={styles.label}>
					{typeof label === 'function' ? (
						label()
					) : typeof label === 'string' ? (
						<Text size="md" weight="semiBold">
							{label}
						</Text>
					) : null}
				</View>
			)
		);
	}

	if (displayOnly) {
		return (
			<View style={styles.container}>
				{renderControl()}
				{renderLabel()}
			</View>
		);
	}

	return (
		<Pressable
			style={styles.container}
			disabled={disabled}
			aria-label={typeof label === 'string' ? label : undefined}
			role="radio"
			aria-checked={checked}
			aria-disabled={disabled}
			hitSlop={20}
			{...pressableProps}
		>
			{renderControl()}
			{renderLabel()}
		</Pressable>
	);
}

export function useRadioStyles({
	checked,
	disabled,
	color,
}: {
	checked: boolean;
	disabled: boolean;
	color: RadioColor;
}) {
	const styleRules = useStyleRules();

	return StyleSheet.create({
		container: {
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			alignSelf: 'flex-start',
		},
		radioOuter: {
			justifyContent: 'center',
			alignItems: 'center',
			width: 24,
			height: 24,
			backgroundColor: disabled ? styleRules.colors.fieldDisabledBackground : 'transparent',
			borderWidth: 2,
			borderRadius: 50,
			borderColor: {
				[RadioColors.Primary]: disabled ? styleRules.colors.strokeLight : styleRules.colors.primary,
				[RadioColors.Stroke]: checked ? styleRules.colors.primary : styleRules.colors.stroke,
			}[color],
		},
		radioInner: {
			width: 14,
			height: 14,
			backgroundColor:
				checked && disabled
					? styleRules.colors.textLight
					: checked
					? styleRules.colors.primary
					: 'transparent',
			borderRadius: 50,
		},
		label: {
			marginLeft: 6,
		},
	});
}
