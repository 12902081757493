import { CelebrationsImageType } from '@mobe/api/celebrations/celebrationsService';
import {
	useGoalDetailQuery,
	useInvalidateGoalDetailQuery,
	useInvalidateGoalsQuery,
	useOptimisticallyUpdateActionStep,
	useSetGoalStatusMutation,
	useSetSubStepOptions,
	useSetSubStepStatusMutation,
} from '@mobe/api/goals/goalsApiHooks';
import { IActionStep } from '@mobe/api/goals/goalsService';
import { useAppFeedback } from '@mobe/components/appFeedbackPopup/AppFeedbackProvider';
import ChatButton from '@mobe/components/chatButton/ChatButton';
import { useCongratsPopupStore } from '@mobe/components/congratsPopup/useCongratsPopupStore';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import Row from '@mobe/components/layout/Row';
import VrArray from '@mobe/components/layout/VrArray';
import { ModuleHeading } from '@mobe/components/moduleHeading/ModuleHeading';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useLayout from '@mobe/utils/styles/useLayout';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import useRefetchStaleQueryOnFocusEffect from '@mobe/utils/useRefetchStaleQueryOnFocusEffect';
import { useFocusEffect } from '@react-navigation/native';
import { max } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import {
	GoalDetailScreenNavigationProp,
	GoalDetailScreenRouteProp,
} from '../../navigation/modal/types';
import * as PlanAnalyticsEvents from '../analyticsEvents';
import ActionStepCard from '../components/ActionStepCard';
import CompletionButton from '../components/CompletionButton';
import PathwayIcon from '../components/PathwayIcon';
import TargetDate from '../components/TargetDate';
import TrackerModule from '../components/TrackerModule';
import GoalDetailGraphic from '../components/graphics/GoalDetailGraphic';

export const GOAL_DETAIL_ID_PARAM_KEY = 'goalId';

export interface IGoalDetailScreenParams {
	[GOAL_DETAIL_ID_PARAM_KEY]: number;
}

interface IGoalDetailScreenProps {
	navigation: GoalDetailScreenNavigationProp;
	route: GoalDetailScreenRouteProp;
}

export default function GoalDetailScreen({ route, navigation }: IGoalDetailScreenProps) {
	const styleRules = useStyleRules();
	const styles = useStyles();
	const { t } = useTranslation();
	const { wrapper, vr, vrTop } = useStyleHelpers();
	const genericErrorAlert = useGenericErrorAlert();
	const goalId = route.params[GOAL_DETAIL_ID_PARAM_KEY];
	const goalQuery = useGoalDetailQuery(goalId);
	const setGoalStatusMutation = useSetGoalStatusMutation();
	const setSubStepStatusMutation = useSetSubStepStatusMutation();
	const setSubStepOptions = useSetSubStepOptions();
	const invalidateGoalDetailQuery = useInvalidateGoalDetailQuery();
	const invalidateGoalsQuery = useInvalidateGoalsQuery();
	const optimisticallyUpdateActionStep = useOptimisticallyUpdateActionStep();
	const appFeedback = useAppFeedback();
	const congratsPopupStore = useCongratsPopupStore();

	// Sorts action steps so that completed ones come first. Action steps are only re-sorted if the
	// array length changes. This prevents immediate re-sorting upon updating status.
	const sortedStaticActionSteps = React.useMemo(() => {
		return goalQuery.data
			? [...(goalQuery.data?.actions || [])].sort((actionStep) =>
					actionStep.status === 'Completed' ? 1 : -1
			  )
			: [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalQuery.data?.actions.length]);

	useRefetchStaleQueryOnFocusEffect(goalQuery);

	useFocusEffect(
		React.useCallback(() => {
			if (!goalQuery.data?.goalType) return;

			navigation.setOptions({
				title:
					goalQuery.data?.goalType === 'LongTerm'
						? t('plan.goalDetail.longTermScreenTitle')
						: t('plan.goalDetail.shortTermScreenTitle'),
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [goalQuery.data?.goalType])
	);

	function markGoalAsInProgress() {
		setGoalStatusMutation.mutate(
			{ goalId, goalStatus: 'InProgress' },
			{
				onError: () => genericErrorAlert(),
				onSettled: () => {
					invalidateGoalDetailQuery(goalId);
					invalidateGoalsQuery(goalQuery.data?.goalType);
				},
			}
		);
	}

	function handleCompleteGoalPress() {
		setGoalStatusMutation.mutate(
			{ goalId, goalStatus: 'Completed' },
			{
				onSuccess: () => {
					congratsPopupStore.setMessage({
						title: t('plan.congratsPopup.shortTermGoalComplete.title'),
						message: t('plan.congratsPopup.shortTermGoalComplete.message'),
						imageType: CelebrationsImageType.STGoalComplete,
						hasConfetti: true,
						onClose: () => {
							navigation.pop();
							goalQuery.refetch();
							invalidateGoalsQuery(goalQuery.data?.goalType);
							appFeedback.show();
						},
						onUndo: markGoalAsInProgress,
					});
				},
				onError: () => genericErrorAlert(),
			}
		);
	}

	function handleAddEntryPress() {
		if (!goalQuery.data?.trackerAbbreviation) return;
		navigation.navigate('TRACK_ENTRY_MODAL_SCREEN', {
			trackerAbbreviation: goalQuery.data?.trackerAbbreviation,
			displaySuccessToast: true,
		});
	}

	function handleViewDetailPress() {
		if (!goalQuery.data?.trackerAbbreviation) return;
		navigation.navigate('TRACK_DETAIL_SCREEN', {
			trackerAbbreviation: goalQuery.data?.trackerAbbreviation,
		});
	}

	function handleActionStepCompletePress(actionStep: IActionStep) {
		optimisticallyUpdateActionStep({
			id: actionStep.id,
			subStepsComplete: actionStep.subStepsComplete + 1,
			goalId,
		});
		setSubStepStatusMutation.mutate(
			{ actionStepId: actionStep.id, status: 'Completed' },
			setSubStepOptions({
				actionStep,
				onUndo: goalQuery.refetch,
				onSuccessSettled: () => {
					PlanAnalyticsEvents.actionStepCompletePressGoal();
					goalQuery.refetch();
				},
			})
		);
	}

	return (
		<ScreenTemplateWithFooter
			noPadding
			fullWidth
			scrollViewBackgroundColor={styleRules.colors.background}
			footer={
				<DeferredLoadingIndicator isLoading={goalQuery.isFetching} deferDuration={5000}>
					{goalQuery.isSuccess && <TargetDate date={new Date(goalQuery.data.endDate)} />}
				</DeferredLoadingIndicator>
			}
			footerStyle={styles.footer}
		>
			<DeferredLoadingIndicator isLoading={goalQuery.isFetching}>
				{goalQuery.isSuccess && (
					<>
						<View
							style={[
								styles.hero,
								goalQuery.data.goalStatus === 'Completed' && styles.hero_completed,
							]}
						>
							<View style={styles.heroGraphic}>
								<GoalDetailGraphic
									goalType={goalQuery.data.goalType}
									goalStatus={goalQuery.data.goalStatus}
								/>
							</View>
							<View style={styles.heroPathway}>
								{goalQuery.data.pathwayName !== null && (
									<PathwayIcon
										pathway={goalQuery.data.pathwayName}
										size={25}
										style={{ opacity: goalQuery.data.goalStatus === 'Completed' ? 0.5 : 1 }}
										color={
											goalQuery.data.goalStatus === 'Completed'
												? 'textLight'
												: goalQuery.data.goalType === 'LongTerm'
												? 'textInverted'
												: 'primaryLight'
										}
									/>
								)}
							</View>
							<View style={styles.heroBody}>
								<Text
									accessibilityAutoFocus
									size={goalQuery.data.goalType === 'LongTerm' ? 'xl' : 'lg'}
									weight={goalQuery.data.goalType === 'LongTerm' ? 'bold' : 'medium'}
									align="center"
									role="heading"
									aria-level="2"
								>
									{goalQuery.data.title}
								</Text>
							</View>
						</View>
						{goalQuery.data.goalStatus === 'InProgress' &&
							goalQuery.data.goalType === 'ShortTerm' && (
								<View style={styles.completeButtonWrapper}>
									<CompletionButton
										onPressAnimationComplete={handleCompleteGoalPress}
										disabled={setGoalStatusMutation.isPending}
										style={styles.completeButton}
									>
										{(renderCheckmark) => (
											<Row>
												<Row.Item>{renderCheckmark()}</Row.Item>
												<Row.Item>
													<Text weight="semiBold">
														{t('plan.goalDetail.completeShortTermGoalButton')}
													</Text>
												</Row.Item>
											</Row>
										)}
									</CompletionButton>
								</View>
							)}
						<View style={wrapper}>
							{goalQuery.data.actions.length > 0 && (
								<>
									<ModuleHeading
										title={t('plan.goalDetail.actionStepsHeading')}
										count={goalQuery.data.actions.length}
										style={vr(5)}
									/>
									<VrArray increment={4}>
										{sortedStaticActionSteps.map((staticActionStep) => {
											const actionStep = goalQuery.data.actions.find(
												(action) => action.id === staticActionStep.id
											);
											return actionStep ? (
												<ActionStepCard
													key={actionStep.id}
													actionStepData={actionStep}
													isComplete={actionStep.status === 'Completed'}
													onPress={() =>
														navigation.navigate('ACTION_STEP_DETAIL_SCREEN', {
															actionStepId: actionStep.id,
														})
													}
													onCompletePress={() => handleActionStepCompletePress(actionStep)}
												/>
											) : null;
										})}
									</VrArray>
								</>
							)}
							{goalQuery.data.trackerAbbreviation && (
								<TrackerModule
									trackerAbbreviation={goalQuery.data.trackerAbbreviation}
									startDate={new Date(goalQuery.data.startDate)}
									endDate={max([new Date(goalQuery.data.endDate), new Date(Date.now())])}
									onAddEntryPress={handleAddEntryPress}
									onViewDetailPress={handleViewDetailPress}
									style={vrTop(10)}
								/>
							)}
						</View>
					</>
				)}
			</DeferredLoadingIndicator>
		</ScreenTemplateWithFooter>
	);
}

function useStyles() {
	const rules = useStyleRules();
	const { isLargeDisplay } = useLayout();

	return StyleSheet.create({
		hero: {
			width: '100%',
			minHeight: isLargeDisplay ? 250 : 210,
			justifyContent: 'center',
			alignItems: 'center',
			paddingTop: 25,
			paddingBottom: 40,
			backgroundColor: rules.colors.backgroundPrimary,
		},
		heroBody: {
			paddingHorizontal: 20,
			maxWidth: rules.spacing.maxWidthNarrow,
		},
		hero_completed: {
			backgroundColor: rules.colors.cardBackground,
		},
		heroGraphic: {
			position: 'absolute',
			bottom: 0,
			width: '210%',
			maxWidth: 800,
		},
		heroPathway: {
			position: 'absolute',
			bottom: 0,
			width: '100%',
			maxWidth: 500,
			padding: 15,
			alignItems: 'flex-end',
		},
		completeButtonWrapper: {
			flexDirection: 'row',
			justifyContent: 'center',
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			borderBottomWidth: 1,
			borderColor: rules.colors.strokeLight,
			backgroundColor: rules.colors.cardBackground,
		},
		completeButton: {
			flexGrow: isLargeDisplay ? 0 : 1,
			paddingVertical: 16,
		},
		footer: {
			backgroundColor: rules.colors.background,
			borderTopWidth: 0,
		},
	});
}

export function useGoalDetailScreenOptions() {
	const subScreenStyleProps = useSubScreenStyleProps();

	return () => ({
		title: '',
		headerRight: () => <ChatButton />,
		...subScreenStyleProps,
	});
}
