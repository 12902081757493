import { IContentModule } from '@mobe/api/explore/exploreService';
import { Button } from '@mobe/components/button';
import Dismissible from '@mobe/components/dismissible/Dismissible';
import Heading from '@mobe/components/heading/Heading';
import IconButton from '@mobe/components/iconButton/IconButton';
import MobeImage from '@mobe/components/mobeImage/MobeImage';
import { Text } from '@mobe/components/text';
import { useDismissedContentModuleIds } from '@mobe/utils/storage/appStateStorage';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import Color from 'color';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	GestureResponderEvent,
	Platform,
	Pressable,
	StyleProp,
	StyleSheet,
	useWindowDimensions,
	View,
	ViewStyle,
} from 'react-native';

interface IContentModuleCardProps {
	onPress?: (contentModuleId: number, event: GestureResponderEvent) => void;
	onDismiss?: (contentModuleId: number) => void;
	dismissable?: boolean;
	contentModule: IContentModule;
	style?: StyleProp<ViewStyle>;
}

export default function ContentModuleCard({
	onPress = noop,
	onDismiss = noop,
	dismissable = false,
	contentModule,
	style,
}: IContentModuleCardProps) {
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();
	const windowDimensions = useWindowDimensions();
	const styleRules = useStyleRules();
	const styles = useStyles(contentModule.backgroundColor);
	const { colors } = useStyleRules();
	const textColorInverted =
		Color(contentModule.backgroundColor).contrast(Color(colors.textInverted)) > 4.5;
	const [, addDismissedContentModuleId] = useDismissedContentModuleIds();

	function handleOnPress(event: GestureResponderEvent) {
		onPress(contentModule.contentModuleId, event);
	}

	function handleOnAnimationComplete() {
		addDismissedContentModuleId(contentModule.contentModuleId);
	}

	return (
		<Dismissible onAnimationComplete={handleOnAnimationComplete}>
			{(dismiss) => (
				<Pressable
					onPress={handleOnPress}
					style={[styles.pressable, style]}
					role={Platform.OS !== 'web' ? 'button' : undefined}
				>
					<View style={styles.card}>
						<View style={styles.cardMedia}>
							<MobeImage
								style={{ width: '100%', aspectRatio: 1 }}
								source={{ uri: contentModule.imageUrl }}
								renderWidth={Math.min(
									200,
									(windowDimensions.width - styleRules.spacing.appHorizontalMargin * 2) / 2
								)}
								sizesArray={[200, 300, 400]}
								resizeMode="contain"
								useIntrinsicAspectRatio
							/>
						</View>
						<View style={styles.cardBody} accessible>
							<Heading color={textColorInverted ? 'inverted' : 'regular'} style={vr(1)}>
								{contentModule.title}
							</Heading>
							<Text
								weight="medium"
								color={textColorInverted ? 'inverted' : 'regular'}
								style={vr(4)}
							>
								{contentModule.shortDescription}
							</Text>
							<Button
								size="sm"
								title={contentModule.button}
								variant={textColorInverted ? 'outlineInverted' : 'outline'}
								onPress={handleOnPress}
							/>
						</View>
						{dismissable && (
							<IconButton
								style={styles.cardCloseButton}
								aria-label={t('accessibility.dismiss')}
								onPress={() => {
									onDismiss(contentModule.contentModuleId);
									dismiss();
								}}
								size={10}
								name="close"
							/>
						)}
					</View>
				</Pressable>
			)}
		</Dismissible>
	);
}

function useStyles(backgroundColor?: string) {
	const rules = useStyleRules();
	const { cardShadow } = useStyleHelpers();

	return StyleSheet.create({
		pressable: {
			alignSelf: 'center',
			width: '100%',
			maxWidth: 520,
			flexGrow: 1,
			flexShrink: 1,
		},
		card: {
			justifyContent: 'center',
			minHeight: 270,
			borderRadius: rules.borderRadius,
			backgroundColor,
			...cardShadow,
		},
		cardMedia: {
			overflow: 'hidden',
			position: 'absolute',
			right: 0,
			bottom: 0,
			width: '50%',
			height: '100%',
			maxWidth: 200,
			alignItems: 'flex-end',
			justifyContent: 'flex-end',
			borderBottomRightRadius: rules.borderRadius,
		},
		cardBody: {
			width: '60%',
			maxWidth: 300,
			paddingLeft: 16,
			paddingVertical: 36,
		},
		cardCloseButton: {
			position: 'absolute',
			top: 10,
			left: 10,
			alignItems: 'center',
			justifyContent: 'center',
			height: 24,
			width: 24,
			borderRadius: 12,
			backgroundColor: rules.colors.background,
		},
	});
}
