import { yupResolver } from '@hookform/resolvers/yup';
import {
	AppointmentQueryKeys,
	IAppointment,
	useAppointmentsQuery,
	useAppointmentTimeSlotsQuery,
	useUpdateAppointmentMutation,
} from '@mobe/api/appointments/appointmentApiHooks';
import useRescheduleCallToast from '@mobe/components/toast/useRescheduleCallToast';
import { useAlert } from '@mobe/utils/useAlert';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import * as AppointmentsAnalyticsEvents from '../analyticsEvents';
import useDeleteFromCalendar from '../useDeleteFromCalendar';
import { IAppointmentRescheduleScreenProps } from './AppointmentRescheduleScreen';

const validationSchema = Yup.object({
	apptDate: Yup.date().required(),
});

type FormSchema = Yup.InferType<typeof validationSchema>;

export default function useAppointmentRescheduleController({
	route,
	navigation,
}: IAppointmentRescheduleScreenProps) {
	const queryClient = useQueryClient();
	const genericErrorAlert = useGenericErrorAlert();
	const { t } = useTranslation();
	const rescheduleCallToast = useRescheduleCallToast();
	const appointmentsQuery = useAppointmentsQuery();
	const allAppointments = appointmentsQuery.data?.allAppointments;
	const [appointment, setAppointment] = React.useState<IAppointment | undefined>(findAppointment);
	const guideName = appointment?.preferredName || '';
	const confirmationId = appointment?.confirmationId;
	const deleteFromCalendar = useDeleteFromCalendar(appointment, guideName);
	const [isRescheduling, setIsRescheduling] = React.useState(false);

	const updateAppointmentMutation = useUpdateAppointmentMutation();
	const apptDatesQuery = useAppointmentTimeSlotsQuery(appointment || null, false, confirmationId);
	const initialApptDate =
		(appointment?.appointmentStartDate && new Date(appointment?.appointmentStartDate)) || undefined;
	const { mobeAlert } = useAlert();

	const form = useForm<FormSchema>({
		mode: 'onChange',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			apptDate: initialApptDate || new Date(Date.now()),
		},
	});
	const apptDateControl = useController({ name: 'apptDate', control: form.control });

	const [hasError, setHasError] = React.useState(false);
	React.useEffect(() => {
		// prevent stacked errors
		if (hasError) return;
		if (
			apptDatesQuery.isError ||
			appointmentsQuery.status === 'error' ||
			(appointmentsQuery.status === 'success' && !initialApptDate)
		) {
			setHasError(true);
			genericErrorAlert(navigation.popToTop);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apptDatesQuery.isError, appointmentsQuery.status, initialApptDate]);

	React.useEffect(() => {
		const appt = findAppointment();
		if (appt) {
			setAppointment(appt);
		}
	}, [allAppointments]);

	function findAppointment() {
		return allAppointments?.find(
			(appointment) => appointment.confirmationId === route.params.confirmationId
		);
	}

	// Get the first appt time slot duration in minutes
	function getApptDurationInMinutes() {
		if (apptDatesQuery.data) {
			return apptDatesQuery.data.appointmentDurationInMinutes;
		}
		return 0;
	}

	function handleNoTimeSlotsRetryPress() {
		apptDatesQuery.refetch();
	}

	function confirmRescheduleAlert(onConfirm: () => void) {
		mobeAlert(
			t('appointments.reschedule.rescheduleApptAlert.title'),
			t('appointments.reschedule.rescheduleApptAlert.description', {
				date: format(apptDateControl.field.value, 'EEEE, MMMM d'),
				time: format(apptDateControl.field.value, 'p'),
			}),
			[
				{
					text: t('appointments.reschedule.cancelApptAlert.deny'),
					style: 'cancel',
					onPress: noop,
				},
				{
					text: t('appointments.reschedule.cancelApptAlert.confirm'),
					onPress: onConfirm,
				},
			]
		);
	}

	function handleSubmitPress() {
		form.handleSubmit(submit)();
	}

	async function submit(formValues: FormSchema) {
		if (!confirmationId) {
			genericErrorAlert();
			return;
		}

		confirmRescheduleAlert(async () => {
			setIsRescheduling(true);

			updateAppointmentMutation.mutate(
				{
					confirmationId,
					updatedAppointmentPayload: {
						startDate: formValues.apptDate.toISOString(),
						timeZoneOffsetInMinutes: -new Date(Date.now()).getTimezoneOffset(),
					},
				},
				{
					onSuccess: async () => {
						rescheduleCallToast();
						AppointmentsAnalyticsEvents.appointmentRescheduled();
						deleteFromCalendar();
						queryClient.invalidateQueries({ queryKey: [AppointmentQueryKeys.AllAppointments] });
						navigation.pop();
					},
					onError: () => genericErrorAlert(navigation.popToTop),
				}
			);
		});
	}

	return {
		form,
		apptDateControl,
		apptDatesQuery,
		isRescheduling,
		initialApptDate,
		guideName,
		getApptDurationInMinutes,
		handleNoTimeSlotsRetryPress,
		handleSubmitPress,
	};
}
