import { AccountRegistrationErrorCode } from '@mobe/api/account/accountService';
import Box from '@mobe/components/box/Box';
import Text from '@mobe/components/text/Text';
import TextButton from '@mobe/components/textButton/TextButton';
import { useAlert } from '@mobe/utils/useAlert';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	ClaimAccountScreenNavigationProp,
	ClaimAccountScreenRouteProp,
} from '../AuthenticationStackScreen';
import AuthScreenTemplate from '../components/authScreenTemplate/AuthScreenTemplate';
import ClaimAccountForm from './ClaimAccountForm';

export interface IClaimAccountScreenProps {
	navigation: ClaimAccountScreenNavigationProp;
	route: ClaimAccountScreenRouteProp;
}

export default function ClaimAccountScreen({ navigation, route }: IClaimAccountScreenProps) {
	const { mobeAlert } = useAlert();
	const { t } = useTranslation();

	function handleHaveAccountPress() {
		navigation.navigate('LOGIN_SCREEN');
	}

	function handleSubmissionError(errorCode: AccountRegistrationErrorCode) {
		if (
			errorCode === AccountRegistrationErrorCode.AccountAlreadyRegistered ||
			errorCode === AccountRegistrationErrorCode.EmailAlreadyRegistered
		) {
			mobeAlert('', t('auth.claimAccount.errors.accountAlreadyExists'), [
				{
					text: t('forms.genericServerErrorAlert.confirm'),
					onPress: noop,
				},
			]);
		} else if (errorCode !== AccountRegistrationErrorCode.None) {
			mobeAlert('', t('auth.claimAccount.errors.default'), [
				{
					text: t('forms.genericServerErrorAlert.confirm'),
					onPress: noop,
				},
			]);
		}
	}

	return (
		<AuthScreenTemplate
			heading={t('auth.claimAccount.heading')}
			header={
				<Box row>
					<Text>{t('auth.claimAccount.haveAccount')} </Text>
					<TextButton
						onPress={handleHaveAccountPress}
						title={t('auth.claimAccount.haveAccountButton')}
					></TextButton>
				</Box>
			}
		>
			<ClaimAccountForm
				accessToken={route.params.accessToken}
				onSubmissionError={handleSubmissionError}
			/>
		</AuthScreenTemplate>
	);
}
