import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { FlexAlignType, StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native';

export type RowAlign = 'top' | 'middle' | 'bottom';

interface IRowProps extends ViewProps {
	gutterSize?: number;
	children: React.ReactElement<IRowItemProps> | Array<React.ReactElement<IRowItemProps> | boolean>;
	style?: StyleProp<ViewStyle>;
	align?: RowAlign;
	wrap?: boolean;
}

function Row({
	gutterSize = 16,
	children,
	style,
	align = 'middle',
	wrap = false,
	...viewProps
}: IRowProps) {
	const { notLastChild } = useStyleHelpers();
	const styles = StyleSheet.create({
		row: {
			flexDirection: 'row',
			flexWrap: wrap ? 'wrap' : 'nowrap',
			alignItems: {
				top: 'flex-start',
				middle: 'center',
				bottom: 'flex-end',
			}[align] as FlexAlignType,
		},
	});

	return (
		<View {...viewProps} style={[styles.row, style]}>
			{Array.isArray(children) ? (
				children
					.filter((child) => child)
					.map((child, index) => {
						return (
							<RowItem
								key={index}
								{...(typeof child !== 'boolean' && child.props)}
								style={notLastChild(children.length, index, { marginRight: gutterSize })}
							/>
						);
					})
			) : (
				<RowItem {...children.props} />
			)}
		</View>
	);
}

interface IRowItemProps extends ViewProps {
	children?: React.ReactNode;
	fill?: boolean;
	style?: StyleProp<ViewStyle>;
}

function RowItem({ children, fill = false, style = {}, ...viewProps }: IRowItemProps) {
	return (
		<View
			{...viewProps}
			style={[
				{ flexShrink: fill ? 1 : 0, flexGrow: fill ? 1 : 0, flexBasis: fill ? 0 : undefined },
				style,
			]}
		>
			{children}
		</View>
	);
}

Row.Item = RowItem;

export default Row;
