import {
	useContentDetailQuery,
	useSetIsHelpfulStatusMutation,
} from '@mobe/api/explore/exploreApiHooks';
import Heading from '@mobe/components/heading/Heading';
import Icon from '@mobe/components/icon/Icon';
import Row from '@mobe/components/layout/Row';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import LikeDislikeButton from './LikeDislikeButton';

interface IIsHelpfulModuleProps {
	contentId: number;
}

function IsHelpfulModule({ contentId }: IIsHelpfulModuleProps) {
	const remoteConfigData = useRemoteConfigData();
	const threshold = remoteConfigData.contentLikesMinCountThreshold;
	const { t } = useTranslation();
	const rules = useStyleRules();
	const styles = useStyles();
	const { vr } = useStyleHelpers();

	const setIsHelpfulStatusMutation = useSetIsHelpfulStatusMutation();
	const contentDetailQuery = useContentDetailQuery(contentId);
	const isHelpful = contentDetailQuery.data?.isHelpful ?? null;
	const helpfulCount = contentDetailQuery.data?.helpfulCount;

	const voteFeedbackTitleStyle = {
		container: { borderColor: isHelpful ? rules.colors.success : rules.colors.error },
		title: {
			color: isHelpful ? rules.colors.successDark : rules.colors.error,
		},
	};

	const voteFeedbackTitleContent =
		isHelpful && typeof helpfulCount === 'number' && helpfulCount > threshold
			? t('likeDislikeArticle.likeWithCount', { count: helpfulCount - 1 }) // -1 to remove current ppt from total..
			: t('likeDislikeArticle.thanksForFeedback');

	const handleLikeDislikeButtonPress = (isHelpful: boolean) => {
		return () => {
			setIsHelpfulStatusMutation.mutate({
				sharedContentId: contentId,
				isHelpful,
			});
		};
	};

	return (
		<View style={styles.wrapper}>
			<Heading align="center" level="h3" style={vr(2)}>
				{t('likeDislikeArticle.title')}
			</Heading>
			<Row style={styles.likeDislikeButtonsContainer}>
				{!isHelpful && (
					<Row.Item fill={isHelpful === null}>
						<LikeDislikeButton
							variant="like"
							isHelpful={isHelpful}
							isLoading={setIsHelpfulStatusMutation.isPending}
							onPress={handleLikeDislikeButtonPress(true)}
						/>
					</Row.Item>
				)}
				{isHelpful !== null && (
					<Row.Item accessible fill>
						<View style={[styles.voteFeedbackContainer, voteFeedbackTitleStyle.container]}>
							<Row gutterSize={12}>
								<Row.Item>
									{isHelpful ? (
										<Icon
											name="thumbsUp"
											accessibilityLabel={t('likeDislikeArticle.likeSelectedAccessibilityLabel')}
											aria-label={t('likeDislikeArticle.likeSelectedAccessibilityLabel')}
											size={20}
											color="success"
										/>
									) : (
										<Icon
											name="thumbsDown"
											accessibilityLabel={t('likeDislikeArticle.dislikeSelectedAccessibilityLabel')}
											aria-label={t('likeDislikeArticle.dislikeSelectedAccessibilityLabel')}
											size={20}
											color="error"
										/>
									)}
								</Row.Item>
								<Row.Item>
									<Text style={voteFeedbackTitleStyle.title}>{voteFeedbackTitleContent}</Text>
								</Row.Item>
							</Row>
						</View>
					</Row.Item>
				)}
				{isHelpful !== false && (
					<Row.Item fill={isHelpful === null}>
						<LikeDislikeButton
							variant="dislike"
							isHelpful={isHelpful}
							isLoading={setIsHelpfulStatusMutation.isPending}
							onPress={handleLikeDislikeButtonPress(false)}
						/>
					</Row.Item>
				)}
			</Row>
		</View>
	);
}

export function useStyles() {
	const rules = useStyleRules();
	const { bottom: bottomInset, top: topInset } = useSafeAreaInsets();

	return StyleSheet.create({
		wrapper: {
			paddingTop: Math.max(topInset, 48),
			marginTop: 'auto',
		},
		likeDislikeButtonsContainer: {
			width: '100%',
			maxWidth: rules.spacing.maxWidth / 2,
			alignSelf: 'center',
			paddingBottom: Math.max(bottomInset, 48),
			paddingTop: 10,
			paddingHorizontal: rules.spacing.appHorizontalMargin,
		},
		voteFeedbackContainer: {
			alignSelf: 'center',
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: Math.min(50 * rules.settings.fontScale, 60),
			width: '100%',
			maxWidth: rules.spacing.maxWidth / 2,
			paddingHorizontal: 14,
			borderWidth: 2,
			borderRadius: 5,
			backgroundColor: rules.colors.cardBackground,
		},
	});
}

export default IsHelpfulModule;
