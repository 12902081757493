import Color from 'color';
import { Platform, StyleSheet, ViewStyle } from 'react-native';
import { useStyleRules } from '../styleRules/useStyleRules';

export default function useStyleHelpers() {
	const rules = useStyleRules();

	const helperStyles = StyleSheet.create({
		wrapper: {
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			paddingVertical: rules.spacing.appVerticalMargin,
			width: '100%',
			maxWidth: rules.spacing.maxWidth,
			alignSelf: 'center',
		},
		wrapperNarrow: {
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			paddingVertical: rules.spacing.appVerticalMargin,
			width: '100%',
			maxWidth: rules.spacing.maxWidthNarrow,
			alignSelf: 'center',
		},
		wrapperHorizontal: {
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			width: '100%',
			maxWidth: rules.spacing.maxWidth,
			alignSelf: 'center',
		},
		wrapperHorizontalNarrow: {
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			width: '100%',
			maxWidth: rules.spacing.maxWidthNarrow,
			alignSelf: 'center',
		},
		wrapperVertical: {
			paddingVertical: rules.spacing.appVerticalMargin,
		},
		cardBackground: {
			backgroundColor: rules.colors.cardBackground,
		},
		background: {
			backgroundColor: rules.colors.background,
		},
		fill: {
			flex: 1,
		},
		cardShadow: {
			...(rules.settings.colorScheme === 'light'
				? {
						...Platform.select({
							native: {
								shadowOffset: {
									height: 1.5,
									width: 0,
								},
								shadowColor: rules.colors.shadow,
								shadowOpacity: 0.2,
								shadowRadius: 1.5,
								elevation: 2,
							},
							web: {
								boxShadow: `${Color(rules.colors.shadow).alpha(0.2).string()} 0 1.5px 3px`,
							},
						}),
				  }
				: {}),
		},
		popupShadow: {
			...(rules.settings.colorScheme === 'light'
				? {
						...Platform.select({
							native: {
								shadowOffset: {
									height: 2,
									width: 0,
								},
								shadowColor: rules.colors.shadow,
								shadowOpacity: 0.16,
								shadowRadius: 4,
								elevation: 4,
							},
							web: {
								boxShadow: `${Color(rules.colors.shadow).alpha(0.16).string()} 0 2px 5px`,
							},
						}),
				  }
				: {}),
		},
		headerShadow: {
			...(rules.settings.colorScheme === 'light'
				? {
						...Platform.select({
							native: {
								shadowOffset: {
									height: 2,
									width: 0,
								},
								shadowColor: rules.colors.shadow,
								shadowOpacity: 0.06,
								shadowRadius: 1,
								elevation: 2,
							},
							web: {
								boxShadow: `${Color(rules.colors.shadow).alpha(0.06).string()} 0 1px 3px`,
							},
						}),
				  }
				: {}),
		},
		topShadow: {
			...(rules.settings.colorScheme === 'light'
				? {
						...Platform.select({
							native: {
								shadowOffset: {
									height: -2,
									width: 0,
								},
								shadowColor: rules.colors.shadow,
								shadowOpacity: 0.05,
								shadowRadius: 2,
								elevation: 2,
							},
							web: {
								boxShadow: `${Color(rules.colors.shadow).alpha(0.1).string()} 0 -2px 3px`,
							},
						}),
				  }
				: {}),
		},
		noCardShadow: {
			...Platform.select({
				native: {
					shadowOpacity: 0,
					elevation: 0,
				},
				web: {
					boxShadow: 'none',
				},
			}),
		},
		// Hide elements visually but let them be seen by screen readers
		screenReaderOnly: {
			width: 1,
			height: 1,
			opacity: 0,
			position: 'absolute',
			top: 0,
			left: -1,
			overflow: 'hidden',
		},
	});

	function vr(increment: number): ViewStyle {
		return {
			marginBottom: rules.spacing.baseline * increment,
		};
	}

	function vrTop(increment: number): ViewStyle {
		return {
			marginTop: rules.spacing.baseline * increment,
		};
	}

	function constrain(maxWidth: number, center?: boolean): ViewStyle {
		const shouldCenter = center ?? true;

		return { maxWidth, alignSelf: shouldCenter ? 'center' : 'flex-start' };
	}

	/**
	 * Arbitrary width constraint specifically for text.
	 * Max container dimension will scale proportionally with font-scale.
	 */
	function constrainText(maxWidth: number, center?: boolean): ViewStyle {
		const shouldCenter = center ?? true;

		return {
			maxWidth: maxWidth * rules.settings.fontScale,
			alignSelf: shouldCenter ? 'center' : 'flex-start',
		};
	}

	function notLastChild(arrayLength: number, index: number, styleRule: ViewStyle) {
		return index < arrayLength - 1 ? styleRule : {};
	}

	return { ...helperStyles, vr, vrTop, constrain, constrainText, notLastChild };
}
