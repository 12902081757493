import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function GuideIntroGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(264, 284);
	const rules = useStyleRules();

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={rules.colors.strokeLight}
					d="M132 284c72.902 0 132-59.098 132-132S204.902 20 132 20 0 79.098 0 152s59.098 132 132 132z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FEBA8D"
					d="M94.384 199.284c-25.321-6.45-53.172-45.735-53.172-45.735l7.129-6.187c7.392 8.698 38.087 23.7 38.087 23.7-.16-7.975.045-15.232.551-21.855 3.414-44.941 20.35-59.911 24.987-63.21.737-.532 1.173-.749 1.173-.749s.006.32.038.948c.064 1.332.186 4.016.314 7.718.949 27.506 2.057 110.77-19.107 105.37z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FEBA8D"
					d="M48.342 147.363c-.096-1.057-.813-16.5-3.875-19.441-3.068-2.946-9.128-6.943-10.87-6.629-1.736.333-7.648 10.159-4.862 16.936 2.786 6.77 12.478 15.322 12.478 15.322l7.129-6.188z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#17213B"
					d="m50.308 149.35-15.13.621a1.788 1.788 0 0 1-1.858-1.71l-1.262-30.24a1.786 1.786 0 0 1 1.697-1.864l15.136-.635a1.8 1.8 0 0 1 1.858 1.704l1.262 30.266a1.79 1.79 0 0 1-1.703 1.858z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FEBA8D"
					d="M32.335 122.478s4.548-5.079 9.089-1.191c4.548 3.869-.301 12.164-4.253 14.425-3.939 2.267-5.201 3.439-5.201 3.439l.365-16.673z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#37120B"
					d="M133.061 35.242s13.618 11.603 1.133 24.952c0 0 10.396 1.015 13.932-1.87 0 0-.698 1.415-1.339 2.039 0 0 11.633.423 16.43-3.211C168.028 53.532 164.94-6 132.644.651c0 0-27.364-2.063-24.815 29.601 2.55 31.672-7.789 31.672-7.789 31.672s2.883 1.424 4.266 1.424c0 0 2.031-.776 2.428-1.906 0 0-.397 1.768-.922 2.321 0 0 11.152 1.063 16.731-3.148 5.56-4.201 12.106-18.836 10.518-25.373z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FEBA8D"
					d="M177.015 226.458c-10.89-24.399-16.091-31.297-16.27-38.971-.141-6.066 2.857-12.632 8.66-28.684 1.371-3.785 2.312-7.68 2.895-11.619 4.606-30.964-13.304-64.439-24.424-66.726-3.106-.807-8.346-11.997-8.295-30.02l-1.39.271-23.437 4.648s.538 7.368.544 14.314c.026 5.797-.294 11.684-1.608 12.382-1.684.147-3.215.455-3.215.455S90.644 96.434 89.356 115.33c-1.71 25.237 16.084 55.151.314 105.594-16.123 51.506 93.359 19.017 87.345 5.534z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#DFA37B"
					d="M114.754 55.356s.538 7.368.557 14.314c13.105-.365 20.043-12.338 22.88-18.962l-23.437 4.648z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#37120B"
					d="M140.619 48.155s6.867-10.85 8.519-20.148c1.172-6.624-2.197-31.19-26.89-27.663 0 0-7.174.708-13.919 5.265-5.361 3.624-13.099 11.447-4.439 32.876l2.248 7.244 34.481 2.426z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FEBA8D"
					d="M110.379 61.925s31.028.51 31.047-18.815c.019-19.325 4.042-32.274-15.77-33.423-19.806-1.139-23.675 6.229-25.238 12.687-1.588 6.441 1.89 38.922 9.961 39.551z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#37120B"
					d="M111.532 9.216s8.493 22.678 21.529 26.027c13.035 3.358 18.697-.138 18.697-.138s-9.999-7.951-12.068-21.81c0 0-21.759-14.874-28.158-4.079z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#37120B"
					d="M113.626 11.053s-6.347 5.067-7.878 9.991c-1.55 4.915-2.242 15.053-5.208 14.713 0 0-6.399-15.42.801-24.135 7.629-9.247 12.285-.569 12.285-.569z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FEBA8D"
					d="m217.369 252.379-9.32 2.799s-30.727-50.981-33.52-64.26c-.909-4.31-3.138-12.958-5.745-22.636-5.4-20.056-12.363-44.595-12.363-44.595l-2.299-9.935-7.821-33.872s14.976 1.531 23.745 5.188c2.888 1.217 5.111 2.665 5.873 4.362 6.451 14.137 12.875 49.854 17.385 75.335 2.517 14.201 4.439 25.225 5.444 27.563 2.787 6.514 11.325 42.365 18.621 60.051z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#37120B"
					d="M112.454 11.829s6.06 23.739 22.502 31.337c0 0-16.442-14.585-17.845-30.258-1.403-15.668-4.657-1.079-4.657-1.079z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#5078A2"
					d="M114.446 145.351c-1.306 32.06-6.117 63.805-20.062 61.774-17.122-2.498-21.574-18.479-22.483-30.349-.57-7.392.25-13.195.25-13.195s8.41-.038 9.781-1.287c6.47-5.977 4.542-64.574 7.757-70.672 3.196-6.079 23.969-9.98 23.969-9.98s2.095 32.015.788 63.709z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#305B85"
					d="M114.446 145.351c-1.306 32.059-6.117 63.805-20.062 61.774-17.122-2.498-21.574-18.479-22.483-30.349 4.196-.211 11.594-1.236 14.528-5.713 4.215-6.419 17.346-47.734 17.346-47.734s5.163 9.275 10.671 22.022z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#5078A2"
					d="M220.758 204.993c0 8.052-8.219 13.003-17.039 15.412-8.481 2.319-17.5 2.28-20.331.397-4.868-3.273-12.817-39.579-15.129-50.635-.423-2.012-.66-3.177-.66-3.177s-6.335 15.11-6.527 16.526c-.096.634-.167 1.499-.231 2.274-.064.928-.096 1.697-.096 1.697s-34.372 17.346-64.465 1.416c0 0-12.228-70.697-6.924-82.861 2.639-6.041 16.411-18.531 21.913-23.899 1.243-1.211 1.115-3.613.641-5.797-.404-1.845 1.262-3.478 3.094-3.011 3.542.922 9.909.762 16.743-1.467 3.408-1.115 6.918-2.742 10.236-5.015.961-.66 2.281-.038 2.422 1.121.518 4.215 1.793 11.914 4.118 11.914 3.209-.026 25.058 2.665 26.66 5.361.557.929 6.226 13.791 13.4 30.823l.019.038c13.465 31.952 32.156 78.577 32.156 88.883z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#305B85"
					d="M167.599 166.989s-6.335 15.11-6.527 16.526c-.097.634-.167 1.499-.231 2.274-3.062 2.492-6.764 4.394-10.531 5.541-10.716 3.241-15.379-17.295-14.726-27.544.641-10.249 15.783-39.496 20.837-40.098 5.041-.609 11.178 43.301 11.178 43.301z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#D97D3D"
					d="M168.258 170.166c-.422-2.011-.659-3.177-.659-3.177.346 1.083.557 2.14.659 3.177z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#916D53"
					d="M124.022 198.049c-19.562 0-27.319-8.09-27.921-8.544l-1.262-9.218c.192.154 26.429 15.898 67.04.814l3.856 4.881c-18.544 8.596-31.566 12.067-41.713 12.067z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#305B85"
					d="M220.758 204.993c0 8.052-8.219 13.004-17.039 15.412 12.151-14.476-14.438-102.045-15.117-104.294 13.465 31.951 32.156 78.576 32.156 88.882z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FEBA8D"
					d="M48.342 147.363c-.096-1.057-.813-16.5-3.875-19.441-3.068-2.946-9.128-6.943-10.87-6.629-1.736.333-7.648 10.159-4.862 16.936 2.786 6.77 12.478 15.322 12.478 15.322l7.129-6.188z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#334062"
					d="m53.136 149.184-15.008.815c-.49.03-.91-.48-.928-1.142l-1.198-37.794c-.035-.654.357-1.202.853-1.24l15.013-.822c.49-.023.91.479.933 1.134L54 147.929c.011.662-.363 1.21-.864 1.255z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#17213B"
					d="m50.291 149.258-15.163.74c-.982.053-1.81-.854-1.862-2.036l-1.265-35.988c-.045-1.174.719-2.157 1.701-2.218l15.17-.755c.982-.038 1.817.869 1.862 2.028l1.265 36.019c.032 1.166-.713 2.149-1.708 2.21z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FEBA8D"
					d="M32.335 122.478s4.548-5.079 9.089-1.191c4.548 3.869-.301 12.164-4.253 14.425-3.939 2.267-5.201 3.439-5.201 3.439l.365-16.673z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#37120B"
					d="M133.061 35.242s13.618 11.603 1.133 24.952c0 0 10.396 1.015 13.932-1.87 0 0-.698 1.415-1.339 2.039 0 0 11.633.423 16.43-3.211C168.028 53.532 164.94-6 132.644.651c0 0-27.364-2.063-24.815 29.601 2.55 31.672-7.789 31.672-7.789 31.672s2.883 1.424 4.266 1.424c0 0 2.031-.776 2.428-1.906 0 0-.397 1.768-.922 2.321 0 0 11.152 1.063 16.731-3.148 5.56-4.201 12.106-18.836 10.518-25.373z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#DFA37B"
					d="M114.754 55.356s.538 7.368.557 14.314c13.105-.365 20.043-12.338 22.88-18.962l-23.437 4.648z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#37120B"
					d="M140.619 48.155s6.867-10.85 8.519-20.148c1.172-6.624-2.197-31.19-26.89-27.663 0 0-7.174.708-13.919 5.265-5.361 3.624-13.099 11.447-4.439 32.876l2.248 7.244 34.481 2.426z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FEBA8D"
					d="M110.379 61.925s31.028.51 31.047-18.815c.019-19.325 4.042-32.274-15.77-33.423-19.806-1.139-23.675 6.229-25.238 12.687-1.588 6.441 1.89 38.922 9.961 39.551z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#37120B"
					d="M111.532 9.216s8.493 22.678 21.529 26.027c13.035 3.358 18.697-.138 18.697-.138s-9.999-7.951-12.068-21.81c0 0-21.759-14.874-28.158-4.079z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#37120B"
					d="M113.626 11.053s-6.347 5.067-7.878 9.991c-1.55 4.915-2.242 15.053-5.208 14.713 0 0-6.399-15.42.801-24.135 7.629-9.247 12.285-.569 12.285-.569z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#37120B"
					d="M112.454 11.829s6.06 23.739 22.502 31.337c0 0-16.442-14.585-17.845-30.258-1.403-15.668-4.657-1.079-4.657-1.079z"
				/>
				<Path
					fill="#24325A"
					d="M158.452 173.792c-.109-.32-1.659-4.01-1.698-4.471-7.962.916-12.478-13.567-19.908-14.028-3.933-.224-6.854-1.223-11.094-1.685-5.637-.634-8.59 15.175-13.945 13.49l-16.648 27.915c-7.859 17.449-14.258 35.807-13.957 54.985l.625 24.122C97.296 280.483 114.236 284 132 284c18.927 0 36.919-3.992 53.192-11.166-.787-16.935-2.208-35.193-4.622-49.304-5.189-30.381-18.307-39.496-22.118-49.738z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#5078A2"
					d="M220.758 204.993c0 8.052-8.219 13.003-17.039 15.412-8.481 2.319-17.5 2.28-20.331.397-4.868-3.273-12.817-39.579-15.129-50.635-.423-2.012-.66-3.177-.66-3.177s-6.335 15.11-6.527 16.526c-.096.634-.167 1.499-.231 2.274-.064.928-.096 1.697-.096 1.697s-34.372 17.346-64.465 1.416c0 0-12.228-70.697-6.924-82.861 2.639-6.041 16.411-18.531 21.913-23.899 1.243-1.211 1.115-3.613.641-5.797-.404-1.845 1.262-3.478 3.094-3.011 3.542.922 9.909.762 16.743-1.467 3.408-1.115 6.918-2.742 10.236-5.015.961-.66 2.281-.038 2.422 1.121.518 4.215 1.793 11.914 4.118 11.914 3.209-.026 25.058 2.665 26.66 5.361.557.929 6.226 13.791 13.4 30.823l.019.038c13.465 31.952 32.156 78.577 32.156 88.883z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#305B85"
					d="M167.599 166.989s-6.335 15.11-6.527 16.526c-.097.634-.167 1.499-.231 2.274-3.062 2.492-6.764 4.394-10.531 5.541-10.716 3.241-15.379-17.295-14.726-27.544.641-10.249 15.783-39.496 20.837-40.098 5.041-.609 11.178 43.301 11.178 43.301z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#D97D3D"
					d="M168.258 170.166c-.422-2.011-.659-3.177-.659-3.177.346 1.083.557 2.14.659 3.177z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#916D53"
					d="M124.022 198.049c-19.562 0-27.319-8.09-27.921-8.544l-1.262-9.218c.192.154 26.429 15.898 67.04.814l3.856 4.881c-18.544 8.596-31.566 12.067-41.713 12.067z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#305B85"
					d="M220.758 204.993c0 8.052-8.219 13.004-17.039 15.412 12.151-14.476-14.438-102.045-15.117-104.294 13.465 31.951 32.156 78.576 32.156 88.882z"
				/>
				<Path
					fill="none"
					d="M132 20C59.098 20 0 79.098 0 152c0 55.138 33.811 102.371 81.827 122.12l-.625-24.122c-.301-19.178 6.098-37.536 13.957-54.985l16.648-27.915c5.355 1.685 8.308-14.124 13.945-13.49 4.24.462 7.161 1.461 11.094 1.685 7.43.461 11.946 14.944 19.908 14.028.039.461 1.589 4.151 1.698 4.471 3.811 10.242 16.929 19.357 22.118 49.738 2.414 14.111 3.835 32.369 4.622 49.304C231.6 252.375 264 205.975 264 152c0-72.902-59.098-132-132-132z"
				/>
				<Path
					fill="none"
					d="m208.049 255.179 8.654-4.349s.302.708.807 1.721C245.953 228.339 264 192.28 264 152c0-72.902-59.098-132-132-132S0 79.098 0 152s59.098 132 132 132c29.088 0 55.972-9.416 77.787-25.355-.883-1.787-1.738-3.466-1.738-3.466z"
				/>
				<Path
					fill="#FEBA8D"
					d="M208.049 255.179s.855 1.679 1.738 3.466a132.477 132.477 0 0 0 7.723-6.094c-.505-1.013-.807-1.721-.807-1.721l-8.654 4.349z"
				/>
			</Svg>
		</View>
	);
}
