import { Button } from '@mobe/components/button';
import Heading from '@mobe/components/heading/Heading';
import OrderedListItem from '@mobe/components/orderedList/OrderedList';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	OnboardingConfirmationScreenNavigationProp,
	OnboardingConfirmationScreenRouteProp,
} from '../OnboardingStackScreen';
import OnboardingConfirmationGraphic from '../components/graphics/OnboardingConfirmationGraphic';

interface IOnboardingConfirmationScreenProps {
	navigation: OnboardingConfirmationScreenNavigationProp;
	route: OnboardingConfirmationScreenRouteProp;
}

export default function OnboardingConfirmationScreen({
	navigation,
}: IOnboardingConfirmationScreenProps) {
	const { constrainText, constrain, vr } = useStyleHelpers();
	const parentNavigation = navigation.getParent();
	const { t } = useTranslation();

	useFocusEffect(
		React.useCallback(() => {
			if (!parentNavigation) {
				return;
			}

			parentNavigation.setOptions({
				headerBackground: undefined,
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	function handleDonePress() {
		if (parentNavigation?.canGoBack()) {
			parentNavigation.goBack();
		} else {
			navigation.replace('MENU_STACK');
		}
	}

	return (
		<ScreenTemplateWithFooter
			centered
			fixedPositionFooter
			footer={
				<Button
					title={t('onboarding.confirmationScreen.completeButton')}
					onPress={handleDonePress}
				/>
			}
		>
			<View style={[constrain(200), vr(8)]}>
				<OnboardingConfirmationGraphic />
			</View>
			<Heading level="h1" align="center" style={[constrainText(320), vr(3)]} accessibilityAutoFocus>
				{t('onboarding.confirmationScreen.heading')}
			</Heading>
			<Text weight="medium" size="lg" align="center" style={vr(7)}>
				{t('onboarding.confirmationScreen.additionalText')}
			</Text>
			<OrderedListItem>
				<Text size="lg" weight="bold" style={vr(3)}>
					{t('onboarding.confirmationScreen.subHeading')}
				</Text>
			</OrderedListItem>
			<View role="list">
				{t('onboarding.confirmationScreen.orderedList', {
					returnObjects: true,
				}).map((text, i) => (
					<OrderedListItem key={i} index={i} style={vr(5)}>
						<Text weight="medium">{text}</Text>
					</OrderedListItem>
				))}
			</View>
		</ScreenTemplateWithFooter>
	);
}
