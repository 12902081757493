import { useMutation, useQuery } from '@tanstack/react-query';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import { assignSurvey, getSurvey, submitSurvey } from './surveyService';

export function useSurveyQuery(token: string) {
	return useQuery({
		queryKey: ['surveyQueryKey', token],
		queryFn: () => getSurvey(token),
	});
}

export function useSubmitSurveyMutation() {
	return useMutation({ mutationFn: submitSurvey });
}

export function useAssignQuery(surveyId: string) {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: ['assignSurveyQueryKey', surveyId],
		queryFn: () => assignSurvey(surveyId),
		enabled: isAuthenticated,
	});
}
