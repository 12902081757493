import env from '@mobe/env/env';
import { client } from '../client';

// TODO: we do not use any of this data
export interface IAuthenticationProfileData {
	avatarUrl: string;
	firstName: string;
	lastName: string;
	memberDIKey: number;
	preferredName: string;
	userId: number;
}

export interface IValidicData {
	uid: string;
	validicId: string;
	marketplaceToken: string;
	marketplaceUrl: string;
	mobileToken: string;
}

export interface IAuthenticationData {
	loginMessage: string | null;
	token: string;
	tokenExpirationTimeInSeconds: number;
}

export interface IAuthenticationResponse extends IAuthenticationData {
	profileInfo: IAuthenticationProfileData;
	validicInfo: IValidicData | null;
}

export enum LoginAPIErrorCode {
	None = 'NON_API_NONE',
	Mismatch = 'MISMATCH',
	AccountLocked = 'LOCKED_ACCOUNT',
	NeedsValidation = 'NOT_VALIDATED',
	AccountDisabledOnServer = 'CUSTOMER_END',
	PasswordExpired = 'EXPIRED_PASSWORD',
	// NOTE: the Unknown status code isn't provided by the API; it's used internally
	Unknown = 'NON_API_UNKNOWN',
}

export interface IAuthenticationError {
	remaining?: number;
	message?: string;
	data?: {
		token?: string;
		resetPasswordGuid?: string;
	};
}

export interface ILogin {
	username: string;
	password: string;
	rememberMe: boolean | null;
	enableBiometricLogin: boolean | null;
	redirectTo?: string;
	bioAuthPrompt?: () => Promise<boolean>;
}

export async function login({ username, password }: ILogin) {
	const response = await client.post('authentication/login', {
		username,
		password,
	});
	const data = response.data as IAuthenticationResponse;

	if (env.isDev) {
		console.info(`authenticationService.login(): authenticated with token [${data.token}]`);
		console.info(
			`authenticationService.login(): Validic user token [${data.validicInfo?.validicId}]`
		);
	}

	return data;
}

export enum LogoutType {
	Manual = 'ManuallyTriggeredLogout',
	SessionExpired = 'SessionExpirationLogout',
}

interface ILogout {
	logoutType?: LogoutType;
}

export async function logout({ logoutType = LogoutType.Manual }: ILogout) {
	await client.post('authentication/logout', undefined, {
		headers: {
			LogOutType: logoutType,
		},
	});
	return {};
}

interface ISetDeviceToken {
	deviceToken: string;
}

export async function setDeviceToken({ deviceToken }: ISetDeviceToken) {
	await client.put('device-tokens', {
		deviceToken,
	});
	return {};
}

export interface IVerifyCodeResponse {
	accessToken: string;
}

export enum VerifyCodeErrorCode {
	INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
}

interface ISendVerificationCode {
	phoneNumber: string;
}

export async function sendVerificationCode({ phoneNumber }: ISendVerificationCode) {
	await client.post('verification-codes', {
		phoneNumber,
	});
	return {};
}

interface IVerifyCode {
	phoneNumber: string;
	code: string;
}

export async function verifyCode({ phoneNumber, code }: IVerifyCode) {
	const response = await client.post(`verification-codes/${code}/verify`, {
		phoneNumber,
	});
	return response.data as IVerifyCodeResponse;
}
