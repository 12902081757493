import { useQuery } from '@tanstack/react-query';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import { CoachesQueryKeys, CoachType, fetchCoaches, ICoach } from './guidesApi';

export function useCoachesQuery<TSelectData = ICoach[]>(
	select?: ((data: ICoach[]) => TSelectData) | undefined,
	refetch?: boolean
) {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [CoachesQueryKeys.Coaches],
		queryFn: fetchCoaches,
		enabled: isAuthenticated,
		staleTime: refetch ? 0 : Infinity,
		select,
	});
}

export function useGuideQuery(refetch?: boolean) {
	return useCoachesQuery(
		(data) => data.find((guide) => guide.coachType !== CoachType.Pharmacist),
		refetch
	);
}

export function usePharmacistQuery(refetch?: boolean) {
	return useCoachesQuery(
		(data) => data.find((guide) => guide.coachType === CoachType.Pharmacist),
		refetch
	);
}
