import { sub } from 'date-fns';
import { uniq } from 'lodash';
import { useStorageBoolean, useStorageNumber, useStorageObject, useStorageString } from './storage';

export function useHasViewedAppIntro() {
	return useStorageBoolean('hasViewedAppIntro', false);
}

export function useLoginCount() {
	return useStorageNumber('loginCount', 0);
}

export function useHasSeenBioAuthPrompt() {
	return useStorageBoolean('hasSeenBioAuthPrompt', false);
}

export function useHasSeenEducationalNotice() {
	return useStorageBoolean('hasSeenEducationalNotice', false);
}

export function useHasSeenWelcomeVideo() {
	return useStorageBoolean('hasSeenWelcomeVideo', false);
}

export function useHasDismissedGuideMatch() {
	return useStorageBoolean('hasDismissedGuideMatch', false);
}

export function useHasDismissedPharmacistMatch() {
	return useStorageBoolean('hasDismissedPharmacistMatch', false);
}

export function useHasDismissedTrackerCTAFromHome() {
	return useStorageBoolean('hasDismissedTrackerCTAFromHome', false);
}

export function useHasDismissedTrackerCTAFromTrack() {
	return useStorageBoolean('hasDismissedTrackerCTAFromTrack', false);
}

export function useHasDismissedTrackerDataLagMessage() {
	return useStorageBoolean('hasDismissedTrackerDataLagMessage', false);
}

export function useLoginAlertDismissedUntilDate(): [Date, (date: Date) => void] {
	const [dismissedUntilDateString, setDismissedUntilDateString] = useStorageString(
		'loginAlertDismissedUntilDateString',
		sub(Date.now(), { weeks: 1 }).toString()
	);

	function setDismissedUntilDate(date: Date) {
		setDismissedUntilDateString(date.toString());
	}

	return [new Date(dismissedUntilDateString), setDismissedUntilDate];
}

export function useDismissedContentModuleIds(): [number[], (value: number) => void] {
	const [dismissedContentModuleIds, setDismissedContentModuleIds] = useStorageObject<number[]>(
		'dismissedContentModuleIds',
		[]
	);

	function addDismissedContentModuleId(value: number) {
		setDismissedContentModuleIds(
			dismissedContentModuleIds.length ? uniq([...dismissedContentModuleIds, value]) : [value]
		);
	}

	return [dismissedContentModuleIds, addDismissedContentModuleId];
}

export function useHasDismissedVisionCard() {
	return useStorageBoolean('hasDismissedVisionImage', false);
}
