import { useTrackersAddEntryMutation } from '@mobe/api/track/trackApiHooks';
import { useGetTrackerIdFromAbbreviation } from '@mobe/api/track/trackApiUtils';
import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import useTrackerContent from '@mobe/features/track/useTrackerContent';
import { addHours, startOfDay, subDays } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	OnboardingStepsScreenNavigationProp,
	OnboardingStepsScreenRouteProp,
} from '../OnboardingStackScreen';
import OnboardingScreenTemplate from '../components/OnboardingScreenTemplate';
import useNavigateToNextScreen from '../useNavigateToNextScreen';

interface IOnboardingStepsScreenProps {
	navigation: OnboardingStepsScreenNavigationProp;
	route: OnboardingStepsScreenRouteProp;
}

export default function OnboardingMedicationScreen({ route }: IOnboardingStepsScreenProps) {
	const { t } = useTranslation();
	const navigateToNextScreen = useNavigateToNextScreen(route.name);
	const trackerContent = useTrackerContent()[TrackerAbbreviation.Steps];
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const addEntry = useTrackersAddEntryMutation();
	const { form, renderForm } = trackerContent.useForm({
		tracker: trackerContent,
		// Noon on previous day
		staticDate: addHours(startOfDay(subDays(new Date(Date.now()), 1)), 12),
		customInputLabel: t('onboarding.questionLabels.steps'),
		onKeyboardSubmit: handleSubmit,
		isRequired: false,
	});

	function handleSubmit() {
		form.handleSubmit((data) => {
			if (!data.tracker) {
				navigateToNextScreen();
				return;
			}

			addEntry.mutate(
				{
					value: data.tracker,
					activityDateTime: data.date.toISOString(),
					trackerTypeId: getTrackerIdFromAbbreviation(TrackerAbbreviation.Steps),
				},
				{
					onSuccess: navigateToNextScreen,
				}
			);
		})();
	}

	return (
		<OnboardingScreenTemplate
			routeName={route.name}
			graphic={trackerContent.graphic}
			title={trackerContent.title}
			isLoading={addEntry.isPending}
			onNextPress={handleSubmit}
		>
			{renderForm()}
		</OnboardingScreenTemplate>
	);
}
