import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { Platform, StyleSheet } from 'react-native';

interface IBaseInputStyles {
	hasError?: boolean;
	hasFocus?: boolean;
	disabled?: boolean;
	autoHeight?: boolean;
	isSecureText?: boolean;
}

export default function useBaseInputStyles({
	hasError = false,
	hasFocus = false,
	disabled = false,
	autoHeight = false,
	isSecureText = false,
}: IBaseInputStyles = {}) {
	const rules = useStyleRules();
	const autoHeightLineHeight = rules.spacing.baseline * 6 * rules.settings.fontScale;

	const styles = StyleSheet.create({
		inputWrapper: {
			flexDirection: 'row',
			alignItems: 'center',
			height: Math.max(44, rules.fontSizes.lg * 2),
			borderColor: hasError
				? rules.colors.error
				: hasFocus
				? rules.colors.primary
				: rules.colors.stroke,
			borderWidth: 1,
			borderRadius: 5,
			backgroundColor: rules.colors.fieldBackground,

			...(autoHeight && {
				height: 'auto',
				minHeight: 44 * rules.settings.fontScale,
				maxHeight: autoHeightLineHeight * 5,
			}),

			...(disabled && {
				backgroundColor: rules.colors.fieldDisabledBackground,
				borderColor: rules.colors.fieldDisabledBackground,
			}),
		},
		input: {
			flexGrow: 1,
			flexShrink: 1,
			minHeight: 50, // seemingly arbitrary minHeight ensures you can't vertically scroll single line inputs
			paddingHorizontal: 10,
			color: hasError ? rules.colors.error : hasFocus ? rules.colors.text : rules.colors.text,
			fontSize: rules.fontSizes.lg,

			// use device default font for secure text entry
			...(!isSecureText && {
				fontFamily: rules.fontFamilies.sans,
			}),

			...(autoHeight && {
				lineHeight: autoHeightLineHeight,
				minHeight: autoHeightLineHeight,
				paddingVertical: 5,
			}),
		},
		inputLeft: {
			marginLeft: 10,
			flexShrink: 0,
		},
		inputRight: {
			paddingRight: 10,
			flexShrink: 0,

			// Make label sit in correct spot on web
			...(Platform.OS === 'web' && {
				position: 'absolute',
				right: 0,
			}),
		},
	});

	return styles;
}
