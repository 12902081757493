import { useQuery } from '@tanstack/react-query';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import { getProgress } from './progressService';

export enum ProgressQueryKeys {
	AllProgress = 'progress.allProgress',
}

export function useProgressQuery() {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [ProgressQueryKeys.AllProgress],
		queryFn: getProgress,
		enabled: isAuthenticated,
	});
}
