import { useSecureStoreMutation, useSecureStoreQuery } from '../secureStorage';
import { useStorageBoolean } from './storage';

const SECURE_USERNAME_QUERY_KEY = 'authentication.credentials.username';

export function useSecureUsernameQuery() {
	return useSecureStoreQuery<string>(SECURE_USERNAME_QUERY_KEY);
}

export function useSecureUsernameMutation() {
	return useSecureStoreMutation<string>(SECURE_USERNAME_QUERY_KEY);
}

const SECURE_PASSWORD_QUERY_KEY = 'authentication.credentials.password';

export function useSecurePasswordQuery() {
	return useSecureStoreQuery<string>(SECURE_PASSWORD_QUERY_KEY);
}

export function useSecurePasswordMutation() {
	return useSecureStoreMutation<string>(SECURE_PASSWORD_QUERY_KEY);
}

type UpdateSavedLoginCredentials = {
	username: string | null;
	password: string | null;
	rememberMe: boolean | null;
};

export function useLoginOptions() {
	const usernameQuery = useSecureUsernameQuery();
	const usernameMutation = useSecureUsernameMutation();
	const passwordQuery = useSecurePasswordQuery();
	const passwordMutation = useSecurePasswordMutation();
	const [enableBiometricLogin, setEnableBiometricLogin] = useStorageBoolean(
		'enableBiometricLogin',
		false
	);
	const [rememberMe, setRememberMe] = useStorageBoolean('rememberMe', false);

	function toggleEnableBiometricLogin() {
		// Automatically turn on rememberMe when bio auth is toggled on
		if (!rememberMe && !enableBiometricLogin) {
			setRememberMe(true);
		}
		setEnableBiometricLogin(!enableBiometricLogin);
	}

	function toggleRememberMe() {
		// Automatically turn off bio auth when remember me is toggled off
		if (rememberMe && enableBiometricLogin) {
			setEnableBiometricLogin(false);
		}
		setRememberMe(!rememberMe);
	}

	function updateSavedLoginCredentials({
		username,
		password,
		rememberMe,
	}: UpdateSavedLoginCredentials) {
		if (rememberMe === true) {
			usernameMutation.mutate({ value: username ? username : '' });
			passwordMutation.mutate({ value: password ? password : '' });
		}

		if (rememberMe === false) {
			usernameMutation.mutate({ value: '' });
			passwordMutation.mutate({ value: '' });
		}
	}

	function resetSavedLoginCredentials() {
		usernameMutation.mutate({ value: '' });
		passwordMutation.mutate({ value: '' });
		setEnableBiometricLogin(false);
		setRememberMe(false);
	}

	return {
		savedLoginCredentials: {
			username: usernameQuery.data,
			password: passwordQuery.data,
		},
		updateSavedLoginCredentials,
		resetSavedLoginCredentials,

		enableBiometricLogin,
		setEnableBiometricLogin,
		toggleEnableBiometricLogin,

		rememberMe,
		setRememberMe,
		toggleRememberMe,
	};
}
